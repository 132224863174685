import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from "@mui/styles";
import SvgIcon from "@mui/material/SvgIcon";

const useStyles = makeStyles((theme) => ({
  nav: {
    bottom: " 0px !important ",
    "  & .MuiBottomNavigation-root": {
      backgroundColor: "#282828",
      opacity: "1 !important",
      height: "77px  !important",
    },
    "&  .MuiBottomNavigationAction-root ,.MuiBottomNavigationAction-iconOnly": {
      color: "#3C4251 !important",
      marginBottom:"0.75rem !important"
    },
    "&  .MuiBottomNavigationAction-label": {
      color: "#767677 !important",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "19px !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
    },
    "&  .Mui-selected ,.MuiBottomNavigationAction-iconOnly": {
      color: "#AB97EB !important ",
      fontWeight: "600 !important",
    },
    "& .Mui-selected  .MuiBottomNavigationAction-label": {
      color: "#fff !important ",
      fontSize: "12px !important",
      fontWeight: "600 !important",
      lineHeight: "19px !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
    },
    borderRadius: "0px !important",
    borderTop: "1px solid #5F6060 !important",
    minHeight: "77px",
    backgroundColor: " #282828 !important"

  },
}));

const BottomNavigationBar = (props) => {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const classes = useStyles();

  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);
  function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M17.5 16V7.5C17.5 7.18524 17.3518 6.88885 17.1 6.7L10.1 1.45C9.74444 1.18333 9.25556 1.18333 8.9 1.45L1.9 6.7C1.64819 6.88885 1.5 7.18524 1.5 7.5V16C1.5 16.5523 1.94772 17 2.5 17H16.5C17.0523 17 17.5 16.5523 17.5 16Z" />
      </SvgIcon>
    );
  }
  function MoneyIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M1.5 12C1.5 5.92487 6.42487 1 12.5 1C18.5751 1 23.5 5.92487 23.5 12C23.5 18.0751 18.5751 23 12.5 23C6.42487 23 1.5 18.0751 1.5 12ZM8.5 6C7.94772 6 7.5 6.44772 7.5 7C7.5 7.55228 7.94772 8 8.5 8H11.4999C11.8399 8 12.3721 8.11022 12.7906 8.41153C12.9798 8.54775 13.1622 8.73303 13.2946 9H8.49695C7.94466 9 7.49695 9.44772 7.49695 10C7.49695 10.5523 7.94466 11 8.49695 11H13.2946C13.1622 11.267 12.9798 11.4523 12.7906 11.5885C12.3721 11.8898 11.8399 12 11.4999 12H8.49695C8.07221 12 7.69381 12.2683 7.55327 12.6691C7.41274 13.0699 7.54073 13.5158 7.87248 13.781L12.8754 17.781C13.3068 18.1259 13.936 18.0558 14.2809 17.6245C14.6258 17.1931 14.5557 16.5638 14.1243 16.219L11.349 14H11.4999C12.16 14 13.1277 13.8102 13.9592 13.2115C14.6338 12.7258 15.1774 11.9977 15.396 11H16.4999C17.0522 11 17.4999 10.5523 17.4999 10C17.4999 9.44772 17.0522 9 16.4999 9H15.396C15.315 8.63006 15.1892 8.29719 15.0287 8H16.5C17.0523 8 17.5 7.55228 17.5 7C17.5 6.44772 17.0523 6 16.5 6H11.4999H8.5Z" />
      </SvgIcon>
    );
  }

  function UserIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 11A5 5 0 1 0 9 1a5 5 0 0 0 0 10Zm0 0a8 8 0 0 1 8 8H1a8 8 0 0 1 8-8Z" /><path d="M17 19v1a1 1 0 0 0 1-1h-1ZM1 19H0a1 1 0 0 0 1 1v-1ZM13 6a4 4 0 0 1-4 4v2a6 6 0 0 0 6-6h-2ZM9 2a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6v2ZM5 6a4 4 0 0 1 4-4V0a6 6 0 0 0-6 6h2Zm4 4a4 4 0 0 1-4-4H3a6 6 0 0 0 6 6v-2Zm9 9a9 9 0 0 0-9-9v2a7 7 0 0 1 7 7h2ZM1 20h16v-2H1v2Zm8-10a9 9 0 0 0-9 9h2a7 7 0 0 1 7-7v-2Z" />
      </SvgIcon>
    );
  }
  return (
    <Toolbar style={{ minHeight: "77px" ,zIndex:1}}>
      <Paper
        ref={ref}
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
        }}
        elevation={3}
        className={classes.nav}
      >
        <BottomNavigation
          showLabels
          value={props.activeTab}
        >
          <BottomNavigationAction to="/home" component={Link} label="Home" icon={<HomeIcon style={{ marginLeft: "5px" }} />} />
          <BottomNavigationAction to="/holdings" component={Link} label="Portfolio" icon={<MoneyIcon style={{ marginBottom: "3px" }} />} />
          <BottomNavigationAction to="/profile" component={Link} label="Profile" icon={<UserIcon style={{ marginLeft: "5px" }} />} />

        </BottomNavigation>
      </Paper>
    </Toolbar>
  );
};
export default BottomNavigationBar;
