import React from 'react';

const Upload = (({theme})=>{
  return(
 
<svg width="20" height="10" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3309 4.88134C12.5914 1.93547 9.60389 0.146854 6.65803 0.886336C4.3559 1.46424 2.6843 3.45393 2.51207 5.8212C0.87747 6.09077 -0.229127 7.63438 0.0404372 9.26898C0.280067 10.7222 1.53924 11.7864 3.01202 11.7806H5.51174V10.7807H3.01202C1.90758 10.7807 1.01224 9.88532 1.01224 8.78088C1.01224 7.67644 1.90758 6.7811 3.01202 6.7811C3.28814 6.7811 3.51196 6.55728 3.51196 6.28115C3.50946 3.79614 5.52196 1.77961 8.00697 1.77714C10.1581 1.77499 12.0099 3.29576 12.426 5.40625C12.4671 5.61697 12.6381 5.77796 12.8509 5.8062C14.2177 6.00084 15.1679 7.26661 14.9733 8.63336C14.7985 9.86067 13.7507 10.7745 12.511 10.7807H10.5112V11.7806H12.511C14.4438 11.7747 16.0058 10.2031 16 8.27034C15.9951 6.66145 14.8938 5.26314 13.3309 4.88134Z" fill={theme ? "#AC93FF" :"#DD4A47"}/>
<path d="M7.65652 6.92609L5.65674 8.92587L6.36166 9.6308L7.51154 8.48592V13.2804H8.51143V8.48592L9.6563 9.6308L10.3612 8.92587L8.36144 6.92609C8.16643 6.73224 7.85153 6.73224 7.65652 6.92609Z" fill={theme ? "#AC93FF" :"#DD4A47"}/>
</svg>

      );
})

export default Upload;