import React, { useState,useEffect } from "react";
import { Grid, Typography, Box, Button, Input, TextField } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import upload from "../../Images/upload.svg";
import clear from "../../Images/clear.svg";
import HelperText from "../../component/DarkMode/HelperText";
import BottomNavigation from "../../component/Home/BottomNavigation";
import Compressor from "compressorjs";
import { connect } from "react-redux";
import VerifyButton from "../../component/Kyc/VerifyButton"
import { kycService } from "../../_services/kyc";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0px 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",

    paddingTop: "16px",
  },
  icon: {
    fill: "#fff  !important",
    marginRight: "20px",
    cursor: "pointer",
    marginLeft: "0px",
  },

  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },

  button: {
    // padding: "12px px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    // textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
    "&:disabled": {
      color: "#6E58B0 !important",
      backgroundColor: "#3B3159 !important",
    },
  },
  bottomGrid: {
    padding: "0px 16px",
    position: "absolute",
    bottom: "77px",
    left: "50%",
    transform: "translate(-50%, -0px)",
    width: "100%",
    textAlign: " center",
    display: "inline-grid",
    backgroundColor: "#1C1C1C",
  },

  textfield: {
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      position: "relative",
      width: "100%",
      padding: "10px 60px 10px 16px",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      color: " #5F6060",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400 !important",
      fontSize: "14px  !important",
    },
    margin: "5px 0px 15px 0px !important ",
  },
  subtext: {
    paddingTop: "10px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "16px !important",
  },
  label: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
    marginTop:"34px !important"
  },
  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  Btn: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "12px !important",
    // background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  32px 12px !important",
    textAlign: "center!important ",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient( #583EAC ,#DD4A47), linear-gradient(1800deg, #583EAC 0%,  #DD4A47 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  },
  BtnTwo: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "12px !important",
    background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  32px 12px !important",
    textAlign: "center!important ",
    borderRadius: "8px !important",
    backgroundImage: "repeating-linear-gradient(0deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(90deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(180deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(270deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px)!important",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100% !important",
    backgroundSize: "1.5px 100%, 100% 1.5px, 1.6px 100% , 100% 1.5px !important",
    backgroundRepeat: "no-repeat!important",
   
  },

  confirmbutton: {
    // padding:"12px 100px  !important ",
    height: "54px  !important",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },
}));

const UploadAadhar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("error");
  const [aadhar, setAadhar] = useState(true);
  const [fileChoosen, setFilechoosen] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [submitButton, setSubmitButton] = useState(true);
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;
  const [nameInvalid, setNameInvalid] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

useEffect(()=>{
  getCameraPermission()
},[])


  const chooseFile = (event) => {
    setNameInvalid(false)
    setAadhar(true)
    const image = event.target.files[0];
    const type = event.target.files[0].type;
    if (type === "application/pdf" || type === "image/png" || type === "image/jpeg") {
      if (type != "application/pdf") {
        new Compressor(image, {
          quality: 0.6,
          success: (res) => {
            setFilechoosen(res);
            setSubmitButton(false);

          },
        })
      } else {
        setFilechoosen(image);
        setSubmitButton(false);
      }
    } else {
      setAadhar(false)
      setSubmitButton(true);
      setHelperText("Unsupported format")
   
    }
    setSelectedFileName(event.target.files[0].name)

  }

const getCameraPermission=()=>{
  if(window.ReactNativeWebView != undefined && (getBrowser(window)=='app' || getBrowser(window)=='Google Chrome')){
    let data = {
      checkCameraPermission:"true" 
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
    console.log("post message sent")
  }else{
    console.log("post message not sent")
  }
}

function getBrowser(window) {
  let currentBrowser = 'app';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
  else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
  else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
  else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
  else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
  else { console.log('Others'); }
  return currentBrowser;
}

  // update aadhar details
  const uploadFile = async() => {
    if (fileChoosen != undefined) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("kyc_copy", fileChoosen);
      formData.append("userid", userID);
     await  kycService.uploadaadhar(formData).then((res) => {
      if (res && res.data) {
        setIsLoading(false);
        if(res.data?.isNameMismatch==false){

          console.log(res)
          if (res.status === 200) {
            if(res.data.success){
              navigate("/uploadpan", {
                    replace: true,
                    state: { id:userID},
                  });
            }else{
              setAadhar(false)
              setHelperText("Invalid details")
              setSubmitButton(true);
            }
          }else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }else if(res.data.isNameMismatch){
          setNameInvalid(true)
        }else{
          setAadhar(false)
          setHelperText("Invalid details")
          setSubmitButton(true); 
        }
      }else{
        setAadhar(false)
        setHelperText("Invalid details")
        setSubmitButton(true); 
      }
    })
    }
  }

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Verify Aadhaar
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid
              className={classes.container}
              pt={0}
              container
              flexDirection="column"
            >
              <Typography
                className={classes.label}
              >
                Upload Aadhaar Card
              </Typography>
              <Grid className={aadhar ? classes.BtnTwo : classes.Btn}>


                <label htmlFor="Upload">
                  <Input
                    style={{ display: "none" }}
                    fullWidth
                    type="file"

                    inputProps={{ accept: "application/pdf,image/png, image/jpeg" }}
                    name="Upload"
                    id="Upload"
                    onChange={chooseFile}
                  />

                  <Button
                    startIcon={<img src={upload} />}
                    component="span"
                    className={classes.button}
                    style={{
                      textTransform: selectedFileName ? "none" : "uppercase",
                      fontSize: "12px", height: "34px", width: "168px"
                    }}
                    variant="contained"
                  disableRipple
                    endIcon={ aadhar?"":<img src={clear}  onClick={chooseFile} />}
                 
                  >
                   <span style={selectedFileName &&{
                          whiteSpace:"nowrap", 
                          overflow:"hidden", 
                          textOverflow:"ellipsis"
                       }}> {selectedFileName ? selectedFileName : "Upload Image"}</span>
                  </Button>
                </label>

                <Typography className={classes.subtext}>
                  Supported file formats : JPG, PNG, & PDF{" "}
                </Typography>
              </Grid>
            </Grid>
            {aadhar ? "" : <HelperText helperText={helperText} type={type} />}



           {nameInvalid?(
              <Grid className={classes.bottomGrid} pb={5}>
              <Typography
              mb={4}
                style={{
                  margin:"24px auto 16px",
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
              Please update your name
              </Typography>
              <Button
            
                disableRipple
                className={classes.confirmbutton}
                fullWidth
                variant="contained"
                onClick={()=>{
                  navigate('/updateDetails', {
                  replace: true,
                  state:{
                    fromLocation:"aadhar"
                  } 
                    })
               }}
                style={isLoading ?{ background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                backgroundRepeat:"repeat-x",
                backgroundSize:"400% 100%",
                WebkitAnimation:"AnimationName 6s linear infinite",
                MozAnimation:"AnimationName 6s linear infinite",
                animation:"AnimationName 6s linear infinite"}: {}}
              >
               Update
              </Button>
            
            
            </Grid>
            ):( 
            <Grid item xs={12} md={12} className={classes.bottom}  >
              < VerifyButton submitButton={submitButton || isLoading} text={" Verify"} onClick={uploadFile} isLoading={isLoading} />
              </Grid>
              )
           }
             
             
          </Grid>
        </Box>
        <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        <BottomNavigation />
      </Page>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
 

export default connect(mapStateToProps)(UploadAadhar);