import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  MenuItem,
  createFilterOptions,
  Autocomplete,
  FormControl,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { MuiThemeProvider } from "@mui/material/styles";
import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { RegisterService } from "../../_services/register";
import { loginService } from "../../_services/login";
import rawCountries from "../../Assets/country";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../component/DarkMode/Header";
import Notification from "../../component/AlertModel/Notification";
import HelperText from "../../component/DarkMode/HelperText";
import { math } from "@amcharts/amcharts5";
import { userService } from "../../_services/users";
import Backdrop from "@mui/material/Backdrop";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth";

import { initializeApp, getApps } from "firebase/app";
// import jwt from 'jsonwebtoken';
import DisableRightClick from "../../_helpers/DisableRightClick";

const secretKey = "unlistedsecratetestingkey";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
    position: "relative",
  },
  textfield: {
    autocomplete: "off !important",
    color: "#fff !important",
    marginTop: "1rem !important",
    width: "6rem !important",
    height: "60px !important",
    paddingleft: "25%!important",
    borderRadius: "10px !important",
    marginRight: "1rem !important",
    fontSize: "16px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box",
    boxShadow: "2px 1000px 1px #0D0D0D inset",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      padding: "7.5px 0px 7.5px 0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: "#AB97EB ",
      marginRight: "15px !important",
    },

    "& .MuiAutocomplete-clearIndicator": {
      color: "#AB97EB ",
    },
    "	&.MuiAutocomplete-paper": {
      width: "600px !important",
    },
    "&.MuiAutocomplete-noOptions": {
      color: "#ffffff !important",
      margin: "2rem !important",
    },
  },

  paper: {
    borderRadius: "10px !important",
    marginTop: "1rem !important",
    width: "155px",
    maxHeight: "192px",
    background: "#0d0d0d !important",
    color: "white !important",
    boxShadow: "0 0 6px 0 rgba(157, 96, 212, 0.5) !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset !important",
    overflow: "hidden !important",
  },

  verifyText: {
    color: "white",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
  },
  loader: {
    color: "white !important",
    width: "25px !important",
    height: "25px !important",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  helpertextContent: {
    margin: "8px 0 0 4px !important",
    height: "16px",
    left: "50px",
    top: "217px",
    color: "#fff",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
  },
  box: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "flex-start !important",
    position: "absolute !important",
    margin: "1rem auto !important",
    bottom: "103px !important",
    transform: "translate(-50%, 0%)  !important",
    left: "50%  !important",
    backgroundColor: "#1C1C1C !important",
    width: "calc(100% - 36px)",
    borderRadius: "10px",
  },
  radioTitle: {
    padding: "4px",
    color: "#fff",
    left: "16px",
    top: "135px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  radioSubTitle: {
    padding: "4px",
    color: "#A489FF",
    left: "16px",
    top: "135px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
  radioIcon: {
    color: "#A489FF !important",
    "&.Mui-checked": {
      color: "#A489FF !important",
    },
  },
}));

const theme = createTheme({
  components: {
    // Name of the component
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          // Some CSS
          color: "#737373",
        },
      },
    },
  },
});
const SignupPhone = () => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState(location.state.message);
  const [severity, setSeverity] = React.useState(null);
  const [submit, setSubmit] = React.useState(false);
  const phoneRegex = RegExp(/^[0-9]{12}$/);
  const [mobileNoLength, setMobileNoLength] = useState(10);
  const [changeEmail, setChangeEmail] = useState(false);
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("");
  const [initialValues, setInitialValues] = useState({
    mobileNumber: location?.state?.phone ? location?.state.phone : "",
    ccode: location?.state?.phone ? location?.state.ccode : "91",
    oldUser: "",
  });
  const [createdUserid, setCreatedUserId] = useState(location?.state.userId);

  const [helperText, setHelperText] = useState();
  const [email, setEmail] = useState(null);
  const [valueColor, setValueColor] = useState();
  const navigate = useNavigate();

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      option.code + option.countryCode + option.countryName,
  });
  let auth;
  async function initializeFirebae() {
    try {
      let app;
      let firebaseEnv = process.env.REACT_APP_ADMIN_CLIENT.replace(/\\/g, "");
      let firebaseEnvFormated = firebaseEnv.replace(/""/g, "");
      let firebaseEnvFormatedObject = JSON.parse(firebaseEnvFormated);
      if (getApps().length === 0) {
        app = initializeApp(firebaseEnvFormatedObject.REACT_APP_ADMIN_CLIENT);
      }
      auth = getAuth(app);
    } catch (error) {
      console.log(error);
    }
  }
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };
  const handleOnSubmit = async (values) => {
    await initializeFirebae();
    setSubmit(true);
    if (location.state.type === "register" && values.oldUser === "") {
      loginService
        .checkMobileExists(`+${values.ccode}${values.mobileNumber}`)
        .then((res) => {
          if (res) {
            if (res.data?.message === "Successfully Found Data") {
              setSeverity("error");
              setValueColor(2);
              let email = res.data.data.email.replace(
                /^(.{2})[^@]+/,
                "$1xxxxxx"
              );
              setSubmit(false);
              // setSnackbarTitle("Mobile number already registered");
              setHelperText("Phone number in use with");
              setEmail(email);
              setCode(res.data.data.code);
              const emailUpdate = res.data.data.can_email_update ? false : true;
              setUserId(res.data.data.user_id);
              setChangeEmail(emailUpdate);
              setInitialValues({
                mobileNumber: values.mobileNumber,
                ccode: values.ccode,
                oldUser: "1",
              });
            } else if (res.data?.message === "NEW_USER") {
              RegisterService.registerMobileNumber(
                `+${values.ccode}${values.mobileNumber}`
              )
                .then((res) => {
                  if (res) {
                    if (res.status === 200) {
                      if (res.status === 200) {
                        generateRecaptcha();
                        let appVerifier = window.recaptchaVerifier;
                        signInWithPhoneNumber(
                          auth,
                          `+${values.ccode}${values.mobileNumber}`,
                          appVerifier
                        )
                          .then((confirmationResult) => {
                            window.confirmationResult = confirmationResult;
                            setSubmit(false);
                            navigate("/otpphone", {
                              replace: true,
                              state: {
                                mobile: `+${
                                  values.ccode + values.mobileNumber
                                }`,
                                type: "register",
                                email:
                                  location != undefined
                                    ? location.state != undefined
                                      ? location.state.email
                                      : null
                                    : null,
                                userId:
                                  location != undefined
                                    ? location.state != undefined
                                      ? location.state.userId
                                      : null
                                    : null,
                                phone: values.mobileNumber,
                                ccode: values.ccode,
                              },
                            });
                          })
                          .catch((error) => {
                            // Error; SMS not sent
                            console.log(error);
                          });
                      }
                    } else if (res.status === 401) {
                      setSeverity("error");
                      setValueColor(2);
                      setSubmit(false);
                      let email = res.data.data.email.replace(
                        /^(.{2})[^@]+/,
                        "$1xxxxxx"
                      );
                      setSnackbarTitle("Mobile number already registered");
                      setHelperText("Phone number in use with");
                      setEmail(email);
                      setInitialValues({
                        mobileNumber: values.mobileNumber,
                        ccode: values.ccode,
                        oldUser: "1",
                      });
                      // setOpen(true);
                    } else if (res.status === 400) {
                      setSeverity("error");
                      setSubmit(false);
                      setSnackbarTitle("Invalid number");

                      setOpen(true);
                    } else if (res.status === 204) {
                      setSubmit(false);
                      setSeverity("error");
                      setSnackbarTitle("Mobile number is required");
                      setOpen(true);
                    } else if (res.status === 500) {
                      setSubmit(false);
                      setSeverity("error");
                      setSnackbarTitle("Something went wrong");
                      setOpen(true);
                    } else {
                      setSubmit(false);
                      setSeverity("error");
                      setSnackbarTitle("Something went wrong");
                      setOpen(true);
                    }
                  }
                })
                .catch(() => {
                  setSubmit(false);
                  setSeverity("error");
                  setSnackbarTitle("Something went wrong");
                  setOpen(true);
                });
            } else {
              setSubmit(false);
              setSeverity("error");
              setSnackbarTitle("Something went wrong");
              setOpen(true);
            }
          }
        });
    } else {
      loginService
        .mobileAuthentication(`+${values.ccode}${values.mobileNumber}`)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              generateRecaptcha();
              let appVerifier = window.recaptchaVerifier;
              signInWithPhoneNumber(
                auth,
                `+${values.ccode}${values.mobileNumber}`,
                appVerifier
              )
                .then((confirmationResult) => {
                  window.confirmationResult = confirmationResult;
                  setSubmit(false);
                  navigate("/otpphone", {
                    replace: true,
                    state: {
                      mobile: `+${values.ccode}${values.mobileNumber}`,
                      type: "login",
                      oldUser: values.oldUser === "0" ? true : false,
                      email: location?.state?.email,
                      userId: userId,
                      code: code,
                      phone: values.mobileNumber,
                      ccode: values.ccode,
                    },
                    type: "login",
                  });
                })
                .catch((error) => {
                  // Error; SMS not sent
                  console.log(error);
                });
            } else if (res.status === 401) {
              if (res.data === "REQUIRED_REGISTRATION") {
                setSeverity("error");
                setSnackbarTitle("Please enter a registered phone number");
                setSubmit(false);
                setOpen(true);
              } else {
                if (res.data.message === "ACCOUNT_BLOCKED") {
                  setSeverity("error");
                  let timerMsg =
                    "Account blocked, please try after " +
                    Math.abs(res.data.time.hour) +
                    " hours " +
                    Math.abs(res.data.time.minute) +
                    " minutes";
                  setSnackbarTitle(timerMsg);
                  setSubmit(false);
                  setOpen(true);
                }
              }
            } else if (res.status === 400) {
              setSeverity("error");
              setSnackbarTitle("Invalid number");
              setSubmit(false);
              setOpen(true);
            } else if (res.status === 204) {
              setSubmit(false);
              setSeverity("error");
              setSnackbarTitle("Mobile number is required");
              setOpen(true);
            } else if (res.status === 403) {
              setSubmit(false);
              setSeverity("error");
              setSnackbarTitle("You don't have access to login");
              setOpen(true);
            } else {
              setSubmit(false);
              setSeverity("error");
              setSnackbarTitle("Network error, please try later");
              setOpen(true);
            }
          } else {
            setSubmit(false);
            setSeverity("error");
            setSnackbarTitle("Network error, please try later");
            setOpen(true);
          }
        })
        .catch(() => {
          setSubmit(false);
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
        });
    }
  };

  useEffect(() => {
    if (location.state.message) {
      setOpen(true);
    }
  }, [snackbarTitle]);

  const getMoblengthbyCode = (value) => {
    if (value.mobileNoLength == undefined) {
      return 15;
    } else {
      return value.mobileNoLength;
    }
  };

  const newEmail = location?.state?.email?.replace(/^(.{2})[^@]+/, "$1xxxxxx");
  React.useEffect(() => {
    document.addEventListener("fcmMessage", async function (e) {
      if (e.data.fcmToken) {
        let data = {
          fcm_token: e.data.fcmToken,
          deviceType: e.data.deviceType,
          userId: createdUserid,
        };
        await userService.updateFcmToken(data);
      } else {
        console.log("no msg");
      }
    });
    return () => document.removeEventListener("fcmMessage", function (e) {});
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView != undefined) {
      let data = {
        getFcmToken: true,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      console.log("post message sent");
    } else {
      console.log("no msg");
    }
  }, []);

  return (
    <>
      <Page>
        <DisableRightClick>
          <Box className={classes.boxcontainer}>
            <Notification
              snackbarTitle={snackbarTitle}
              open={open}
              setOpen={setOpen}
              type={severity}
            />
            <Header />
            <Container>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  mobileNumber: Yup.string()
                    .nullable()
                    .matches(phoneRegex, "Enter a valid Mobile Number")
                    .required("Mobile Number is required"),
                })}
              >
                {({
                  errors,
                  setFieldValue,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container className={classes.container}>
                      <Grid container>
                        <Typography className={classes.verifyText}>
                          Enter your phone number
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} style={{ display: "flex" }}>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              disableClearable
                              className={classes.textfield}
                              style={{
                                "&.MuiAutocomplete-noOptions": {
                                  color: "#fff !important",
                                },
                              }}
                              onChange={(event, newValue) => {
                                setFieldValue("mobileNumber", "");
                                setMobileNoLength(getMoblengthbyCode(newValue));
                                setFieldValue("ccode", newValue.countryCode);
                              }}
                              defaultValue={rawCountries.find((e) => {
                                return e.countryCode == values.ccode;
                              })}
                              blurOnSelect
                              classes={{ paper: classes.paper }}
                              name="ccode"
                              id="countryCode"
                              sx={{ width: 180 }}
                              options={rawCountries}
                              autoHighlight
                              filterOptions={filterOptions}
                              getOptionLabel={(option) =>
                                `${option.code.toUpperCase()} +${
                                  option.countryCode
                                } `
                              }
                              renderOption={(props, option) => (
                                <>
                                  <Box
                                    component="li"
                                    {...props}
                                    style={{
                                      justifyContent: "center !important",
                                      padding: 0,
                                      width: "auto",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "-6px  1rem",
                                      overflow: "hidden !important",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textTransform: "uppercase",
                                        fontFamily: "Rubik",
                                        fontWeight: "600",
                                        margin: 0,
                                        display: "flex",
                                        fontSize: "16px",
                                        justifyContent: "center",
                                        alignItem: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginRight: "6px",
                                          fontSize: "10px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {option.emoji}
                                      </span>{" "}
                                      {option.code}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        pl: "25px ",
                                        fontFamily: "Rubik",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {" "}
                                      + {option.countryCode}
                                    </Typography>
                                  </Box>
                                  <hr
                                    style={{
                                      width: "80%",
                                      backgroundColor: "#262626",
                                      border: "none",
                                      height: "1px",
                                      margin: "-3px auto",
                                    }}
                                  ></hr>
                                </>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    minWidth: "115px",
                                  }}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: {
                                      color: "white",
                                      fontSize: 14,
                                      textAlign: "center",
                                      maxWidth: "60%",
                                      paddingRight: "0",
                                      fontFamily: "Rubik",
                                      marginTop: "3px",
                                      textTransform: "uppercase",
                                    },
                                  }}
                                />
                              )}
                            />
                          </ThemeProvider>
                          <div style={{ width: "100%" }}>
                            <Textfield
                              type="tel"
                              inputmode="decimal"
                              id="mobileNumber"
                              name="mobileNumber"
                              value={values.mobileNumber}
                              onInput={(e) => {
                                const maxLength =
                                  (mobileNoLength != undefined &&
                                    mobileNoLength != "undefined") ||
                                  (mobileNoLength != null &&
                                    mobileNoLength != "null")
                                    ? parseInt(mobileNoLength)
                                    : 15;
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, maxLength)
                                  .replace(/[^0-9]/g, "");
                              }}
                              inputProps={{
                                maxLength:
                                  (mobileNoLength != undefined &&
                                    mobileNoLength != "undefined") ||
                                  (mobileNoLength != null &&
                                    mobileNoLength != "null")
                                    ? parseInt(mobileNoLength)
                                    : 15,
                              }}
                              onChange={(e) => {
                                setOpen(false);
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setFieldValue("mobileNumber", e.target.value);
                                  setFieldValue("oldUser", "");
                                  setHelperText("");
                                  setEmail("");
                                  setValueColor();
                                }
                              }}
                              placeholder={"98765 43210"}
                              valueColor={valueColor}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className={classes.flex}>
                        <HelperText helperText={helperText} type={severity} />
                        <Typography className={classes.helpertextContent}>
                          {" "}
                          {email}
                        </Typography>
                      </Grid>
                    </Grid>
                    {email && (
                      <Box className={classes.box}>
                        <FormControl
                          sx={{ m: 2 }}
                          error={values.error}
                          variant="standard"
                        >
                          <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="oldUser"
                            value={values.oldUser}
                            onChange={handleChange}
                            id="marketCap"
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio className={classes.radioIcon} />}
                              label={
                                <div
                                  style={{ marginLeft: "8px", width: "150%" }}
                                >
                                  <Typography className={classes.radioTitle}>
                                    Login using
                                  </Typography>
                                  <Typography className={classes.radioSubTitle}>
                                    {email}
                                  </Typography>
                                </div>
                              }
                            />
                            <hr
                              style={{
                                width: "110%",
                                backgroundColor: "#262626",
                                border: "none",
                                height: "1px",
                                margin: "3px 2.5rem",
                              }}
                            ></hr>
                            <FormControlLabel
                              value="0"
                              control={<Radio className={classes.radioIcon} />}
                              label={
                                <div style={{ marginLeft: "8px" }}>
                                  <Typography className={classes.radioTitle}>
                                    Continue with
                                  </Typography>
                                  <Typography className={classes.radioSubTitle}>
                                    {newEmail}
                                  </Typography>
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    <Buttons
                      submitButton={
                        (mobileNoLength !== values.mobileNumber.length &&
                          !email) ||
                        submit
                      }
                      type="submit"
                      value={email ? "Proceed" : "SEND OTP"}
                      value1={values}
                      handleError={handleOnSubmit}
                      animation={submit}
                    />
                  </form>
                )}
              </Formik>
            </Container>
            <div id="recaptcha"></div>
          </Box>
        </DisableRightClick>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={submit}
        ></Backdrop>
      </Page>
    </>
  );
};
export default SignupPhone;
