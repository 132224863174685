import React from "react";
import { Box, Typography, Grid , Avatar} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  card: {
    width:"80% !important",
    border:"1px solid #1C1C1C !important ",
    borderRadius: "10px !important",
    // backgroundColor:"#1C1C1C !important",
    // opacity:"0.5"
  },
 bg:{
    backgroundColor:"#1C1C1C !important",
 },
 portfolio:{
  display: "flex !important",
  justifyContent: "center !important",
  alignItems: "center !important",
 }
}));

function HoldingSkeleton() {
  const classes = useStyles();
          return (
            <div>
              <Box sx={{ m: 1 }}>
              <Grid container mt={3}>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.portfolio}
                  >
                    <Skeleton variant="rectangular" width="75%" height={120} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                  </Grid>
              </Grid>
             {[1,2,3,4,5].map(()=>{
              return(
                <>
                 <Skeleton width="100%" height={5}  className={classes.bg}/>
                 <Grid container mt={3}>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.portfolio}
                  >
                    <Skeleton variant="rectangular" width="95%" height={80} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                  </Grid>
                 </Grid>
                </>
              )
             })}
              </Box>
            </div>
          );
   

}

export default HoldingSkeleton;
