import React, { useState } from "react";
import { Grid, Typography, Box, Button, Input, Autocomplete, TextField } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import upload from "../../Images/upload.svg";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import InfoIcon from "@mui/icons-material/Info";
import BottomNavigation from "../../component/Home/BottomNavigation";
import FormControl from "@mui/material/FormControl";
import HelperText from "../../component/DarkMode/HelperText";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import Textfield from "../../component/DarkMode/Textfield";
import { kycService } from "../../_services/kyc";
import { connect } from "react-redux";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    padding: "0 16px !important",
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    // marginBottom: "1rem",
  },
  infoIcon:{
    color: "#fff",
    fontSize: "12px !important" ,
    margin:'4px 4px 2px !important'
},
  icon: {
    fill: "#fff  !important",
    marginRight: "20px",
    cursor: "pointer",
    marginLeft: "0px",
  },

  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },
  input:{
    color:"#fff !important",
    borderBottom: "solid 2px #AB97EB",
    "& .MuiInput-input":{
      textAlign:"center !important"
    }
  },
  paper: {
    borderRadius: "10px !important",
    marginTop: "1rem !important",
    width: "100%",
    maxHeight: "192px",
    background: "#0d0d0d !important",
    color: "white !important",
    boxShadow: "0 0 6px 0 rgba(157, 96, 212, 0.5) !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset !important",
    overflow: "hidden !important"
  },
  searchField: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400 !important",
      fontSize: "14px  !important",
      lineHeight: "16px  !important",
    },
    padding: "5px ",
    borderRadius: "10px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box",
    boxShadow: "2px 1000px 1px black inset",
    // height:'3.5rem !important',
    "&:hover": {
      backgroundColor: "black",
    },
    color: "#fff !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
    backgroundOrigin: "border-box",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: "16px !important",
    lineHeight: "16px !important",
    color: "#fff !important",
  },
  autotextfield: {
    marginTop:'1rem !important',
    autocomplete: "off !important",
    color: "#fff !important",
    marginBottom: "1rem !important",
    width: "100% !important",
    height: "3.3rem !important",
    paddingleft: "25%!important",
    borderRadius: "10px !important",
    fontSize: "14px",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      padding: "7.5px 5px  !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: "#AB97EB ",
      marginRight: "15px !important"
    },

    "& .MuiAutocomplete-clearIndicator": {
      color: "#AB97EB "
    },
    "	&.MuiAutocomplete-paper": {
      width: "600px !important"
    },
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: "16px !important",
    lineHeight: "16px !important",
  },
  textfield: {
    padding: "10px ",
    margin: "1rem 0rem",
    borderRadius: "10px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box",
    boxShadow: "2px 1000px 1px black inset",
    "&:hover": {
      backgroundColor: "black",
    },

    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: " 400 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: "#fff !important",
    },
  },
  google: {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    color: "#fff",
    fontWeight: "500  !important",
    lineHeight: "16px !important",
    fontStyle: "normal",
  },
  label: {
    marginTop: "34px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  emailBtn: {
    width: "100% !important",
    marginTop: "1rem !important",
    background: "#2B2B2B !important",
    border: "2px solid #2B2B2B !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    height: "3.5rem",
    "&:hover": {
      backgroundColor: "#2B2B2B",
    },
  },

  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#DD4A47 !important",
    lineHeight: "16px !important ",
    margin: "0px 0px 0px  10px !important",
  },
  bankname: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "20px !important",
  },
  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  button: {
    // padding: "12px 44px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
    "&:disabled": {
      color: "#6E58B0 !important",
      backgroundColor: "#3B3159 !important",
    },
  },
  font: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#FFF !important",
    lineHeight: "16px !important",
  },
  Btn: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "24px !important",
    // background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  32px 12px !important",
    textAlign: "center!important ",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient( #583EAC ,#DD4A47), linear-gradient(1800deg, #583EAC 0%,  #DD4A47 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  },
  BtnTwo: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "24px !important",
    background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  32px 12px !important",
    textAlign: "center!important ",
    borderRadius: "8px !important",
    backgroundImage: "repeating-linear-gradient(0deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(90deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(180deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(270deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px)!important",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100% !important",
    backgroundSize: "1.5px 100%, 100% 1.5px, 1.6px 100% , 100% 1.5px !important",
    backgroundRepeat: "no-repeat!important",
  },
  subtext: {
    paddingTop: "10px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "16px !important",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      width: "241px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
    },
  },
  titles: {
    padding: " 0px !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "16px  !important",
    color: "#AC93FF !important",
    lineHeight: "24px !important",
    textAlign: "center !important",
    // BorderTop:"1px solid red !important"
  },
  dialogTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    margin:"1rem !important",
    padding:"0 !important",
    // minHeight:"70px !important",
    display:"flex !important",
    justifyContent:"center !important",
    alignItems:"center !important"
  },
  btnText:{
    whiteSpace:"nowrap !important", 
    overflow:"hidden !important", 
    textOverflow:"ellipsis !important",
    textTransform:"none !important"
  },
  download:{
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "12px  !important", 
    color: "#FFF !important",
  },
  dialogBtn:{
    borderRadius:"10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight:"14px !important",
    "&:disabled":{
      color:"#3c2f60 !important",
    },
},
  bottom1:{
    margin:"10rem 16px 0 !important",
    paddingBottom:'40px  !important'
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props}  classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#fff',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    margin:"0 0 0px 18px !important",
    padding:'0 0 8px'
  },
}));



function SimpleDialog(props) {
  const { onClose, selectedValue, open, type,setOpen } = props;
  const classes = useStyles();
  const navigate = useNavigate();


  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog open={open} className={classes.cards}>
      <Typography className={classes.dialogTitle}>

        { type === "kyc" ?" You’ve successfully completed KYC" : "Place a request to your broker via email or brokers application."}
      </Typography>
      <Grid container style={{display:"flex"}}>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
           < Button className={classes.dialogBtn}
           disableRipple
           style={{color:"#A489FF"}}
           onClick={(() => {
            if(type === "kyc"){
              navigate("/home")
            }else{
              navigate("/faqs",{
                replace:true,
                state:{demat:true}
              })
            }
          })}
            >
           { type === "kyc" ? "Home" : "Know more"}
            </  Button>
           </Grid>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ",borderLeft:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
            < Button className={classes.dialogBtn} style={{color:"#8C8D8D"}}
            disableRipple
            onClick={()=>{
              if(type === "kyc"){
                navigate("/profile")
              }else{
              setOpen(false)
              }
            }}
            >
           close
            </  Button>
          </Grid>
          </Grid>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const Demat = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();


  const type = "error"
  const [errorMessage,setErrorMessage] =useState()
  const [newAcc, setNewAcc] = useState(true);
  const [demat, setDemat] = useState();
  const [dematvalues, setDematvalues] = useState(true);
  const [dematvaluesError, setDematvalueserror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dematData, setDematData] = useState(true);
  const [showValidDematnum, setShowValidDematnum] = useState(false);
  const [showValidDematcml, setShowValidDematcml] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [allNames, setAllNames] = useState([]);
  const [toolTip, setTooltip] = useState(false);
  const [timer, setTimer] = useState(null);

  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const [dialog,setDialog] = useState();
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

  const [initValues, setInitValues] = useState({
    demat: "",
    dematfile: "",
    dpWith:""
  });
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;

  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            color: "#737373",
            fontSize:"14px",
            
          },
        },
      },
    },
  });

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleTooltipClose = () => {
    setTooltip(false);
  };



  const dematnumValidation = (demat) => {

    const dematvalue = demat;
    const mystring = dematvalue.substring(0, 2);
    let regexOne = /^([a-zA-Z]){2}([0-9]){14}?$/;
    let regexTwo = /^([0-9]){16}?$/;
    if (dematvalue.match(regexOne)) {
      if (mystring == "IN") {
        return true
      } else {
        return false
      }
    } else if (dematvalue.match(regexTwo)) {
      return true
    } else {
      return false
    }
  };
  const dematcmlValidation = async (dematcml) => {
    const type = dematcml.type;
    if (type === "application/pdf") {
      setSelectedFileName(dematcml.name)
    let isPasswordRequired = await  checkPassword(dematcml)
      if(isPasswordRequired){
        console.log("hi")
        setErrorMessage("Password required")
        setOpenPasswordDialog(true)
        setShowValidDematcml(false)
        setDemat(2)
        setDematvalueserror(true)
      }else{
        setShowValidDematcml(true)
      }
    } else {
      return false
    }
  };

  const checkPassword = async  (dematcml) =>{
    const formData = new FormData();
    formData.append("Doc", dematcml);
    setLoading(true)
    let password =  await kycService.checkIsDematPasswordProtected(formData)
    setLoading(false)
    return password.data.passwordProtected
  }

  const handleOnSubmit = (values) => {
    let data;
    setDemat()
    setErrorMessage()
    setIsLoading(true)
    if (dematnumValidation(values.demat) !== true) {
      setDemat(2)
      setDematvalueserror(true)
      setShowValidDematnum(true)
    } else {
      if (dematnumValidation(values.demat) == true) {
        setDematvalues(values.demat)
            const formData = new FormData();
            formData.append("kyc_copy", values.dematfile);
            formData.append("dematId", values.demat);
            formData.append("password", values.dematPassword);
            formData.append("userId", userID);
            formData.append("dpWith", values.dpWith);
            kycService.uploadAndVerifyDemat(formData).then((res) => {
            setIsLoading(false)

              if (res) {
                setIsLoading(false)
                if (res.status === 200) {
                  if (res.data.success) {
                    setOpen(true)
                    setDialog("kyc")
                  } else if(res.data.password_required){
                    setErrorMessage(res.data.message === "Password is needed" ? "Password required" : "Invalid password")
                    setInitValues({
                      demat: values.demat,
                      dematfile: values.dematfile,
                      dpWith:values.dpWith,
                      dematPassword:""
                    })
                    setOpenPasswordDialog(true)
                    setShowValidDematcml(false)
                    setDemat(2)
                    setDematvalueserror(true)
                  }else {
                    setInitValues({
                      demat: values.demat,
                      dpWith:values.dpWith,
                      dematfile: ""
                    })
                    setErrorMessage("Invalid details")
                    setShowValidDematcml(false)
                    setDemat(2)
                    setDematvalueserror(true)
                    setSelectedFileName("")
                    const fileInput = document.getElementById("dematfile");
                    fileInput.addEventListener("change", dematcmlValidation);
                    fileInput.addEventListener("click", function() {
                        fileInput.value = "";
                    });
                  }
                } else {
                  if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenSessionExpiredModal(true)
                  }
                }
              }
            })
      }
    }


  }

  const allBranches = async (value) => {
    console.log(value)
   await kycService
      .getDPWithName(value)
      .then((res) => {
       if(res.data.message?.length ===0){
        value &&  setAllNames([
         { "id":"",
          'dp_name':value}
        ])
       }else{
         setAllNames(res.data.message)
       }
      })
      .catch((err) => console.log(err));
  };

  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              mt={2}
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Add Demat Account
              </Typography>
            </Grid>
          </Grid>
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            validationSchema={Yup.object().shape({
              demat: Yup.string()
                .nullable()
                .required("demat number is required"),
              dematfile: Yup.string()
                .nullable()
                .required("demat  is required"),
                dpWith: Yup.string()
                .nullable()
                .required("DP with  is required")
            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}

                autoComplete="off"

              >
                <Grid className={classes.container} container flexDirection="column">
                 <Box style={{display:"flex", alignItems:"end"}}>
                 <Typography
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.label}
                  >
                    Enter Demat Account Number
                  </Typography>
                  <BootstrapTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={toolTip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                           <div style={{width:'5px' ,height:'5px', background:'#484848', transform:"rotate(131deg)", margin:'0px 0 -4px 128px'}}>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            alignItems:"end",
                            marginTop:'1px',
                            position:"relative",
                            fontSize:'10px',
                            fontFamily:"Poppins",
                            lineHeight:'12px',
                            padding:"2px 4px",
                            borderRadius:'3px',
                            background: "#484848",
                            // border:'1px solid grey'
                            }}>
                          DP Id followed by Client Id
                          </div>                     
                        </React.Fragment>}
                      placement="bottom-end"
                    >
                      <InfoIcon onClick={()=>{
                          !toolTip && setTooltip(true)
                          setTimeout(()=>{
                          setTooltip(false)
                        },1500)
                      }} className={classes.infoIcon} />
                    </BootstrapTooltip>
                 </Box>
                  <FormControl variant="standard" style={{ fontFamily: "Poppins" }}>

                    <Textfield
                      name="demat"
                      id="demat"
                      value={values.demat}
                      fullWidth
                      onChange={(e) => {
                        setDematvalueserror(false)
                        setFieldValue("demat", e.target.value);
                        if (dematnumValidation(e.target.value) == true) {

                          setShowValidDematnum(true);

                        } else {
                          setShowValidDematnum(false);
                        }

                      }
                      }

                      onInput={(e) => {
                        if (e.target.value.length >= 16) {
                          setNewAcc(false)
                          setDemat("")
                        } else {
                          setDematvalueserror(false)
                          setDemat()
                          setNewAcc(true)
                        }
                        e.target.value = e.target.value
                          .toUpperCase()
                          .toString()
                          .slice(0, 16)
                      }}
                      placeholder={"012345678912"}
                      valueColor={demat}

                    />

                  </FormControl>
                  {dematvaluesError ? (
                    <HelperText helperText={errorMessage} type={type} />
                  ) : (
                    ""
                  )}
                           <ThemeProvider theme={theme}>
            <Autocomplete
              blurOnSelect
              disableClearable
              className={classes.autotextfield}
              onInputChange={(event, newInputValue) => {
                if(event?.target?.value ===""){
                  setAllNames([])
                }  else{
                  clearTimeout(timer)
                  const newTimer = setTimeout(() => {
                    event?.target.value && allBranches(event?.target.value)
                  }, 200);
                  setTimer(newTimer)  
                } 
              }}
              noOptionsText="Please enter depository participant name"
              onChange={(event, value) => setFieldValue("dpWith", value.dp_name)}
              classes={{ paper: classes.paper,}}
              name="dpWith"
              id="dpWith"
              sx={{ width: 180 }}
              options={allNames}
              autoHighlight
              getOptionLabel={(option) => `${option.dp_name}`}
              renderOption={(props, option) => (
                <>

                  <Box
                    component="li"
                    {...props}

                    style={{ justifyContent: "center !important", padding: 0, width: "auto", display: "flex", justifyContent: "space-between", margin: "-6px  1rem", overflow: "hidden !important" }}

                  >  <Typography sx={{ pl: "25px ", margin:'4px', fontFamily: "Poppins", fontWeight: "400", fontSize: "14px" }}>  {option.dp_name}</Typography>

                  </Box>
                  <hr style={{
                    width: "80%", backgroundColor: "#262626",
                    border: "none",
                    height: "1px", margin: "-3px auto"
                  }}></hr>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="Enter DP name with"
                  style={{
                    minWidth: "119px"
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps, style: {
                      color: "white", fontSize: 14,
                      maxWidth: "80%", paddingRight: "0", fontFamily: "Poppins"
                    }

                  }}
                />
              )}
            />
</ThemeProvider>
                  <Grid
                    className={dematData ? classes.BtnTwo : classes.Btn}
                    disableRipple

                    style={{
                      textAlign: "center ",
                    }}
                  >
                    <label htmlFor="dematfile">
                      <Input
                        disabled={newAcc}
                        id="dematfile"
                        fullWidth
                        onChange={(event) => {
                          setFieldValue("dematfile", event.currentTarget.files[0]);
                          if (dematcmlValidation(event.currentTarget.files[0]) == true) {

                            setShowValidDematcml(true);

                          } else {
                            setShowValidDematcml(false);

                          }
                        }}
                        style={{ display: "none" }}
                        type="file"
                        inputProps={{ accept: "application/pdf" }}

                      />
                      <Button
                        disabled={newAcc}
                        disableRipple
                        startIcon={<img src={upload} />}
                        type="submit"
                        className={classes.button}
                        style={loading ?{
                          background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                          backgroundSize:"400% 100%",
                          WebkitAnimation:"AnimationName 6s linear infinite",
                          MozAnimation:"AnimationName 6s linear infinite",
                          animation:"AnimationName 6s linear infinite",fontSize: "12px",height: "34px", width: "210px",
                          backgroundRepeat:"repeat-x",
                        }:{
                          opacity: newAcc == true ? "0.4" : "1 ",
                          fontSize: "12px", height: "34px", width: "210px",
                        }}
                        variant="contained"
                        component="span"
                      >
                       <span 
                        className={selectedFileName && classes.btnText}
                      >{selectedFileName ? selectedFileName : "UPLOAD CML/CMR COPY"}</span> 
                      </Button>
                    </label>
                    <Typography className={classes.subtext}>
                      Supported file format : PDF
                    </Typography>
                  </Grid>
                  {dematData ? (
                    ""
                  ) : (
                    <HelperText helperText="Unsupported format" type={type} />
                  )}
                  <Typography mt={2} className={classes.download} style={{fontSize:"12px !important"}}>
                    How to get a <span style={{color:"#6D50CC", textDecoration:"underline"}}
                    onClick={()=> setOpen(true)}
                    >CML/CMR copy</span>?
                  </Typography>
                </Grid>
                {newAcc ? (
                  <>
                    {" "}
                    <Grid className={classes.container} container direction="row">
                      <Grid
                        container
                        spacing={1}
                        style={{ margin: "2rem  auto 1rem", alignItems: "end" }}
                      >
                        <Grid item xs={5} style={{ padding: "0px" }}>
                          <hr
                            style={{
                              backgroundColor: "#494949",
                              border: "none",
                              height: "1px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ padding: "0px" }}>
                          <Typography
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              textAlign: "center",
                              marginTop: "-4px",
                              color: "#494949",
                            }}
                          >
                            OR
                          </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ padding: "0px" }}>
                          <hr
                            style={{
                              backgroundColor: "#494949",
                              border: "none",
                              height: "1px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid mb={5} className={classes.container} container direction="row">
                      <Button
                      disableRipple
                        className={classes.emailBtn}
                        fullWidth
                        variant="contained"
                        onClick={() => { window.open('https://onboard.northeastltd.com', '_blank') }}
                      >
                        <Typography className={classes.google}>
                          Create New Demat Account
                        </Typography>
                      </Button>
                    </Grid>{" "}
                  </>
                ) : (
                  ""
                )}

                <Grid className={ isKeyboardOpen ? classes.bottom1: classes.bottom}>
                <Button
                    disabled={(!showValidDematnum || !showValidDematcml || isLoading || values.dpWith ==="")}
                    disableRipple
                    type="submit"
                    className={classes.button}
                    fullWidth
                    variant="contained"
                    style={isLoading ? {background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                    backgroundRepeat:"repeat-x",
                    backgroundSize:"400% 100%",fontSize: "16px", height: "54px",
                    WebkitAnimation:"AnimationName 6s linear infinite",
                    MozAnimation:"AnimationName 6s linear infinite",
                    animation:"AnimationName 6s linear infinite", width:"calc(100vw - 36px)"} : { width:"calc(100vw - 36px)", fontSize: "16px",  height: "54px"}}
                  >
                    Verify
                  </Button>
                </Grid>
                <Dialog open={openPasswordDialog} className={classes.cards}>
                <Typography className={classes.dialogTitle}>
                Please enter PDF password
              </Typography>
              <Box style={{padding:"0 1rem 1rem"}}>
              <Input 
                required
                disableUnderline
                autoComplete="off"
                autoCorrect="off"
                autoFocus
                className={classes.input}
                name="dematPassword"
                id="dematPassword"
                value={values.dematPassword}
                fullWidth
                onChange={handleChange}
                placeholder={"Password"}
                />
              </Box>
              <Grid container style={{display:"flex"}}>
                  <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
                  < Button className={classes.dialogBtn}
                  disableRipple
                  disabled={values.dematPassword?.trim()  === "" }
                  style={{color:"#A489FF"}}
                  type="submit"
                  onClick={(() => {
                    setOpenPasswordDialog(false)
                    handleOnSubmit(values);
                  })}
                    >
                  Verify
                    </  Button>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ",borderLeft:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
                    < Button className={classes.dialogBtn} style={{color:"#8C8D8D"}}
                    disableRipple
                    onClick={()=>{
                        setDematvalueserror(false)
                        setDemat()
                        setNewAcc(true)
                        setOpenPasswordDialog(false)
                        setInitValues({
                          demat: "",
                          dematfile: "",
                          dpWith: "",
                          dematPassword:""
                        })
                        setSelectedFileName("")
                        const fileInput = document.getElementById("dematfile");
                        fileInput.addEventListener("change", dematcmlValidation);
                        fileInput.addEventListener("click", function() {
                            fileInput.value = "";
                        });
                    }}
                    >
                  Close
                    </  Button>
                  </Grid>
                  </Grid>
                  </Dialog>
              </form>
            )}
          </Formik>
        </Box>
        <SimpleDialog open={open} type={dialog} setOpen={setOpen} onClose={handleClose} />
        <BottomNavigation />
        <SessionExpiredModal open={openSessionExpiredModal}/>
      </Page>
    </>
  );
};




const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};


export default connect(mapStateToProps)(Demat);