import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../component/Page/Page";
import { Box, Tabs, Typography, Grid } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import bulb from "../../Images/bulb.svg";
import ActiveBulb from "../../Images/ActiveBulb.svg";
import ActiveLike from "../../Images/ActiveLike.svg";
import ActiveHeart from "../../Images/ActiveHeart.svg";
import like from "../../Images/like.svg";
import heart from "../../Images/heart.svg";
import moment from "moment/moment";
import BottomNavigation from "../../component/Home/BottomNavigation";
import { NewsServices } from "../../_services/insights";
import NewsInfoSkeleton from "../../component/Skeleton/NewsInfoSkeleton";
import Lottie from 'react-lottie';
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import LikeJson from './lotties/like.json';
import BulbJson from './lotties/bulb.json';
import LoveJson from './lotties/love.json';
import Zoom from '@mui/material/Zoom';
import SessionExpiredModal from "../../component/AlertModel/SessionExpiredModal";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    maxWidth: "100%",
    overflowX: "hidden",
    padding:"14px  16px 0 !important"
  },
  title: {
    margin: "16px 0px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    lineHeight: "24px !important",
    color: "#fff !important",
    textAlign:"justify"
  },
  subtitle: {
    marginBottom: "2.2rem  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    lineHeight: "22px !important",
    color: "#fff !important",
    textAlign:"justify"
  },
  days: {
    marginTop: "8px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    lineHeight: "15px !important",
    color: "#5F6060 !important",
  },
  count:{
    fontFamily: 'Poppins  !important',
    fontStyle: 'normal  !important',
    fontWeight: '400  !important',
    fontSize: '10px  !important',
    color: '#FFFFFF  !important',
    display:"flex !important",
  },
  card: {
    minHeight: "233px !important",
    margin: "14px 0px 0px 0px  !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    width: "-webkit-fill-available !important",
  },
  reactionText: {
    color: "#fff !important",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    lineHeight: "12px !important",
    transition: "all !important",
    lineHeight: "15px !important",
  },
  icon: {
    fill: "#fff  !important",
    margin: "0px",
    cursor: "pointer",
  },
}));


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    margin:"0 0 -2px 0px !important",
  },
}));

const NewInfo = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [data,setData] = useState();
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [expand, setExpand] = useState(false);
  const [reaction,setReaction] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const getNewsInfo = (reactId) =>{
    const data ={
      userId:location.state.userId
    }
    NewsServices.getIndividualNews(location.state.newsId, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setTimeout(()=>{
              setOpen(false)
              setReaction(false)
              setData(res.data.mainData[0])
              setSkeletonLoader(false)
              },100)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: reaction=== 1 ? BulbJson : reaction=== 2 ? LoveJson : reaction=== 3 ? LikeJson: null,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

  const updateReaction = (reactId,isDelete,newsId,reactionId) =>{
    setReaction(reactId)
    const data ={
      related_security_id:location.state.securityId,
      related_user_id:location.state.userId,
      deleteReaction: isDelete,
      reaction_type_id:reactId,
      related_news_id:parseInt(newsId)
    }
    !reaction && NewsServices.postNewsReaction(reactionId, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            getNewsInfo(reactId)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }


  useEffect(()=>{
    getNewsInfo()
  },[])


  return (
    <div style={{maxWidth:"100vw"}}>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() =>
                  navigate("/companyOverview", {
                    replace: true,
                    state: {
                      id: location.state.securityId,
                      data: true,
                    },
                  })
                }
                className={classes.icon}
              />
            </Grid>
          </Grid>
         {skeletonLoader ? <NewsInfoSkeleton/> : (
          <>
          <Grid container>
            <Grid item xs={12} md={12} textAlign="center">
              <img
                className={classes.card}
                src={data?.news_image_url ? data.news_image_url : ScriptLogo}
                alt={data.id}
                // height= {233}
              />
            </Grid>
          </Grid>
          <Typography className={classes.title}>
            {data.short_description}
          </Typography>
          <Typography className={classes.subtitle}>
            {data.long_description}
          </Typography>
          <hr
            style={{
              backgroundColor: "#323333",
              border: "none",
              height: "2px",
            }}
          />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6} md={6} mb={4} mt={0.5}>
            <Grid container style={{ textAlign:'center'}} onClick={()=> {
                          setExpand(true)
                        }}   
                      >
                           <HtmlTooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener  
                            TransitionComponent={Zoom}
                            title={
                          <React.Fragment>
                             <div
                          style={{
                            display: "flex",
                            alignItems:"center",
                            padding:"2px",
                            marginTop: (data.heart_reaction || data.like_reaction || data.bulb_reaction) ? "0px" : "",
                            background: "#1C1C1C",
                            borderRadius: "10px", transition: "all 500ms"
                          }}
                        >
                          <Box onClick={() => updateReaction(3, data.like_reaction ? 1 : 0, data.id, data.reaction_row_id)}  style={{ minWidth: "34px", transition: "width 300ms,margin-left 300ms, margin-right 300ms" }} >
                            {reaction !== 3 && <Typography className={classes.reactionText}>Like</Typography>}
                            {reaction == 3 ? <Lottie
                              options={defaultOptions}
                              height={35}
                              width={35}
                            /> : data.like_reaction ? <img width={24} height={24}  src={ActiveLike} alt={"like"} /> : <img width={24} height={24} src={like} alt={"rupees"} />}
                          </Box>
                          <Box onClick={() => updateReaction(1, data.bulb_reaction ? 1 : 0, data.id, data.reaction_row_id)}  style={{ minWidth: "57px", transition: "width 300ms, margin-left 300ms, margin-right 300ms" }} >
                            {reaction !== 1 &&<Typography className={classes.reactionText}>Informative</Typography>}
                            {reaction == 1 ? <Lottie
                              options={defaultOptions}
                              height={35}
                              width={35}
                            /> : data.bulb_reaction ? <img width={24} height={24}  src={ActiveBulb} alt={"bulb"} /> : <img width={24} height={24} src={bulb} alt={"bulb"}  />}
                          </Box>
                           <Box onClick={() => updateReaction(2, data.heart_reaction ? 1 : 0, data.id, data.reaction_row_id)}  style={{ minWidth: "34px", transition: "width 300ms, margin-left 300ms,margin-right 300ms" }} >
                           { reaction !== 2 &&<Typography className={classes.reactionText}>Love</Typography>}
                           {reaction == 2 ? <Lottie
                             options={defaultOptions}
                             height={35}
                             width={35}
                           /> : data.heart_reaction ? <img width={24} height={24}  src={ActiveHeart} alt={"heart"} /> : <img width={24} height={24} src={heart} alt={"heart"}  />}
                         </Box>
                        </div>
                          </React.Fragment>
                            }
                        placement="top"
                      >
                          <div 
                           onClick={()=>{
                            handleTooltipOpen()
                            setExpand(true)
                            setReaction()}}
                            style={{
                              display: "flex",
                              alignItems:"center",
                              borderRadius: "10px", transition: "all 500ms"
                            }}
                          >
                         <Box>
                              <img width={24} height={24} src={ data.like_reaction || data.bulb_reaction ? heart : like} alt={"rupees"} />
                            </Box>
                            <Box >
                             <img width={24} height={24} src={ data.bulb_reaction ? like  : bulb} alt={"bulb"} style={{ marginLeft:  "-12px" }} />
                            </Box>
                            <Box>
                            {data.like_reaction || data.bulb_reaction || data.heart_reaction ? <img width={24} height={24} src={ data.like_reaction ? ActiveLike : data.bulb_reaction ? ActiveBulb : ActiveHeart } alt={"heart"} style={{ marginLeft: "-12px" }} /> :  <img width={24} height={24} src={heart} alt={"rupees"} style={{ marginLeft: "-12px" }} />}
                            </Box>
                          <Typography ml={1}  className={classes.count} style={{lineHeight:expand ? "30px" : "18px"}}>{data.news_count}</Typography>
                          </div>
                          </HtmlTooltip>
                      </Grid>
            </Grid>
            <Grid item xs={6} md={6} style={{display:"flex", justifyContent:"end"}} mt={0.5}>
              <Typography
                className={classes.days}
                style={{
                  fontFamily: "Poppins",
                }}
              >
                {moment(data.news_picked_date).format("MMM DD, YY")}
              </Typography>
            </Grid>
          </Grid>
         </>
         )
         }
        </Box>
      </Page>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
      <BottomNavigation activeTab={0} />
    </div>
  );
};

export default NewInfo;
