import React, { useState } from "react";
import { Grid, Box, Button, Stack, Typography, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import BottomNavigation from "../../component/Home/BottomNavigation";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@mui/styles";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import Frame1 from '../../../src/Images/vouch/Frame1.png'
import Frame2 from '../../../src/Images/vouch/Frame2.png'
import Frame3 from '../../../src/Images/vouch/Frame3.png'
import Frame4 from '../../../src/Images/vouch/Frame4.png'
import Frame5 from '../../../src/Images/vouch/Frame5.png'
import Frame6 from '../../../src/Images/vouch/Frame6.png'

const images = [
  {

    imgPath: Frame1,
  },
  {
    title:"Quick Brief",
    subtitle:"An escrow account is an account where funds are held in trust whilst  two or more parties complete a transaction. Basically, the money  you pay reaches Unlistedkart only after the shares have been transferred to your Demat account." ,
    subtitle1:"The escrow system is protected and audited by ICICI Bank and Universal Trusteeship Services." ,
    imgPath: Frame2,
  },
  {
    title:"The Process",
    imgPath: Frame3,
    label: "The money you pay is held in an escrow account.",
  },
  {
    label: "The escrow account holds the money until the shares are transferred to your Demat account. ",
    imgPath: Frame4,
  },
  {
    label: "Post transfer of shares, the money  is released from the escrow  account to Unlistedkart.",
    imgPath: Frame5,
  },
  {
    label: "‘Tis all because you matter.",
    imgPath: Frame6,
  },
];

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)  !important",
    background: "#0D0D0D !important",
    textAlign: "center",
    display: "flex",
    alignItems: " center",
    justifyContent: " center",
    padding: "0px 10px"
  },
  heading: {
    fontSize: "18px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "600 !important",
    color: "#FFF !important",
    lineHeight: "27px !important",
  },

  button: {
    fontSize: "16px  !important",
    width:"156px !important",
    padding: "12px 44px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "none !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },
  text: {
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    color: "#FFF !important",
    lineHeight: "22px !important",
  },
  skip: {
    fontSize: "12px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    color: "#A489FF !important",
    lineHeight: "16px !important",
  },
  dot: {
    paddingBottom: "20px !important",
    backgroundColor: "transparent  !important",
    "& 	.MuiMobileStepper-dot": {
      backgroundColor: "#5F6060 !important",
      width: "4px !important",
      height: "4px",
    },

    "& 	.MuiMobileStepper-dotActive": {
      backgroundColor: "#A489FF !important",
      width: "12px !important",
      borderRadius: "4px",
      height: "4px",
    },
  },
  title:{
    fontSize: "18px  !important",
    fontFamily: "Poppins  !important",
    // fontStyle: "SemiBold  !important",
    fontWeight: "500 !important",
    color: "#A489FF !important",
    lineHeight: "22px !important",
  }
}));

const Vouchslides = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  // const slides = localStorage.getItem("KYCSlides")

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === maxSteps - 1) {
      navigate("/companyOverview", {replace:true, state:{buy:true,id:location.state.security_id}})
      localStorage.setItem("VouchSlides", true)
    }
  };


  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Page >
        <Box className={classes.boxcontainer}>
          <Box>
            <Grid
              height={27}
              style={{
                display: "flex",
                alignItems: " center",
                height: "27px",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              {activeStep == 0 ? (
                <Typography className={classes.heading}>
                  All ‘bout Escrow{" "}
                </Typography>
              ) : (
                ""
              )}
            </Grid>

            <Paper
              square
              elevation={0}
              sx={{
                height: 484,
                width: 328,
                bgcolor: "#1C1C1C",
              }}
            >
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {images.map((step, index) => (
                    <div key={step.label} >
                      {<img
                          height={350}
                          width={ 328}
                          src={step.imgPath}
                          alt={step.label}
                          style={{marginTop:"1rem"}}
                        />}
                    </div>
                  ))}
                </SwipeableViews>
                <MobileStepper
                  className={classes.dot}
                  sx={{
                    bgcolor: "#1C1C1C",
                    justifyContent: "center",
                    marginTop:"1rem"
                  }}
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}

                />
                <Button
                  className={classes.button}
                  size="small"
                  onClick={handleNext}
                >
                  {activeStep === 5  ? "CLOSE" : "NEXT"}
                </Button>
            </Paper>
          </Box>
        </Box>
      </Page>
      <BottomNavigation activeTab={0} />
    </>
  );
};

export default Vouchslides;
