import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { InputBase, IconButton, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../../src/index.css";
import Logo from "../../Images/filter.svg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
 
  searchField:{
    "& .MuiInputBase-input": {
      position: "relative",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
    },
    padding: "14px 18px ",
    borderRadius: "10px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box",
    boxShadow: "2px 1000px 1px black inset",
    width: "100% !important",
    height:'40px !important',
    "&:hover": {
      backgroundColor: "#0d0d0d",
    },
    color: "#fff !important",
    backgroundImage: "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
    backgroundOrigin: "border-box",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: "16px !important",
    lineHeight: "16px !important",
    color: "#fff !important",
  }
}));

function Searh({ searchkey }) {
  const inputEl = useRef("");
  const classes = useStyles();
  const navigate = useNavigate();

  const getSearchterm = (e) => {
    searchkey(inputEl.current.value);
  };

  const navigateFilter = () => {
    navigate("/filter");
  };

  return (
    <Grid  style={{marginBottom:"24px", display: "flex", justifyContent: "space-between",}}>
      <InputBase
          className={classes.searchField}
          inputRef={inputEl}
          onChange={getSearchterm}
          fullWidth
          placeholder="Search UnlistedKart"
          inputProps={{ 'aria-label': 'search' }}
          startAdornment={<SearchIcon sx={{ color: "#B9BABB", opacity: "1" , marginRight:'8px'}}/>}
        />
        <img onClick={navigateFilter} style={{ width: "25px", width: "30px" , marginLeft:"10px", marginTop:"8px"}} src={Logo} alt="logo" />

    </Grid>
  );
}

export default Searh;
