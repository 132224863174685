import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box, Typography, Grid, Button, Dialog, Input, FormControl, InputBase } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import { Add, AddCircleOutline, AttachmentRounded } from "@mui/icons-material";
import HelperText from "../../component/DarkMode/HelperText";
import Upload from "../../Images/home/upload";
import clear from "../../Images/clear.svg";
import { userService } from "../../_services/users";
import BottomNavigation from "../../component/Home/BottomNavigation";

import moment from "moment";
import { AllFaq } from "../../_services/faq's";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    paddingBottom: '2rem'

  },
  orderId: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "18px !important",
    textAlign: 'justify'
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  margin: {
    marginTop: '0px'
  },
  cards: {
    "& 	.MuiDialog-paper": {
      minWidth: "327px !important",
      borderRadius: "16px !important",
      backgroundColor: "#1C1C1C !important",
      padding: "16px 0px !important",
    },
  },
  text: {
    color: "#A489FF!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 20px !important",
    lineHeight: "24px !important",
    marginLeft: '1rem !important'
  },
  rate: {
    fontFamily: "Roboto   !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff ",
    lineHeight: "14px !important",
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal ",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#767677",
    display:'flex',
    lineHeight: "15px !important",
  },
  Typography: {
    textAlign: "justify",
    color: "#fff !important",
    padding: "5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
      color: "#A489FF",
    }
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff",
    lineHeight: "14px !important",
  },
  button: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    padding: "4px 8px !important ",
    borderRadius: "4px !important",
    background: " #8264E6 !important",
    textTransform: "none !important",
    maxHeight: "29px !important",
    float: 'right',
    // maxWidth: " 59px !important",
  },
  signCards: {
    "& 	.MuiDialog-paper": {
      width: "341px",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
    },
  },
  title: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "600  !important",
    fontSize: "12px  !important",
    color: "#8264E6 !important",
    display:'flex !important',
    alignItems:'center !important',
    lineHeight: "18px !important",
  },
  end: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
  typo: {
    textAlign: "justify",
    color: "#fff !important",
    padding: "5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
      color: "#A489FF"
    },
    '& b': {
      color: "#A489FF",
    },
    marginTop: 4,
  },
  typoHeader: {
    textAlign: "justify",
    color: "#A489FF",
    marginTop: 16,
    padding: "0px 30px !important",
  },
  typoSubHeader: {
    textAlign: "start",
    color: "#A489FF",
    marginTop: 16,
    padding: "30px !important",
  },
  ulClass: {
    padding: "0px 0px 0px 16px"
  },
  btn: {
    color: "#fff !important",
    padding: "10px 60px !important",
    background: "#6D50CC !important",
    fontFamily: "Poppins !important",
    borderRadius: "16px !important",
    fontWeight: "600 !important",
  },
  btnBox: {
    textAlign: "center !important"
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  text1: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "16px  !important",
    color: "#5F6060 !important",
    lineHeight: "24px !important",
  },
  card: {
    margin: "auto",
    maxWidth: "400px !important",
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    padding: "15px !important",
  },
  textfield: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      padding: "7px 0px 5px",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
    },
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    justifyContent: 'center',
    paddingTop: "16px",
  },
  label: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
    textAlign: 'center',
    margin: "10px  0 !important"
  },
  Btn: {
    width: "320px !important",
    justifyContent: "center !important",
    marginTop: "12px !important",
    // background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  20px 4px !important",
    textAlign: "center!important ",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient( #583EAC ,#DD4A47), linear-gradient(1800deg, #583EAC 0%,  #DD4A47 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  },
  BtnTwo: {
    width: "315px !important",
    justifyContent: "center !important",
    marginTop: "12px !important",
    background: "#1C1C1C !important",
    textTransform: "none !important",
    padding: "  20px 4px !important",
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: "center!important ",
    borderRadius: "8px !important",
    backgroundImage: "repeating-linear-gradient(0deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(90deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(180deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(270deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px)!important",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100% !important",
    backgroundSize: "1.5px 100%, 100% 1.5px, 1.6px 100% , 100% 1.5px !important",
    backgroundRepeat: "no-repeat!important",

  },

  subtext: {
    // paddingTop: "10px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "16px !important",
  },
}));

const Support = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [upload, setUpload] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = useState();
  const [text, setText] = useState({
    question: "",
    message: ""
  });
  const [fileChoosen, setFilechoosen] = useState();
  const [helperText, setHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [supportData, setSupportData] = useState('');
  const [url, setUrl] = useState('');
  const [open, setOpen] = useState(false);

  const [data, setData] = useState([]);

  const [aadhar, setAadhar] = useState(true);
  const userId = location.state.userId

  useEffect(() => {
    const element = document.getElementById("content");
    element.scrollIntoView();
    getTickets()

  }, [])

  const getTickets=(()=>{
    setLoading(true)
    setData([])
    userService.getTickets(userId).then((res) => {
    setLoading(false)
      setData(res.data.data)
    }).catch((error) => console.log(error))

  })

  const navigateFunction = (() => {
    navigate("/profile")
  })

  const chooseFile = (event) => {
    setAadhar(true)
    const image = event.target.files[0];
    const type = event.target.files[0]?.type;
    setSelectedFileName(event.target.files[0]?.name)
    if (type === "image/jpg" || type === "image/png" || type === "image/jpeg") {
      
        setFilechoosen(image);
        // setSubmitButton(false);
    
    } else {
      setAadhar(false)
      // setSubmitButton(true);
      setHelperText("Unsupported format")

    }
  }

  const handlePaymentProof = (() => {
    const formData = new FormData();
    setLoading(true)
    formData.append("Doc", fileChoosen);
    formData.append("question", text.question);
    formData.append("message", text.message);
    userService.createTicket(formData).then((res) => {
      setLoading(false)
      setUpload(false)
      if (res.status === 201) {
        setFilechoosen()
        setText({
          message: "",
          question: ""
        })
        // setLoadData(!loadData)
        setSelectedFileName()
        getTickets()
      } else {
      }

    })
  })

  const handleFileUrl = (file_url) => {
    // try{
    //   fetch(file_url)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const blobUrl = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = blobUrl;
    //     link.download = file_url.split('/').pop()
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(blobUrl);
    //   })
    // }catch(error){
    //   console.log(error)
    // }
    setUrl(file_url)
    setOpen(true)
};


  return (
    <Box className={classes.boxcontainer} id="content">
      <Grid container direction="row" >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "initial",
            padding: '20px'
          }}
        >
          <KeyboardBackspaceIcon
            className={classes.icon}
            onClick={navigateFunction}
          />
          <Typography className={classes.text}>Support Tickets</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'end', margin: '0 1rem' }}>
          <Button variant="contained" className={classes.button} onClick={() => setUpload(true)}>
            <AddCircleOutline sx={{ width: 20, marginRight: '4px' }} />
            Raise support ticket</Button>
        </Grid>
        {data.length > 0 ? 
        (data.map((item)=>{
          return(
            <Grid item xs={12} key={item.id}
            onClick={()=>{
              item.feedback && setOpen(true)
              setSupportData(item)
            }}>
          <Box style={{ margin: "8px 16px" }}>
            <Grid container className={classes.card}>
              <Grid
                item
                xs={7}
                md={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography className={classes.title}>
                  # 00{item.id}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                md={5}
                className={classes.end}
                
              >
                <Typography className={classes.title} onClick={(e)=>{
                  e.stopPropagation()
                  handleFileUrl(item.file_url)
                }}>
                  {item.related_file_id ? <><AttachmentRounded sx={{size:'15px'}}/><span style={{marginLeft:'4px'}}>View</span></>: null}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", alignItems: "center", marginTop: '4px' }}
              >
                <Typography className={classes.orderId}>
                  <span style={{ color: 'lightGrey', }}>Question : </span>  {item.question}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", alignItems: "center", marginTop: '4px' }}
              >
                <Typography className={classes.orderId}>
                  <span style={{ color: 'lightGrey', }}>Message : </span>  {item.description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                md={5}
                className={classes.end}
              >
              </Grid>

              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    backgroundColor: "#494949",
                    border: "none",
                    height: "1px",
                    marginTop: "5px ",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.subtitle}> Date </Typography>

                  <Typography className={classes.rate} style={{ disply: "flex", color: "#fff" }} mt={0.2} >
                  {moment(item.created_datetime).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} sx={{ display: "flex",flexDirection:'column', alignItems:'end' }}>
                  <Typography className={classes.subtitle}>Status</Typography>
                  <Typography mt={0.2}
                    className={classes.rate}
                    style={{ color:item.status ===1 ? '#DD4A47':item.status ===2 ?  '#ac93ff': "#37ff37" }}
                  >
                    {item.status ===1 ? "Open":item.status ===2 ? "In Progress" : "Closed"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid> 
          )
        })):
          <Grid className={classes.center}><Typography className={classes.text1}>{loading ? "Loading...":"Oopsy, no tickets!"}</Typography></Grid>}
      </Grid>
      <Dialog onClose={() => setUpload(false)} open={upload} className={classes.signCards}>
        <Grid
          className={classes.container}
          pt={0}
          container
          p={1.25}
          flexDirection="column"
        >
          <Typography
            className={classes.label}
          >
            Create Ticket
          </Typography>

          <Grid item xs={12} sm={12} md={12}
            style={{
              borderBottom: " solid 1px #AC93FF "
            }}>
            <FormControl variant="standard">
              <InputBase
                onChange={(e) => {
                  setText({
                    ...text,
                    ['question']: e.target.value
                  })
                }}
                name="name"
                id="name"
                // value={transactionId}
                className={classes.textfield}
                inputProps={{
                  // readOnly: true,
                }}
                placeholder={"Question *"}
              />
              {/* {values.name ? (
                            ""
                          ) : (
                            <FormHelperText style={{ color: "red" }}>
                              {errors.name && touched.name && errors.name}
                            </FormHelperText>
                          )} */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}
            style={{
              borderBottom: " solid 1px #AC93FF "
            }}>
            <FormControl variant="standard">
              <InputBase
                onChange={(e) => {
                  setText({
                    ...text,
                    ['message']: e.target.value
                  })
                }}
                name="name"
                id="name"
                // value={transactionId}
                className={classes.textfield}
                inputProps={{
                  // readOnly: true,
                }}
                multiline
                minRows={3}
                placeholder={"Message *"}
              />
            </FormControl>
          </Grid>
          <Grid className={classes.BtnTwo}>
            <label htmlFor="Upload">
              <Input
                style={{ display: "none" }}
                fullWidth
                type="file"
                inputProps={{ accept: "application/pdf,image/png, image/jpeg" }}
                name="Upload"
                id="Upload"
                onChange={chooseFile}
              />
              <Button
                startIcon={<Upload theme={true} />}
                component="span"
                className={classes.button}
                style={{
                  // textTransform: selectedFileName ? "none" : "uppercase",
                  fontSize: "12px", height: "34px", width: "168px"
                }}
                variant="contained"
                disableRipple
                endIcon={<img src={clear} onClick={() => {
                  setSelectedFileName()
                }} />}

              >
                <span style={selectedFileName && {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}> {selectedFileName ? selectedFileName : "Upload"}</span>
              </Button>
            </label>
            <br />
            <Typography className={classes.subtext}>
              Supported file formats : JPG, PNG, & JPEG{" "}
            </Typography>
          </Grid>
          {aadhar ? "" : <HelperText helperText={helperText} type={"error"} />}

        </Grid>
        {/* </Grid> */}
        <Grid container style={{ display: "flex" }}>
          <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", borderLeft: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
            < Button className={classes.dialogBtn} style={{ color: "#8C8D8D", textTransform: 'none' }}
              disableRipple
              // disabled
              onClick={() => {
                // setSigned(false)
                // setAadhar(true)
                setUpload(false)
              }}
            >
              Close
            </  Button>
          </Grid>
          <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", borderLeft: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
            < Button className={classes.dialogBtn} style={{ color: (!text.question || !text.message )|| loading || !aadhar ? '#494949' : "#A489FF", textTransform: 'none' }}
              disableRipple
              disabled={(!text.question || !text.message) ||loading || !aadhar}
              onClick={() => {
                setAadhar(true)
                handlePaymentProof()
              }}
            >
              Create
            </  Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog open={open} 
      onClose={()=>{
        setOpen(false)
        setUrl(false)
      }} 
      className={classes.cards}>
        {
          url ?
         <>
          <Grid item xs={12} md={12} style={{  padding: "1rem" }}>
          <Typography className={classes.title}>
                    File 
                  </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ padding: "0 1rem" , textAlign:'center'}}
          >
          <img style={{maxWidth:'100%', objectFit:'contain'}} src={url} alt=""/>
          </Grid>
         </>
          :
          <>
          <Grid item xs={12} md={12} style={{  padding: "0 1rem" }}>
        <Typography className={classes.title}>
                  # 00{supportData.id}
                </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ padding: "0 1rem" }}
        >
          <Typography className={classes.orderId}>
            Remarks from Unlistedkart
          </Typography>
        </Grid>
    
        <Grid item xs={12} md={12}>
          <hr
            style={{
              backgroundColor: "#494949",
              border: "none",
              height: "1px",
              margin: "8px  16px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
        <Typography className={classes.orderId}>

           {supportData.feedback}
           </Typography>
        </Grid>
        </>
        }
       
      </Dialog>
      <BottomNavigation  activeTab={2}/>
    </Box>
  )
}

export default Support