import React, {useState} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,Checkbox,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Page from "../../component/Page/Page";
import * as Yup from "yup";
import { Formik } from "formik";
 import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterService } from "../../_services/register";
 
import Header from "../../component/DarkMode/Header";
import Cookies from "universal-cookie";
import { setToken, loginSuccess } from "../../store/bugs";
import { loginService } from "../../_services/login";
import { connect } from "react-redux";
import Notification from "../../component/AlertModel/Notification";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
  },
  verifyText: {
    padding: "4px",
    color: "white",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "30px",
  },
 
  verfySubTexts: {
    margin:"8px 0 !important",
    padding: "0 4px",
    color: "#fff",
    left: "16px",
    top: "135px",
    fontFamily: "Rubik !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "14px !important",
    letterSpacing:"-0.5px !important"
  },
  loader:{
    color: "white !important" ,
    width:'25px !important',
    height:'25px !important',
   },
   checkboxText:{
    fontFamily: "Rubik !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "14px !important",
    color:"#fff"
   },
   checkbox:{
    "&.MuiCheckbox-root" :{
      padding:"6px 4px 6px 6px !important"
    }
   }
 
}));

const UpdateDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation()
  const [valueColor, setValueColor] = useState();
  const [openTandC, setOpenTandC] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [submit, setSubmit] = useState(false);
  const cookies = new Cookies();

  const closemodel = () => {
    setOpenTandC(false)
  }

  const handleOnSubmit = (values) => {
    setSubmit(true);
    
    const  userDetails = {
         first_name: values.firstName,
        last_name: values.lastName ,
        isLastNameExist:values.noLastName
      };
      RegisterService.updateUserDetails(userDetails).then((res) => {
        setSubmit(false);
     if(res.data.success){
       //redeirect based on fromLocation
      if(location.state.fromLocation ==="aadhar"){
        navigate('/uploadaadhar')
      }else if(location.state.fromLocation ==="pan"){
        navigate('/uploadpan')
      }
     }
     })
     .catch(()=>{
      setSubmit(false);
      setSeverity("error");
      setSnackbarTitle("Something went wrong");
      setOpen(true);
     })
    }


    const setUserInfo = () => {
      loginService.getLoggedInUserInfo().then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const auth = { loggedIn: true, user: res.data };
            props.loginSuccess({ auth: auth });
            // setUserData(res.data);
          }
        } else {
          setSeverity("error");
          setSnackbarTitle("Unable to connect the server, please try again");
          setOpen(true);
        }
      });
    };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

    const handleError = (value1,value2) => {
      if ((value1.trim() === "")||(value1.trim() === "") ) {
        setValueColor(2);
      }
      else {
        setValueColor(0);
      }
    };

    const capitalize =((e)=> e.charAt(0).toUpperCase() + e.slice(1))

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
        <Notification  snackbarTitle={snackbarTitle} open={open} setOpen={setOpen} type={severity} />
          <Header/>
          <Container >
          <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                noLastName:false
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("First name required"),
                lastName: Yup.string().when("noLastName", {
                  is: false,
                  then: Yup.string()
                   .required("Last name required"),
                  otherwise: Yup.string(),
                }),
              })}
              onSubmit={ (values) => {
                handleOnSubmit(values)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}  autoComplete="off">
              <Grid container className={classes.container}>
              <Grid container  direction="column">
                <Textfield
                  valueColor={(touched.firstName && errors.firstName) && values.firstName
                    ? 2
                    : values.firstName
                    ? 1
                    : null || valueColor}
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={(e)=>{
                    setFieldValue("firstName",capitalize(e.target.value).replace(/[^a-z]/gi, ''))
                    setOpen(false)
                 }} 
                  placeholder={"First Name as per PAN"} />
                <Textfield 
                disabled={values.noLastName}
                valueColor={ (touched.lastName && errors.lastName) && values.lastName
                  ? 2
                  : values.lastName
                  ? 1
                  : null || valueColor}
                 name="lastName"
                 id="lastName"
                 value={values.lastName}
                 onChange={(e)=>{
                  setFieldValue("lastName",capitalize(e.target.value).replace(/[^a-z ]/gi, ''))
                  setOpen(false)
               }} 
                  placeholder={"Last Name as per PAN"}  />
                       <FormControlLabel   style={{paddingLeft:"4px"}}
                 value={values.noLastName}
                 onChange={(e)=>{
                  setFieldValue("noLastName", e.target.checked)
                  setFieldValue("lastName",'')
                }} 
                 name="noLastName"
                 control={<Checkbox className={classes.checkbox}  size="small" style={{color:"rgb(130, 100, 229)"}}  />}
                 label={<Typography className={classes.checkboxText}>I don’t have a last name</Typography>} />
              </Grid>

              <Grid style={{ margin: " 10% auto" }}>
                <Buttons
                 handleError={handleError}
                 value1={values.firstName}
                 value2={values.lastName}
                 animation={submit}
                 type="submit" 
                 value={"Update"} />
              </Grid>
            </Grid>
            </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    </>
  );
};

const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
});

export default connect(null, dispatchToProps)(UpdateDetails);

