import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import NoInternetConnection from "../NoInternet/Noninternet";
let maintenanceMode = JSON.parse(localStorage.getItem('maintenanceMode'))
  const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px !important",
  },
}));

const Page = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  let location = useLocation();
  const isAuthenticated = props.isAuthenticated;
  const  internetImage = localStorage.getItem('cachedImage')
  useEffect(() => {
    let allow = false;
    if (
      location.pathname === "/signupemail" ||
      location.pathname === "/signupphone" ||
      location.pathname === "/" ||
      location.pathname === "/otpphone" ||
      location.pathname === "/otpemail" ||
      location.pathname === "/type" ||
      location.pathname === "/companydetails" ||
      location.pathname === "/individualdetails" 
    ) {
      allow = true;
    } else {
      allow = false;
    }
    if (!allow) {
      if (!isAuthenticated) {
        history("/", { replace: true });
      } else if (maintenanceMode) {
        history("/maintenance", { replace: true });
      }
    } else if (isAuthenticated) {
      if (maintenanceMode) {
        history("/maintenance", { replace: true });
      } else {
        history("/home", { replace: true });
      }
    } else {
      if (maintenanceMode) {
        history("/maintenance", { replace: true });
      }
    }
    async function getImage() {
      try {
        !internetImage && await fetch('https://unlistedkartuserdocs.s3.ap-south-1.amazonaws.com/NoInternet.svg')
        .then(response => response.blob())
        .then(blob => {
          // Convert the blob to a base64 encoded string
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            localStorage.setItem('cachedImage', reader.result);
          };
        });
      } catch (error) {
       console.log(error) 
      }
    }
    getImage()
  }, []);

  return (
    <>
      <NoInternetConnection>
      <Container maxWidth={"md"} className={classes.root}>
        {props.children}
      </Container>
      </NoInternetConnection>
    </>
  );
};

// export default Page;

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    isAuthenticated: state.authReducer.auth && state.authReducer.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(Page);
