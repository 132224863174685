import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import OtpButton from "../../component/DarkMode/OtpButton";
import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import CreateIcon from "@mui/icons-material/Create";
import { useLocation, useNavigate } from "react-router-dom";
import SnackBar from "../../component/SnackBar/SnackBar";
import { RegisterService } from "../../_services/register";
import HelperText from "../../component/DarkMode/HelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import Notification from "../../component/AlertModel/Notification";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
    position:'relative'
  },
  container: {
    paddingTop: "52px"
  },
  verifyText: {
    color: "white",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
  },
  verfySubText: {
    color: "white",
    left: "16px",
    top: "135px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400!important",
    fontSize: "16px !important",
    lineHeight: "18px !important",
  },
  loader:{
    color: "white !important" ,
    width:'25px !important',
    height:'25px !important',
   }
}));

const ChangeEmail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isDisabled = true;
  const [submitButton, setSubmitButton] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [resendOtpCount, setResendOtpCount] = React.useState(0);
  const [invalidOtpCount,setInvalidOtpCount] = React.useState(0);
  const [resendOtpTime, setResendOtpTime] = React.useState(30);
  const [helperText, setHelperText] = useState();
  const [type, setType] = useState();
  const [timerText,setTimerText] = useState("")
  const [otp, setOtp] = useState(false);
  const [timer,setTimer] = useState(true)
  const [submit, setSubmit] = useState(false);
  const [valueColor, setValueColor] = useState();
  const email = location != undefined ? location.state != undefined ? location.state.email : null : null
  const userID = location != undefined ? location.state != undefined ? location.state.userId : null : null
  const handleOtp = () => {
    setOtp(true)
    const data = {
      email:email,
    };
    RegisterService.changeEmail(data).then((res) => {
      if (res.data.message === "OTP SENT SUCCESSSFULLY") {
        setResendOtpCount(resendOtpCount+1)
        setResendOtpTime(30)
        setTimer(true)
        setTimeout(()=> {
          setOtp(false)
        },1000)
        // setSeverity("success");
        // setSnackbarTitle("Otp sent successfully");
        // setOpen(true);
      } else {
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setOpen(true);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOnSubmit = (values) => {
    setSubmit(true);
    setHelperText("");
    setType()
    const data = {
      email:email,
      otp:values.otp,
      id:userID
    };
    RegisterService.updateEmail(data).then((res) => {
      setSubmit(false);
      setTimer(false)
      setResendOtpTime(0)
      if (res.data.message === "OTP_VERIFIED. and Data Updated.") {
        setSeverity("success");
        setSnackbarTitle("Email updated successfully");
        setOpen(true);
        setTimeout(() => {
          navigate("/editProfile");
        }, 3000);
      } else  if (res.data.message === "INVALID_OTP"){
          setInvalidOtpCount(invalidOtpCount +1)
          setHelperText("Invalid OTP");
          setType("error")
          setValueColor(2);
      }else  if (res.data.message === "ACCOUNT_BLOCKED"){
        setInvalidOtpCount(invalidOtpCount +1)
        setHelperText("Too many failed attmpts, try after 24 hours");
        setType("error")
        setValueColor(2);
      }else{
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setOpen(true);
      }
    });
  };
  

  useEffect(() => {
    if(resendOtpTime===0){
      setResendOtpTime(null)
      setTimeout(()=>{
        setTimerText()
       },1000)
    }

    // exit early when we reach 0
    if (!resendOtpTime) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const resendOtpSec = setInterval(() => {
      setResendOtpTime(resendOtpTime - 1);
      setTimerText(`Didn't receive OTP? Retry in 00:${resendOtpTime <10 ? `0${resendOtpTime}` :resendOtpTime}`)
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(resendOtpSec);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [resendOtpTime]);

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
        <Notification  snackbarTitle={snackbarTitle} open={open} setOpen={setOpen} type={severity}/>
          <Container >
            <Grid className={classes.container} spacing={1}>
              <Grid>
                <Typography className={classes.verifyText}>
                  Verify your email
                </Typography>
                <Grid mb={2.5} style={{ display: "flex" }}>
                  <Typography className={classes.verfySubText}>
                    {location!=undefined ? location.state != undefined? location.state.email  : null : null} 
                    <CreateIcon
                    onClick={() => {
                      navigate("/editProfile", {
                        replace: true,
                        state:"email"
                      });
                    }}
                    style={{ color: "#8264E5", fontSize:"20px", padding:"4px", marginBottom:"-6px" ,  boxSizing:"unset"}}
                  />
                  </Typography>
                  
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  otp: "",
                }}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .min(0)
                    .test("length", "Must be exactly 6 digits", (val) => {
                      return val && val.toString().length === 6;
                    }),
                })}
                onSubmit={(values) => {
                  handleOnSubmit(values);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}  autoComplete="off">
                    <Grid>
                      <Textfield
                       otp={values.otp.length>1 &&  true}
                       valueColor={valueColor}
                       placeholder={"OTP"}
                       type="tel"
                       onChange={(e)=>{
                        setFieldValue('otp', e.target.value)
                        setHelperText();
                        setType()
                        setValueColor();
                      }}
                       value={values.otp}
                       error={Boolean(touched.otp && errors.otp)}
                       helperText={touched.otp && errors.otp}
                       name="otp"
                       id="otp"
                       onInput={(e) => {
                        setOpen(false)
                         e.target.value = e.target.value
                         .toString()
                         .slice(0, 6)
                         .replace(/[^0-9]/g, "");
                         if (e.target.value.length===6) {
                           setSubmitButton(false)
                          }else{
                            setSubmitButton(true)
                           }
                       }}
                      />
                        {timer?
                       <HelperText helperText={timerText} type={null} />:
                       <HelperText helperText={helperText} type={type} />}
                      <OtpButton
                          disabled={timerText|| otp  || invalidOtpCount ===4}
                          handleOtp={()=>{
                            handleOtp()
                            setValueColor()
                            setHelperText()
                            setFieldValue("otp", '')
                          }}
                          value={"Resend OTP"}
                        />
                    </Grid>
                    <Grid>
                      <Buttons
                        submitButton={submitButton || otp || invalidOtpCount ===4}
                        value={"VERIFY"}
                        type="submit"
                        // handleError={handleError}
                        value1={values.otp}
                        animation={submit}
                      />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Container>
        </Box>
      </Page>
    </>
  );
};

export default ChangeEmail;
