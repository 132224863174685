import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Search from "./Search";
import AvailableSlide from "./AvailableSlide";
import ComingSoonSlide from "./ComingSoonSlide";
import OutOfStockSlide from "./OutOfStockSlide";
import { Context } from "../../store/store";
import { CloseOutlined } from "@mui/icons-material";
import { userService } from "../../_services/users";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#1C1C1C !important ",
    border: "1px solid #323333 !important",
    bottom: "0",
    width: "100%",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#AC93FF !important",
      height: 2,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#AC93FF !important",
    },
  },
  tab: {
    "&.MuiTab-root ": {
      padding: " 12px 10px  !important",
      textTransform: "initial  !important",
      color: "white !important",
      opacity: "1 !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: "Poppins !important",
    },
  },
  popUpInfo: {
    marginTop: "10px",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    color: "#fff",
  },
  festivalInfo: {
    marginTop: "10px",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    color: "#fff",
  },
  tabPanel: {
    padding: "14px 8px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabComponent = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [shares, setShares] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchresult, setSearchresult] = useState([]);
  const [state, dispatch] = React.useContext(Context);
  const dematInfo = localStorage.getItem("dematInfo");
  const [info, showInfo] = useState(
    dematInfo === undefined || dematInfo === null ? true : false
  );
  const [holiday,setHoliday]= useState({
    open:false,
    data:null
  })
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const key = "name";
      const filteredArr =
        shares &&
        shares.filter(
          (obj) =>
            obj[key].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      setSearchresult(filteredArr);
    } else {
      setSearchresult(shares);
    }
  };

  const handleInfo = () => {
    showInfo(false);
    localStorage.setItem("dematInfo", false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type: "SET_SECTOR", payload: null });
    dispatch({ type: "SET_FILTERVALUE", payload: null });
  };

  const handleHoliday = () => {
    setHoliday({
      open:false,
      data:null
    });
    const currentDate = new Date();
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
    const data = {
      value: true,
      expirationTime: endOfDay.getTime()
    };
    sessionStorage.setItem("holiday", JSON.stringify(data));
  };

  const getHoliday =(()=>{
    try{
      userService.getHoliday().then((res)=>{
        if(res && res.data.length !==0){
          setHoliday({
            open:true,
            data:res.data[0]
          })
        }
      })
    }catch(error){
      console.log(error)
    }
  })

  useEffect(()=>{
    const isHolidayViewed = sessionStorage.getItem('holiday') 
    const value = isHolidayViewed !==null ? JSON.parse(isHolidayViewed) : false
    const currentTime = new Date().getTime(); 
    if (value && currentTime >= value.expirationTime) {
      sessionStorage.removeItem('holiday');
      getHoliday()
    } else if(!value){
      getHoliday()
    } else{
      return null;
    }
  },[])

  const renderInfoCard = () => {
    return (
      <Card
        style={{
          margin: "16px 16px 0",
          backgroundColor: "transparent",
          position: "relative",
          padding: "15px 10px 10px",
          border: "1px solid #3a2a6d",
          borderRadius: "10px",
        }}
      >
        <CloseOutlined
          onClick={handleInfo}
          style={{
            color: "#fff",
            position: "absolute",
            right: "10",
            top: "5",
            width: "16px",
          }}
        />
        <Typography className={classes.popUpInfo}>
          <p style={{ margin: "5px 0" }}> Dear Customer,</p>
          Please note that recent updates in depository operations (NSDL and
          CDSL) have led to a longer processing time for share transfers,
          particularly for first-time buyers on our app. This is due to the
          necessity of manually adding your demat account as a beneficiary, a
          step that must be completed before your account can receive shares.
          Consequently, it may take 5 to 7 working days for your shares to be
          credited. Rest assured, your funds are securely held in an escrow
          account during this period. We're actively collaborating with
          depositories to expedite this process and will keep you informed of
          any advancements. For any queries, feel free to contact us at{" "}
          <a href="" style={{ color: "#fff" }}>
            operations@unlistedkart.com
          </a>
          .
        </Typography>
      </Card>
    );
  };

  const renderHolidayCard = () => {
    return (
      <Card
        style={{
          margin: "16px 16px 0",
          backgroundColor: "transparent",
          position: "relative",
          padding: "15px 10px 10px",
          border: "1px solid #3a2a6d",
          borderRadius: "10px",
        }}
      >
        <CloseOutlined
          onClick={handleHoliday}
          style={{
            color: "#fff",
            position: "absolute",
            right: "10",
            top: "5",
            width: "16px",
          }}
        />
        <Typography className={classes.festivalInfo}>
          <p style={{ margin: "5px 0",fontWeight:'bold',color:'#7057BE' }}> {holiday.data.holiday_name}</p>
          {holiday.data.description}
        </Typography>
      </Card>
    );
  };
  return (
    <>
      <AppBar position="sticky" className={classes.appbar}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab className={classes.tab} label="Available" {...a11yProps(0)} />
          <Tab className={classes.tab} label="Coming Soon" {...a11yProps(1)} />
          <Tab className={classes.tab} label="Out of Stock" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {info ? renderInfoCard() :holiday.open ?renderHolidayCard() : null }

      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <Search searchkey={searchHandler} />
        <AvailableSlide
          setShares={setShares}
          Sharesvalues={searchTerm.length < 1 ? shares : searchresult}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <ComingSoonSlide />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <OutOfStockSlide />
      </TabPanel>
    </>
  );
};

export default TabComponent;
