import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import "./sessionStyle.css"
import { Typography } from '@mui/material';
import { logoutSuccess } from "../../store/bugs";
import { connect } from "react-redux";
import { useNavigate} from "react-router-dom"

const SessionExpiredModal = (props) => {

    const navigate = useNavigate();

    const onConfirmClick = ()=>{
        props.logoutSuccess();
        navigate("/",{ replace: true })
    }

    return (
        <div >
            <SweetAlert
             style={{background:"#1c1c1c"}}
             openAnim={false}
             show={props.open}
             confirmBtnText="OK"
             onConfirm={onConfirmClick}    
             confirmBtnBsStyle="primary"    
            >
              <Typography variant={"h6"}  style={{fontFamily:"Poppins", color:"white", fontSize:"16px"}} >Session has been expired! <br/>Please re-login to continue</Typography>  
            </SweetAlert>
            
        </div>
    );
}

const dispatchToProps = (dispatch) => ({
    logoutSuccess: () => dispatch(logoutSuccess()),
  });

export default connect(null, dispatchToProps)(SessionExpiredModal);
