import axios from "axios";

import { helpers } from "../_helpers/index";
 

//default header and api endpoint config
export const loginService = {
  googleAuthentication,
  mobileAuthentication,
  emailAuthentication,
  getLoggedInUserInfo,
  verifyMobileOtp,
  verifyEmailOtp,
  checkMobileExists,
  getOtpViaCall,
  appleAuthentication,
  getFirebaseSecrets
};

function getFirebaseSecrets() {
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.get(`/mobile/getFirebaseSecrets`, requestOptions)
      .then(res => res)
      .catch(error => error.response)
}
function getOtpViaCall(username) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      `/getOtpViaCall`,
      JSON.stringify({ username: username }),
      requestOptions
    )
    .then((res) => res)
    .catch((error) => error.response);
}

 
 
async function appleAuthentication(data) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/apple_signup`, JSON.stringify(data), requestOptions)
    .then((res) => res)
    .catch((error) => error.response);
}


async function googleAuthentication(data) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/google_signup`, JSON.stringify(data), requestOptions)
    .then((res) => res)
    .catch((error) => error.response);
}

function mobileAuthentication(username) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      `/mobile/otpauthentication`,
      JSON.stringify({ username: username }),
      requestOptions
    )
    .then((res) => res)
    .catch((error) => error.response);
}

function emailAuthentication(data) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      `/email/otpauthentication`,
      JSON.stringify(data),
      requestOptions
    )
    .then((res) => res)
    .catch((error) => error.response);
}

async function getLoggedInUserInfo() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .get(`/users/login_detail`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function verifyMobileOtp(username, otp) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      `/mobile/otpvalidation`,
      JSON.stringify({ username, otp }),
      requestOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

async function verifyEmailOtp(email, otp) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      `/email/otpvalidation`,
      JSON.stringify({ email, otp }),
      requestOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

async function checkMobileExists(username) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      `/mobile_data_check`,
      JSON.stringify({ username }),
      requestOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}