import React, {useEffect, useState} from "react";
// import InfoIcon from "@mui/icons-material/Info";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { kycService } from "../../_services/kyc";
import SessionExpiredModal from "../../component/AlertModel/SessionExpiredModal";
import InfoIcon from "../../Images/whiteInfo.svg";

const useStyles = makeStyles((theme) => ({
  helpertextContent: {
    color: "#fff !important",
    fontSize: "10px !important",
    marginLeft: "6px !important",
    fontFamily:"Poppins !important",
    fontWeight:"400 !important"
  },
}));

function KycBand() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [kycStatus , setKycStatus] = useState()
  const [openSessionExpiredModal, setOpenSessionExpiredModal] =
    React.useState(false);
    const slides = localStorage.getItem("KYCSlides")

  useEffect(() => {
    getUserBasicKycDetails()
  }, []);

  // Check kyc status and display kyc status on the top of home screen
  const getUserBasicKycDetails = () => {
    kycService
       .getUserBasicKycDetails()
       .then((res) => {
         if (res) {
           if (res.status === 200) {
             if (res.data) {
               setKycStatus(res.data.data.display_status);
             }
           } else {
             if (res.status === 401 && res.data.name === "TokenExpiredError") {
               setOpenSessionExpiredModal(true);
             }
           }
         }
       })
       .catch((error) => console.log(error));
   }

  return (
  <>
   {(kycStatus === "In Progress" || kycStatus === "Pending") && 
    <Grid style={{ background: "#765BCF", height: "2rem" }}>
      <Grid
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={InfoIcon} width={14} />
        <Typography className={classes.helpertextContent}>
          Complete your KYC  {" "}
          <a
            style={{ 
              textDecoration: "underline",
              color: "#fff",
            }}
          onClick={()=> {
            if(kycStatus === "Pending" && !slides){
              navigate("/kycslides")
            }else{
              navigate("/status")
            }}}
          >
            here
          </a>
        </Typography>
      </Grid>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Grid>
  }</>
  );
}

export default KycBand;
