import React, { useState,useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Page from "../../component/Page/Page";
import BottomNavigation from "../../component/Home/BottomNavigation";
import HoldingCard from "../../component/Portfilio/HoldingCard";
import PriceCardsDropdown from "../../component/Portfilio/PriceCardsDropdown";
import OrderDropdown from "../../component/Portfilio/OrderDropdown";
import { holdingService } from "../../_services/holding";
import { orderService } from "../../_services/orders";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import HoldingsSkeleton from "../../component/Skeleton/HoldingSkeleton.jsx";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh)",
    background: "#0D0D0D !important",
  },
  appbar: {
    backgroundColor: "#1C1C1C !important ",
    border: "1px solid #323333 !important",
    bottom: "0",
    width: "100%",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#AC93FF !important",
      height: 2,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#AC93FF !important",
      fontWeight: "500 !important",
    },
  },
  tab: {
    "&.MuiTab-root ": {
      textTransform: "initial  !important",
      color: "white !important",
      opacity: "1 !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: "Poppins !important",
    },
  },
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px !important",
    },
  },
  center :{
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)", 
  },
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "16px  !important",
    color: "#5F6060 !important",
    lineHeight: "24px !important",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Holdings(props) {
  // const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(location?.state?.value ? 1: 0);
  const [signed, setSigned] = useState(location?.state?.signed);

  const [holding, setHolding] = useState(null);
  const [order, setOrder] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const userID =  props.authReducer.auth.user && props.authReducer.auth.user.id;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
     getHoldingData()
     getOrdersData()
     const element = document.getElementById("content");
     element.scrollIntoView();
  },[])

  React.useEffect(() => {
    document.addEventListener("getLatestOrderData", async function (e) {
       if(e.data.orders){
         getHoldingData()
         getOrdersData()
        }else{
        console.log("no msg")
      }
  });
    return () =>
      document.removeEventListener("getLatestOrderData", function (e) {
      });
  }, []);
  
  const getHoldingData =async () => {
    holdingService
      .getHoldings()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
           
            setHolding(res.data);
            setSkeleton(false);
          }else if(res.status === 401){
 
            setOpenSessionExpiredModal(true)
            setSkeleton(false);
          } else {
            console.log("error");
          }
        }
      })
      .catch((error) => console.log(error));
  };


  const getOrdersData = async() => {
    orderService
      .getOrders()
      .then((res) => {
        if (res) {
     
          if (res.status === 200) {
            setOrder(res.data);
            setSkeleton(false);
          }else if(res.status === 401){
      
            setOpenSessionExpiredModal(true)
            setSkeleton(false);
          } else {
            console.log("error");
          }
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer} id="content">
          <>
            <AppBar position="sticky" className={classes.appbar}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="My Scrips"
                  {...a11yProps(0)}
                />
                <Tab className={classes.tab} label="Orders" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} className={classes.tabPanel}>
              
             {skeleton? <HoldingsSkeleton/>:<>
             {
              holding && holding.total_count? <> <HoldingCard data={holding}/>
              <Grid item xs={5} style={{ paddingLeft: "0px" }}>
                <hr
                  style={{
                    backgroundColor: "#494949",
                    border: "none",
                    height: "1px",
                    margin: "16px",
                  }}
                />
                {holding &&<PriceCardsDropdown data={holding.client_holdings}/>}
              </Grid> </>:<Grid className={classes.center}><Typography className={classes.text}>Oopsy, no scrips!</Typography></Grid>
             }
             </>}
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
              {order && order?.client_holdings && order?.client_holdings?.length !== 0 ? <OrderDropdown data={order} signed={signed} setSigned={setSigned} userID={userID}/>:<Grid className={classes.center}><Typography className={classes.text}>Oopsy, no orders!</Typography></Grid>}
             
            </TabPanel>
          </>
      <BottomNavigation  activeTab={1}/>
      <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Box>
      </Page>
    </div>
  );
}

 
 
const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(Holdings);