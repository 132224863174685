import axios from 'axios'
import { helpers } from '../_helpers/index'
 
export const holdingService = {
    getHoldings,
}

function getHoldings() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/holdings_of_client/`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
