import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  colors,
  useTheme,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircleIcon from "@mui/icons-material/Circle";
Chart.register(ArcElement);
const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    "& .MuiPaper-root-MuiCard-root": {
      backgroundColor: "#000 !important",
    },
  },
  status: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: " 4px",
  },
  boxHeader: {
    fontWeight: "normal",
    marginLeft: "14px",
    padding: "4px 0px",
  },
  active: {
    color: "#00C914",
    width: "4px  !important",
    height: "4px  !important",
  },
  inactive: {
    color: "#D50000",
    width: "4px  !important",
    height: "4px  !important",
  },
  closed: {
    color: "#314B8F",
    width: "4px  !important",
    height: "4px  !important",
  },
  processing: {
    color: "#A48800",
    width: "4px  !important",
    height: "4px  !important",
  },
  completed: {
    color: "#A40094",
    width: "4px  !important",
    height: "4px  !important",
  },
  description: {
    fontFamily: "poppins  !important",
    lineHeight:"14px !important",
    maxWidth: '90%',
    },
  text: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight: "18.75px !important",
  },
}));
const KeyInvestors = ({ totalinvestment, keyinvestordata }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [countData, setCountData] = useState([]);
  const [status, setStatus] = useState([]);
  const [id, setId] = useState();
const [hover,setHover] = useState(false);
  const [color,setColor] = useState()
  let statusData = [];
  let item = [];
  useEffect(() => {
    setStatus(keyinvestordata);
    status.map((data) => {
      item.push(data.investorcolor);
      statusData.push(parseInt(data.investorPercentage));
    });
    setItems(item);
    setCountData(statusData);
  }, [status]);
  const data = {
    datasets: [
      {
        data: countData,
        backgroundColor: items,
        borderWidth: 0,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: items,
  };
  const options = {
    hoverOffset: color && 30,
    animation: { duration: 2000 },
    cutout: 60,
    layout: { padding: 30 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      // intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },

    // on click of slice highlight the names listed below grpah
    onClick: (evt, activeEls, chart) => {
      if(activeEls?.length === 1 && id !== activeEls[0].index){
        setId(activeEls[0].index)
        setColor(chart.data.labels[activeEls[0].index])
      }else{
         setColor("");   
         setId(null)
      }
    },

  };
  return (
    <>
      <Card
        className={classes.root}
        style={{ backgroundColor: "#0D0D0D", color: "#fff" }}
      >
        <CardContent style={{padding :"0 16px 16pxp"}}>
          <Box height={250} position="relative" onMouseEnter={()=> setHover(true)} onMouseLeave={()=>{
            setHover(false)
            setColor("");   
            setId(null)
          }}>
            <Doughnut data={data} options={options} />
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: "50%",
                left: 0,
                textAlign: "center",
                marginTop: "-8px",
                lineHeight: "20px",
              }}
              onClick={()=>{
                setColor("");   
                setId(null)
              }}
            >
              <span className={classes.text} style={{ fontFamily: "Roboto" }}>
              {keyinvestordata && color && hover ? `${keyinvestordata[id].investorPercentage}%` : "100%"}
              </span>
              <br/>
            </div>
          </Box>
          <Box mt={2}>
            <>
              <Grid container rowSpacing={1} className={classes.status}>
                {keyinvestordata.map((item,index) => {
                  return (
                    <Grid
                      item
                      key={index}
                      xs={6}
                      sm={6}
                      md={6}
                      style={{ alignItems: "baseline", display: "flex" }}
                    >
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        style={{ alignItems: "baseline", display: "flex" }}
                      >
                        <CircleIcon
                          style={{
                            width:color ===item.investorcolor && hover ? " 10px" : "8px",
                            height:color ===item.investorcolor && hover ?" 10px" : "8px",
                            color: item.investorcolor,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        sm={11}
                        md={11}
                        style={{ alignItems: "baseline", display: "flex" }}
                      >
                        <Typography
                          className={classes.description}
                          style={{ color: color ===item.investorcolor && hover ? "#fff" : "#767677" ,
                          fontSize: color ===item.investorcolor && hover ? "11px" : "10px",
                         fontWeight: color ===item.investorcolor && hover ? "500" : "400",}}
                        >
                          {item.investorName}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default KeyInvestors;