import React, { useRef, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Dark from "@amcharts/amcharts5/themes/Dark"
import Responsive from "@amcharts/amcharts5/themes/Responsive"
import * as am5stock from "@amcharts/amcharts5/stock";
import { Typography, Grid,Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { securityService } from '../../_services/security';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "15px !important",
    margin:"0px 2px !important"
  },
  value: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "10px  !important",
    color: "#fff !important",
    lineHeight: "15px !important",
    margin:"0px 2px !important"
  },
  box:{
    display:"flex !important",
     background:"#19132C !important",
      padding:"4px 12px !important",
      borderRadius:"10px !important",
      alignItems:"center !important"
  }
}));
function Stock(props) {
  const chart = useRef(null);
  const classes = useStyles();
  const ref = useRef();
  const [value,setValue] = React.useState()
  useLayoutEffect(() => {

    // amchart licence code
    am5.addLicense("AM5S371141295")
    let root = am5.Root.new("chartdiv",{
      useSafeResolution: false
    });

    // show current day as start of graph
    const today =  new Date().getDay()
    root.locale.firstDayOfWeek = today;
    root.setThemes([
      am5themes_Animated.new(root),
      Dark.new(root),
      Responsive.new(root)
    ]);
    let stockChart = root.container.children.push(am5stock.StockChart.new(root, { }))
    root.numberFormatter.set("numberFormat", "#,###.00");
    let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: false,
      panY: false,
    }));
    let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        pan: "zoom",
        opposite: false,
        minGridDistance: 25
      }),
      extraMin: 0.1,
      // tooltip: am5.Tooltip.new(root, {}),
      numberFormat: "[bold fontFamily: Roboto fontWeight:600 fontSize:10px #767677]#,###",
      extraTooltipPrecision: 1,
      forceHidden: false,
      
    }));
    var myTheme = am5.Theme.new(root);
    root.utc = true;
    root.dateFormatter.set("dateFormat", "dd MMM yy").toUpperCase();
    let dateAxis = mainPanel.xAxes.push(am5xy.DateAxis.new(root, {
      groupData: true,
      groupCount:130,
      baseInterval: {
        timeUnit: "day",
        count: 1,
      },
        renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 25
      }),
      startLocation:0,
      endLocation:0,
      // tooltip: am5.Tooltip.new(root, {}),
      forceHidden: true
    }));

    // tooltip text which displayed on graph
    var tooltip = am5.Tooltip.new(root, {
      pointerOrientation: "vertical",
      labelText:"[bold fontFamily: Roboto fontWeight:500]₹{valueY}[/] [#767677 bold]| [/][#767677 fontFamily: Poppins fontWeight:400 ]{valueX.formatDate(MMM dt, yyyy)}[/]"
    })

    let valueSeries = mainPanel.series.push(am5xy.LineSeries.new(root, {
      valueXField: "Date",
      valueYField: "Close",
      valueYGrouped: "average",
      calculateAggregates: true,
      xAxis: dateAxis,
      yAxis: valueAxis,
      stroke: am5.color('#AC93FF'),
      fill: am5.color('#0D0D0D'),
      color: am5.color(0xf3f3f3),
      tooltip: tooltip,
    }));
    valueSeries.strokes.template.set("strokeWidth", 1.5);
    let valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart,
    }));
    let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
      opposite: false
    });
    let volumeValueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: "#.#a",
      forceHidden: true,
      extraTooltipPrecision: 0.1,
      height: am5.percent(0),
      y: am5.percent(100),
      centerY: am5.percent(100),
      renderer: volumeAxisRenderer,
      opposite: false,
    }));
    let volumeSeries = mainPanel.series.push(am5xy.ColumnSeries.new(root, {
      name: "Volume",
      xAxis:volumeValueAxis,
      yAxis:  dateAxis,
      valueYField: "Volume",
      valueXField: "Date",
      tooltip: am5.Tooltip.new(root, {}),
      clustered: true
    }));
    mainPanel.appear(100, 500);
    volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        return stockChart.getVolumeColor(dataItem);
      }
      return fill;
    })
    valueLegend.data.setAll([]);
    mainPanel.set("cursor", am5xy.XYCursor.new(root, {
      yAxis: valueAxis,
      xAxis: dateAxis,
      color: am5.color(0xff0000)
    }));
    let cursor = mainPanel.get("cursor");

    // cursor line styles
    cursor.lineX.setAll({
      stroke: am5.color(0x767677),
      strokeWidth: 1,
      strokeDasharray: []
    });
    cursor.lineY.setAll({
      visible: false
    });
    mainPanel.panelControls.set("forceHidden");
    cursor.lineX.setAll({
      visible: true
    });

    valueSeries.events.on("datavalidated", function() {
      setTimeout(()=>{
        mainPanel.get("cursor").setAll({
          positionX: 0.5,
          positionY: 0.5,
          alwaysShow: true
        });
      }, 1000)
    });
    
    mainPanel.plotContainer.events.on("pointerover", ()=>{
      mainPanel.get("cursor").setAll({
        positionX: undefined,
        positionY: undefined,
      });    
    })
    mainPanel.plotContainer.events.on("pointerout", ()=>{
      mainPanel.get("cursor").setAll({
        alwaysShow: false
      });    
    })
 
// get scrip  price trend data
    function loadData(ticker, series, granularity) {
      const data = {
        related_security_id:props.id
      }
      securityService.priceHistory(data).then((res) => {
          if (res.status == 200 && res.data.data.length >0) {
            setValue(res.data.minAndMAx[0])
          let data =res.data.data
            let processor = am5.DataProcessor.new(root, {
              dateFields: ["Date"],
              dateFormat: granularity == "minute" ? "MMM dt, yyyy" : "MMM dt, yyyy",
              numericFields: ["Close"]
            });
            processor.processMany(data);
            ref.current = data[0].Date;
            am5.array.each(series, function (item) {
              item.data.setAll(data);
            });
        }
      });
    }
    // Load initial data for the first series
    let currentGranularity = "day";
    loadData("MSFT", [valueSeries, volumeSeries], currentGranularity);
    var periodSelector = am5stock.PeriodSelector.new(root, {
      stockChart: stockChart,
      periods: [
        { timeUnit: "day", count: 8, name: "1W" },
        { timeUnit: "month", count: 1, name: "1M" },
        { timeUnit: "week", count: 27, name: "6M" },
        { timeUnit: "week", count: 53, name: "1Y" },
        { timeUnit: "week", count: 105, name: "2Y" },
      ],
    })
    valueSeries.events.on("datavalidated", function(err,data) {
        periodSelector.selectPeriod({ timeUnit: "week", count: 27, name: "6M" })
        let today = new Date()
        let array = []
        const month  = [0,0,1,7,13]
        month.map((i)=>{
            let oneMonth = moment(today).subtract(i, 'months').format("YYYY-MM-DD")
            array.push(Math.floor(new Date(oneMonth).getTime()) )
        })
        let divID = document.getElementsByClassName("am5stock-link")
        array.map((i,index)=>{
          if(ref.current > i) {
            divID[index].className += "   am5stock-disabledBtn" 
          } 
        })
    })

    // display list of deafult periods 
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document.getElementById("chartdiv"),
      stockChart: stockChart,
      controls: [
        periodSelector
      ],
    })

    // circle on the point of line
    var tooltipBullet = mainPanel.plotContainer.children.push(am5.Circle.new(root, {
      radius: 5,
      fill: am5.color('#AC93FF'),
      stroke:am5.color('#FFF'),
      x: -1000
    }));
    
    valueSeries.on("tooltipDataItem", function(tooltipDataItem) {
      var x = -1000;
      var y = -1000;
      if (tooltipDataItem) {
        var point = tooltipDataItem.get("point");
        if (point) {
          x = point.x;
          y = point.y;
        }
      }
      tooltipBullet.setAll({
        x: x,
        y: y
      })
    });
    
    tooltip.on("opacity", function(opacity){
      tooltipBullet.set("opacity", opacity);
    });

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <>
    <div>
{value &&
  <Grid style={{display:"flex", justifyContent:"end", margin:'1rem 1rem 0'}}>
  <div className={classes.box}>
    <Typography className={classes.text}>
    52 Weeks High
    </Typography>
    <Typography className={classes.value}> {" "}{value.max}</Typography>
    <Typography mx={0.2} style={{color:"#A489FF"}}>{" "}|{" "}</Typography>
    <Typography className={classes.text}>
    52 Weeks Low
    </Typography>
    <Typography className={classes.value}> {" "}{value.min}</Typography>
  </div>
</Grid>}
      </div>
      <div id="chartdiv" style={{ width: "100%", height: "calc(100vh - 400px)", textAlign:"center" }}></div>
    </>
  );
}
export default Stock;