import { createAction, createReducer } from '@reduxjs/toolkit'

//action creater
export const bugAdded = createAction("bugAdded")
export const bugRemoved = createAction("bugRemoved");
export const bugResolved = createAction("bugResolved");




const stete = {
    token:null,    
    auth:null,
    rateAlert:null,
    timer:null,
    bugs:[],
    request_id:''
   
}

export const setToken = createAction("setToken")
export const loginSuccess = createAction("loginSuccess")
export const logoutSuccess = createAction("logoutSuccess") 
export const RoutesRole = createAction("RoutesRole")
export const rateAlert = createAction("rateAlert")
export const setRequestID = createAction("setRequestID")


let lastId = 0;
export default createReducer(stete,{
    
    bugAdded:(state, action) =>{
        state.bugs.push({
            id:++lastId,
            description:action.payload.description,
            resolved:false
        })
    },

    setToken:(state, action) =>{
        state.token=action.payload.token
    },
    

    loginSuccess:(state, action,dispatch) =>{
        state.auth=action.payload.auth
        state.rateAlert=true;
        state.timer=new Date()
      
    },
    kycRefId:(state, action,dispatch) =>{
        state.id="emptykjhkj"
    },
   
    logoutSuccess:(state, action) =>{
        state.auth.loggedIn=false;
        state.auth.user=null;
        state.token=null
        state.rateAlert=null;
        state.timer=null
        state.request_id = ""
    },
    rateAlert:(state, action) =>{
        state.rateAlert=false;
    },

    RoutesRole:(state, action) =>{     
        state.auth.user.default_role_code=action.payload.role;
        state.auth.user.default_description=action.payload.description;        
    },

    [bugResolved.type]:(state, action)=>{
        const index = state.bugs.findIndex(bug => bug.id===action.payload.id);
        state.bugs[index].resolved=true;
    },

    bugRemoved:(state, action) => {
        return state.bugs.filter(bug => bug.id !== action.payload.id)
    }, 
    setRequestID:(state, action)=>{
        state.request_id = action.payload
    }
   

})



