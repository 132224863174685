import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import BottomNavigation from "../../component/Home/BottomNavigation";
import { Box, Tabs, Tab, Typography, Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import ShareCard from "../../component/Overview/ShareCard";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import About from "../../component/Overview/About";
import Stock from "../../component/Overview/TrendGraph";
import News from "./News";
import { useLocation, useNavigate } from "react-router-dom";
import { securityService } from "../../_services/security";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
 
  },
  appbar: {
    backgroundColor: "#1C1C1C !important ",
    border: "1px solid #323333 !important",
    position: "fixed",
    bottom: "0",
    width: "100%",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#AC93FF !important",
      height: 2,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#AC93FF !important",
      fontWeight: "500 !important",
    },
  },
  tab: {
    "&.MuiTab-root ": {
      fontFamily: "Poppins  !important",
      textTransform: "initial  !important",
      color: "#494949 !important",
      opacity: "1 !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
    },
  },
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px !important",
    },
  },
  icon: {
    fill: "#fff  !important",
    margin: "15px 0px 10px 15px",
    cursor: "pointer",
    fontSize:"24px !important"
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Overview() {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(location?.state?.data  ? 2 : 0);
  const [securityId, setSecurityId] = useState(
    location != undefined
      ? location.state != undefined
        ? location.state.id
        : null
      : null
  );
  const [overview, setOverview] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    securityService.getSecurityByID(securityId).then((res) => {
      if (res) {
        if (res.status == 200) {
          setOverview(res.data);
        }else{
          console.log("error")
        }
      }
    });
  }, []);

  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/home")}
                className={classes.icon}
              />
            </Grid>
          </Grid>
          <ShareCard  overview={overview} securityId={securityId}/>
          <>
            <AppBar position="sticky" className={classes.appbar}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  className={classes.tab}
                  style={{ fontFamily: "Poppins" }}
                  label="Price Trend"
                  {...a11yProps(0)}
                />
                <Tab
                  className={classes.tab}
                  style={{ fontFamily: "Poppins" }}
                  label="About"
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.tab}
                  style={{ fontFamily: "Poppins" }}
                  label="News"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>

            <TabPanel className={classes.tabPanel} value={value} index={0}>
              <Stock  id={securityId}/>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
              <About overview={overview} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={2}>
              <News securityId={securityId}/>
            </TabPanel>
          </>
        </Box>
      </Page>
      <BottomNavigation  activeTab={0}/>
    </div>
  );
}

export default Overview;
