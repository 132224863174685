import axios from 'axios'

 
export const RegisterService = {
    registerMobileNumber,
    registerEmail,
    verifyRegisterData,
    verifyEmailOtp,
    verifyMobileOtp,
    updateMobileNumber,
    changeEmail,
    updateEmail,
    updateUserDetails
};   

function registerMobileNumber(mobileNumber) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/public_user/otpauthentication`, JSON.stringify({ mobile_number: mobileNumber}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function registerEmail(data) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/gmail_signup`, JSON.stringify(data), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


function verifyRegisterData(data){
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/update_public_user_details`, JSON.stringify(data), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}

async function verifyEmailOtp(data) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      `/verify_email_otp`,
      JSON.stringify(data),
      requestOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}


async function verifyMobileOtp(data) {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios
      .post(
        `/verify_mobile_otp`,
        JSON.stringify(data),
        requestOptions
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  }

  function updateMobileNumber(data){
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/update_user_mobile_no`, JSON.stringify(data), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}

function changeEmail(data) {
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/send_otp_for_email_update`, JSON.stringify(data), requestOptions)
      .then(res => res)
      .catch(error => error.response)
}

function updateEmail(data){
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/verfiy_otp_for_email_update`, JSON.stringify(data), requestOptions)
      .then(res => {
          return res
      })
      .catch(error => {
          return error.response
      })
}



function updateUserDetails(data){
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/update_userdetails`, JSON.stringify(data), requestOptions)
      .then(res => {
          return res
      })
      .catch(error => {
          return error.response
      })
}
