import React, {useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box,  Typography, Grid, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    paddingBottom:'2rem'
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  text: {
    color: "#A489FF !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 20px !important",
    lineHeight: "24px !important",
    marginLeft:"1rem !important"
  },
  Typography: {
    textAlign: "justify",
    color: "#fff",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF",
  },
  },
  btn:{
    color:"#fff !important",
    padding:"10px 60px !important",
    background:"#6D50CC !important",
    fontFamily:"Poppins !important",
    borderRadius:"16px !important",
    fontWeight:"600 !important",
},
btnBox:{
    textAlign:"center !important"
}
}));

function LegalPolicy() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

 useEffect(() => {
  const element = document.getElementById("content");
  element.scrollIntoView();
  }, []);

 const navigateFunction = (()=>{
  if(location.state){
    navigate("/termsandcondition",{ replace: true , state:{...location.state,condition:true}})
  }else{
    navigate("/profile",{ replace: true , state:{condition:true}})
  }
 })

  return (
    <div>
        <Box className={classes.boxcontainer} id="content">
          <Grid container direction="row" style={{padding: "20px ",}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "initial",
                
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={navigateFunction}
              />
              <Typography className={classes.text}>Legal and Compliance</Typography>
            </Grid>
          </Grid>
       
          <Grid item xs={12} md={12} >
          <Typography variant="body2" className={classes.Typography}>
            The information, software, products, and services included in or
            available through <strong>UNLISTEDKART</strong> sites/services may
            include inaccuracies or typographical errors. Changes are
            periodically made to <strong>UNLISTEDKART</strong> sites/services
            and to the information therein. <strong>UNLISTEDKART</strong> and/or
            its respective suppliers may make improvements and/or changes in{" "}
            <strong>UNLISTEDKART</strong> sites/services at any time. Advice
            received via <strong>UNLISTEDKART</strong> sites/services should not
            be relied upon for personal, medical, legal or financial decisions
            and you should consult an appropriate professional for specific
            advice tailored to your situation.
            <br />
            <br />
            <strong>UNLISTEDKART</strong>.Com and/or its respective suppliers
            make no representations about the suitability, reliability,
            availability, timeliness, lack of viruses or other harmful
            components and accuracy of the information, software, products,
            services and related graphics contained within{" "}
            <strong>UNLISTEDKART</strong> sites/services for any purpose. All
            such information, software, products, services and related graphics
            are provided "As is" without warranty of any kind.{" "}
            <strong>UNLISTEDKART</strong> and/or its respective suppliers hereby
            disclaim all warranties and conditions with regard to this
            information, software, products, services and related graphics,
            including all implied warranties and conditions of merchantability,
            fitness for a particular purpose, workmanlike effort, title and
            non-infringement.
            <br />
            <br />
            This site may contain links to other web sites operated by third
            parties ("Linked sites"). You acknowledge that, when you click on a
            link to visit a linked site, a frame may appear that contains{" "}
            <strong>UNLISTEDKART</strong> logo, advertisements and/or other
            content selected by <strong>UNLISTEDKART</strong>. You acknowledge
            that <strong>UNLISTEDKART</strong> and its sponsors neither endorse
            nor are affiliated with the linked site and are not responsible for
            any content of any linked site or any link contained in a link site,
            or any changes or updates to such sites. You also acknowledge that{" "}
            <strong>UNLISTEDKART</strong> is providing these links to you only
            as a convenience.
            <br />
            <br />
            You specifically agree that <strong>UNLISTEDKART</strong> shall not
            be responsible for unauthorized access to or alteration of your
            transmissions or data, any material or data sent or received or not
            sent or received, or any transactions entered into through an{" "}
            <strong>UNLISTEDKART</strong> site/service. You specifically agree
            that <strong>UNLISTEDKART</strong> is not responsible or liable for
            any threatening, defamatory, obscene, offensive or illegal content
            or conduct of any other party or any infringement of another's
            rights, including intellectual property rights. You specifically
            agree that <strong>UNLISTEDKART</strong> is not responsible for any
            content sent using and/or included in an{" "}
            <strong>UNLISTEDKART</strong> site/service by any third party.
            <br />
            <br />
            In no event shall <strong>UNLISTEDKART</strong> and/or its suppliers
            be liable for any direct, indirect, punitive, incidental, special,
            consequential damages or any damages whatsoever including, without
            limitation, damages for loss of use, data or profits, arising out of
            or in any way connected with the use or performance of{" "}
            <strong>UNLISTEDKART</strong> sites/services, with the delay or
            inability to use <strong>UNLISTEDKART</strong> sites/services or
            related services, the provision of or failure to provide services,
            or for any information, software, products, services and related
            graphics obtained through <strong>UNLISTEDKART</strong>{" "}
            sites/services, or otherwise arising out of the use of{" "}
            <strong>UNLISTEDKART</strong> sites/services, whether based on
            contract, tort, negligence, strict liability or otherwise, even if{" "}
            <strong>UNLISTEDKART</strong> or any of its suppliers has been
            advised of the possibility of damages. <strong>UNLISTEDKART</strong>{" "}
            does not endorse in anyway any advertisers/ contents of advertisers
            on its webpages. Please therefore verify the veracity of all
            information on your own before undertaking reliance and actioning
            thereupon. <strong>UNLISTEDKART</strong> shall not be responsible
            nor liable for any consequential damages arising on account of your
            relying on the contents of the advertisement.
            <br />
            <br />
            This agreement is governed by the laws of republic of india. You
            hereby irrevocably consent to the exclusive jurisdiction and venue
            of courts in bangalore, karnataka, india in all disputes arising out
            of or relating to the use of <strong>UNLISTEDKART</strong>{" "}
            sites/services. Use of <strong>UNLISTEDKART</strong> sites/services
            is unauthorized in any jurisdiction that does not give effect to all
            provisions of these terms and conditions, including without
            limitation this paragraph. You agree to indemnify and hold{" "}
            <strong>UNLISTEDKART</strong>, its subsidiaries, affiliates,
            officers and employees, harmless from any claim, demand, or damage,
            including reasonable attorneys' fees, asserted by any third party
            due to or arising out of your use of or conduct on{" "}
            <strong>UNLISTEDKART</strong> sites/services.{" "}
            <br/>
            <br/>
            <strong>UNLISTEDKART</strong> reserves the right to disclose any
            personal information about you or your use of{" "}
            <strong>UNLISTEDKART</strong> sites/services, including its
            contents, without your prior permission if{" "}
            <strong>UNLISTEDKART</strong> has a good faith belief that such
            action is necessary to:
            <ul className={classes.ulClass}>
                <li>conform to legal requirements or comply
            with legal process;</li>
                <li>protect and defend the rights or property of{" "}
            <strong>UNLISTEDKART</strong> or its affiliated companies;</li>
                <li>enforce the terms or use; or</li>
                <li>act to protect the interests of its
            members or others.</li>
            </ul>
              <strong>UNLISTEDKART</strong> performance of this
            agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of{" "}
            <strong>UNLISTEDKART</strong>'s right to comply with governmental,
            court and law enforcement requests or requirements relating to your
            use of <strong>UNLISTEDKART</strong> sites/services or information
            provided to or gathered by <strong>UNLISTEDKART</strong> with
            respect to such use. If any part of this agreement is determined to
            be invalid or unenforceable pursuant to applicable law including,
            but not limited to, the warranty disclaimers and liability
            limitations set forth above, then the invalid or unenforceable
            provision will be deemed superseded by a valid, enforceable
            provision that most closely matches the intent of the original
            provision and the remainder of the agreement shall continue in
            effect. Unless otherwise specified herein, this agreement
            constitutes the entire agreement between the user and{" "}
            <strong>UNLISTEDKART</strong> with respect to{" "}
            <strong>UNLISTEDKART</strong> sites/services and it supersedes all
            prior or contemporaneous communications and proposals, whether
            electronic, oral or written, between the user and{" "}
            <strong>UNLISTEDKART</strong> with respect to{" "}
            <strong>UNLISTEDKART</strong> sites/services.
            <br />
            <br />
            <strong>UNLISTEDKART</strong> group is not in any manner answerable,
            responsible or liable to any person or persons for any acts of
            omission or commission, errors, mistakes and/or violation, actual or
            perceived, by us or our partners, agents, associates etc.
            <br />
            <br />
            <strong>UNLISTEDKART</strong> group is not answerable, responsible
            or liable for any information on this website or for any services
            rendered by us, our employees, and our servants.
          </Typography>
          </Grid>
          <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={navigateFunction}>
                Close
            </Button>
           </Box>
        </Box>
    </div>
  );
}

export default LegalPolicy;
