import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Autocomplete, TextField, } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import placeholder from "../../Images/placeholder.svg";
import InputBase from "@mui/material/InputBase";
import BottomNavigation from "../../component/Home/BottomNavigation";
import SearchIcon from "@mui/icons-material/Search";
import { kycService } from "../../_services/kyc";
import Loader from "../../component/Loading/Loading";
import Search from "../../component/Kyc/Search";
import rawCountries from "../../Assets/country";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    padding: "15px 16px  34px 16px !important",
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    // paddingBottom: "1rem !important",
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
    marginLeft: "0px",
  },


  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "12px  !important",
    color: "#A489FF !important",
    lineHeight: "18px !important",
  },
  texts: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "16px  !important",
    color: "#5F6060 !important",
    lineHeight: "24px !important",
    textAlign: "center !important"
  },
  bankname: {
    overflowX: " hidden",
    textTransform: "capitalize !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "20px !important",
  },
  textfield: {
    autocomplete: "off !important",
    color: "#fff !important",
    marginBottom: "1rem !important",
    width: "100% !important",
    height: "3.3rem !important",
    paddingleft: "25%!important",
    borderRadius: "10px !important",
    fontSize: "14px",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      padding: "7.5px 5px  !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: "#AB97EB ",
      marginRight: "15px !important"
    },

    "& .MuiAutocomplete-clearIndicator": {
      color: "#AB97EB "
    },
    "	&.MuiAutocomplete-paper": {
      width: "600px !important"
    },
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: "16px !important",
    lineHeight: "16px !important",
  },

  paper: {
    borderRadius: "10px !important",
    marginTop: "1rem !important",
    width: "100%",
    maxHeight: "192px",
    background: "#0d0d0d !important",
    color: "white !important",
    boxShadow: "0 0 6px 0 rgba(157, 96, 212, 0.5) !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset !important",
    overflow: "hidden !important"
  },
}));

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: "#737373",
          fontSize:"14px",
          
        },
      },
    },
  },
});

const Branch = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [allBranch, setAllBranch] = useState([]);
const [newBranch,setNewBranch] = useState(null);
  const [bankId, setBankId] = useState(
    location != undefined
      ? location.state != undefined
        ? location.state.bankId
        : null
      : null
  );
  const [bankName, setBankName] = useState(
    location != undefined
      ? location.state != undefined
        ? location.state.bankName
        : null
      : null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchresult, setSearchresult] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [show, setShow] = useState(false);
  const [noBranch, setNoBranch] = useState(false);

  // get all states 
  const allState = () => {
    kycService
      .getAllState()
      .then((res) => {
        if (res) {

          if (res.status === 200) {

            setAllStates(res.data.data);

            setIsLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              console.log(res.status);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };


  // get all branch names 
  const allBranches = (value) => {
    setIsLoading(true);
    setNoBranch(false)
    setShow(false)
    kycService
      .getAllBranchByBankId(bankId, value)
      .then((res) => {

        if (res) {
          setAllBranch(res.data.data);

          if (res.status === 200) {
            setIsLoading(false);
            // if(res.data.length){

            // }
            if (res.data.length > 0) {
              setShow(true);
              setAllBranch(res.data.data);
            } else {
              setNoBranch(true)
            }

          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              console.log(res.status);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {

    // allBranches();
    allState();
  }, []);

  // search idfc code or address
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const newList =
        allBranch &&
        allBranch.filter((branch) => {
          return Object.values(branch)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        });
        setNewBranch(null)
        setSearchresult(newList);

    } else {
      setSearchresult(allBranch);
    }
    if(searchTerm.length==11){
      kycService.getAllBankByIfsc(searchTerm).then((data)=>{
        if(data.data.data == 'Not Found'){
          setNoBranch(true)
        }
        else{
          setNewBranch(data.data.data)
          var bankData = data.data.data;
          var insertBranchVal = {
            "ifsc":bankData.IFSC,
            "branch":bankData.BRANCH,
            "address":bankData.ADDRESS,
            "city":bankData.CITY,
            "district":bankData.DISTRICT,
            "state":bankData.STATE,
            "related_bank_id":bankId,
            "bankName":bankName
          }
          kycService.inserNewBranches(insertBranchVal)
        }
      })
       }else{
        setNewBranch(null);
       }
      
  };
  
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/bank")}
                className={classes.icon}
              />
              <Typography
              ml={2.5}
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Locate your Branch
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{
            padding: "0px 16px "
          }} >
             <ThemeProvider theme={theme}>
            <Autocomplete
              blurOnSelect
              // disabled={allStates.length ===0}
              disableClearable
              className={classes.textfield}
              onChange={(event, value) => allBranches(value)}
              classes={{ paper: classes.paper,}}
              name="ccode"
              id="countryCode"
              sx={{ width: 180 }}
              options={allStates}
              autoHighlight
              getOptionLabel={(option) => `${option.state}`}
              renderOption={(props, option) => (
                <>

                  <Box
                    component="li"
                    {...props}

                    style={{ justifyContent: "center !important", padding: 0, width: "auto", display: "flex", justifyContent: "space-between", margin: "-6px  1rem", overflow: "hidden !important" }}

                  >  <Typography sx={{ pl: "25px ", fontFamily: "Poppins", fontWeight: "400", fontSize: "14px" }}>  {option.state}</Typography>

                  </Box>
                  <hr style={{
                    width: "80%", backgroundColor: "#262626",
                    border: "none",
                    height: "1px", margin: "-3px auto"
                  }}></hr>


                </>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="Choose a State"
                  style={{
                    minWidth: "119px"
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps, style: {
                      color: "white", fontSize: 14,
                      maxWidth: "80%", paddingRight: "0", fontFamily: "Poppins"
                    }

                  }}
                />
              )}
            />
</ThemeProvider>
          </Grid>


          {isLoading && <Loader />}
          {show ? (
            <>
              {allBranch.length > 5 &&
              <Grid style={{
                padding: "0px 16px 16px"
              }} >
               <Search text={true} searchkey={searchHandler} placeholder="Search by IFSC or branch name" />

              </Grid>}
              <Grid style={{ paddingTop: "16px" }}>

                {newBranch ?(
                    <Grid
                    xs={12}
                    md={12}
                    style={{ padding: "10px 16px" }}
                    container
                    flexDirection="column"

                    onClick={() =>
                      navigate("/addbank", {
                        replace: true,
                        state: {
                          bankIfsc: newBranch.IFSC,
                          bankName: bankName
                        },
                      })
                    }
                  >
                    <Grid item xs={12} md={12} >
                      <Typography className={classes.bankname}>
                        {newBranch.STATE} - {newBranch.ADDRESS}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} >

                      <Typography className={classes.text}>
                        [{newBranch.IFSC}]
                      </Typography>
                    </Grid>
                    <Grid item>
                      <hr
                        style={{
                          // marginTop: "20px",
                          backgroundColor: "#323333",
                          border: "none",
                          height: "1px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  
) :searchTerm.length < 1 ?
                  (allBranch.map((item, index) => {
                    return (
                      <Grid
                        xs={12}
                        md={12}
                        key={index}
                        style={{ padding: "4px 16px" }}
                        container
                        flexDirection="column"

                        onClick={() =>
                          navigate("/addbank", {
                            replace: true,
                            state: {
                              bankIfsc: item.ifsc,
                              bankName: bankName
                            },
                          })
                        }
                      >
                        <Grid item xs={12} md={12} >
                          <Typography className={classes.bankname}>
                            {item.state} - {item.address}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} >

                          <Typography className={classes.text}>
                            [{item.ifsc}]
                          </Typography>
                        </Grid>
                        <Grid item>
                          <hr
                            style={{
                              marginTop: "16px",
                              backgroundColor: "#323333",
                              border: "none",
                              height: "1px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })

                  ) : (searchresult && searchresult.length === 0 ? <Grid className={classes.center}><Typography className={classes.texts}>Oopsy, no results!</Typography></Grid> :

                    searchresult.map((item, index) => {
                      return (
                        <Grid
                          xs={12}
                          md={12}
                          key={index}
                          style={{ padding: "4px 16px" }}
                          container
                          flexDirection="column"

                          onClick={() =>
                            navigate("/addbank", {
                              replace: true,
                              state: {
                                bankIfsc: item.ifsc,
                                bankName: bankName
                              },
                            })
                          }
                        >
                          <Grid item xs={12} md={12} >
                            <Typography className={classes.bankname}>
                              {item.state} - {item.address}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} >

                            <Typography className={classes.text}>
                              [{item.ifsc}]
                            </Typography>
                          </Grid>
                          <Grid item>
                            <hr
                              style={{
                                marginTop: "16px",
                                backgroundColor: "#323333",
                                border: "none",
                                height: "1px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })

                  )

                }
              </Grid>
            </>) :
            noBranch ? (<Grid className={classes.center}><Typography className={classes.texts}>Oopsy, no Branches found!</Typography></Grid>) :
              ""}
        </Box>

        <BottomNavigation />
      </Page>
    </>
  );
};

export default Branch;