import axios from "axios";
import { helpers } from "../_helpers";
 

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const securityService = {
  getSecurityByID,
  priceHistory
};

function getSecurityByID(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/security/${id}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

function priceHistory(data) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/priceGraph`, data, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}
