import React,{useEffect,useState} from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import NoInternetConnection from "./component/NoInternet/Noninternet";
import Splash from "./container/Login/Splash";
import InternetConnectivity from "./container/Login/InternetConnectivity ";
import Loading from "./container/Login/Loading";
import SignupEmail from "./container/Login/SignupEmail";
import SignupPhone from "./container/Login/SignupPhone";
import OtpPhone from "./container/Login/OtpPhone";
import OtpEmail from "./container/Login/OtpEmail";
import IndivudualDetails from "./container/Login/IndivudualDetails";
import CompanyDetails from "./container/Login/CompanyDetails";
import Landing from "./container/Login/Landing";
import Signup from "./container/Login/Signup";
import Home from "./container/Home/Home";
import Filter from "../src/container/Home/Filter";
import Overview from "../src/container/Overview/Overview";
import Sell from "../src/container/Sell/Sell";
import Select from "../src/container/Sell/Select";
import Sellportfilio from "./container/Sell/Sellportfilio ";
import NewsInfo from "./container/Overview/NewInfo";
import PaymentStatus from "./container/Payment/PaymentStatus";
import Holdings from "./container/Portfilio/Holdings";
import Status from "./container/Kyc/Status";
import UploadAadhar from "./container/Kyc/UploadAadhar";
import UploadPan from "./container/Kyc/UploadPan";
import TakeSelfie from "./container/Kyc/TakeSelfie";
import Sign from "./container/Kyc/Sign";
import Bank from "./container/Kyc/Bank";
import Branch from "./container/Kyc/Branch";
import AddBank from "./container/Kyc/AddBank";
import Demat from "./container/Kyc/Demat";
import Profile from "./container/Profile/Profile";
import EditProfile from "./container/Profile/EditProfile";
import Edit from "./container/Profile/Edit";
import FAQs from "./container/Profile/FAQs";
import LegalPolicy from  "./container/Profile/LegalPolicy"
import TermsConditions from  "./container/Profile/Terms&Conditions"
import PolicyReferences from  "./container/Profile/Privacy&Policy"
import RisksDisclosures from  "./container/Profile/Risks&Disclosures"
import NoReturnsPolicy from  "./container/Profile/NoReturnsPolicy"
import SellRequest from "./container/Sell/SellRequest";
import ConfirmBankAccount from "./container/Sell/ConfirmBankAccount";
import KycSlides from "./container/Kyc/KycSlides"
import ChangeMobileNumber from "./container/Login/changeMobileNumber";
import ChangeEmail from "./container/Login/changeEmail";
import TermsAndConditionAlert from "./component/AlertModel/TermsAndCondition";
import UpdateDetails from './container/Profile/UpdateDetails'
import SignEasyLoading from './container/Login/SignEasyLoading'
import Vouchslides from "./container/Vouch/Vouch";
import PoweredBy from "./container/Vouch/Poweredby";
import Maintenance from "./container/Login/Maintenance";
import Vouchstatus from "./container/Vouch/Vouchstatus";
import StoreRedirector from "./container/Others/Appredirector";
import axios from "axios";
import Support from "./container/Profile/Support";

let persistor = persistStore(store);

function App() {
  const history = createBrowserHistory();

  const [isLoading,setIsloading] = useState(true)
  useEffect(()=>{
    fetch('/config.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    ).then((response)=>{
    return response.json();
    }).then((data)=>{
      axios.defaults.baseURL = data.ApiEndPoint
      localStorage.setItem('razorPayKey',data.razorPayKey)
      localStorage.setItem('maintenanceMode',data.maintenanceMode)
      localStorage.setItem('singleLotMaxPrice',data.singleLotMaxPrice)
      localStorage.setItem('buyTestingUserId1',data.buyTestingUserId1)
      localStorage.setItem('buyTestingUserId2',data.buyTestingUserId2)
      setIsloading(false)
    })
    
  
 

  },[])

  return (
    <NoInternetConnection>
{
  !isLoading ? <>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Routes>
            <Route path="/signupemail" exact element={<SignupEmail />} />
            <Route path="/signupphone" exact element={<SignupPhone />} />
            <Route path="/otpphone" exact element={<OtpPhone />} />
            <Route path="/otpemail" exact element={<OtpEmail />} />
            <Route
              path="/individualdetails"
              exact
              element={<IndivudualDetails />}
            />
              <Route
              path="/updateDetails"
              exact
              element={<UpdateDetails />}
            />
            Splash
            <Route path="/splash" exact element={<Splash/>} />
            <Route path="/no-internet" exact element={<InternetConnectivity />} />
             <Route path="/loading" exact element={<Loading />} />
             <Route path="/SignEasyLoading" exact element={<SignEasyLoading />} />
            <Route path="/companydetails" exact element={<CompanyDetails />} />
            <Route path="/type" exact element={<Landing />} />
            <Route path="/" exact element={<Signup />} />
            <Route path="/home" exact element={<Home />} />
            <Route path="/filter" exact element={<Filter />} />
            <Route path="/companyOverview" exact element={<Overview />} />
            <Route path="/Support" exact element={<Support />} />

            <Route path="/sell" exact element={<Sell />} />
            <Route path="/sellrequest" exact element={<SellRequest />} />
           <Route path="/select" exact element={<Select />} />
            <Route path="/sellportfilio" exact element={<Sellportfilio />} />
            <Route path="/newinfo" exact element={<NewsInfo />} />
            <Route path="/payment-status" exact element={<PaymentStatus />} />
            <Route path="/holdings" exact element={<Holdings />} />
            <Route path="/status" exact element={<Status />} />
            <Route path="/uploadaadhar" exact element={<UploadAadhar/>} />
            <Route path="/uploadpan" exact element={<UploadPan/>} />  
            <Route path="/takeselfie" exact element={<TakeSelfie/>} /> 
            <Route path="/sign" exact element={<Sign/>} />  
            <Route path="/kycslides" exact element={<KycSlides />} /> 
            <Route path="/vouchslides" exact element={<Vouchslides />} />
            <Route path="/vouchstatus" exact element={<Vouchstatus />} /> 
            <Route path="/poweredby" exact element={<PoweredBy />} /> 
            <Route path="/maintenance" exact element={<Maintenance />} /> 
            <Route path="/bank" exact element={<Bank/>} /> 
            <Route path="/branch" exact element={<Branch/>} />   
            <Route path="/addbank" exact element={<AddBank/>} />  
            <Route path="/demat" exact element={<Demat/>} />  
            <Route path="/profile" exact element={<Profile />} />
            <Route path="/editProfile" exact element={<EditProfile />} />
            <Route path="/edit" exact element={<Edit />} />
            <Route path="/FAQs" exact element={<FAQs />} />
            <Route path="/legalpolicy" exact element={<LegalPolicy />} />
            <Route path="/subtermsandconditions" exact element={<TermsConditions />} />
            <Route path="/privacyandpolicy" exact element={<PolicyReferences />} />
            <Route path="/risksanddisclosures" exact element={<RisksDisclosures />} />
            <Route path="/noreturnpolicy" exact element={<NoReturnsPolicy />} />
            <Route path="/confirmBank" exact element={<ConfirmBankAccount />} />
            <Route path="/changemobilenumber" exact element={<ChangeMobileNumber />} />
            <Route path="/changeemail" exact element={<ChangeEmail />} />
            <Route path="/termsandcondition" exact element={<TermsAndConditionAlert />} />
            <Route path="/getunlistedkartapp" exact element={<StoreRedirector />} />

          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  </>: <></> 
}
    </NoInternetConnection>
  );
}

export default App;
