import React, {useState,useEffect} from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Grid, Button, Dialog, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { makeStyles, styled } from "@mui/styles";
import { razorPayIntegration } from "../../_services/razorPayIntegration";
import {  useNavigate } from "react-router-dom";
import SnackBar from "../../component/SnackBar/SnackBar";
import { orderService } from "../../_services/orders";
import PoweredBy from "../../container/Vouch/Poweredby";
import Backdrop from '@mui/material/Backdrop';
// const settings = require('../../settings');

const innerTheme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {},
    },
  },
});


const useStyles = makeStyles((theme) => ({
  buyBtn: {
    fontFamily: "Poppins  !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    width: "100%",
    padding: "12px 0px !important",
    borderRadius: "10px !important",
    background: "#6D50CC !important",
    textAlign: "center",
    marginBottom: "1rem !important",
  },
  dialogSubTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    // margin:"1rem !important",
    padding:"2rem 1rem !important"
  },
  cards: {
    "& 	.MuiDialog-paper": {
      width: "241px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      // padding: "16px 0px !important",
      // height:"136px !important"
    },
  },
  drawer: {
    "& 	.MuiDrawer-paper": {
      background: "#0d0d0d",
      borderRadius: "10px !important",
    },
  },
  addBtn: {
    fontSize: "1.3rem !important",
    width: "100% !important",
    height: "40px !important",
    borderRadius: "10px !important",
    "&:disabled":{
      opacity:0.5,
      color:'#fff !important'
    }
  },
  textFiled: {
    padding: "8px",
    color: "#fff !important",
    textAlign: "center",
    background: "#1c1c1c",
    borderRadius: "10px",
    margin: "0 1rem ",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },
  hrline: {
    backgroundColor: "#1c1c1c",
    border: "none",
    height: "2px",
  },
  noOfShares: {
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "10px !important",
    lineHeight: "15px  !important",
    textAlign: "center",
    color: "#AC93FF",
  },
  investment: {
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
    textAlign: "center !important",
    color: "#FFFFFF",
  },
  totalAmt: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
    textAlign: "center",
    color: "#AC93FF",
    margin: "8px !important",
  },
  title: {
    margin: "12px !important",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    lineHeight: "16px !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
}));
export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [noOfShares, setNoOfShares] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [android, setAndroid] = useState(false);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [vouchDialog, setVouchDialog] = useState(false);
  const [url,setUrl]  = useState(false);
  
  var sharePrice = 0.00
  var sharesPerLot = 0.00

  
  if (props !== undefined) {
      sharePrice = parseFloat(props?.data?.price)
      var split = Number(props.data?.price).toFixed(2).split(".")
      const userDetails = JSON.parse(localStorage.getItem('persist:root'))
      const auth = JSON.parse(userDetails.authReducer)
      const userId = auth?.auth?.user?.id
      sharesPerLot = parseFloat((userId ==localStorage.getItem('buyTestingUserId1') || userId ==localStorage.getItem('buyTestingUserId2')) ? 1 :localStorage.getItem('singleLotMaxPrice') / sharePrice)
      // sharesPerLot = parseFloat(localStorage.getItem('singleLotMaxPrice') / sharePrice)
      // sharesPerLot = parseFloat(localStorage.getItem('singleLotMaxPrice') / sharePrice)
  }

  const handleDecrement = () => {
    if (noOfShares > 0) {
        setNoOfShares(noOfShares - 1)
    }
}
const handleIncrement = () => {
    if ((Math.ceil(sharesPerLot) * parseInt(noOfShares)) > parseInt(quantity)) {
        console.log("not available");
    } else {
        setNoOfShares(noOfShares + 1)
    }
    console.log((Math.ceil(sharesPerLot) * parseInt(noOfShares)), parseInt(noOfShares), Math.ceil(sharesPerLot), parseInt(quantity));
}

// Calling razorpay api for payment process
const paymentAction = async (values) => {
  const data = {};
  data.security_id = props.data?.id;
  data.buy_quantity = values?.quantity;
  data.price = values?.price_total;
  setLoading(true)
  razorPayIntegration.orderCreateBuyUnlistedShare(data).then((res) => {
  if (res) {
    setLoading(false)
      if (res.status === 201) {
        const order = {
          amount: res.data.amount,
          id: res.data.order_id,
          currency: res.data.currency,
          email: res.data.notes.email,
          contact: res.data.notes.contact_no,
        };
        setVouchDialog(true)
        setTimeout(()=>{
          localStorage.setItem('security_id',props.data?.id)
          // window.location.href =res.data.notes.link
          if(navigator.userAgent.match(/Android/i)) {
            setAndroid(true) 
            }else{
              window.location.href =res.data.notes.link
              setVouchDialog(false)
          }
          setUrl(res.data.notes.link)
        },2000)
      } else {
        setSnackbarTitle("Server error. Are you online?");
        setSnackbarOpen(true);
      }
    } else {
      setLoading(false)
      setSnackbarTitle("Server error. Are you online?");
      setSnackbarOpen(true);
    }
  });
};

// check payment status and redirects to payment status page
const paymentCallback =async(data, res) => {
  if (res.status === 200) {
    console.log(data,"datadatadata");
    if (res.data === "Payment verification successful") {
    let orderData =   await orderService.createServiceOrderForApp(data)
      navigate("/payment-status", {
        replace: true,
        state: { data: data, payment_status: "SUCCESS", orderData:orderData.data.service_id,security_id:props.data?.id},
       });
    } else {
      navigate("/payment-status", {
        replace: true,
        state: { data: data , payment_status: "FAIL", security_id:props.data?.id},
       
      });
    }
  } else {
  }
};

const key = url && url?.split('data=')[1]
useEffect(()=>{
      const interval = setInterval(() => {
        console.log(key)
        if(key){
          const data = {
            paymentid:key
          }
          razorPayIntegration.getPaymentStatus(data).then((res)=>{
            console.log(res)
            if(res.data.message.data.status === 'completed' || res.data.message.data.status === 'awaiting settlement'){
                const orderData = {
                    order_id:key
                  }
                  setUrl(false)
                   orderService.createServiceOrderForVouch(orderData).then((res)=>{
                    console.log(res)
                    if(res.data.success){
                        navigate("/payment-status", {
                          replace: true,
                          state: { orderData: res.data.service_id,data:{razorpay_order_id:key}, payment_status: "SUCCESS"}
                         });
                    }
                })
              }else if(res.data.message.data.status !== 'initiated'){
                setUrl(false)
                navigate("/payment-status", {
                  replace: true,
                  state: { data: data, payment_status: "FAIL"}
                 });
              }
            })
       }    
        }, 3000);
  
      return () => {
        clearInterval(interval); // Clear the interval when the component unmounts
      };
   },[url])

useEffect(() => { 
  setNoOfShares(1)
  setQuantity((noOfShares - 1) * Math.ceil(sharesPerLot))
}, [])

const handleClose = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarOpen(false);
};
const totalOrderValue = ((parseFloat(sharePrice) * parseFloat(noOfShares)) * parseFloat(Math.ceil(sharesPerLot))).toFixed(2)

  const list = (anchor) => (
    <div style={{ background: "#0d0d0d" }} role="presentation">
        <Formik
                    initialValues={{
                        quantity: "",
                        price: parseFloat(Number(props.data?.price).toFixed(3)),
                        logo: props.data?.logo_url,
                        scripName: props.data?.name,
                        security_id: props.data?.id,
                        price_total:0

                    }}
                    validationSchema={Yup.object().shape({



                    })}
                    onSubmit={(values) => {
                      // setUpiDialog(true)
                      //  setTimeout(()=>{
                        // setLoading(true)
                        // setUpiDialog(false)
                         values.price_total =(parseFloat(sharePrice) * parseFloat(noOfShares)) * parseFloat(Math.ceil(sharesPerLot))
                        values.quantity = Number((noOfShares) * Math.ceil(sharesPerLot))
                        paymentAction(values)
                      //  },3000)
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue

                    }) => (
                        <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          textAlign: "center",
          padding: "1rem",
        }}
      >
        <Grid item xs={4} md={4}>
          <Button
            variant="contained"
            className={classes.addBtn}
            style={{
              background: "#A94B4B",
            }}
             onClick={() => {
              handleDecrement()
              setFieldValue("quantity", (noOfShares -1) * Math.ceil(sharesPerLot), '')
          }}
          disabled={noOfShares===1}
          >
            -
          </Button>
        </Grid>
        <Grid item xs={4} md={4}>
        <Typography
            size="small"
            fullWidth
            className={classes.textFiled}
            value={`${noOfShares} ${noOfShares <=1 ? "lot" : "Lots"}`}
          >
            {noOfShares}{" "}
            <span style={{color:"grey"}}>
            {`${noOfShares <=1 ? " lot" : "  Lots"}`}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Button
           disabled={((parseFloat(sharePrice) * parseFloat(noOfShares+1)) * parseFloat(Math.ceil(sharesPerLot))).toFixed(2) > 500000 ? true :false}
           onClick={() => {                           
            handleIncrement()
            setFieldValue("quantity", (noOfShares +1 ) * Math.ceil(sharesPerLot), '')
         }}
            variant="contained"
            className={classes.addBtn}
            style={{ background: "#44327C" }}
          >
            +
          </Button>
        </Grid>
      </Grid>
      <hr className={classes.hrline} style={{ marginTop: 0 }} />
      <Typography my={2} className={classes.noOfShares} style={{fontWeight:"500"}}>
        Number of Shares : {((parseFloat(noOfShares)) * parseFloat(Math.ceil(sharesPerLot)))} Shares
      </Typography>
      <hr className={classes.hrline} />
      <Typography className={classes.investment}>Total investment</Typography>
      <Typography className={classes.totalAmt} style={{}}>
        ₹ {totalOrderValue}
      </Typography>
      <hr className={classes.hrline} />
      <div style={{ margin: "1rem" }}>
        <Button variant="contained" className={classes.buyBtn}
        type="submit"
        >
          BUY
        </Button>
      </div>
      </form>
                    )}
                </Formik>
    </div>
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={innerTheme}>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={props.drawerOpen}
          onClose={props.onClose}
        >
          <div
            style={{
              display: "flex",
              background: "#1C1C1C",
              padding: "5px",
              justifyContent: "space-between",
              color: "#fff",
            }}
          >
            <Typography className={classes.title}>
              {props?.data?.name}
            </Typography>
            <div style={{ textAlign: "right" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {list("bottom")}
        </Drawer>
      </ThemeProvider>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
            <CircularProgress sx={{color:'#6D50CC'}} size={30} />
        </Backdrop>
      <SnackBar
        open={snackbarOpen}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
              <Dialog fullScreen  open={vouchDialog}>
               {(url && android) ?<div style={{position:'relative'}}>
                {/* <CloseIcon  style={{position:'absolute' ,right:'10px', top:"10px", color:"#fff"}} onClick={()=>{
                  setVouchDialog(false)
                  setUrl(false)
                }}/>  */}
                <iframe style={{height:'100vh', width:'100vw'}} allowFullScreen src={url}/> 
               </div>:<PoweredBy/>}
            </Dialog>
    </React.Fragment>
  );
}
