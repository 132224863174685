import React,{ useEffect } from "react";
import { makeStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import "../../../src/index.css";
const useStyles = makeStyles((theme) => ({
  textfield: {
    padding: "10px ",
    marginTop: "1rem",
    borderRadius: "10px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box",
    boxShadow: "2px 1000px 1px #0D0D0D inset",
    padding:"13px !important",
    "&:hover": {
      backgroundColor: "#0d0d0d",
    },
      '&:placeholder': {
       letterSpacing:"1px !important"
      },
    
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: " 400 !important",
      fontSize: "16px !important",
      lineHeight: "18px !important",
      color: "#fff !important",
    },
    "& .Mui-disabled" :{
      "-webkit-text-fill-color":"#fff !important",
      opacity:"0.4 !important"
    }
  },
}));

function Textfield({
  placeholder,
  value,
  name,
  helperText,
  error,
  inputProps,
  onInput,
  pattern,
  valueColor,
  type,
  id,
  otp,
  onChange,
  disabled
}) {
  const classes = useStyles();
  useEffect(() => {}, [value]);
  return (
    <div>
      <InputBase
        disabled={disabled}
        pattern={pattern}
        error={error}
        helperText={helperText}
        inputProps={ inputProps}
        onInput={onInput}
        name={name}
        type={type}
        autoComplete="off"
        autoCorrect="off"
        id={id}
        value={value}
        onChange={onChange}
        fullWidth
        className={classes.textfield}
        style={{
          backgroundImage:
            valueColor == 1
              ? "linear-gradient(#5CAA72 , #583EAC), linear-gradient(180deg, #5CAA72 0%, #583EAC 100%)"
              : valueColor == 2
              ? "linear-gradient(#DD4A47, #583EAC), linear-gradient(180deg, #DD4A47 0%, #583EAC 100%)"
              : "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
          backgroundOrigin: "border-box",
          letterSpacing:otp ? "4px" : "none"
        }}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Textfield;
