import React, {useState} from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Page from "../../component/Page/Page";
import FilterTab from "../../component/Home/FilterTab";
import { makeStyles } from "@mui/styles";
import { Context } from '../../store/store';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0D0D0D !important",
  },
  container: {
    height:"51px !important",
    backgroundColor: "#0D0D0D !important",
    padding: "0px 16px",
    display: "flex",
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
    marginRight:'1rem'
  },
  bottom: {
    padding:"1rem 1rem 2rem",
    backgroundColor:"#0d0d0d",
    position: "fixed",
    bottom: "0%",
    left: "50%",
    transform: "translate(-50%, 5%)",
  },
  outlined: {
    width:"100% !important",
    height:"44px !important",
    marginRight:"11px !important",
    padding:"10px 50px !important",
    fontWeight:'500 !important',
    fontSize: "16px !important",
    fontFamily:"Poppins !important",
    color: "#AC93FF !important",
    border: "1.5px solid #6E58B0 !important",
    borderRadius: "10px !important",
    "&:hover": {
      border: "1.5px solid #6E58B0 !important",
    },
  },
  contained: {
    width:"100% !important",
    height:"44px !important",
    marginLeft:"11px !important",
    padding:"10px 50px !important",
    fontFamily:"Poppins !important",
    fontWeight:'500 !important',
    fontSize: "16px !important",
    color: "#fff",
    backgroundColor: "#6D50CC !important",
    borderRadius: "10px !important",
    "&:hover": {
      backgroundColor: "#6D50CC !important",
    },
  },
}));

const Filter = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [sector, setSector] = React.useState([]);
  const [initialValue,setInitialValue] = React.useState([])
  const [state, dispatch] = React.useContext(Context);
  const [sortValue, setSortValue] = React.useState(state && state.filterValue && state.filterValue.sortValue ? state.filterValue.sortValue: undefined);

  const filter = sector.filter((el)=>{
    return el.isActive ===true
  })

 

// apply and store filter values even if we go back to filter screen to show previous filtered value using redux
  const applyFilter = () =>{
    const array =[]
    filter.map(item => array.push(item.code))
    dispatch({ type: 'SET_SECTOR', payload: sector });
    dispatch({ type: 'SET_FILTERVALUE', payload: {
      sortValue:sortValue,
      hasFilter: array.length === 0 ? 0 : 1,
      filterValue: array
    } });
      navigate("/home")
  }

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/home")}
                className={classes.icon}
              />
              <Typography
                variant="h6"
                style={{ color: "#fff", fontWeight: "400", fontSize: "16px" ,  fontFamily: "Poppins",}}
              >
                Filter
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                variant="text"
                style={{
                  fontFamily: "Poppins",
                  color: "#A489FF",
                  fontWeight: "600",
                  fontSize: "12px",
                  letterSpacing:" 1.25px"
                }}
                onClick={()=> {
                  setSortValue(undefined)
                  const newArray = sector.map((data) =>({...data, isActive: false}))
                  setSector(newArray)}}
              >
                CLEAR ALL
              </Button>
            </Grid>
          </Grid>
          <FilterTab sector={sector} setSector={setSector} setInitialValue={setInitialValue} sortValue={sortValue} setSortValue={setSortValue}/>
          <Grid className={classes.bottom} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button  
               onClick={() => navigate("/home")} 
               variant="outlined" className={classes.outlined}>
                CANCEL
              </Button>
              <Button variant="contained" className={classes.contained}
             onClick={applyFilter}
              >
                APPLY
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </>
  );
};

export default Filter;
