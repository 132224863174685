import React from 'react'
import { Grid, Typography, Box, Stack, MenuItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "../../component/Home/BottomNavigation";
import AddIcon from '@mui/icons-material/Add';
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    marginBottom: "1rem"
  },
  icon: {
    fill: "#fff  !important",
    margin: "10px",
    cursor: "pointer",
    marginLeft: "0px",
  },
  requestBtn: {
    color: "#A489FF !important",
    padding: "0 !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: '12px !important',
    lineHeight: "18px !important"
  },
  card: {
    margin: "16px auto",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    background: '#1C1C1C',
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "15px !important",
  },
  title: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "18px !important",
  },
  subTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "14px !important",
  },
  dialogBtn: {
    color: "#fff !important",
    marginBottom: "8px !important",
    padding: "4px 1rem !important",
    textTransform: "none !important",
    borderRadius: "16px !important",
    fontStyle: "Poppins !important",
    background:"#6D50CC !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "21px !important",
    "&:disabled": {
      color: "#6E58B0 !important",
      background: "#3B3159 !important",
    }
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal ",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#767677 ",
    lineHeight: "15px !important",
  },
  rate: {
    padding:'2px !important',
    fontFamily: "Roboto   !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff ",
    lineHeight: "14px !important",
  },
}));

const SellRequest = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  
  const data = [{
    name: "The Mysore Paper Mills",
    id: "#REQ794792",
    request: "Submitted",
    qty: "210",
    price: "-",
  }, {
    name: "API Holdings Limited",
    id: "#REQ794792",
    request: "Accepted",
    qty: "110",
    price: "300",
  }, 
   {
  name: "Test 2",
  id: "#REQ794792",
  request: "Not Accepted",
  qty: "77",
  price: "879",
},{
    name: "Test 1",
    id: "#REQ794792",
    request: "Accepte",
    qty: "76",
    price: "879",
  }
  ]

  return (
    <Page>
      <Box className={classes.boxcontainer}>
        <Grid className={classes.container} container direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <KeyboardBackspaceIcon
            onClick={() => navigate("/sell")}
            className={classes.icon}
          />
            <MenuItem style={{padding:0}}>
              <Typography className={classes.requestBtn}>Place New Request</Typography>
              <ListItemIcon style={{ minWidth: "0", border: "2px solid #A489FF", borderRadius: "50%", marginLeft: "8px" }}>
                <AddIcon style={{ color: "#fff", fontSize:"15px" }} />
              </ListItemIcon>
            </MenuItem>
        </Grid>

        {data.map((item) => {
          return (
            <Grid container className={classes.card} >
              <Grid
                item
                xs={8}
                md={8}
              >
                <Typography className={classes.title}>
                  {item.name}
                </Typography>
                <Typography className={classes.subTitle}>
                  {item.id}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                style={{ display: "contents" }}
              >
                <Button variant="contained" className={classes.dialogBtn}
                  disabled={item.request === "Submitted" || item.request === "Not Accepted" ? true : false}>
                  {item.qty === "76" ? "Continue" : "Sell"}
                </Button>
              </Grid>
              <Grid item xs={12} md={12} className={classes.accordion}>

              </Grid>
              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    backgroundColor: "#494949",
                    border: "none",
                    height: "1px",
                    marginTop: "5px ",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent:"space-between" }}>
            <Grid item xs={4} md={4} sx={{ textAlign: "center" }}>
              <Typography className={classes.subtitle}>Qty</Typography>
              <Typography className={classes.rate}> {item.qty} Shares </Typography>
            </Grid>
            <Grid item xs={4} md={4} sx={{ textAlign: "center" }}>
              <Typography className={classes.subtitle}> Deal Term </Typography>
              <Typography className={classes.rate} style={{ disply: "flex" }}>
                {item.price}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} sx={{ textAlign: "center" }}>
              <Typography className={classes.subtitle}>Status</Typography>
              <Typography
              style={{color:item.request === "Accepted" ? "#7BDE89": item.request === "Not Accepted" ? "#DD4A47" : "#A489FF"}}
                className={classes.rate}
              >
                {item.request}
              </Typography>
            </Grid>
          </Grid>
              
            </Grid>
          )
        })}
      </Box>
      <BottomNavigation activeTab={2} />

    </Page>

  )
}

export default SellRequest