import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { buyUnlistedShareService } from "../../_services/buyUnlistedShare";
import clsx from "clsx";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import CardSkeleton from "../../component/Skeleton/CardSkeleton";
import { Context } from '../../store/store';
import { rateAlert, setRequestID } from "../../store/bugs";
import { connect } from "react-redux";
import {  useNavigate } from "react-router-dom";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: "93px !important",
    minHeight: "93px !important",
    borderRadius: "11px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    paddingRight: "0px !important",
    paddingBottom: "0px !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight:"19px !important",
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "11px  !important",
    color: "#AC93FF !important",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:"nowrap", 
    maxWidth: '100%',
    textTransform: "uppercase",
    lineHeight:"1rem !important"
  },
  button: {
    background: "linear-gradient(264.66deg, #AB97EB -16.97%, #583EAC 110.76%)",
    borderRadius: "10px 0px !important",
    textTransform: "none !important",
    maxHeight: "25px !important",
    boxShadow:"none !important",
    width:"90px !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#FFFFFF !important",
    lineHeight: "15px !important",
    maxWidth: " 100px !important",
    padding:"2px 10px !important"
  },
  rate: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    lineHeight:"17px !important",
    fontSize: "15px  !important",
    color: "#fff !important",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:"nowrap", 
    maxWidth: '100%',
    textTransform: "uppercase",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  upWard: {
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    color: "green !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },
  downWard: {
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    color: "red !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },
  center :{
    position: "absolute",
    top: "53%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    },
  text: {
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400  !important",
      fontSize: "16px  !important",
      color: "#5F6060 !important",
      lineHeight: "24px !important",
      textAlign:"center !important"
    },
    padding: {
      "& .MuiBox-root": {
        padding: "0px !important",
      },
    }, 
    verticalFlex:{
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "space-between",
      justifyContent: "center",
    
    }
}));

function AvailableSlide(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [state, dispatch] = React.useContext(Context);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const userID = props.authReducer &&
  props.authReducer.auth &&
  props.authReducer.auth.user &&
  props.authReducer.auth.user.id;
  const setShares = props.setShares
  const Sharesvalues =props.Sharesvalues
  const [skeletonLoader, setSkeletonLoader] = useState(true); 
  useEffect(() => {
    if(state.filterValue){
      const filtervalue = state.filterValue.filterValue.toString()
      const  replaceValue = filtervalue.replaceAll(",", "','")
      const marketValue = state.filterValue.sortValue
      const hasFilter = state.filterValue.hasFilter
      const marketCap = state.filterValue.sortValue === undefined ? 0 : 1
      const data ={
        filterValue: `['${replaceValue}']`,
        related_market_capital_id:parseInt(marketValue)
      }
      
      // get available scrips with filter parameters
      buyUnlistedShareService.getUnlistedShare(userID,1,hasFilter,data,marketCap).then((res) => {
        if (res) {
          if (res.status === 200) {
            setShares(res.data);
            setSkeletonLoader(false);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      });
    }else{

      // get available scrips without filter parameters
      buyUnlistedShareService.getUnlistedShare(userID,1).then((res) => {
        if (res) {
          if (res.status === 200) {
            setShares(res.data);
            setSkeletonLoader(false);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
          }
          }
        }
      });
    }
   
  }, []);

const getSecurity=(id)=>{
  navigate("/companyOverview", {
    replace: true,
    state: { id:id},
  });
}

  return (
    <div
      style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
    >
      {skeletonLoader ? (
        <>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
            <CardSkeleton key={n} />
          ))}
        </>
      ) : (
        Sharesvalues.map((item) => {
          var percentage = Number(item.percentage_of_change).toFixed(2);
          return (
            <div key={item.id} className={classes.padding}   >
              <Box sx={{ m: 0,mb:2}} className={classes.padding}  >
                <Grid container className={classes.card} onClick={()=>getSecurity(item.id)}>
                  <Grid
                    item
                    xs={3.5}
                    md={3.5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    pl={1.5}
                  >
                    <img
                      style={{  height: "63px", width: "76px"}}
                      src={item.logo_url !== null ? item.logo_url : ScriptLogo}
                      alt="logo"
                    />
                  </Grid>
                  <Grid item xs={8.5} md={8.5} pl={1.5}  className={classes.verticalFlex}>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                      <Grid item xs={7} md={7} style={{ padding: "14px 8px 5px 0", textAlign: "start" }}>
                        {" "}
                        <Typography className={classes.title}>
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} md={5}>
                        {" "}
                        <Grid style={{ padding: "14px 16px 0 0", textAlign: "end" }}>
                          <Typography className={classes.rate}>
                            ₹{item.price}
                          </Typography>
                          <Typography
                            className={clsx({
                              [classes.upWard]: percentage >= 0,
                              [classes.downWard]: percentage < 0,
                            })}
                            variant="subtitle2"
                          >
                            {percentage.charAt(0) !== "-" ? (
                              <>
                                {`+${percentage}%`}
                              </> 
                            ) : (
                              <>
                                {`-${percentage.substring(1)}%`}
                              </>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex", fontSize:"17px" }}>
                      <Grid item xs={item && item.related_security_tag && item.related_security_tag.length>=1 ?7 : 12} >
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                          pb={1.75}
                        >
                            {item.sector}
                        </Typography>
                      </Grid>
                      { item && item.related_security_tag && item.related_security_tag.length>=1 && 
                      <Grid
                        item
                        xs={5}
                        style={{display:"flex", justifyContent:"end", alignItems:'flex-end'}}
                        md={5}>
                        <Button variant="contained" className={classes.button} disableRipple >
                          {item && item.related_security_tag[0].tag_name}
                        </Button>
                      </Grid>
                        }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </div>
          );
        })
      )}
        <SessionExpiredModal
                open={openSessionExpiredModal}
            />
      {!skeletonLoader && Sharesvalues && Sharesvalues.length ===0 && 
                    <Grid className={classes.center}><Typography className={classes.text}>Oopsy, no results!</Typography></Grid>
                  }
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

const dispatchToProps = (dispatch) => ({
  rateAlert: () => dispatch(rateAlert()),
  request_id: (req) => dispatch(setRequestID(req)),
});

export default connect(mapStateToProps, dispatchToProps)(AvailableSlide);
