import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Stepper,
  StepConnector,
  StepLabel,
  Step,
  Button,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Check from "@mui/icons-material/Check";
import Page from "../../component/Page/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { digioVerificationService } from "../../_services/digioVerificationService";
import SessionExpiredModal from "../../component/AlertModel/SessionExpiredModal";
import { connect } from "react-redux";
import Loader from "../../component/Loading/Loading";
import SnackBar from "../../component/SnackBar/SnackBar";
import { kycService } from "../../_services/kyc";
import BottomNavigation from "../../component/Home/BottomNavigation";
// import  '../../../src/app.css';
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    padding:"16px !important"
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    padding: "1rem",
    display: "flex",
  },
  root: {
    backround: "#0d0d0d",
    flexGrow: 1,
    marginBottom: "5rem",
  },
  icon: {
    fill: "#fff  !important",
    // margin: "10px",
    cursor: "pointer",
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
  },
  button: {
    float: "right",
    marginTop: "-1rem",
    // marginLeft: "2rem",
  },
  header: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    lineHeight: "24px  !important",
    color: "#fff !important",
    marginLeft: "20px !important",
  },
  submitBtn: {
    display: "flex !important",
    justifyContent: "center  !important",
    width: "calc(100vw - 36px)",
    height: "54px",
    borderRadius: "10px  !important",
    padding: "12px 0px !important",
    boxShadow: "none  !important",
    "&:hover": {
      backgroundColor: " #6D50CC  !important",
      boxShadow: "none  !important",
    },
    textTransform: "uppercase  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    lineHeight: "24px  !important",
    color: "#fff !important",
  },
  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  stepDescription: {
    float: "left",
    display: "flex",
    alignItems: "center",
    // marginTop: "10px !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "14px  !important",
    lineHeight: "24px  !important",
    color: "#fff !important",
  },
  stepTitle: {
    float: "right",
    display: "flex",
    alignItems: "center",
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    float: "right",
    display: "flex",
    alignItems: "center",
    left: "calc(-56% + 16px)",
    right: "calc(56% + 16px)",
  },
  active: {
    "& $line": {
      borderLeft: "3px solid #7BDE89 !important",
      marginLeft: "-0.1rem",
      marginTop: "-3px",
    },
  },
  completed: {
    "& $line": {
      borderLeft: "3px solid #7BDE89 !important",
      marginLeft: "-1rem",
      marginTop: "-3px",
    },
  },
  line: {
    float: "right",
    display: "flex",
    marginTop: "-3px",
    alignItems: "center",
    marginRight: "1rem !important",
    borderLeft: "3px solid #323333 !important",
    borderRadius: 1,
    minHeight: "4rem !important",
  },
})(StepConnector);

const Status = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const classes = useStyles();
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [userDocs, setUserDocs] = useState([]);
  const [steps, setSteps] = useState([]);
  const [status, setStatus] = useState(location != undefined ? location.state != undefined ? location.state.status : null : null);

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [isLoading, serIsLoading] = useState(false);
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  function getBrowser(window) {
    let currentBrowser = "app";
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      currentBrowser = "Google Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      currentBrowser = "Mozilla Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      currentBrowser = "Internet Exployer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "Opera";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "YaBrowser";
    } else {
      console.log("Others");
    }
    return currentBrowser;
  }
  useEffect(() => {
    serIsLoading(true);
    KycStaus();

  }, []);

  async function KycStaus() {
    try {
      let userCompletedSteps =
        await digioVerificationService.getVerificationStatus(userID);
      if (userCompletedSteps && userCompletedSteps.status == 200) {
        setSteps(userCompletedSteps.data.userKycResponse);
        serIsLoading(false);
        let count = 0;
        for (
          let index = 0;
          index < userCompletedSteps.data.userKycResponse.length;
          index++
        ) {
          if (
            userCompletedSteps.data.userKycResponse[index]
              .kyc_approval_status === 1
          ) {
            count = count + 1;
          }
        }
        setActiveStep(count);
      } else {
        if (
          userCompletedSteps.status === 401 &&
          userCompletedSteps.data.name === "TokenExpiredError"
        ) {
          setOpenSessionExpiredModal(true);
          serIsLoading(false);
        }
      }
      if (
        window.ReactNativeWebView != undefined &&
        (getBrowser(window) == "app" || getBrowser(window) == "Google Chrome")
      ) {
        let data = {
          isKycPage: "false",
          userToken: digioVerificationService.getUserToken(),
          userID: userID,
          isSelfiPage: "false",
          goToNextPage: localStorage.getItem("status"),
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
        console.log("post message sent");
      } else {
        console.log("post message not sent");
      }
      let data = await kycService.getKycInfo();

    } catch (error) {
      setSeverity("error");
      setSnackbarTitle("Something went wrong, Please try again later");
      setOpenSnackBar(true);
      serIsLoading(false);
    }
  }

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 10,
      alignItems: "center",
    },
    circle: {
      borderRadius: "50%",
      color: "#fff",
      background: "#323333",
      padding: "2px",
      marginTop: "8px !important",
    },
    completed: {
      background: "#7BDE89",
      borderRadius: "50%",
      padding: "2px",
      color: "#fff",
    },
    active: {
      background: "#323333",
      borderRadius: "50%",
      padding: "2px",
      color: "#AC93FF",
    },
  });

  // set active step as completion status
  const handleKyc = (label, index) => {
    if (activeStep === 1) {
      navigate("/uploadpan");
    } else if (activeStep === 2) {
      navigate("/takeselfie");
    } else if (activeStep === 3) {
      navigate("/sign");
    } else if (activeStep === 4) {
      navigate("/bank");
    }
    else if (activeStep === 5) {
      navigate("/demat")
    }
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div className={classes.root}>
        {completed ? (
          <Check fontSize="small" className={classes.completed} />
        ) : active ? (
          <Check fontSize="small" className={classes.active} />
        ) : (
          <Check fontSize="small" className={classes.circle} />
        )}
      </div>
    );
  }
  return (
    <React.Fragment>
      <Page>
     
        <Box className={classes.boxcontainer} >
        {isLoading ? (
              <Loader />
            ) : (
              <>
          <Grid container direction="row">
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={() => navigate("/profile")}
              />
              <Typography className={classes.header}>
                {status === "Approved" ? "Verified" :
                  ( activeStep === 0 ? " Start KYC" : "Continue KYC")
                }
              </Typography>
          </Grid>
          <div>
           
              <Stepper
                style={{ padding: "50px 32px 0px 32px" }}
                activeStep={activeStep}
                connector={<QontoConnector />}
                orientation="vertical"
              >
                {steps.map((label, index) => (
                  <Step key={label.title} className={classes.button}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      className={classes.stepTitle}
                    >
                    </StepLabel>
                    <Typography className={classes.stepDescription}>
                      {label.document_name}
                    </Typography>
                  </Step>
                ))}
              </Stepper>
      
          </div>
    
        <Grid className={classes.bottom} item sx={12} md={12}>
          {status === "Approved" ? "" : <Button
            style={{
              backgroundColor: "#6D50CC",
            }}
            variant="contained"
            // disableRipple
            className={classes.submitBtn}
            elevation={0}

            onClick={() => {
              {
                activeStep === 0 ? navigate("/uploadaadhar", {
                  replace: true,
                  state: { activestep: activeStep }
                }) : handleKyc()
              }

            }}
          >
            {activeStep === 0 ? " Start KYC" : "Continue"}

          </Button>}

        </Grid>
    
        </> 
     
        )}
               </Box>  
      </Page>
      <BottomNavigation activeTab={2} />
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(Status);
