import React from "react";
import { Grid, Typography, Box, } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        display: "grid",
        padding: "0 0px !important",
        minHeight: "100vh ",
        background: "#0D0D0D !important",
    },

    center: {
        margin: " auto",
        textAlign: "center",
    },
    heading: {
        fontStyle: "normal  !important",
        color: "#fff  !important",
        fontWeight: "500  !important",
        fontSize: "18px  !important",
        fontFamily: "poppins  !important",
        lineHeight: "27px  !important",
    },
    text: {
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "400  !important",
        fontSize: "12px  !important",
        color: "#767677  !important",
        lineHeight: "18px !important",
    },
}));

const InternetConnectivity  = () => {
    const classes = useStyles();
    const cachedImage = localStorage.getItem('cachedImage');


    return (
        <>
                <Box className={classes.boxcontainer}>


                    <Grid className={classes.center}  >

                        <img src={cachedImage} style={{ height: "60px", width: "60px"}} />

                        <Typography
                        mt={1}
                            className={classes.heading}
                        >
                            Please wait..
                        </Typography>
                        <Typography className={classes.text} mt={0.2}>

                        Please check your internet connection & <br/>
                          try again.
                        </Typography>


                    </Grid>
                </Box>
        </>
    );
};

export default InternetConnectivity;
