import React, {useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box,  Typography, Grid, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    paddingBottom:'2rem'

  }, 
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  margin:{
    marginTop:'0px'
  },
  text: {
    color: "#A489FF!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 20px !important",
    lineHeight: "24px !important",
    marginLeft:'1rem !important'
  },
  Typography: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF",
  }
},
  typo: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF"},
     '& b': {
      color: "#A489FF",
   },
    marginTop:4,
  },
  typoHeader: {
    textAlign: "justify",
    color: "#A489FF",
    marginTop: 16,
    padding:"0px 30px !important",
  },
  typoSubHeader:{
    textAlign: "start",
    color: "#A489FF",
    marginTop: 16,
    padding:"30px !important",
  },
  ulClass:{
    padding:"0px 0px 0px 16px"
  },
  btn:{
    color:"#fff !important",
    padding:"10px 60px !important",
    background:"#6D50CC !important",
    fontFamily:"Poppins !important",
    borderRadius:"16px !important",
    fontWeight:"600 !important",
},
btnBox:{
    textAlign:"center !important"
}


}));

function RisksDisclosures() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    const element = document.getElementById("content");
    element.scrollIntoView();
   },[])

   const navigateFunction = (()=>{
    if(location.state){
      navigate("/termsandcondition",{ replace: true , state:{...location.state,condition:true}})
    }else{
      navigate("/profile",{ replace: true , state:{condition:true}})
    }
   })
 

  return (
    <div>
        <Box className={classes.boxcontainer} id="content">
          <Grid container direction="row" style={{padding: "20px ",}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "initial",
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={navigateFunction}
              />
              <Typography className={classes.text}>Risk Disclosure and Compliance</Typography>
            </Grid>
          </Grid>
       
          <Grid item sm={12} xs={12} md={12} lg={12}>
          <Typography variant="body2" className={classes.Typography}>
            There is no assurance that any of the companies we invest in will go
            public soon, if ever, nor are there any assurances that the
            securities of those companies will become publicly traded or
            unrestricted. Private investments are not appropriate for all
            investors. This asset class involves a high degree of risk,
            volatility, and illiquidity, beyond that associated with traditional
            asset classes. Investors should have the financial ability and
            willingness to accept the risks (including, among other things, the
            risk of loss of their entire investment and the lack of liquidity)
            that are characteristic of these types of investments. Investors
            should consider private investments a supplement, comprising a
            modest portion of an overall investment strategy.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            General Disclosure
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Investors are solely responsible are advised before making an
            investment decision with respect to any <b>UNLISTEDKART</b>{" "}
            displayed equity to carefully review the respective offering
            documents (including any relevant underlying agreements), and the
            related subscription documents regarding the Funds and are
            responsible for performing such due diligence as deemed appropriate,
            including consulting their own legal, tax, ERISA, and financial
            advisers. Any information provided by <b>UNLISTEDKART</b>, and its
            affiliates should not form the primary basis of the investment
            decision. This material is based upon information {" "}
            <b>UNLISTEDKART</b> believes is reliable. However,{" "}
            <b>UNLISTEDKART</b> does not represent or warrant that it is
            accurate, complete, and/or up to date.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <b>UNLISTEDKART</b> does not accept any responsibility to update any
            opinion, analyses or other information contained in the material.
            Material provided by <b>UNLISTEDKART</b> is for your general
            information only and solely to assist you in deciding whether to
            proceed with a further investigation of the Funds. It is not an
            offer or solicitation to buy or sell any security, which can be made
            only through the delivery of formal offering document(s) which
            include, among others, a confidential offering memorandum, limited
            liability company operating agreement, subscription agreement, and
            related subscription documents. Such formal offering documents
            contain additional information not set forth herein, including
            information regarding certain risks of investing, which such
            additional information is material to any decision to invest in the
            Funds.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The information contained herein is based on certain assumptions,
            hypotheses, estimates, and anticipated outcomes which may or may not
            be true. This Website contains forward-looking statements, including
            observations about markets and industry and regulatory trends as of
            the publication date of this Website. Forward-looking statements may
            be identified by, among other things, the use of words such as
            "expects," "anticipates," "believes," or "estimates," or the
            negatives of these terms, and similar expressions. Forward-looking
            statements reflect the Funds' views as of such date with respect to
            possible future events. Actual results could differ materially from
            those in the forward-looking statements because of factors beyond
            the Funds’ control. Investors are cautioned not to place undue
            reliance on such statements. No party has an obligation to update
            any of the forward-looking statements on this Website. Charts,
            tables, and graphs contained in this Website are not intended to be
            used to assist the reader in determining which securities to buy or
            sell or when to buy or sell securities. Performance may fluctuate,
            especially over short periods. In this Website, performance results
            are shown the net of all fees and expenses (such as transaction
            costs, management, performance, administrative and any other fees
            and expenses applicable to the Funds) and may reflect the
            reinvestment of dividends and other earnings. Statements made herein
            that are not attributed to a third-party source reflect the views
            and opinions of {""} <b>UNLISTEDKART</b>. No representation is made that any investor
            will or is likely to achieve results comparable to those shown or
            will make any profit or will be able to avoid incurring substantial
            losses. The past performance of <b>UNLISTEDKART</b>, its principals,
            partners, or employees, or any of the securities referred to herein
            is not indicative of future returns. Performance results are
            generally net of applicable fees and reflect reinvestment of
            profits. Investment returns will fluctuate and may be volatile,
            especially over short time horizons.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The Funds' investment strategy involves investments for which no
            public market exists. Little public information exists about many of
            these investments, and the Funds will be required to rely on its
            diligence efforts to obtain adequate information to evaluate the
            potential risks and returns involved in these investments.
            Therefore, the greater risk that the Funds may invest based on
            incomplete or inaccurate information may adversely affect the Funds'
            investment performance, which could impact both initial and ultimate
            valuations. This could subject the Funds to greater risk than
            investments in established publicly traded companies or businesses
            and negatively affect the Funds' investment returns. The Funds may
            not be able to secure shares in some or all the securities that have
            been targeted for purchase. If the shares are not accessible, the
            Funds will not be able to execute their investment strategy. There
            is no assurance that the Funds' diligence efforts will result in an
            investment being successful. There is no guarantee that{" "}
            <b>UNLISTEDKART</b> will be successful in achieving the Funds'
            investment objectives. An investment in the Funds contains risks,
            including the risk of complete loss. Any performance reflected
            herein is estimated, is based on incomplete information and is
            subject to change. Actual results, when available, may differ. Given
            the nature and number of companies underlying these securities, the
            Funds should be considered a more volatile and risky investment. The
            returns for the Funds have not yet been audited and may change
            because of audit. Any trademarks or business names in this Website
            are included solely for informational purposes and, in certain
            cases, as examples of companies in the asset class in which the
            Funds may invest, and any such trademarks and business names are
            owned by their respective trademark owners. While the companies
            represented by such trademarks and business names may be invested in
            by the Funds, there is no guarantee that such companies will be
            invested in by the Funds.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
          <b>KYC, AML & FATCA Policy:</b>
         <p className={classes.margin}> 
          At UnlistedKart, we are committed to maintaining the highest standards of integrity and professionalism in our relationship with you, our client. This commitment extends to the personal information and financial dealings you entrust to us. As part of this commitment, we undertake to provide full transparency regarding our policies, especially those related to Know Your Customer (KYC), Anti-Money Laundering (AML), and the Foreign Account Tax Compliance Act (FATCA).
          </p>
          <b>Our Commitment</b>
          <p className={classes.margin}>
          The Facilitator, herein referred to as UnlistedKart, hereby undertakes that it has fully disclosed all applicable policies, including but not limited to KYC, AML, and FATCA, on its website. This disclosure is aimed at ensuring all parties involved are well-informed of their rights and obligations, as well as the measures UnlistedKart employs to uphold regulatory compliance and ethical standards.
          </p>
          <b>Know Your Customer (KYC)</b>
          <p className={classes.margin}>
          Our Know Your Customer (KYC) policy is designed to deter and reduce the likelihood of UnlistedKart becoming a conduit for financial misconduct or the misuse of our offerings. It is imperative for us to solicit from all clients legitimate, precise, and prompt data concerning their identity and their financial engagements. For the transactions we handle, we gather necessary documents including the Customer Master List (CML), PAN Card, and a Cancelled cheque. Furthermore, for private transactions demanding comprehensive KYC, we obtain declarations and commitments regarding the origin of funds, eligibility, transnactional data sharing, and connections to neighbouring countires, all in adherence to the stipulations of local legislation and guidelines.
          </p>
          <b>Anti-Money Laundering (AML)</b>
          <p className={classes.margin}>
          UnlistedKart's AML policy reflects our commitment to monitor and prevent any transactions that facilitate criminal activities, including the funding of terrorism and illegal money laundering. We conduct due diligence on all transactions processed through our platform and report any suspicious activities to the relevant authorities to ensure compliance with AML regulations.
          </p>
          <b>Foreign Account Tax Compliance Act (FATCA)</b>
          <p className={classes.margin}>
          In compliance with FATCA, UnlistedKart collects information from our clients to ascertain their U.S. tax status. This policy helps in the identification of accounts held by U.S. taxpayers or foreign entities in which U.S. taxpayers hold a substantial ownership interest, ensuring compliance with U.S. tax laws.
          </p>
          <b>Accessibility of Our Policies</b>
          <p className={classes.margin}>
          These policies are accessible on our website at all times. We encourage all our clients and stakeholders to review them thoroughly to understand how UnlistedKart operates within the framework of these regulations. This transparency is pivotal to our mutual success and ensures a secure and trustworthy environment for all parties involved.
          </p>
          <b>Updates and Amendments</b>
          <p className={classes.margin}>
          UnlistedKart reserves the right to update or amend these policies as necessary to comply with new regulations or to better serve our clients' needs. Any such changes will be posted on our website and, where appropriate, notified to you directly.
          </p>
          <b>Contact Us</b>
          <p className={classes.margin}>
          Should you have any questions or require further clarification regarding any of our policies, please do not hesitate to contact us. Our dedicated team is here to provide you with the support you need to navigate these requirements effectively.
          </p>
          </Typography>
        </Grid>
        <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={navigateFunction}>
                Close
            </Button>
           </Box>
        </Box>
    </div>
  );
}

export default RisksDisclosures;
