import React, {useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box,  Typography, Grid, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    boxcontainer: {
      minHeight: "100vh",
      background: "#0D0D0D !important",
      paddingBottom:'2rem'
      
    },
    icon: {
      fill: "#fff  !important",
      cursor: "pointer",
    },
    text: {
      color: "#A489FF!important",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: " 500 !important",
      fontSize: " 20px !important",
      lineHeight: "24px !important",
      marginLeft:"1rem !important"
    },
    Typography: {
      textAlign: "justify",
      color: "#fff !important",
      padding:"5px 30px !important",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: " 400 !important",
      fontSize: " 14px !important",
      lineHeight: "24px !important",
      '& strong': {
       color: "#A489FF",
    }
  },
    typo: {
      textAlign: "justify",
      color: "#fff !important",
      padding:"5px 30px !important",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: " 400 !important",
      fontSize: " 14px !important",
      lineHeight: "24px !important",
      '& strong': {
       color: "#A489FF"},
      marginTop:4,
    },
    typoHeader: {
      textAlign: "justify",
      color: "#A489FF",
      marginTop: 16,
      padding:"0px 30px !important",
    },
    typoSubHeader:{
      textAlign: "start",
      color: "#A489FF",
      marginTop: 16,
      padding:"0 30px !important",
    },
    ulClass:{
      padding:"0px 0px 0px 16px"
    },
    btn:{
      color:"#fff !important",
      padding:"10px 60px !important",
      background:"#6D50CC !important",
      fontFamily:"Poppins !important",
      borderRadius:"16px !important",
      fontWeight:"600 !important",
  },
  btnBox:{
      textAlign:"center !important"
  }
  
  
  }));

function NoReturnsPolicy() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    const element = document.getElementById("content");
    element.scrollIntoView();
   },[])

   const navigateFunction = (()=>{
    if(location.state){
      navigate("/termsandcondition",{ replace: true , state:{...location.state,condition:true}})
    }else{
      navigate("/profile",{ replace: true , state:{condition:true}})
    }
   })

  return (
    <div>
        <Box className={classes.boxcontainer} id="content">
          <Grid container direction="row" style={{padding: "20px ",}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "initial",
                
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={navigateFunction}
              />
              <Typography className={classes.text}>Return and Exchange Policy</Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={12}>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            General No returns / exchange /refunds policy
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Thank you for choosing Unlistedkart and making the purchase. We hope
            that you are pleased and satisfied with your purchase and our
            outstanding services. However, unfortunately, if you are not fully
            satisfied with your purchase and looking forward to exchange or
            return, we are sorry to inform you that equities or shares obtained
            on the Unlisted space cannot be returned or exchanged. Most
            importantly, we, at Unlistedkart, would request you to go through
            the company's risk disclosure and all legal and compliance-related
            documents carefully before investing in Unlisted stocks. To learn
            more about our no return/no exchange policy, please read below.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                Any purchase made via the online platform and serviced according
                to the available inventory cannot be returned/exchanged.
              </li>
              <li>
                Although at times, Unlistedkart LLP might help you liquidate
                your shares, provided we are able to find the right investor for
                you.
              </li>
              <li>
                If you have shares that you have obtained from Unlistedkart LLP
                and would like to liquidate, please make sure to get in touch
                with us at{" "}
                <a
                  style={{ textDecoration: "none", color:"#A489FF" }}
                  href="mailto:operations@unlistedkart.com"
                >
                  operations@unlistedkart.com
                </a>
                .
              </li>
              <li>
                Most importantly, please be informed that the pricing of a given
                share might not be the same as the price you may have got it at.
                It could be significantly lower or a loose investment.
              </li>
              <li>
                Make sure to read the disclosure and all the legal and
                compliance-related documents carefully.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Contact Us
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If you have any questions, feedback, or complaint, get in touch with
            us at{" "}
            <a
              style={{ textDecoration: "none", color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>
            .
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart LLP <br />
            Attn: 1206 12th Floor A Wing Mittal Towers MG road
            <br />
            Bangalore Karnataka 560001 India
          </Typography>
        </Grid>
        <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={navigateFunction}>
                Close
            </Button>
           </Box>
        </Box>
    </div>
  );
}

export default NoReturnsPolicy;
