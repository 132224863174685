import React, { useState, useRef } from "react";
import { Grid, Typography, Box, Button, Dialog } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import BottomNavigation from "../../component/Home/BottomNavigation";
import selfie from "../../Images/kyc/selfie.svg";
import { kycService } from "../../_services/kyc";
import { PhotoCameraRounded } from "@mui/icons-material";
import Compressor from "compressorjs";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",

    padding: "15px 0px",
  },
  icon: {
    fill: "#fff  !important",
    marginRight: "20px",
    cursor: "pointer",
    marginLeft: "0px",
  },


  button: {
    width: "calc(100vw - 36px)  !important",
    height: "54px  !important",
    padding: "12px 0px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },

  dialogBtn: {
    borderRadius: "10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight: "14px  !important"
  },
  cards: {
    "& 	.MuiDialog-paper": {
      width: "241px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      // padding: "16px 0px !important",
      // height:"136px !important"
    },
  },
  imgBox: {
    border: "none !important"
  },
  dialogTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    margin: "1rem !important",
    padding: "0 !important",
    minHeight: "70px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important"
  },

  bottom: {
    textAlign: "center",
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -80%)",
  },
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    lineHeight: "22px !important",
  },
}));

const TakeSelfie = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showCamera, setShowCamera] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState("");
  let videoRef = useRef(null);
  const [localFilesChoosen, setLocalFilechoosen] = React.useState();
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;

  let photoRef = useRef(null);
  const hiddenFileInput = React.useRef(null);

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    handleCapture(event.target);
    new Compressor(fileUploaded, {
      quality: 0.2,
      success: (res) => {
        setLocalFilechoosen(res);
        setIsLoading(false);
      },
      error: (err) => {
      }
    });
  };

  const handleClickOpen = () => {
    hiddenFileInput.current.click();

  }

  // upload  user selfie image details
  const UploadSelfiImage = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("kyc_copy", localFilesChoosen);
    formData.append("userid", userID);
    try {
      let data = await kycService.uploadSelfiImage(
        formData
      );
      setIsLoading(false);
      if (data.data.success) {
        navigate("/sign", { replace: true })
      } else {
        setOpen(true)
      }

    } catch {
      setIsLoading(false);

      console.log("error");
    }
  }
  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setSource(newUrl);
      }
    }
  }
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Take a Selfie
              </Typography>
            </Grid>
          </Grid>
          {
            source ? (
              <div>
                <Box display="flex" justifyContent="center" border={1} className={classes.imgBox}>
                  <img src={source} alt={"snap"} style={{ height: "calc(100vh - 300px)", width: "calc(100vw - 50px)",objectFit:'contain' }} className={classes.img}></img>
                </Box>
              </div>

            ) :


              (<Grid
                item
                xs={12}
                md={12}
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                }}
                className={classes.center}
              >
                <Grid
                  style={{
                    marginBottom: "10px",
                    width: "318px",
                    height: "263px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <img src={selfie} style={{ height: "100%", width: "100%" }} />
                </Grid>


              </Grid>)
          }


          <Grid className={classes.bottom}>
            <Grid
              style={{
                textAlign: "center",
                marginBottom: "40px",

              }}
            >
              {source ?
                <Typography className={classes.text}>

                  Not satifisfied? <span onClick={() => setSource("")} style={{ color: "#6D50CC", textDecoration: "underline" }}>
                    Retake
                  </span>
                </Typography>
                :
                <Typography className={classes.text}>

                  Ensure you’re sitting in a well <br /> lit room :)
                </Typography>
              }
            </Grid>
            {source ?
              <Button
                disabled={isLoading}
                type="submit"
                disableRipple
                className={classes.button}
                style={isLoading ? {
                  background: "repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                  backgroundRepeat: "repeat-x",
                  backgroundSize: "400% 100%",
                  WebkitAnimation: "AnimationName 6s linear infinite",
                  MozAnimation: "AnimationName 6s linear infinite",
                  animation: "AnimationName 6s linear infinite"
                } : {}}
                fullWidth
                variant="contained"
                onClick={UploadSelfiImage}
              >
                Upload
              </Button> : <Button
                disableRipple
                disabled={isLoading}
                type="submit"
                className={classes.button}
                fullWidth
                variant="contained"
                onClick={handleClickOpen}
              >
                <input
                  ref={hiddenFileInput}
                  accept="image/*;capture=camera"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  capture="camera"
                  style={{ display: 'none' }}
                  onChange={handleChange}

                />
                Open Camera
              </Button>
            }
          </Grid>
        </Box>
        <Dialog onClose={() => setOpen(false)} open={open} className={classes.cards}>
          <Grid item xs={12} md={12}>
            <Typography pt={1} px={3} className={classes.dialogTitle} >
               Couldn't see you properly! <br />Take another selfie :)
            </Typography>
          </Grid>
          <Grid container style={{ display: "flex" }}>
            <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
              < Button className={classes.dialogBtn}
                disableRipple
                style={{ color: "#A489FF" }}
                onClick={() => {
                  setOpen(false)
                  setSource()
                }}
              >
                Retake
              </  Button>
            </Grid>
            <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", borderLeft: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
              < Button className={classes.dialogBtn} style={{ color: "#8C8D8D" }}
                disableRipple
                onClick={() => {
                  navigate("/status")
                }}
              >
                Close
              </  Button>
            </Grid>
          </Grid>
        </Dialog>
        <BottomNavigation />
      </Page>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(TakeSelfie);
