import React from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
// import BackgroundImage from "../../Images/home/loginbg.gif";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "../../component/Home/BottomNavigation";
const useStyles = makeStyles((theme) => ({
  portfolio: {
    width: "100% !important",
    justifyContent: "left !important",
    marginTop: "1rem !important",
    backgroundImage:
      "linear-gradient(268.11deg,#AB97EB 16.06%, #583EAC 94.33%) !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  24px 16px !important",
  },
  Btn: {
    width: "100% !important",
    justifyContent: "left !important",
    marginTop: "1rem !important",
    background: "#2B2B2B !important",
    border: "2px solid #2B2B2B !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  24px 16px !important",
    "&:hover": {
      backgroundColor: "#2B2B2B",
    },
  },
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    // backgroundImage: `url(${BackgroundImage})  !important`,
    // backgroundRepeat: "no-repeat  !important",
    // backgroundSize: "cover  !important",
  },
  container: {
    padding: "15% 3% 25% 0% !important",
  },
  cardHeaderMargin: {
    width: "258px !important ",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "600  !important",
    fontSize: "30px !important",
    lineHeight: "45px !important",
    color: "#fff",
  },

  font: {
    color: "#fff",
    fontWeight: "500  !important",
    lineHeight: "16px !important",
    fontStyle: "normal",
  },
}));

function Sell() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer}>
          <Container
            
            style={{
              position: "absolute",
              bottom: "10%",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <Container className={classes.container}>
              <Typography
                className={classes.cardHeaderMargin}
                style={{ color: "#fff" }}
              >
                Which shares do you wish to
              </Typography>
              <Typography
                className={classes.cardHeaderMargin}
                style={{ color: "#AC93FF" }}
              >
                sell?
              </Typography>
            </Container>
            <Grid container direction="column">
              <Button
                className={classes.portfolio}
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate("/select");
                }}
              >
                <Typography
                  className={classes.font}
                  style={{ fontFamily: "poppins" }}
                >
                  UnlistedKart Portfolio
                </Typography>
              </Button>

              <Grid container  style={{ margin: "2rem  auto "}}>
                <Grid item xs={5} style={{ paddingLeft:"0px"}}>
                  <hr
                    style={{
                      backgroundColor: "#494949",
                      border: "none",
                      height: "1px",
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingLeft:"0px"}}>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "center",
                      marginTop: "-4px",
                      color: "#494949",
                      fontFamily: "Rubik",
                    }}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid item xs={5} style={{ paddingLeft:"0px"}}>
                  <hr
                    style={{
                      backgroundColor: "#494949",
                      border: "none",
                      height: "1px",
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                className={classes.Btn}
                fullWidth
                variant="contained"
                onClick={() =>
                  navigate("/sellportfilio", {
                    replace: true,
                    state: { type: "othersportfolio" },
                  })
                }
              >
                <Typography
                  className={classes.font}
                  style={{ fontFamily: "Poppins" }}
                >
                  Other Portfolio
                </Typography>
              </Button>
            </Grid>
          </Container>
        </Box>
      </Page>
      <BottomNavigation activeTab={2} />
    </div>
  );
}

export default Sell;
