import React from "react";
import { Grid, Typography } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from "react-router-dom";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "8px 8px 2px 8px",
  },
  icon: {
    fill: "#fff  !important",
    margin: "4px",
    fontSize:'27px  !important',
    cursor:"pointer"
  },
}));

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
const path = window.location.pathname
const location = useLocation()
  return (
    <>
      <Grid
        style={{ display: "flex", justifyContent: "space-around" }}
        className={classes.container}
        container
        direction="row"
      >
        <Grid item xs={1}>
          <KeyboardBackspaceIcon 
          onClick= {()=> {
            if(path=="/individualdetails" || path=="/companydetails"){
              navigate("/type",{
                  replace: true,
                  state:location != undefined ? location.state  : null
              })
            }else if(location?.state?.fromLocation ==="aadhar"){
              navigate('/uploadaadhar')
            }else if(location?.state?.fromLocation ==="pan"){
              navigate('/uploadpan')
            }else{
              navigate("/")
            }
          }}
          className={classes.icon} />
        </Grid>
        <Grid item xs={10} style={{ marginLeft: "1rem" }}>
          <Typography
            variant="h6"
            style={{ color: "#fff", fontWeight: "bold" }}
          >
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
