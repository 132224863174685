import React, { useState } from "react";
import { Grid, Typography, Box, Button, Input, TextField } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import upload from "../../Images/upload.svg";
import clear from "../../Images/clear.svg";
import HelperText from "../../component/DarkMode/HelperText";
import BottomNavigation from "../../component/Home/BottomNavigation";
import Compressor from "compressorjs";
import { connect } from "react-redux";
import VerifyButton from "../../component/Kyc/VerifyButton"
import { kycService } from "../../_services/kyc";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0px 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",

    padding: "16px 0px",
  },
  icon: {
    fill: "#fff  !important",
    marginRight: "20px",
    cursor: "pointer",
    marginLeft: "0px",
  },

  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },

  button: {
    // padding: "12px px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    // textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
    "&:disabled": {
      color: "#6E58B0 !important",
      backgroundColor: "#3B3159 !important",
    },
  },

  textfield: {
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      position: "relative",
      width: "100%",
      padding: "10px 60px 10px 16px",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      color: " #5F6060",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400 !important",
      fontSize: "14px  !important",
    },
    margin: "5px 0px 15px 0px !important ",
  },
  subtext: {
    paddingTop: "10px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "16px !important",
  },
  label: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  bottom: {
    textAlign: "center",
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  Btn: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "1rem !important",
    // background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  32px 12px !important",
    textAlign: "center!important ",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient( #583EAC ,#DD4A47), linear-gradient(1800deg, #583EAC 0%,  #DD4A47 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  },
  BtnTwo: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    marginTop: "0.75rem !important",
    background: "#1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  30px 12px !important",
    textAlign: "center!important ",
    borderRadius: "8px !important",
    backgroundImage: "repeating-linear-gradient(0deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(90deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(180deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px), repeating-linear-gradient(270deg, #333333, #333333 11px, transparent 11px, transparent 17px, #333333 17px)!important",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100% !important",
    backgroundSize: "1.5px 100%, 100% 1.5px, 1.6px 100% , 100% 1.5px !important",
    backgroundRepeat: "no-repeat!important",
   
  },
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    lineHeight: "22px !important",
  },
}));

const Sign = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("error");
  const [sign, setSign] = useState(true);
  const [fileChoosen, setFilechoosen] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [submitButton, setSubmitButton] = useState(true);
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;



  const chooseFile = (event) => {
    setSign(true)
    const image = event.target.files[0];
    const type = event.target.files[0].type;
    if (type === "image/png" || type === "image/jpeg") {
        setFilechoosen(image);
        setSubmitButton(false);
    } else {
      setSign(false)
      setSubmitButton(true);
      setHelperText("Unsupported format")
   
    }
    setSelectedFileName(event.target.files[0].name)

  }

  const uploadFile = async() => {
    if (fileChoosen != undefined) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("kyc_copy", fileChoosen);
      formData.append("userid", userID);
    let aadharUploadResponse = await  kycService.uploadSign(formData).then((res) => {
      if (res) {
        setIsLoading(false);
        if (res.status === 200) {
          if (res.data.success) {
            navigate("/bank", {
              replace: true,
              state: { id:userID},
            });
          } else {
            setSign(false)
      setHelperText("Invalid details")
      setSubmitButton(true);
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {

          }
        }
      }
    })
    }
  }

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Add Signature
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid
              className={classes.container}
              container
              flexDirection="column"
            >
              <Typography
                className={classes.label}
              >
               Upload Signature
              </Typography>
              <Grid className={sign ? classes.BtnTwo : classes.Btn}>


                <label htmlFor="Upload">
                  <Input
                    style={{ display: "none" }}
                    fullWidth
                    type="file"
                    inputProps={{ accept: "image/png, image/jpeg" }}
                    name="Upload"
                    id="Upload"
                    onChange={chooseFile}
                  />

                  <Button
                    startIcon={<img src={upload} />}
                    component="span"
                    className={classes.button}
                    style={{
                      textTransform: selectedFileName ? "none" : "uppercase",
                      fontSize: "12px", height: "34px", width: "168px",wordBreak:" break-word"
                    }}
                    variant="contained"
                  
                    endIcon={ sign?"":<img src={clear}  onClick={chooseFile} />}
                 
                  >
                   <span style={selectedFileName &&{
                          whiteSpace:"nowrap", 
                          overflow:"hidden", 
                          textOverflow:"ellipsis"
                       }}> {selectedFileName ? selectedFileName : "Upload Image"}</span>
                  </Button>
                </label>

                <Typography className={classes.subtext}>
                  Supported file formats : JPG & PNG
                </Typography>
              </Grid>
            </Grid>
            {sign ? "" : <HelperText helperText={helperText} type={type} />}


            <Grid className={classes.bottom}>
            <Grid 
              style={{
                textAlign: "center",
                marginBottom: "40px",

              }}
            >
            </Grid>
              {" "}
              < VerifyButton submitButton={submitButton || isLoading} text={"Proceed"} onClick={uploadFile} isLoading={isLoading} />

            </Grid>
          </Grid>
        </Box>

        <BottomNavigation />
      </Page>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
 

export default connect(mapStateToProps)(Sign);