import axios from 'axios'
import { helpers } from '../_helpers/index'
 
export const orderService = {
    getOrders,
    createServiceOrderForApp,
    createServiceOrderForVouch,
    escrowStatus
}

function getOrders() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/orders_of_client/`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
 

function createServiceOrderForApp(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/createServiceOrderForApp`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



function createServiceOrderForVouch(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/createServiceOrderForAppFromVouch`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function escrowStatus(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getEscrowTransaction`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}