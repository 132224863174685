import axios from 'axios'
import { helpers } from '../_helpers'
import logo from '../Images/favicon.ico'
 const razorPayKey =localStorage.getItem('razorPayKey')
export const razorPayIntegration = {
    orderCreateBuyUnlistedShare,
    orderCreateResearchReport,
    pgConnect,
    getPaymentStatus
}

function orderCreateBuyUnlistedShare(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/buy_unlisted_shares_vouch`,JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


function getPaymentStatus(data){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/getPaymentStatus`,JSON.stringify(data), requestOptions)
      .then(data => {
          return data
      })
      .catch(error => {
          return error.response
      })
}

function orderCreateResearchReport(scrip_id){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/buy_reasearch_reports_razorpay/${scrip_id}`, requestOptions)
      .then(res => {
          return res
      })
      .catch(error => {
          return error.response
      })
}

async function pgConnect(order, callBack){
    const res = await helpers.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
     if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const { amount, id: order_id, currency, name, email, contact } = order;

    const options = {
      key: razorPayKey, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Unlistedkart LLP",
      description: "",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        
        const data = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          email:email,          
        };      

        const result = await axios.post(
          `/payment/verify_razorpay`, 
          data
        );

        let resultDate = {...data}
        resultDate.amount = amount;
       

        callBack(data, result)
        // alert(result.data.msg);
      },
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#314B8F",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
