import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box, Button, Typography, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";
import { AllFaq } from "../../../src/_services/faq's";
import ReactHtmlParser from "react-html-parser";
import BottomNavigation from "../../component/Home/BottomNavigation";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  text: {
    color: "#fff!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 16px !important",
    lineHeight: "24px !important",
  },
  label: {
    marginTop: "20px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "12px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  textfield: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
      borderBottom: " solid 1px #1C1C1C !important",
    },
  },
  font: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#FFF !important",
    lineHeight: "16px !important",
  },
  Btn: {
    // textAlign:"center !important ",
    maxWidth: "231px !important",
    justifyContent: "center !important",
    marginTop: "1rem !important",
    background: "#2B2B2B !important",
    border: "2px solid #2B2B2B !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  8px 12px !important",
  },
  accordion: {
    padding: "10px 0px!important",
    "& 	.MuiAccordion-root": {
      backgroundColor: "#0D0D0D  !important",
      boxShadow: "none !important",
    },
  },
  accordionSummary: {
    padding: "0x !important",
    minHeight: "20px !important",
    // maxHeight: "20px !important",
    "&.MuiAccordionSummary-root": {
      padding: "0x !important",
      color: "#fff !important",
      justifyContent: " space-between",
    },
    "&.MuiAccordionSummary-content":{
      margin:"0px !important",
      padding: "0x !important",
    },
    "&.MuiAccordionDetails-root":{
      padding: "8px !important "
    }
  },
  accordionDetails: {
    // padding: "16px !important ",
    marginBottom:"1rem  !important",
    paddingBottom:"0px !important ",
    
  },
  
  title: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    lineHeight: "21px !important",
    // textTransform: "capitalize !important",
},
   subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "22px !important",
    // textTransform: "capitalize !important",
    textAlign:"justify"
},
}));

function FAQs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [allFaqData, setAllFaqData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const getAllFaq = () => {
    AllFaq.getAllFaq()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAllFaqData(res.data.data);
            if(demat){
              const element = document.getElementById(13);
              element.scrollIntoView();
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };
const demat = location && location.state && location.state.demat
  useEffect(() => {
    getAllFaq();
  }, []);

  const handleChange = (value) => () => {
    if(expanded === value){
      setExpanded(false)
    } else{
      setExpanded(value);
    } 
  };

  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid container direction="row" style={{padding: "14px 16px 20px 16px",}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={() => {
                  if(demat){
                    navigate("/demat")
                  }else{
                    navigate("/profile")
                  }
                }}
              />
              <Typography ml={2.5} className={classes.text}>FAQs</Typography>
            </Grid>
          </Grid>
          {allFaqData.map((item, i)=>{return( 
          <>
          <Grid item xs={12} md={12} className={classes.accordion} key={item.id}>
            <Accordion 
            onChange={handleChange(item.id)}
            expanded={expanded ? expanded === item.id : demat && item.question === "How to get your CMR/ CML copy?"}
            // defaultExpanded={ demat && item.question === "How to get your CMR/ CML copy?" || expanded === item.id } 
            id={i}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={
                  <ExpandMoreIcon style={{ color: "#fff", width: "20px" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography mr={2} className={classes.title}>
                {item.question}
                </Typography>
              </AccordionSummary >
              <AccordionDetails   className={classes.accordionDetails}>
                <Typography mr={3} className={classes.subtitle}>
                {ReactHtmlParser(item.answer.replaceAll("<a", `<a style="color:#A489FF"`))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <hr
              style={{
                backgroundColor: "#1C1C1C",
                border: "none",
                height: "1px",
                margin:"0px"
              }}
            />
          </Grid>
          </>)})}
         
        </Box>
      </Page>
      <BottomNavigation  activeTab={2}/>
    </div>
  );
}

export default FAQs;
