import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";
import closeicon from '../../Images/closeIcon.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    maxWidth: "400px !important",
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    padding: "15px !important",
  },
  title: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "18px !important",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "600  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "14px !important",
  },
  upWard: {
    color: "#7BDE89!important",
    fontWeight: "400  !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    paddingLeft: "5px  !important",
    lineHeight: "15px !important",
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#767677 ",
    lineHeight: "15px !important",
    marginRight:"6px !important"
  },

  downWard: {
    color: "red !important",
    fontWeight: "400  !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    padding: "5px  !important",
    lineHeight: "14px !important",
  },
  rate: {
    fontFamily: "Roboto   !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "10px  !important",
    color: "#fff",
    lineHeight: "12px !important",
  },
  transRate:{
    fontFamily: "Roboto   !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff",
    lineHeight: "14px !important",
  },
  transTitle:{
    fontFamily: "Poppins   !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#fff",
    lineHeight: "15px !important",
  },
  accordion: {
    "& 	.MuiAccordion-root": {
      backgroundColor: " #1C1C1C !important",
      boxShadow: "none !important",
    },
    "& .Mui-expanded":{
      justifyContent: "space-between !important",
      margin:'0px  !important',
    },
    "& .MuiAccordionSummary-content": {
      margin:'0px !important',
    },
  },
  accordionSummary: {
    alignItems:"center !important",
    "& .MuiAccordionSummary-root": {
      color: "#fff !important",
      justifyContent: "space-between !important",

    },
    "&.MuiAccordionSummary-content": {
      color: "#fff !important",
      justifyContent: "space-between !important",
    },
    "& .Mui-expanded":{
      justifyContent: "space-between !important",
      margin:'0px  !important',
    }
  },


  indicateWords: {
    fontFamily: "Poppins !important",
    fontStyle: "italic !important",
    fontWeight: " 400 !important",
    fontSize: "10px !important",
    lineHeight: "15px !important",
    color: "#767677 !important",
  },
  dot:{
  height: "2px", 
  margin:"5px",
  width: "2px",
  backgroundColor: "#767677",
  borderRadius: "50%",
  display: "inline-block",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      minWidth: "327px !important",
      borderRadius: "16px !important",
      backgroundColor:"#1C1C1C !important",
      padding: "16px 0px !important",
    },
  },
  titles: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "16px  !important",
    color: "#AC93FF !important",
    lineHeight: "24px !important",
    textAlign: "center !important",
  },
  subtitles: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    textAlign: "center !important",
  },
}));

function PriceCardsDropdown({data}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const   [open, setOpen] = React.useState(false);
  const   [transactionsData, setTransactionData] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const parse =((data)=>  parseFloat(data).toFixed(2))

  return (
    <div style={{marginBottom:'2rem'}}>
      {data && data.map((item,index)=>{
        return(
          <Box style={{ margin: "10px 16px" }} key={index}>
        <Grid container >
          <Grid
            item
            xs={6}
            md={6}
            style={{ display: "flex", alignItems: "center",  marginBottom:"8px" }}
          >
            <Typography className={classes.title}>
              {item.scrip_name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
               marginBottom:"8px"
            }}
          >
            <Typography className={classes.value}>{item.individualPandL >=0 ? "+" : "-" } ₹{parse(item.individualPandL).replace("-", "")} </Typography>
            <Typography
              className={clsx({
                [classes.upWard]: item.percentage_of_change >= 0,
                [classes.downWard]: item.percentage_of_change < 0,
              })}
              variant="subtitle2"
            >
              {"   "} {item.percentage_of_change >=0 && "+" }
              {parse(item.percentage_of_change)}%
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            style={{ display: "flex", alignItems: "center",  marginBottom:"8px" }}
          >
             <Typography className={classes.subtitle}>Qty {" "}</Typography>
             <Typography className={classes.rate}>{" "}{item.total_quantity} Shares</Typography>
          </Grid>
          <Grid
            item
            xs={7}
            md={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginBottom:"8px"
            }}
          >
           <Typography className={classes.subtitle}>Buy Price</Typography>
           {item.holding_transactions.length>1 ?
           <Typography onClick={()=> {
            setOpen(true)
            setTransactionData(item)
          }} className={classes.rate} style={{color:"#A489FF"}} >₹<span style={{textDecoration:"underline"}}>{parse(item.average_price_per_share)}</span>*</Typography> :
           <Typography className={classes.rate} >₹{parse(item.average_price_per_share)}</Typography>
            }
            <span className={classes.dot}/>
            <Typography className={classes.subtitle}>CMP</Typography>
            <Typography className={classes.rate}>₹{parse(item.current_market_price)}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{ display: "flex", alignItems: "center",  marginBottom:"8px" }}
          >
            <Typography className={classes.subtitle}>Invested</Typography>
            <Typography className={classes.rate}>₹{parse(item.total_price)}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginBottom:"8px"
            }}
          >
           <Typography className={classes.subtitle}>Value</Typography>
           <Typography className={classes.rate}>₹{parse(item.current_share_value)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
            <hr
              style={{
                backgroundColor: "#494949",
                border: "none",
                height: "1px",
              }}
            />
          </Grid>
      </Box>
        )
      })}

    <Dialog  open={open} 
      onClose={()=>{
        setOpen(false)
      }} 
       className={classes.cards}>
         <Grid item xs={12} md={12} style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0 1rem"}}>
            <Typography className={classes.title}>
              {transactionsData?.scrip_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <hr
              style={{
                backgroundColor: "#494949",
                border: "none",
                height: "1px",
                margin: "8px  16px",
              }}
            />
          </Grid>
      <Grid item xs={12} md={12} className={classes.accordion}>
            <Accordion defaultExpanded>
              <AccordionSummary
              style={{ display:"flex", justifyContent: "space-between",minHeight: "10px  "}}
                expandIcon={
                  <ExpandMoreIcon style={{ color: "#fff", width: "15px" }} />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.accordionSummary}
              >
                <Grid item xs={6} md={6} sx={{ display: "flex" }}>  <Typography className={classes.transTitle}>
                    {" "}
                    Buy Price
                  </Typography></Grid>
                  <Grid item xs={6} md={6} sx={{ display: "flex" }}><Typography className={classes.transRate}>
                    ₹{transactionsData?.average_price_per_share}
                    <span style={{ color: "#AC93FF", fontSize: "12px" , padding: "2px",}}>
                      *
                    </span>
                  </Typography></Grid>
                  
              </AccordionSummary>
             {transactionsData?.holding_transactions.map((item,index)=>{
              return(
                <AccordionDetails
                key={index}
                style={{
                  justifyContent: "space-between",
                  padding: "4px 0px",
                  margin: "0 1rem",
                  display: "flex",
                }}
              >
                <Grid item xs={6} md={6} sx={{ display: "flex" }}>
                  <Typography className={classes.transTitle}>
                    {" "}
                    {moment(item.transaction_date).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}
                >
                  <Typography className={classes.transRate}>₹{parseFloat(item.price_per_share).toFixed(1)}</Typography>
                </Grid>
              </AccordionDetails>
              )
             })}
            </Accordion>
              <Grid item xs={12} md={12} sx={{pl:2}}>
                {" "}
                <Typography className={classes.indicateWords}>
                  <span
                    style={{
                      color: "#AC93FF",
                      fontSize: "12px",
                      paddingRight: "2px",
                    }}
                  >
                    *
                  </span>
                  indicates average buy price
                </Typography>
              </Grid>
          </Grid>
    </Dialog>
    </div>
  );
}

export default PriceCardsDropdown;
