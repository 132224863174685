import React, { useState } from "react";
import { Grid, Box, Button, Stack, Typography, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import BottomNavigation from "../../component/Home/BottomNavigation";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@mui/styles";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import Frame1 from '../../../src/Images/kyc/Frame1.svg'
import Frame2 from '../../../src/Images/kyc/Frame2.svg'
import Frame3 from '../../../src/Images/kyc/Frame3.svg'
import Frame4 from '../../../src/Images/kyc/Frame4.svg'
import Frame5 from '../../../src/Images/kyc/Frame5.svg'
import Frame6 from '../../../src/Images/kyc/Frame6.svg'
const images = [
  {

    imgPath: Frame1,
  },
  {
    label: "Keep your Aadhaar & PAN details within reach",
    imgPath: Frame2,
  },
  {
    label: " Keep your bank details handy for a penny drop verification",
    imgPath: Frame3,
  },
  {
    label: "Capture your sign and just upload",

    imgPath: Frame4,
  },
  {
    label: "CML/CMR copy is required for Demat verification",

    imgPath: Frame5,
  },
  {
    label: "That’s it!",
    imgPath: Frame6,
  },
];

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)  !important",
    background: "#0D0D0D !important",
    textAlign: "center",
    display: "flex",
    alignItems: " center",
    justifyContent: " center",
    padding: "0px 10px"
  },
  heading: {
    fontSize: "18px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "600 !important",
    color: "#FFF !important",
    lineHeight: "27px !important",
  },

  button: {
    fontSize: "16px  !important",
    width:"156px !important",
    padding: "12px 44px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "none !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },
  text: {
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    color: "#FFF !important",
    lineHeight: "22px !important",
  },
  skip: {
    fontSize: "12px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    color: "#A489FF !important",
    lineHeight: "16px !important",
  },
  dot: {
    paddingBottom: "20px !important",
    backgroundColor: "transparent  !important",
    "& 	.MuiMobileStepper-dot": {
      backgroundColor: "#5F6060 !important",
      width: "4px !important",
      height: "4px",
    },

    "& 	.MuiMobileStepper-dotActive": {
      backgroundColor: "#A489FF !important",
      width: "12px !important",
      borderRadius: "4px",
      height: "4px",
    },
  },
}));

const KycSlides = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = useState(location != undefined ? location.state != undefined ? location.state.activestep : null : null);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === maxSteps - 1) {
      navigate("/uploadaadhar")
      localStorage.setItem("KYCSlides", true)
    }
  };


  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Page >
        <Box className={classes.boxcontainer}>
          <Box>
            <Grid
              height={27}
              style={{
                display: "flex",
                alignItems: " center",
                height: "27px",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              {activeStep == 0 ? (
                <Typography className={classes.heading}>
                  All ‘bout KYC{" "}
                </Typography>
              ) : (
                ""
              )}
            </Grid>

            <Paper
              square
              elevation={0}
              sx={{
                display: "flex",
                borderRadius: "15px",
                alignItems: "end",
                textAlign: "center",
                justifyContent: "center",
                height: 484,
                width: 328,
                m: 2,
                padding:"28px 0px",
                bgcolor: "#1C1C1C",
              }}
            >
              <Box sx={{

                width: 300,


              }}>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {images.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          height={250}
                          width={280}
                          src={step.imgPath}
                          alt={step.label}
                        />
                      ) : null}
                    </div>
                  ))}
                </SwipeableViews>
           {images[activeStep].label ?
              <Grid
                  height={50}
                  style={{
                    display: "flex",
                    alignItems: " center",
                    height: " 50px",
                    justifyContent: "center",
                    margin: "1rem 2rem",
                  }}
                >
                  <Typography className={classes.text}>
                    {images[activeStep].label}
                  </Typography>
                </Grid>
                :
             <Grid style={{padding:"20px"}}></Grid>}
                <MobileStepper
                  className={classes.dot}
                  sx={{
                    bgcolor: "#1C1C1C",
                    justifyContent: "center",
                  }}
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}

                />
                <Button
                  className={classes.button}
                  size="small"
                  onClick={handleNext}
                >
                  {activeStep === 5  ? "CLOSE" : "NEXT"}
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Page>
      <BottomNavigation activeTab={2} />
    </>
  );
};

export default KycSlides;
