import axios from "axios";
import { helpers } from "../_helpers/index";
 
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const kycService = {
  getKycInfo,
  createKycRequest,
  resubmitKyc,
  getAllBanks,
  getAllBranchByBankId,
  uploadaadhar,
  getNameByPANnumber,
  confirmUserPanData,
  uploadAndVerifyDemat,
  checkIsDematPasswordProtected,
  verifyBankAccount,
  uploadPan,
  uploadSign,
  getAllState,
  getUserBasicKycDetails,
  uploadSelfiImage,
  getAllBankByIfsc,
  inserNewBranches,
  getDPWithName
};

async function getKycInfo() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/kyc/info`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function getUserBasicKycDetails() {
 
    helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getUserBasicKycDetails`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}


function createKycRequest() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/kyc/create`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

function resubmitKyc() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/kyc/resubmit`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function getAllBankByIfsc(ifsc) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/get_data_by_ifscCode?ifscCode=${ifsc}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function getDPWithName(name) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/searchDepositoryParticipantByName?name=${name}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function getAllBanks() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllBanks`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}
async function getAllState() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllState`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function getAllBranchByBankId(bankId,selectedState) {

  helpers.setHeader();
  const val = {
    bankId: bankId,
    state: selectedState.state
  };

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/getAllBranchByBankId`,val, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function uploadaadhar(formData){
 
  helpers.setHeader();
 
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/uploadAdhar`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}


async function uploadPan(formData){
 
  helpers.setHeader();
 
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/uploadPan`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function checkIsDematPasswordProtected(formData){
 
  helpers.setHeader();
 
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/checkPdfProtected`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function uploadSign(formData){
 
  helpers.setHeader();
 
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/uploadSign`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}



async function getNameByPANnumber(panNo,userID){
 
  helpers.setHeader();
  const val = {
    panNumber: panNo,
    userID:userID
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/verifyPan`,val, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}


async function inserNewBranches(branchObj){
  helpers.setHeader();
  const val = {
    "ifsc":branchObj.ifsc,
    "branch":branchObj.branch,
    "address":branchObj.address,
    "city":branchObj.city,
    "district":branchObj.district,
    "state":branchObj.state,
    "related_bank_id":branchObj.related_bank_id
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/insertNewBranches`,val, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

async function confirmUserPanData(userId,panCardNo){
  helpers.setHeader();
  const val = {
    userId: userId,
    panCardNo:panCardNo
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/updatePanDataInDb`,val, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}



async function uploadAndVerifyDemat(formData){
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/verifyDematAccount`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}



async function verifyBankAccount(accNo,IFSC,userID,bankName){
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let data ={
    accNo:accNo,
    IFSC:IFSC,
    userID:userID,
    bankName:bankName

  }
  return axios
    .post(`/hypervergePennyDropVerification`,data, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

 
async function uploadSelfiImage(formData){
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/uploadSelfi`,formData, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}