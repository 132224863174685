import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Stepper,
  StepConnector,
  StepLabel,
  Step,
  Button,
  Dialog,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Check from "@mui/icons-material/Check";
import Page from "../../component/Page/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { digioVerificationService } from "../../_services/digioVerificationService";
import SessionExpiredModal from "../../component/AlertModel/SessionExpiredModal";
import { connect } from "react-redux";
import Loader from "../../component/Loading/Loading";
import SnackBar from "../../component/SnackBar/SnackBar";
import { kycService } from "../../_services/kyc";
import BottomNavigation from "../../component/Home/BottomNavigation";
import Download from "../../Images/vouchdownload.svg";
import infoIcon from "../../Images/vouch/info.svg";
import { orderService } from "../../_services/orders";
import moment from "moment/moment";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    padding: "16px !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    padding: "1rem",
    display: "flex",
  },
  root: {
    backround: "#0d0d0d",
    flexGrow: 1,
    marginBottom: "5rem",
  },
  icon: {
    fill: "#fff  !important",
    // margin: "10px",
    cursor: "pointer",
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
  },
  button: {
    float: "right",
    marginTop: "-1rem",
    // marginLeft: "2rem",
  },
  header: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    lineHeight: "24px  !important",
    color: "#fff !important",
    marginLeft: "20px !important",
  },
  submitBtn: {
    display: "flex !important",
    justifyContent: "center  !important",
    width: "calc(100vw - 36px)",
    height: "54px",
    borderRadius: "10px  !important",
    padding: "12px 0px !important",
    boxShadow: "none  !important",
    "&:hover": {
      backgroundColor: " #6D50CC  !important",
      boxShadow: "none  !important",
    },
    textTransform: "uppercase  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    lineHeight: "24px  !important",
    color: "#fff !important",
  },
  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  stepDescription: {
    float: "left",
    display: "flex",
    alignItems: "center",
    // marginTop: "10px !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    lineHeight: "18px  !important",
    color: "#fff !important",
  },
  downloadDescription: {
    float: "left",
    display: "flex",
    textTransform: "none !important",
    alignItems: "center",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "10px  !important",
    lineHeight: "12px  !important",
    color: "#fff !important",
  },
  stepTitle: {
    float: "right",
    display: "flex",
    alignItems: "center",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      width: "241px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      // padding: "16px 0px !important",
      // height:"136px !important"
    },
  },
  dialogSubTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    // margin:"1rem !important",
    padding: "1rem !important",
  },
  dialogBtn: {
    borderRadius: "10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight: "14px  !important",
  },
}));

const Status = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const classes = useStyles();
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([]);

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [isLoading, serIsLoading] = useState(false);
  let complete = steps.length > 1 && steps[1]?.time ? true : false;
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 10,
      alignItems: "center",
    },
    circle: {
      borderRadius: "50%",
      color: "#fff",
      background: complete ? "#7BDE89" : "#323333",
      padding: "2px",
      marginTop: "8px !important",
    },
    completed: {
      background: "#7BDE89",
      background: complete ? "#7BDE89" : "#a489ff",
      borderRadius: "50%",
      padding: "2px",
      color: "#fff",
    },
    active: {
      background: complete ? "#7BDE89" : "#323333",
      borderRadius: "50%",
      padding: "2px",
      color: complete ? "#fff" : "#AC93FF",
    },
  });

  const QontoConnector = withStyles({
    active: {
      "& $line": {
        borderLeft: complete
          ? "3px solid #7BDE89"
          : "3px solid #a489ff !important",
        marginTop: "-20px",
        height: "4rem !important",
      },
    },
    completed: {
      "& $line": {
        borderLeft: complete
          ? "3px solid #7BDE89"
          : "3px solid #a489ff !important",
        marginTop: "-20px",
        height: "3rem !important",
      },
    },
    line: {
      float: "right",
      display: "flex",
      marginTop:  "-20px",
      alignItems: "center",
      marginRight: "1rem !important",
      borderLeft: complete
        ? "3px solid #7BDE89 !important"
        : "3px solid #323333 !important",
      borderRadius: 1,
      height:  "4rem",
    },
  })(StepConnector);

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div className={classes.root}>
        {completed ? (
          <Check fontSize="small" className={classes.completed} />
        ) : active ? (
          <Check fontSize="small" className={classes.active} />
        ) : (
          <Check fontSize="small" className={classes.circle} />
        )}
      </div>
    );
  }

  useEffect(() => {
    const data = {
      service_id: location.state.id,
    };
    serIsLoading(true);
    orderService.escrowStatus(data).then((res) => {
      if (res.status == 200) {
        setSteps(res.data.message);
        serIsLoading(false);
      }
    });
  },[]);
  return (
    <React.Fragment>
      <Page>
        <Box className={classes.boxcontainer}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Grid container direction="row">
                <KeyboardBackspaceIcon
                  className={classes.icon}
                  onClick={() => {
                    navigate("/holdings", {
                      replace: true,
                      state: { value: true },
                    });
                  }}
                />
                <Typography className={classes.header}>
                  Escrow Payment Status
                </Typography>
              </Grid>
              <div>
                <Stepper
                  style={{ padding: "50px 26px 0px 32px" }}
                  activeStep={1}
                  connector={<QontoConnector />}
                  orientation="vertical"
                >
                  {steps.map((label, index) => (
                    <Step key={label.title} className={classes.button}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        className={classes.stepTitle}
                      ></StepLabel>
                      <Box
                        style={{ display: "flex", flexDirection: "column" }}
                        mt={0.5}
                      >
                        <Typography
                          className={classes.stepDescription}
                          style={{ marginTop: index === 2 && "4px" }}
                        >
                          {label.label}{" "}
                          {/* {index !== 1 && (
                            <img
                              onClick={() => setOpen(true)}
                              style={{ marginLeft: "4px" }}
                              src={infoIcon}
                              alt={index}
                            />
                          )} */}
                        </Typography>{" "}
                        <br />
                        {label.time && (
                          <Typography
                            className={classes.downloadDescription}
                            mt={-1.5}
                          >                   
                            {moment(label.time).format("HH:mm , MMM DD YYYY")}
                          </Typography>
                        )}
                      </Box>
                      {/* {index === 1 && label.time && (
                        <>
                          <Grid container flexDirection="column" mt={-0.5}>
                            <Typography
                              mt={1.75}
                              className={classes.downloadDescription}
                            >
                              Below is the share transfer proof
                            </Typography>
                            <Button
                              className={classes.downloadDescription}
                              disableRipple
                              style={{
                                padding: "4px",
                                marginTop: "5px",
                                background: "#6D50CC",
                                width: "84px",
                              }}
                            >
                              <img
                                src={Download}
                                alt=""
                                width={16}
                                height={10}
                                style={{ paddingRight: "5px" }}
                              />
                              Download
                            </Button>
                          </Grid>
                        </>
                      )} */}
                    </Step>
                  ))}
                </Stepper>
              </div>
            </>
          )}
        </Box>

        <Dialog
          onClose={() => setOpen(false)}
          open={open}
          className={classes.cards}
        >
          <Grid item xs={12} md={12}>
            <Typography className={classes.dialogSubTitle}>
              The escrow system is brought to you by Vouch.
            </Typography>
          </Grid>
          <Grid container style={{ display: "flex" }}>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                borderTop: "1px solid #3B3159 ",
                height: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.dialogBtn}
                disableRipple
                style={{ color: "#A489FF" }}
              >
                Know More
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                borderTop: "1px solid #3B3159 ",
                borderLeft: "1px solid #3B3159 ",
                height: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.dialogBtn}
                style={{ color: "#8C8D8D" }}
                disableRipple
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Page>
      <BottomNavigation activeTab={2} />
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(Status);
