import React, { useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import FormControl from "@mui/material/FormControl";
import BottomNavigation from "../../component/Home/BottomNavigation";
import HelperText from "../../component/DarkMode/HelperText";
import * as Yup from "yup";
import { Formik } from "formik";
import VerifyButton from "../../component/Kyc/VerifyButton"
import Textfield from "../../component/DarkMode/Textfield";
import { kycService } from "../../_services/kyc";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",

    padding: "15px 0px",
  },

  icon: {
    fill: "#fff  !important",
    margin: " 0px 10px",
    cursor: "pointer",
    marginLeft: "0px",
  },

  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },


  confirmbutton: {
    // padding:"12px 100px  !important ",
    height: "54px  !important",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },

  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#DD4A47 !important",
    lineHeight: "16px !important ",
    margin: "0px 0px 0px  10px !important",
  },


  label: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    color: "#FFF !important",
    fontSize:"14px !important",
    lineHeight: "18px !important",
  },

  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  subtext: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#A489FF !important",
    lineHeight: "16px !important",
  },
  bottomGrid: {
    padding: "0px 16px",
    position: "absolute",
    bottom: "77px",
    left: "50%",
    transform: "translate(-50%, -0px)",
    width: "100%",
    textAlign: " center ",
    display: "inline-grid",
    backgroundColor: "#1C1C1C",
  },
}));

const UploadPan = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pan, setPan] = useState();
  const [submitButton, setSubmitButton] = useState(true);
  const [verify, setVerify] = useState(false);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [panCardNo, setpanCardNo] = useState('');
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;
  const [keyboard,setKeyboard] = useState("text")
  const [errorMsg, setErrorMessage] = useState('Invalid details');
  const [nameInvalid, setNameInvalid] = useState(false);


  // check user  pan details 
  const handleOnSubmit = async(values) => {


    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
 
    if (values.pan.match(regex)) {
      setNameInvalid(false)
      setpanCardNo(values.pan)
      setIsLoading(true)
      setPan(1)
    let panVerificationResponse = await  kycService.getNameByPANnumber(values.pan,userID)
    if(panVerificationResponse.data.success){
     if( panVerificationResponse.data.isNameMismatch==false){
          setUserName(panVerificationResponse.data.success.result.name)
          setErrorMessage("")
          setVerify(true)
     }else{
      setNameInvalid(true)
      setUserName(panVerificationResponse.data.success.result.name)
      setErrorMessage("")
      setVerify(false)
     
     }
    
    }else{
      setpanCardNo("")
      setPan(2)
      setErrorMessage(panVerificationResponse.data.message)
      setSubmitButton(true);
      setUserName("")
      setVerify(false)
    }
   

    } else {
      setPan(2)
      setSubmitButton(true);
      setpanCardNo("")
    }
    setIsLoading(false)
  }

  // update  pan details
const handleConfirmUserName = async()=>{
  const formData = new FormData();
  setIsLoading(true)
  let panVerificationResponse = await kycService.confirmUserPanData(userID,panCardNo)
  setIsLoading(false)
  if(panVerificationResponse.data.success==true){
    navigate("/takeselfie", {
      replace: true,
      state: { id:userID},
    });
    
  }else{
    setPan(2)
    setSubmitButton(true);
    setpanCardNo("")
  }
  setIsLoading(false);
}


  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Verify PAN
              </Typography>
            </Grid>
          </Grid>
          <Formik
            enableReinitialize={true}
            initialValues={{
              pan: "",
            }}
            validationSchema={Yup.object().shape({
              pan: Yup.string()
                .nullable()
                .required("pan is required")
              // .matches(panRegex, "Enter a valid PAN"),

            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}

                autoComplete="off"
                noValidate
              >
                <Grid>
                  <Grid
                    className={classes.container}
                    container
                    flexDirection="column"
                  >
                    <Typography
                      className={classes.label}
                    >
                      Enter PAN
                    </Typography>
                    <FormControl variant="standard" style={{ fontFamily: "Poppins" }}>
                      <Textfield
                        autoFocus
                        name="pan"
                        id="pan"
                        type= {keyboard}
                        inputmode="decimal"
                        // type={keyboard}
                        value={values.pan}
                        fullWidth
                        onChange={(e)=>{
                          setFieldValue("pan", e.target.value)
                          setPan()
                          if(e.target.value.length <5 || e.target.value.length >=9){
                            setKeyboard("text")
                          }else{
                            setKeyboard("tel")
                          }
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toUpperCase()
                            .toString()
                            .slice(0, 10)
                            .replace(/w/g, "");
                          if (e.target.value.length === 10) {
                            setSubmitButton(false);
                          } else {
                            setSubmitButton(true);
                          }
                        }}
                        placeholder={"XXXYY1122Z"}
                        valueColor={pan}
                        error={Boolean(touched.pan && errors.pan)}
                        helperText={touched.pan && errors.pan}
                      />

                    </FormControl>

                    {pan == "2" ? <HelperText helperText={errorMsg} type={"error"} /> : ""}
                  </Grid>

                  {verify ? "" : <Grid className={classes.bottom}>
                    < VerifyButton submitButton={submitButton || isLoading} text={" Verify"} isLoading={isLoading} />

                  </Grid>}
                  {verify ? (
                    <Grid className={classes.bottomGrid}>
                      <Typography
                        style={{
                          margin:"24px auto 16px",
                          color: "#fff",
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "poppins",
                          lineHeight: "24px",
                        }}
                      >
                        Is this {userName}?
                      </Typography>
                      <Button
                        disableRipple
                        className={classes.confirmbutton}
                        fullWidth
                        variant="contained"
                        onClick={handleConfirmUserName}
                        style={isLoading ?{ background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                        backgroundRepeat:"repeat-x",
                        backgroundSize:"400% 100%",
                        WebkitAnimation:"AnimationName 6s linear infinite",
                        MozAnimation:"AnimationName 6s linear infinite",
                        animation:"AnimationName 6s linear infinite"}: {}}
                      >
                        Yes, I confirm
                      </Button>
                      <Typography className={classes.subtext} my={2.5}
                      onClick={()=>{
                        setVerify(false)
                      }}>
                        Wrong name? Re-enter PAN
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
        {nameInvalid ? (
                    <Grid className={classes.bottomGrid} pb={5}>
                      <Typography
                      mb={4}
                        style={{
                          margin:"24px auto 16px",
                          color: "#fff",
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "poppins",
                          lineHeight: "24px",
                        }}
                      >
                       Name not as per PAN
                      </Typography>
                      <Button
                    
                        disableRipple
                        className={classes.confirmbutton}
                        fullWidth
                        variant="contained"
                        onClick={()=>{
                          navigate('/updateDetails', {
                          replace: true ,
                          state:{
                            fromLocation:"pan"
                          }
                            })
                       }}
                        style={isLoading ?{ background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
                        backgroundRepeat:"repeat-x",
                        backgroundSize:"400% 100%",
                        WebkitAnimation:"AnimationName 6s linear infinite",
                        MozAnimation:"AnimationName 6s linear infinite",
                        animation:"AnimationName 6s linear infinite"}: {}}
                      >
                       Update
                      </Button>
                    
                    
                    </Grid>
                  ) : (
                    ""
                  )}

                </Grid>
              </form>
            )}
          </Formik>
        </Box>

        <BottomNavigation />
      </Page>
    </>
  );
};

 
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
 

export default connect(mapStateToProps)(UploadPan);
