import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Formik } from "formik";
import BottomNavigation from "../../component/Home/BottomNavigation";
import FormHelperText from "@mui/material/FormHelperText";
import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "0 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    marginBottom:"1rem"
  },
  icon: {
    fill: "#fff  !important",
    margin: "10px",
    cursor: "pointer",
    marginLeft: "0px",
  },
  card: {
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    paddingRight: "0px !important",
    paddingBottom: "0px !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#AC93FF !important",
  },
  button: {
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(264.66deg, #AB97EB -16.97%, #583EAC 110.76%) !important",
    borderRadius: "10px 0px !important",
    textTransform: "none !important",
    maxHeight: "25px !important",
    marginTop: "13px !important",
    maxWidth: " 100px !important",
  },
  rate: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  upWard: {
    color: "green !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },
  downWard: {
    color: "red !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },

  textfield: {
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      position: "relative",
      width: "100%",
      padding: "10px 60px 10px 16px",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      color: " #5F6060",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400 !important",
      fontSize: "14px  !important",
    },
    margin: "5px 0px 15px 0px !important ",
  },
  label: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "12px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  font: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#FFF !important",
    lineHeight: "16px !important",
  },
  fonts: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },
  Btn: {
    // textAlign:"center !important ",
    maxWidth: "232px !important",
    justifyContent: "center !important",
    marginTop: "1rem !important",
    background: "#2B2B2B !important",
    border: "2px solid #2B2B2B !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  8px 12px !important",
  },
  portfolio: {
    minWidth: "328px !important",
    justifyContent: "center !important",
    backgroundColor: "#AC93FF !important",
    // backgroundImage: "linear-gradient(to right, #583EAC,#AB97EB) !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  12px 0px !important",
  },
  bottom: {
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      minWidth: "327px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      // backgroundOrigin: "border-box !important",
      // backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      padding: "16px 0px !important",
    },
  },
  titles: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "16px  !important",
    color: "#AC93FF !important",
    lineHeight: "24px !important",
    textAlign: "center !important",
    // BorderTop:"1px solid red !important"
  },
  subtitles: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    textAlign: "center !important",
    //  BorderTop:"1px solid red !important"
  },
}));

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.cards}>
      <DialogTitle
        className={classes.titles}
        style={{ fontFamily: "Poppins", borderBottom: "1px solid #1C1C1C" }}
      >
        Your request has been sent
      </DialogTitle>

      <List
        sx={{ pt: 3}}
        className={classes.subtitles}
      
      >
        <ListItem>
          Our experts will get in touch with you shortly
         
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const Unlistedportfolio = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [initValues, setInitValues] = useState({
    scrip_name:
      location != undefined
        ? location.state != undefined
          ? location.state.scrip_name
          : null
        : null,
    isin:
      location != undefined
        ? location.state != undefined
          ? location.state.isin
          : null
        : null,
    shares: "",
    price: "",
    ulkshares: location.state.type === "othersportfolio" ? false : true,
  });

  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setOpen(false);
    }, 3000);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleOnSubmit = (values) => {
    // setSubmit(true);
    const Data = {
      scrip_name: values.scrip_name,
      isin: values.isin,
      shares: values.shares,
    };
    handleClickOpen();
  };
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/sell")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Sell
              </Typography>
            </Grid>
          </Grid>
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            validationSchema={Yup.object().shape({
              scrip_name: Yup.string()
                .nullable()
                // .matches(phoneRegex, "Enter a valid Mobile Number")
                .required("Scrip name is required"),
              isin: Yup.string().nullable().required("Isin is required"),
              shares: Yup.string()
                .nullable()
                .required("Number of shares is required"),
              price: Yup.string().when("ulkshares", {
                is: false,
                then: Yup.string().required("Price is required"),
                otherwise: Yup.string(),
              }),
            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid>
                  <Grid
                    className={classes.container}
                    container
                    flexDirection="column"
                  >
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                      style={{ fontFamily: "Poppins" }}
                    >
                      Scrip Name
                    </InputLabel>
                    <FormControl
                      variant="standard"
                      style={{ fontFamily: "Poppins" }}
                    >
                      <InputBase
                        onChange={handleChange}
                        name="scrip_name"
                        id="scrip_name"
                        value={values.scrip_name}
                        className={classes.textfield}
                        style={{ fontFamily: "Poppins" }}
                        inputProps={{
                          readOnly: values.scrip_name ? true : false,
                        }}
                        placeholder={
                          values.scrip_name
                            ? values.scrip_name
                            : "Select or enter scip name"
                        }
                      />
                      { values.scrip_name ? "":<FormHelperText style={{ color: "red" }}>
                          {errors.scrip_name &&
                            touched.scrip_name &&
                            errors.scrip_name}
                        </FormHelperText>}
                    </FormControl>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                      style={{ fontFamily: "Poppins" }}
                    >
                      Scrip ISN
                    </InputLabel>
                    <FormControl
                      variant="standard"
                      style={{ fontFamily: "Poppins" }}
                    >
                      <InputBase
                        onChange={handleChange}
                        name="isin"
                        id="isin"
                        value={values.isin}
                        className={classes.textfield}
                        style={{ fontFamily: "Poppins" }}
                        inputProps={{ readOnly: values.isin ? true : false }}
                        placeholder={
                          values.isin ? values.isin : "Enter company ISN"
                        }
                      />
                        { values.isin ? "":<FormHelperText style={{ color: "red" }}>
                          {errors.isin &&
                            touched.isin &&
                            errors.isin}
                        </FormHelperText>}
                    </FormControl>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                      style={{ fontFamily: "Poppins" }}
                    >
                      Number of shares
                    </InputLabel>
                    <FormControl
                      variant="standard"
                      style={{ fontFamily: "Poppins" }}
                    >
                      <InputBase
                        type="number"
                        onChange={handleChange}
                        name="shares"
                        id="shares"
                        value={values.shares}
                        className={classes.textfield}
                        style={{ fontFamily: "Poppins" }}
                        placeholder="Enter number of shares "
                      />
                        <FormHelperText style={{ color: "red" }}>
                          {errors.shares &&
                            touched.shares &&
                            errors.shares}
                        </FormHelperText>
                    </FormControl>
                    {location.state.type === "othersportfolio" ? (
                      <>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input"
                          className={classes.label}
                          style={{ fontFamily: "Poppins" }}
                        >
                          Price
                        </InputLabel>
                        <FormControl
                          variant="standard"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <InputBase
                          type="number"
                            onChange={handleChange}
                            name="price"
                            id="price"
                            value={values.price}
                            className={classes.textfield}
                            style={{ fontFamily: "Poppins" }}
                            placeholder="Enter unit price "
                          />
                        <FormHelperText style={{ color: "red" }}>
                          {errors.price &&
                            touched.price &&
                            errors.price}
                        </FormHelperText>
                        </FormControl>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid style={{ textAlign: "center " }}>
                    <Button
                      className={classes.Btn}
                      fullWidth
                      variant="contained"
                    >
                      <Typography
                        className={classes.font}
                        style={{ fontFamily: "Poppins" }}
                      >
                        Note: This is only a request and it shall be processed
                        basis demand.
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid className={classes.bottom}>
                    {" "}
                    <Button
                      type="submit"
                      className={classes.portfolio}
                      fullWidth
                      variant="contained"
                      // onClick={handleClickOpen}
                    >
                      <Typography
                        className={classes.fonts}
                        style={{ fontFamily: "poppins" }}
                      >
                        Submit Sell Request
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
        <SimpleDialog open={open} onClose={handleClose} />
        <BottomNavigation  activeTab={2}/>
      </Page>
    </>
  );
};

export default Unlistedportfolio;
