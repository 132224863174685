import React, { useState,useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Typography, Button, Box, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { signDealtermService } from "../../_services/dealTermSign";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    maxWidth: "100%",
    overflowX: "hidden",
  },
  iconDiv: {
    textAlign: "center",
    color: "#3DAA00",
  },
  centerDiv: {
    textAlign: "center",
    marginTop: 2,
  },
  status: {
    color: "#fff",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "24px !important",
    lineHeight: "36px !important",
    margin: "1rem !important",
  },
  subText: {
    color: "#fff",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "10px !important",
    lineHeight: "16px !important",
    margin: "0 1rem !important",
  },
  button: {
    display: "flex !important",
    justifyContent: "center  !important",
    height: "54px",
    width: "100% !important",
    borderRadius: "10px  !important",
    boxShadow: "none  !important",
    backgroundColor: "#6D50CC  !important",
    "&:hover": {
      backgroundColor: "#6D50CC  !important",
      boxShadow: "none  !important",
    },
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "16px  !important",
    lineHeight: "22px  !important",
    color: "#fff !important",
  },
  bottom: {
    position: "absolute",
    bottom: "40px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  outer: {
    display: "table",
    position: "absolute",
    top: "0",
    left: "0",
    height: "80%",
    width: "100%",
  },
  middle: {
    display: "table-cell",
    verticalAlign: "middle",
  }
}));

const PaymentStatus = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(location.state.payment_status);
  const [data, setData] = useState(location.state.data);
  const gotoHomePAge = () => {
    navigate("/home");
  };
  const signDealterm = async () => {
       navigate("/loading");
    let uploadOriginal = await signDealtermService.unlistedkartApiCall('uploadOriginal', location.state.data,"",location.state.orderData)
    let uploadRiskRealTerm = await signDealtermService.unlistedkartApiCall('uploadRiskDisclure', '')
    if (uploadOriginal.data.success) {
      let createEnvolp = await signDealtermService.unlistedkartApiCall('createEnvolp', uploadOriginal.data.data.id,uploadRiskRealTerm.data.data.id,{
        userId :uploadOriginal.data.userId,
        orderId :uploadOriginal.data.orderId});
      if (createEnvolp.data.success) {
        let getSingatureUrl = await signDealtermService.unlistedkartApiCall('getSingatureEndPoint', createEnvolp.data.data.id,"", {
          userId :uploadOriginal.data.userId,
          orderId :uploadOriginal.data.orderId}
          );
        if (getSingatureUrl.data.success) {
          // 
          setTimeout(() => {
            navigate("/holdings",{
              replace: true,
              state: { value: true},
              })
          }, 1000);
              // window.open(getSingatureUrl.data.data.url, '_parent')
              if(window.ReactNativeWebView != undefined ){
                let data = {
                  isSignEasyPage:true,
                  urlToOpen:getSingatureUrl.data.data.url
                }
           
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
                console.log("post message sent")
              }else{
                console.log("post message not sent")
              }

        }else{
          console.log(getSingatureUrl,"getSingatureUrl");
        }
      }else{
        console.log(createEnvolp,"createEnvolp");
      }
    }else{
      console.log(uploadOriginal,"uploadOriginal");
    }
  }
  let security_id= localStorage.getItem('security_id')
  return (
    <Page>
      <Box className={classes.boxcontainer}>
        <div className={classes.outer}>
          <div className={classes.middle}>
            <div className={classes.iconDiv}>
              {status === "SUCCESS" ? (
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M58.8137 26.1765C60.0745 27.4448 60.0745 29.5012 58.8137 30.7696L35.5688 54.1535C34.3112 55.4186 32.2734 55.4223 31.0113 54.1618L22.1589 45.3206C20.8936 44.0569 20.8862 42.0005 22.1424 40.7276C23.3987 39.4547 25.4428 39.4472 26.7081 40.711L33.2777 47.2722L54.248 26.1765C55.5088 24.9081 57.5529 24.9082 58.8137 26.1765Z" fill="url(#paint0_linear_2523_405)" />
                  <circle cx="40" cy="40" r="39" stroke="url(#paint1_linear_2523_405)" stroke-width="2" />
                  <defs>
                    <linearGradient id="paint0_linear_2523_405" x1="40.4822" y1="25.2252" x2="40.4822" y2="55.1048" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#AB97EB" />
                      <stop offset="1" stop-color="#583EAC" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2523_405" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#AB97EB" />
                      <stop offset="1" stop-color="#583EAC" />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M40.301 39.6992L27.0679 26.4662M40.301 39.6992L53.534 52.9323M40.301 39.6992L53.534 26.4662M40.301 39.6992L27.0679 52.9323" stroke="#DD4A47" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
                  <circle cx="40" cy="40" r="39" stroke="#DD4A47" stroke-width="2" />
                </svg>
              )}

              <div className="f-modal-alert">
                <div className="f-modal-icon f-modal-warning scaleWarning">
                  <span className="f-modal-body pulseWarningIns"></span>
                  <span className="f-modal-dot pulseWarningIns"></span>
                </div>
              </div>
            </div>
            <div className={classes.centerDiv}>
              {status === "SUCCESS" ? (
                <Typography className={classes.status}>
                  Payment <span style={{ color: "#AC93FD" }}>Complete</span>
                </Typography>
              ) : (
                <Typography className={classes.status}>
                  Payment <span style={{ color: "#DD4A47" }}>Failed</span>
                </Typography>
              )}
            </div>

            <div className={classes.centerDiv}>
              {status === "SUCCESS" ? (
                <>

                  <Typography className={classes.subText}>
                    <InfoIcon style={{ color: "#fff", fontSize: "1rem", marginBottom: "-4px", marginRight: "6px" }} />
                    The order will be processed only after <br />
                    signing the  deal term
                  </Typography>
                </>
              ) : (
                <Typography className={classes.subText}>
                 If the amount is debited it will be refunded <br/> within 2-3 business days. You can retry <br/> payment by clicking  {" "}
                  <span style={{ color: "#AC93FD", textDecoration: "underline" }} onClick={() => navigate("/companyOverview", {replace:true, state:{buy:true,id:security_id}})}>
                    here.
                  </span>
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Grid className={classes.bottom} container>
        <Grid item xs={12} md={12} style={{margin:"0 1rem"}}>
        {status === "SUCCESS" ?
            (<Button fullWidth variant="contained" className={classes.button}
              onClick={signDealterm}
            >
              Sign Deal Term
            </Button>) :
            (<Button fullWidth variant="contained" className={classes.button}
              onClick={() => navigate("/home")}
            >
              Close
            </Button>)
          }
        </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default PaymentStatus;
