import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Dialog, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import sign from "../../Images/signDT.svg";
import { useNavigate } from "react-router-dom";
import Download from "../../Images/download.svg";
import closeicon from '../../Images/closeIcon.svg';
import moment from "moment/moment";
import { connect } from "react-redux";

import { signDealtermService } from "../../_services/dealTermSign";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    maxWidth: "400px !important",
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    padding: "15px !important",
  },
  title: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
    lineHeight: "18px !important",
  },
  orderId: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#767677 !important",
    lineHeight: "18px !important",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff",
    lineHeight: "14px !important",
  },
  upWard: {
    color: "#7BDE89!important",
    fontWeight: "400  !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    paddingLeft: "5px  !important",
    lineHeight: "14px !important",
  },
  end: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal ",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#767677",
    lineHeight: "15px !important",
  },

  downWard: {
    color: "red !important",
    fontWeight: "400  !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    padding: "5px  !important",
    lineHeight: "14px !important",
  },
  rate: {
    fontFamily: "Roboto   !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "12px  !important",
    color: "#fff ",
    lineHeight: "14px !important",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      minWidth: "327px !important",
      borderRadius: "16px !important",
      backgroundColor: "#1C1C1C !important",
      padding: "16px 0px !important",
    },
  },
  dialogSubTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    padding:"1rem !important"
  },
  dialogBtn:{
    borderRadius:"10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight:"14px  !important"
},
signCards: {
  "& 	.MuiDialog-paper": {
    width: "241px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  },
},
  indicateWords: {
    fontFamily: "Poppins !important",
    fontStyle: "italic !important",
    fontWeight: " 400 !important",
    fontSize: "10px !important",
    lineHeight: "15px !important",
    color: "#767677 !important",
  },
}));

function OrderDropdown({ data, userID,signed,setSigned }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [signDialog, setSignDialog] = React.useState(false);
  const [orderData, setOrderData] = useState()
  const [item, setItem] = useState()
  const parse = ((data) => parseFloat(data).toFixed(2))

  // download order details
  const downloadOrderFile = (fileUrl) => {
    if (window.ReactNativeWebView != undefined) {
      let data = {
        fileUrl: fileUrl
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      console.log("post message sent")
    } else {
      console.log("post message not sent")
    }
  }

  const investmentAmt = orderData && (parseFloat(orderData?.price) + parseFloat(orderData?.is_tcs_tds_collected_value)).toFixed(2)

  // signeasy api call
const signDealTerm = 
  async () => {
    navigate("/loading");
    // if (item.envolop_id == null) {
      let uploadOriginal = await signDealtermService.unlistedkartApiCall('uploadOriginal',  {
        razorpay_order_id: item.payment_details[0].order_id
       } , "", item.service_order_id)
       if (uploadOriginal.data.message === "Already signed") {
         navigate("/holdings", {
           replace: true,
           state: { value: true ,signed:true},
          })
        } else {
        let uploadRiskRealTerm = await signDealtermService.unlistedkartApiCall('uploadRiskDisclure', '')
          let createEnvolp = await signDealtermService.unlistedkartApiCall('createEnvolp', uploadOriginal.data.data.id, uploadRiskRealTerm.data.data.id, {
            userId: uploadOriginal.data.userId,
            orderId: uploadOriginal.data.orderId
          });
          if (createEnvolp.data.success) {
            let getSingatureUrl = await signDealtermService.unlistedkartApiCall('getSingatureEndPoint', createEnvolp.data.data.id, "", {
              userId: uploadOriginal.data.userId,
              orderId: uploadOriginal.data.orderId
            }
            );
            if (getSingatureUrl.data.success) {
              // 
              setTimeout(() => {
                navigate("/holdings", {
                  replace: true,
                  state: { value: true },
                })
              }, 1000);
              if(window.ReactNativeWebView != undefined ){
                let data = {
                  isSignEasyPage:true,
                  urlToOpen:getSingatureUrl.data.data.url
                }
           
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
                console.log("post message sent")
              }else{
                console.log("post message not sent")
              }
    
            } else {
              console.log(getSingatureUrl, "getSingatureUrl");
            }
          } else {
            console.log(createEnvolp, "createEnvolp");
          }
       
      }
    // } else {
    //   let getSingatureUrl = await signDealtermService.unlistedkartApiCall('getSingatureEndPoint', item.envolop_id, "", {
    //     userId: userID,
    //     orderId: item.service_order_id
    //   }
    //   );
    //   if (getSingatureUrl.data.success) {
    //     setTimeout(() => {
    //       navigate("/holdings", {
    //         replace: true,
    //         state: { value: true },
    //       })
    //     }, 1000);
    //     window.open(getSingatureUrl.data.data.url, '_parent')
    //   } else {
    //     console.log(getSingatureUrl, "getSingatureUrl");
    //   }
    // }
  }

  return (
    <div style={{ paddingTop: "8px" }}>
      {data.client_holdings.map((item,index) => {
        return (
          <Box style={{ margin: "16px" }}
          key={index}
            onClick={() => {
              setOpen(true)
              setOrderData(item)
            }}>
            <Grid container className={classes.card}>
              <Grid
                item
                xs={7}
                md={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography className={classes.title}>
                  {item.scrip_name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                md={5}
                className={classes.end}
              >
                <Typography className={classes.value}>{moment(item.transaction_date).format("DD-MM-YYYY")}</Typography>
              </Grid>
              <Grid
                item
                xs={7}
                md={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography className={classes.orderId}>
                  #{item.order_id}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                md={5}
                className={classes.end}
              >
              </Grid>
              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    backgroundColor: "#494949",
                    border: "none",
                    height: "1px",
                    marginTop: "10px ",
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.subtitle}>
                  {" "}
                  Order Total
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Typography className={classes.rate}>₹{item?.is_tcs_tds_collected ? (parseFloat(item.price)+ parseFloat(item?.is_tcs_tds_collected_value)).toFixed(2) : parse(item?.price)}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    backgroundColor: "#494949",
                    border: "none",
                    height: "1px",
                    marginTop: "5px ",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Grid item xs={6} md={6} onClick={(e) => {
                  e.stopPropagation()
                }}>
                  <Typography className={classes.subtitle}> Deal Term </Typography>
                  {item.holding_status === "UNDER_PROCESS" && (item.user_signed_status == 'pending' || item.user_signed_status == null) ?
                    <Typography className={classes.rate} style={{ disply: "flex", color: "#DD4A47" }} mt={0.2} 
                    onClick={()=> {
                      setSignDialog(true)
                      setItem(item)
                    }}>
                      {" "}
                      <img
                        src={sign}
                        alt=""
                        width={16}
                        height={10}
                        style={{ paddingRight: "5px" }}
                      />
                      e-Sign
                    </Typography> :
                    <Typography className={classes.rate} style={{ disply: "flex" }} mt={0.2} onClick={() => {
                      setOpen(false)
                      downloadOrderFile(item.file_url)
                    }}>
                      {" "}
                      <img
                        src={Download}
                        alt=""
                        width={16}
                        height={10}
                        style={{ paddingRight: "5px" }}
                      />
                      Download
                    </Typography>}
                </Grid>
                <Grid item xs={6} md={6} sx={{ textAlign: "end" }}
                onClick={(e) => {
                  item.escrow_id &&  e.stopPropagation()
                }}>
                  <Typography className={classes.subtitle}>Status</Typography>
                  <Typography mt={0.2}
                    className={classes.rate}
                    onClick={()=>
                     item.escrow_id && navigate("/vouchstatus", {
                        replace: true,
                        state: { id: item.service_order_id }
                      })}
                    style={{
                      color:
                        item.holding_status == "UNDER_PROCESS" && (item.user_signed_status == 'pending' || item.user_signed_status == null) 
                          ? "#DD4A47"
                          : item.holding_status == "OWNED"
                            ? "#7BDE89"
                            : "#AC93FF",
                    }}
                    // onClick={(e)=>{
                    // e.stopPropagation()
                    // if(item.user_signed_status == 'complete' || item.holding_status === "OWNED" ){
                    //   navigate('/vouchstatus') 
                    // }
                    // }}
                  >
                    {" "}
                    {item.holding_status === "UNDER_PROCESS" && item.user_signed_status == 'complete' ? "In Progress" : item.holding_status === "OWNED" ? "Completed" : "Pending"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )
      })}
      <Dialog open={open} 
      onClose={()=>{
        setOpen(false)
      }} 
      className={classes.cards}>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0 1rem" }}>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ padding: "0 1rem" }}
        >
          <Typography className={classes.orderId}>
            #{orderData?.order_id}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.title}>
              {orderData?.scrip_name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value}>{moment(orderData?.order_created_date).format("DD-MM-YYYY")}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <hr
            style={{
              backgroundColor: "#494949",
              border: "none",
              height: "1px",
              margin: "8px  16px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center", marginBottom: "6px" }}
          >
            <Typography className={classes.subtitle}>
              Qty
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value}>{orderData?.quantity} Shares</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.subtitle}>
              Buy Price
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value}> ₹{parse(orderData?.price_per_share)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          {orderData?.is_tcs_tds_collected ?
          <>
           <Grid item xs={12} md={12}>
          <hr
            style={{
              backgroundColor: "#494949",
              border: "none",
              height: "1px",
              margin: "8px  16px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center", marginBottom: "6px" }}
          >
            <Typography className={classes.subtitle} style={{color:"#fff"}}>
            Investment Total
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value}>₹{parse(orderData?.price)} </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.subtitle}>
             TDS
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value}> ₹{parse(orderData?.is_tcs_tds_collected_value)}</Typography>
          </Grid>
        </Grid></>
          : null}
          <hr
            style={{
              backgroundColor: "#494949",
              border: "none",
              height: "1px",
              margin: "8px  16px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
          <Grid
            item
            xs={7}
            md={7}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.subtitle} style={{ color: "#A489FF" }}>
              Order Total
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            className={classes.end}
          >
            <Typography className={classes.value} style={{ color: "#A489FF" }}>₹{orderData?.is_tcs_tds_collected ? investmentAmt : parse(orderData?.price)}</Typography>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog onClose={()=>setSignDialog(false)} open={signDialog} className={classes.signCards}>
          <Grid item xs={12} md={12}>
          <Typography className={classes.dialogSubTitle}>
          Please sign your <br/> deal term
        </Typography>
          </Grid>
            <Grid container style={{display:"flex"}}>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
           < Button className={classes.dialogBtn}
           disableRipple
           style={{color:"#A489FF"}}
            onClick={signDealTerm}
            >
           Sign
            </  Button>
           </Grid>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ",borderLeft:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
            < Button className={classes.dialogBtn} style={{color:"#8C8D8D"}}
            disableRipple
            onClick={()=>{
              setSignDialog(false)
            }}
            >
           Close
            </  Button>
          </Grid>
          </Grid>
        </Dialog>
        <Dialog onClose={()=>setSigned(false)} open={signed} className={classes.signCards}>
          <Grid item xs={12} md={12}>
          <Typography className={classes.dialogSubTitle} >
            {/* Hey,
            <br/> */}
            Your signature is already done.
        </Typography>
        </Grid>
        <Grid container style={{display:"flex"}}>
           <Grid item xs={12} md={12} style={{ borderTop:"1px solid #3B3159 ",borderLeft:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
            < Button className={classes.dialogBtn} style={{color:"#8C8D8D", textTransform:'none'}}
            disableRipple
            onClick={()=>{
              setSigned(false)
            }}
            >
           Close
            </  Button>
          </Grid>
          </Grid>
        </Dialog>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderDropdown);