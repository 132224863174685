import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import OtpButton from "../../component/DarkMode/OtpButton";
import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import CreateIcon from "@mui/icons-material/Create";
import { useLocation, useNavigate } from "react-router-dom";
import SnackBar from "../../component/SnackBar/SnackBar";
import { RegisterService } from "../../_services/register";
import HelperText from "../../component/DarkMode/HelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { setToken, loginSuccess } from "../../store/bugs";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Notification from "../../component/AlertModel/Notification";
import Backdrop from '@mui/material/Backdrop';
import { loginService } from "../../_services/login";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
    position:'relative'
  },
  container: {
    paddingTop: "52px"
  },
  verifyText: {
    // padding: "4px",
    color: "white",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
  },
  verfySubText: {
    // padding: "4px",
    color: "white",
    left: "16px",
    top: "135px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400!important",
    fontSize: "16px !important",
    lineHeight: "18px !important",
  },
  loader:{
    color: "white !important" ,
    width: '25px !important',
    height: '25px !important',
   },
   otploader:{
    color: "white !important" ,
    width: '18px !important',
    height: '18px !important',
   }
}));

const OtpEmail = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();

  const navigate = useNavigate();
  const location = useLocation();
  const [submitButton, setSubmitButton] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [resendOtpCount, setResendOtpCount] = React.useState(0);
  const [invalidOtpCount,setInvalidOtpCount] = React.useState(0);
  const [resendOtpTime, setResendOtpTime] = React.useState(30);
  const [helperText, setHelperText] = useState();
  const [timer,setTimer] = useState(true)
  const [timerText,setTimerText] = useState("")

  const [type, setType] = useState();
  const [otp, setOtp] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [valueColor, setValueColor] = useState();

  const handleOtp = () => {
    const data = {
      email:location!=undefined ? location.state != undefined? location.state.email  : null : null,
    };
    setOtp(true)
    if(location.state.type ==="login"){
      loginService
      .emailAuthentication(data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
          setResendOtpTime(30)
           setResendOtpCount(resendOtpCount+1)
           setTimer(true)
          }
        }})

    }else{
       RegisterService.registerEmail(data).then((res) => {
         if (res.data.message === "OTP SENT SUCCESSSFULLY") {
           setResendOtpTime(30)
           setResendOtpCount(resendOtpCount+1)
           setTimer(true)
           setTimeout(()=> {
             setOtp(false)
           },1000)
         } else {
           setOtp(false)
           setSeverity("error");
           setSnackbarTitle("Something went wrong");
           setOpen(true);
         }
       });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const setUserInfo = () => {
    loginService.getLoggedInUserInfo().then(async (res) => {
      if (res) {
        if (res.status === 200) {
          const auth = { loggedIn: true, user: res.data };
          props.loginSuccess({ auth: auth });
        }
      } else {
        setSeverity("error");
        setSnackbarTitle("Unable to connect the server, please try again");
        setOpen(true);
      }
    });
  };

  const handleOnSubmit = (values) => {
    setHelperText("");
    setType()
    setSubmit(true);
    if(location.state.type ==="login"){
      setTimer(false)
      loginService
      .verifyEmailOtp(location.state.email || null, values).then((res) => {
        setResendOtpTime(0)
        if (res) {
          if (res.status === 200) {
            if (res.data.message === "OTP_VERIFIED") {
              props.setToken({ token: res.data.Jwttoken.token });
              cookies.set("token", res.data.Jwttoken.token, {
                path: "/",
                expires: new Date(
                  new Date().getTime() + 60 * 60 * 1000 * 24 * 180
                ),
              });
              setUserInfo();
              setTimeout(() => {
                navigate("/home");
              }, 1000);
            }
          }  else if (res.status === 201) {
            if (res.data.message === "INVALID_OTP") {
                setSubmitButton(false)
                setSubmit(false);
                setHelperText("Invalid OTP");
                setType("error")
                setValueColor(2);
              // }
            }
          } else if (res.status === 401) {
            if (res.data.message === "ACCOUNT_BLOCKED") {
              setValueColor(2);
              setSubmit(false);
              setSeverity("error");
              // let timerMsg = "Account blocked, please try after "+ Math.abs(res.data.time.hour) +" hours " + Math.abs(res.data.time.minute)+" minutes"
              setSnackbarTitle('Account blocked, please try after 24hours');
              setOpen(true);
              setSubmitButton(false)
            }
          } else {
            setSubmit(false);
            setSeverity("error");
            setSnackbarTitle("Unable to connect the server, please try again");
            setOpen(true);
            setSubmitButton(false)
          }
        }
      });
    }else{
      const data = {
        email:location!=undefined ? location.state != undefined? location.state.email  : null : null,
        otp:values,
        user_role:"INVESTER_CLIENT"
      };
      RegisterService.verifyEmailOtp(data).then((res) => {
        setTimer(false)
        setResendOtpTime(0)
        setSubmit(false);
        if (res.data.message === "OTP_VERIFIED") {
          // setTimeout(() => {
            navigate("/signupphone", {
              replace: true,
              state: { email: location!=undefined ? location.state != undefined? location.state.email  : null : null , type: "register",userId:res.data.data.created_user_id},
            });
          // }, 500);
        } else  if (res.data.message === "INVALID_OTP"){
            setInvalidOtpCount(invalidOtpCount +1)
            setHelperText("Invalid OTP");
            setType("error")
            setValueColor(2)
        }else  if (res.data.message === "ACCOUNT_BLOCKED"){
          setInvalidOtpCount(invalidOtpCount +1)
          setHelperText("Too many failed attmpts, try after 24 hours");
          setType("error")
          setValueColor(2);
        }else{
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
        }
      });
    }
  };
  


  useEffect(() => {
    if(resendOtpTime===0){
      setResendOtpTime(null)
      setTimeout(()=>{
        setTimerText()
       },1000)
    }

    // exit early when we reach 0
    if (!resendOtpTime) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const resendOtpSec = setInterval(() => {
      setResendOtpTime(resendOtpTime - 1);
      setTimerText(`Didn't receive OTP? Retry in 00:${resendOtpTime <10 ? `0${resendOtpTime}` :resendOtpTime}`)
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(resendOtpSec);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [resendOtpTime]);


  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
        <Notification  snackbarTitle={snackbarTitle} open={open} setOpen={setOpen} type={severity}/>
          <Container >
            <Grid className={classes.container} spacing={1}>
              <Grid>
                <Typography className={classes.verifyText}>
                  Verify your email
                </Typography>
                <Grid mb={2.5} style={{ display: "flex" }}>
                  <Typography className={classes.verfySubText}>
                    {location!=undefined ? location.state != undefined? location.state.email  : null : null} 
                    <CreateIcon
                    onClick={() => {
                      if(location?.state?.type ==="login"){
                        navigate("/signupemail",{
                          replace: true,
                          state: { type: location.state.type }
                      })
                      }else{
                        navigate("/signupemail",{
                          replace: true,
                          state: { email: location.state.email }
                      })
                      }
                     }}
                    style={{ color: "#8264E5", fontSize:"20px", padding:"4px", marginBottom:"-6px", boxSizing:"unset" }}
                  />
                  </Typography>
                  
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  otp: "",
                }}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .min(0)
                    .test("length", "Must be exactly 6 digits", (val) => {
                      return val && val.toString().length === 6;
                    }),
                })}
                onSubmit={(values) => {
                  handleOnSubmit(values.otp);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}  autoComplete="off">
                    <Grid>
                      <Textfield
                       otp={values.otp.length>1 &&  true}
                       valueColor={valueColor}
                       placeholder={"OTP"}
                       type="tel"
                       inputProps={{ maxLength: 6 }}
                       inputmode="decimal"
                       onChange={(e)=>{
                        if(e.target.value.length === 6){
                          handleOnSubmit(e.target.value);
                        }
                        setFieldValue('otp', e.target.value)
                        setHelperText();
                        setType()
                        setValueColor()
                      }}
                       value={values.otp}
                       error={Boolean(touched.otp && errors.otp)}
                       helperText={touched.otp && errors.otp}
                       name="otp"
                       id="otp"
                       onInput={(e) => {
                        setOpen(false)
                         e.target.value = e.target.value
                         .toString()
                         .replace(/[^0-9]/g, "");
                         if (e.target.value.length===6) {
                           setSubmitButton(false)
                          }else{
                            setSubmitButton(true)
                           }
                       }}
                      />
                       {timer?
                       <HelperText helperText={timerText} type={null} />:
                       <HelperText helperText={helperText} type={type} />}
                        <OtpButton
                          disabled={timerText || otp || invalidOtpCount ===4}
                          handleOtp={()=>{
                            handleOtp()
                            setFieldValue("otp", '')
                          }}
                          value={"Resend OTP"}
                        />
                    </Grid>
                    <Grid>
                      <Buttons
                        submitButton={submitButton || invalidOtpCount ===4}
                        value= "VERIFY"
                        type="submit"
                        // handleError={handleError}
                        value1={values.otp}
                        animation={submit}
                      />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Container>
        </Box>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submit}
      >
      </Backdrop>
      </Page>
    </>
  );
};


const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
});

export default connect(null, dispatchToProps)(OtpEmail);