import React from "react";
import { Grid, Typography, Box, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../Images/vouch/vouch.svg";
import ICICI from "../../Images/vouch/bank.png";
const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        display: "grid",
        padding: "0 0px !important",
        minHeight: "100vh ",
        background: "#0D0D0D !important",
    },

    center: {
        margin: "auto",
        textAlign: "center",
    },
    subText: {
        fontStyle: "normal  !important",
        color: "#656566  !important",
        fontWeight: "500  !important",
        fontSize: "10px  !important",
        fontFamily: "poppins  !important",
        lineHeight: "15px  !important",
    },
    text: {
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "600  !important",
        fontSize: "11px  !important",
        color: "#fff  !important",
        lineHeight: "18px !important",
    },
    bottom: {
        position: "absolute",
        bottom: "40px",
        left: "50%",
        width: '100vw',
        transform: "translate(-50%, 0%)",
        textAlign: "center"
    },
}));

const PoweredBy = () => {
    const classes = useStyles();


    return (
        <>
            <Box className={classes.boxcontainer}>
                <Grid className={classes.center}>
                    <img src={logo} style={{marginBottom:"3rem"}}/>
                    <br/>
                    
                    <Typography className={classes.subText}>
                        Protected and Audited by
                    </Typography>
                    <Typography className={classes.text}>
                    <img src={ICICI} width={90} /> and Universal Trusteeship Services
                    </Typography>
                </Grid>
                <Box className={classes.bottom}>
                </Box>
            </Box>
        </>
    );
};

export default PoweredBy;
