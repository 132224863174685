import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Dialog, DialogTitle, List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import clsx from "clsx";
import TemporaryDrawer from "../Drawer/Index";
import { buyUnlistedShareService } from "../../_services/buyUnlistedShare";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useLocation } from "react-router-dom";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';
import { kycService } from "../../_services/kyc";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "105px !important",
    maxWidth: "105px !important",
    height: "105px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    padding: '19px 12px !important'
  },
  cards: {
    "& 	.MuiDialog-paper": {
      width: "241px !important",
      borderRadius: "10px !important",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
    },
  },
  dialogSubTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    // margin:"1rem !important",
    padding:"2rem 1rem !important"
  },
  verticalFlex: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "space-between",
    height: "100%"
  },
  dialogTitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    lineHeight: "18px !important",
    textAlign: "center !important",
    margin: "1rem !important",
    padding: "0 !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important"
  },
  subtitles: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    textAlign: "center !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "11px  !important",
    color: "#AC93FF !important",
    textTransform: "uppercase"
  },
  button: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    padding: "4px 16px !important ",
    borderRadius: "10px !important",
    background: " #8264E6 !important",
    textTransform: "none !important",
    maxHeight: "29px !important",
    maxWidth: " 59px !important",
  },
  rate: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    textAlign: "end",
    lineHeight: "19px !important"
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  upWard: {
    color: "#7BDE89!important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    fontWeight: "400  !important",
    textAlign: "end",
    textDecoration:"underline !important"
  },
  downWard: {
    color: "red !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    fontWeight: "400  !important",
    textAlign: "end",
    textDecoration:"underline !important"
  },
  available: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#fff !important",
  },
  font: {
    fontSize: "10px  !important",
    fontFamily: "Poppins !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight: "15px !important",
    paddingLeft: '1px'
  },
  isin: {
    fontSize: "10px  !important",
    fontFamily: "Poppins  !important",
    fontWeight: "400 !important",
    lineHeight: "15px  !important"
  },
  dialogBtn: {
    borderRadius: "10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight: "14px  !important"
  }
}));

function ShareCard({ overview ,securityId}) {
  const classes = useStyles();
  let location = useLocation();
  let buy = location && location.state && location.state.buy
  const [opendrawer, setOpendrawer] = useState(buy ? true : false);
  const [open, setOpen] = useState(false);
  const [split, setSplit] = useState(false);
  const [message, setMessage] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const slides = localStorage.getItem("KYCSlides")
  const vouchSlides = localStorage.getItem("VouchSlides")
const [upiDialog,setUpiDialog] = useState(false)
  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    setOpendrawer(false);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props}  classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: '#fff',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      margin:"0 0 0px 18px !important",
      padding:'0 0 8px'
    },
  }));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  // Check buy status and popup dialog if kyc slides,vouch slides or e-sign pending
  const checkBuy = (() => {
    buyUnlistedShareService.checkBuyUnlistedShare(overview[0].id).then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data[0].can_continue_purchase && res.data[0].kyc_verified && !res.data[0].pendingOrders) {
            // if(!vouchSlides){
            //   navigate("/vouchslides", {replace:true, state:{security_id:overview[0].id}})
            // }else{
           
            // setOpendrawer(true);
                          navigate("/maintenance", {replace:true, state:{id:securityId}})

            // }
          } else {
            if (!res.data[0].kyc_verified) {
              setOpen(true)
              setMessage("kyc")
            } else {
              if (res.data[0].pendingOrders) {
                setOpen(true)
                setMessage("Orders")
              } else {
                setOpen(true)
                setMessage("chat")
              }

            }
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    });
    // setUpiDialog(true);
    // setTimeout(() => {
    //   setUpiDialog(false);
    // }, 5000);
  })


  useEffect(() => {
    getUserBasicKycDetails()
  }, []);

  // get user details to check whether user can buy or not
  const getUserBasicKycDetails = () => {
    kycService
      .getUserBasicKycDetails()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            if (res.data) {
              setKycStatus(res.data.data.display_status);
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  const handleClose = () => {
    setOpen(false)
  };


  return (
    <div>
      <Box sx={{ mx: 2, mb: 2.5 }}>
        {overview.map((item,index) => {
          let percentage = Number(item.percentage_of_change).toFixed(2);
          return (
            <Grid container key={index}>
              <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={classes.card}

                    style={{ height: "51px", width: "61px" }}
                    src={item.logo_url !== null ? item.logo_url : ScriptLogo}
                    alt="logo"
                  />
                  <Grid item xs={12} md={12} style={{ marginLeft: "13px" }} className={classes.verticalFlex}>
                    <Typography className={classes.title}>
                      {item.name}
                    </Typography>
                    <Grid xs={12} md={12} >
                      <Typography variant="subtitle1" className={classes.subtitle} style={{ fontFamily: "Poppins" }}>
                        {item.sector}
                      </Typography>
                      <Typography className={classes.isin} style={{ color: "#767677" }}>
                        ISIN    <span className={classes.font} style={{ color: "#fff" }}>
                          {item.isin}
                        </span>
                      </Typography>

                      {(item.available_nsdl || item.available_cdsl) && <Typography className={classes.isin} style={{ color: "#767677" }}>
                        Available On <span className={classes.font} style={{ color: "#fff" }}> {item.available_nsdl && "NSDL"} <span style={{ paddingLeft: "1px" }} >{item.available_cdsl && "CDSL"}</span></span>
                      </Typography>}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className={classes.verticalFlex} >
                  <div style={{ width: "100%" }}>
                    <Typography className={classes.rate}>₹{item.price} </Typography>
                    <BootstrapTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={split}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <div style={{width:'5px' ,height:'5px', background:'#484848', transform:"rotate(131deg)", margin:'0px 0 -4px 77px'}}>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            alignItems:"end",
                            marginTop:'1px',
                            position:"relative",
                            fontSize:'10px',
                            fontFamily:"Poppins",
                            lineHeight:'12px',
                            padding:"2px 4px",
                            borderRadius:'3px',
                            background: "#484848",
                            // border:'1px solid grey'
                            }}>
                            Calculated weekly
                          </div>
                        </React.Fragment>}
                      placement="bottom-end"
                    >
                      <Typography
                        onClick={()=>{
                         !split && setSplit(true)
                          setTimeout(()=>{
                          setSplit(false)
                        },1500)
                        }}
                          style={{
                            borderRadius: "10px", transition: "all 500ms"
                          }}
                        className={clsx({
                          [classes.upWard]: percentage >= 0,
                          [classes.downWard]: percentage < 0,
                        })}
                        variant="subtitle2"
                      >
                        {percentage.charAt(0) !== "-" ? (
                          <>
                            {`+${percentage}%`}
                          </>
                        ) : (
                          <>
                            {`-${percentage.substring(1)}%`}
                          </>
                        )}
                      </Typography>
                    </BootstrapTooltip>
                  </div>
                  <div style={{ width: "100%", textAlign: "end" }}>
                    {/* <Button variant="contained" className={classes.button} onClick={checkBuy}>BUY</Button> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })
        }
      </Box>
      {/* <TemporaryDrawer
        data={overview && overview[0]}
        drawerOpen={opendrawer}
        setOpendrawer={setOpendrawer}
        toggleDrawer={toggleDrawer}
        onClose={toggleDrawer("bottom", false)}
      >
      </TemporaryDrawer> */}
        {/* <Dialog onClose={()=>setOpendrawer(false)} open={opendrawer} className={classes.cards}>
          <Grid item xs={12} md={12}>
          <Typography className={classes.dialogSubTitle}>
          We will be right back very soon.
            </Typography>
       
           </Grid>
           

            <Grid container style={{display:"flex"}}>
           <Grid item xs={12} md={12} style={{ borderTop:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
           < Button className={classes.dialogBtn}
           disableRipple
           style={{color:"#A489FF"}}
            onClick={()=>setOpendrawer(false)}
            >
           Close
            </  Button>
           </Grid>
          </Grid>
        </Dialog> */}
      <Dialog onClose={handleClose} open={open} className={classes.cards}>
        <Grid item xs={12} md={12}>
          <Typography pt={1} px={3} className={classes.dialogTitle}>
            {message === "kyc" ? <span >Complete your KYC to <br />  buy shares</span> : message === "chat" ? <span>The scrip is not trasferable to your despository. For further queries chat wit us. </span> : <span >Please sign your previous deal
              terms in the Orders section to
              place a new order </span>}
          </Typography>
        </Grid>
        <Grid container style={{ display: "flex" }}>
          <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
            < Button className={classes.dialogBtn}
              disableRipple
              style={{ color: "#A489FF" }}
              onClick={() => {
                if (message === "chat") {
                  window.open("https://wa.me/918951807103", "_blank")
                } else if (message === "kyc") {
                  if (kycStatus === "Pending" && !slides) {
                    navigate("/kycslides")
                  } else {
                    navigate("/status")
                  }
                } else {
                  navigate("/holdings", {
                    replace: true,
                    state: { value: true },
                  })
                }
              }}
            >
              {message === "kyc" ? "START KYC" : message === "chat" ? "CHAT" : "Orders"}
            </  Button>
          </Grid>
          <Grid item xs={6} md={6} style={{ borderTop: "1px solid #3B3159 ", borderLeft: "1px solid #3B3159 ", height: "40px", display: "flex", justifyContent: 'center' }}>
            < Button className={classes.dialogBtn} style={{ color: "#8C8D8D" }}
              disableRipple
              onClick={() => {
                setOpen(false)
              }}
            >
              {message === "chat" ? "Close" : "Cancel"}
            </  Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog  open={upiDialog} className={classes.cards}>
                  <Grid item xs={12} md={12}>
                  <Typography className={classes.dialogSubTitle}>
                  Kindly come back in sometime, we are enhancing our customer experience
                </Typography>
                  </Grid>
                </Dialog>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </div>
  );
}

export default ShareCard;