import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './store/store';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
//   <React.StrictMode>
     <Store>
    <App />
    </Store>
   // </React.StrictMode>
);
reportWebVitals();


