import axios from 'axios'
import { helpers } from '../_helpers/index'
 
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const AllFaq = {
  getAllFaq,

};

function getAllFaq() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllFaq`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}





