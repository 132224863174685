import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { holdingService } from "../../_services/holding";
import BottomNavigation from "../../component/Home/BottomNavigation";
import CardSkeleton from "../../component/Skeleton/CardSkeleton";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 74px)",
    background: "#0D0D0D !important",
    padding: "0 15px 15px !important",
  },
  container: {
    backgroundColor: "#0D0D0D !important",
    display: "flex",
  },
  icon: {
    fill: "#fff  !important",
    margin: "10px",
    cursor: "pointer",
    marginLeft: "0px",
  },
  card: {
    marginBottom: "10px !important",
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    paddingRight: "0px !important",
    paddingBottom: "0px !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight: "22px !important",
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#AC93FF !important",
  },
  button: {
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(264.66deg, #AB97EB -16.97%, #583EAC 110.76%) !important",
    borderRadius: "10px 0px !important",
    textTransform: "none !important",
    maxHeight: "25px !important",
    marginTop: "13px !important",
    maxWidth: " 100px !important",
  },
  rate: {
    fontFamily: "Poppins !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "10px  !important",
    color: "#fff !important",
  },
  center :{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    },
  text: {
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400  !important",
      fontSize: "16px  !important",
      color: "#5F6060 !important",
      lineHeight: "24px !important",
      textAlign:"center !important"
    },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  upWard: {
    color: "green !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },
  downWard: {
    color: "red !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
  },
}));

const Select = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [holdings, setHoldings] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  useEffect(() => {
    getHoldingData();
  }, []);
  const getHoldingData = () => {
    holdingService
      .getHoldings()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setHoldings(res.data);
            setSkeletonLoader(false);
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error));
  };
  const setUnlistedportfolio = (scripname, isinvalue) => {
    navigate("/sellportfilio", {
      replace: true,
      state: {
        type: "unlistedportfolio",
        scrip_name: scripname,
        isin: isinvalue,
      },
    });
  };
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom:"15px"
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/sell")}
                className={classes.icon}
              />
              <Typography
                variant="h6"
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                }}
              >
                Select Scrip
              </Typography>
            </Grid>
          </Grid>
          {skeletonLoader ? (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                <CardSkeleton key={n} />
              ))}
            </>
          ) : !holdings.length ? (
            <Grid className={classes.center}><Typography className={classes.text}>Oopsy, no results!</Typography></Grid>
          ):(
            <>
              {holdings.map((item) => {
                return (
                  <Grid
                    container
                    className={classes.card}
                    key={item.id}
                    onClick={() => {
                      setUnlistedportfolio(item.scrip_name, item.isin);
                    }}
                  >
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ width: "76px", width: "63px" }}
                        src={
                          item.logo_url !== null ? item.logo_url : ScriptLogo
                        }
                        alt="logo"
                      />
                    </Grid>
                    <Grid item xs={9} md={9} sx={{ margin: "auto" }}>
                      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Grid item xs={8} md={8} sx={{ margin: "auto" }}>
                          {" "}
                          <Typography
                            className={classes.title}
                            style={{ fontFamily: "Roboto" }}
                          >
                            {item.company_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          {" "}
                          <Grid
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: " center",
                            }}
                          >
                            <Typography
                              className={classes.rate}
                              style={{ fontFamily: "Poppins" }}
                            >
                              {item.quantity} Shares
                            </Typography>
                            <ArrowForwardIosIcon
                              style={{
                                color: "#fff",
                                width: "0.7em",
                                height: "0.5em",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{ display: "flex", marginTop: "10px" }}
                      >
                        {" "}
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                          style={{ fontFamily: "Poppins" }}
                        >
                          Sector{" "}
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "500",
                            }}
                          >
                            {item.sector}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          )}
        </Box>
      </Page>
      <BottomNavigation activeTab={2}/>
    </>
  );
};

export default Select;
