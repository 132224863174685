import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyInvestors from "./KeyInvestors";
import ReactHtmlParser from "react-html-parser";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: "24px 16px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#F4F4F4 !important",
    overflowX: "auto",
    wordBreak:"break-word",
    textAlign:"justify",
    "& p":{
      margin:"0 !important"
    }
  },
  padding: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "& .MuiBox-root ": {
      padding: "0px !important",
    },
  },
  heading: {
    paddingTop: "24px   !important",
    paddingLeft: "16px  !important",
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    color: "#FFF !important",
    fontSize: "16px !important",
  },
  faceValue: {
    padding: "8px 15px",
    fontFamily: "Poppins !important",
    fontStyle: "normal  !important",
    fontWeight: "300 !important",
    color: "#767677 !important",
    fontSize: "10px !important",
  },
  price: {
    padding: "5px   !important",
    fontFamily: "Roboto !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    color: "#AC93FF !important",
    fontSize: "14px !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 500  !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },
  subtitle: {
    fontFamily: " Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
  },
}));
function About({ overview }) {
  const [isReadMore, setIsReadMore] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.padding}>
      {overview &&
        overview.map((item, i) => {
          let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          const d = new Date(item.financiallastupdatedate)
          let month = d.getMonth();
          let year  =  new Date(item.financiallastupdatedate).getFullYear().toString().substr(-2)
          var companyOverview;
          const regex = /(<([^>]+)>)/gi;
          let space = /&(nbsp|amp|quot|lt|gt);/g;
          if (item.company_overview !== null) {
            const removedTag = item.company_overview
              .replace(
                '<figure className="table">',
                '<div style="overflow-x:auto;">'
              )
              .replace("</figure>", "</div>")
              .replace(regex, "")
              .replace(space, "");
            companyOverview = removedTag;
          }
          var totalinvestment = item.totalinvestment;
          let financialdata=[]
          let keyinvestordata =[]
          if(item.keyinvestordata!=undefined){
            keyinvestordata  = JSON.parse(item.keyinvestordata);
          }
        if(item.financialdata!=undefined){
          financialdata = JSON.parse(item.financialdata);
        }
        
          const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
          };
          return (
            <>
              {companyOverview  ? (
                <>
                <Typography className={classes.typography}>
                  {isReadMore ? ReactHtmlParser(item.company_overview) : companyOverview.split(" ").splice(0,60).join(" ")}
                  <span
                    onClick={() => toggleReadMore()}
                    style={{ color: "#A489FF", cursor: "pointer",fontsize: "10px ",fontWeight:"400"}}
                  >
                    {isReadMore
                      ? " Show less"
                      : companyOverview.split(" ").length> 60
                      ? " Read more"
                      : ""}
                  </span>
                </Typography>
                <hr
                style={{
                  margin:"0px",
                  backgroundColor: "#1C1C1C",
                  border: "none",
                  height: "2px",
                }}
                />
                </>) : null}

              {keyinvestordata.length > 0 && (
                <>
                  <Typography className={classes.heading}>
                  Key Investors/ Stakeholders
                  </Typography>
                  <KeyInvestors
                    totalinvestment={totalinvestment}
                    keyinvestordata={keyinvestordata}
                  />
                </>
              )}
              {financialdata.length > 0 && (
                <>
                  <Typography className={classes.heading}>
                    Financials{"  "}
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#AC93FF",
                      }}
                    >
                      as on {monthsArray[month]}  {year}
                    </span>
                  </Typography>
                  <Grid container sx={{ display: "flex", padding: "16px" }}>
                    <Grid item xs={9} md={9} sm={9}>
                      <Grid container xs={12} md={12} sm={12}>
                        { financialdata && financialdata.map((item) => {
                          return (
                            <>
                            {item.financialValue &&
                              <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              sx={{ pb: 3, m: 0 }}
                              style={{ margin: "0px" }}
                            >
                              <Typography className={classes.subtitle}>
                                {item.financialName}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                                {item.financialName &&
                                item.financialName === "P/E Ratio"
                                  ? ""
                                  : item.financialValue <0 ? "-₹" : "₹"}
                                {item?.financialValue.toString().replace("-", "")}{" "}
                               {item.financialName === "P/E Ratio" || item.financialName == "EPS"
                                  ? ""
                                  : "Cr"}
                              </Typography>
                            </Grid>
                            }
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      alignSelf="end"
                      pb={3}
                    >
                              <Typography className={classes.subtitle}>
                              Face Value
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                               { item.face_value <0 ? "-₹" : "₹"}{item?.face_value.toString().replace("-", "")}{" "}
                              </Typography>
                            </Grid>
                  </Grid>
                </>
              )}
            </>
          );
        })}
    </div>
  );
}

export default About;


