const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SECTOR':
            return {
                ...state,
                sector: action.payload
            };
        case 'SET_FILTERVALUE':
            return {
                ...state,
                filterValue: action.payload
            };
        default:
            return state;
    }
};
export default Reducer;