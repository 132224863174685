import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    maxWidth: "700px !important",
    minHeight: "94px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
    padding: "10px !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "14px  !important",
    color: "#fff !important",
    lineHeight: "16px !important",
    padding:"5px !important"
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "300 !important",
    fontSize: "10px  !important",
    color: "#767677 !important",
    lineHeight: "15px !important",
  },
  upWard: {
    color: " #7BDE89 !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    fontWeight: "400  !important",
    lineHeight: "15px !important",
    
  },
  downWard: {
    color: "red !important",
    fontSize: "10px !important",
    fontFamily: "Poppins  !important",
    fontWeight: "400  !important",
  },
}));

function HoldingCard({data}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const parse =((data)=>  parseFloat(data).toFixed(2))
  return (
    <div>
      <Box style={{ margin: "5% 15%" }}>
        <Grid container className={classes.card}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <Typography
                className={classes.subtitle}
              >
                Total P/L
              </Typography>
             <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
             <Typography
                className={classes.title}
              >
                {data.Total_p_and_L <0 ? "-₹" : "₹"}{parse(data.Total_p_and_L).toString().replace("-", "")}{" "}
              </Typography>
              <Typography
                // className={classes.upWard}
                className={clsx({
                  [classes.upWard]: data.total_p_and_l_percentage >= 0,
                  [classes.downWard]: data.total_p_and_l_percentage < 0,
                })}
                variant="subtitle2"
              >
                {" "}
                {data.total_p_and_l_percentage >= 0 && "+"}
                 {parse(data.total_p_and_l_percentage)}%
              </Typography>
             </div>
           
          </Grid>
          <Grid item xs={12} md={12}>
            <hr
              style={{
                backgroundColor: "#494949",
                border: "none",
                height: "1px",
                width:"90%"
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid item xs={6} md={6} sx={{ textAlign: "center" }}>
              <Typography
                className={classes.subtitle}
              >
                Total Investment
              </Typography>
              <Typography
                className={classes.title}
              >
                ₹{parse(data.total_price)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} sx={{ textAlign: "center" }}>
            <Typography
              className={classes.subtitle}
            >
              Portfolio Value
            </Typography>
            <Typography
              className={classes.title}
            >
              ₹{parse(data.portfolio_value)}
            </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default HoldingCard;
