import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import BottomNavigation from "../../component/Home/BottomNavigation";
import { Box, Button, Typography, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Edit from "../../Images/Edit.svg";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    padding: "16px !important",
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  label: {
    marginTop: "24px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "12px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  textfield: {
    "& .MuiInputBase-input": {
      textTransform: "capitalize  !important",
      position: "relative",
      width: "100%",
      padding:"7px 0px",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
      borderBottom: " solid 1px #1C1C1C !important",
    },
  },
  font: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "10px  !important",
    color: "#FFF !important",
    lineHeight: "16px !important",
  },
  Btn: {
    // textAlign:"center !important ",
    width: "277px !important",
    justifyContent: "center !important",
    marginTop: "1.5rem !important",
    background: "#1C1C1C !important",
    border: "2px solid #1C1C1C !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "  8px 12px !important",
  },
  center :{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    },
  text: {
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500  !important",
      fontSize: "16px  !important",
      color: "#fff !important",
      lineHeight: "24px !important",
      textAlign:"center !important",
    },
}));

function EditBank() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const Chatwithus = (()=>{
    window.open("https://wa.me/918951807103", "_blank")
  })
  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid container direction="row" style={{ marginBottom:"10px"}}>
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={() => navigate("/profile")}
              />
              <Typography ml={2.5} className={classes.text}>{location.state.type === "bankDetail" ?"Bank Details":"Demat Details"}</Typography>
          </Grid>

         {
          (!location.state.dematDetail && !location.state.bankDetail) ? 
          (
            <Grid className={classes.center}><Typography className={classes.text}>Complete your KYC.<br/> <span style={{ color: "#AC93FF" ,textDecoration:"underline"}} onClick={(()=> navigate("/status"))}>here</span></Typography></Grid>
          )
          :(
            <>
           <Grid container flexDirection="column">
            <Typography
              shrink
              htmlFor="bootstrap-input"
              className={classes.label}
            >
               {location.state.type === "bankDetail" ? 
             " Bank Name" :"Depository"}
            </Typography>

            <FormControl variant="standard">
              <InputBase
                name="name"
                id="name"
                value={location.state.type === "bankDetail" ? location.state.bankDetail.bank_name : location.state.type === "dematDetail" && location.state?.dematDetail.dp_id?.charAt(0)=== "I" && location.state?.dematDetail.dp_id?.charAt(1)=== "N" ? "NSDL" : "CDSL"}
                className={classes.textfield}
                inputProps={{
                  readOnly: true,
                }}
                placeholder={location.state.type === "bankDetail" ? location.state.bankDetail.bank_name:location.state.type === "dematDetail" ? "CDSL" : "Send us mail"}
              />
            </FormControl>

            <Typography
              shrink
              htmlFor="bootstrap-input"
              className={classes.label}
            >
              Account Number
            </Typography>

            <FormControl variant="standard">
              <InputBase
                name="number"
                id="number"
                value={  location.state.type === "bankDetail" ? location.state.bankDetail.account_no : location.state.type === "dematDetail" ?  location.state.dematDetail.dp_id+""+location.state.dematDetail.client_id  :
                " "}
                className={classes.textfield}
                inputProps={{ readOnly: true }}
                placeholder={
                  location.state.type === "bankDetail" ? location.state.bankDetail.account_no : location.state.type === "dematDetail" ? "1208180011518893" :
                  " Send us mail "
                }
              />
            </FormControl>

            {location.state.type === "bankDetail" ? <>
              <Typography
              shrink
              htmlFor="bootstrap-input"
              className={classes.label}
            >
              IFSC
            </Typography>

            <FormControl variant="standard">
              <InputBase
               name="ifsc"
               id="ifsc"
              value={location.state.bankDetail.ifsc_code}
              className={classes.textfield}
              inputProps={{ readOnly: true }}
              placeholder={
                location.state.bankDetail.ifsc_code ? location.state.bankDetail.ifsc_code :
                " Send us mail"
              }
               
              />
            </FormControl> 
            </>
            :""}
          
          </Grid>
          <Grid style={{ textAlign: "center " }}>
            <Button className={classes.Btn} fullWidth variant="contained" disableRipple>
              <Typography className={classes.font}>
              In order to make changes to your {location.state.type === "bankDetail" ? "bank" :"demat"} details,<br/>
                <span style={{ color: "#AC93FF" }} onClick={Chatwithus}>chat with us</span>.
              </Typography>
            </Button>
          </Grid>
          </>
          )
         }
        </Box>
      </Page>
      <BottomNavigation  activeTab={2}/>
    </div>
  );
}

export default EditBank; 

