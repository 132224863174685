import React from "react";
import { Box, Typography, Grid , Avatar} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  card: {
    border:"1px solid #1C1C1C !important ",
    borderRadius: "10px !important",
    // backgroundColor:"#1C1C1C !important",
    // opacity:"0.5"
  },
 bg:{
    backgroundColor:"#1C1C1C !important",
 }
}));

function NewsInfoSkeleton({}) {
  const classes = useStyles();
          return (
            <div>
              <Box sx={{ my: 1 }}>
             
                <Grid container >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rectangular" width="95%" height={300} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                   style={{marginLeft:"10px"}}
                  >
                    <Skeleton variant="rectangular" width="95%" height={18} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="45%" height={18} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    </Grid>

                    <Grid
                    item
                    xs={12}
                    md={12}
                    mt={1}
                   style={{marginLeft:"10px"}}
                  >
                    <Skeleton variant="rectangular" width="95%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="95%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="95%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="95%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="95%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    <Skeleton variant="rectangular" width="25%" height={12} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    </Grid>
                </Grid>
              </Box>
                <Skeleton width="100%" height={5}  className={classes.bg}/>
               <Grid container>
               <Grid
                    item
                    xs={6}
                    md={6}
                    mt={1}
                   style={{marginLeft:"10px"}}
                  >
                    <Grid container>
                    <Grid
                    item
                    xs={6}
                    md={6}  
                  >
                    <Skeleton variant="rectangular" width="80%" height={25} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    </Grid>
                    
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={5}
                    md={5}
                    mt={2}
                   style={{marginLeft:"10px", textAlign:"-webkit-right"}}
                  >
                    <Skeleton variant="rectangular" width="60%" height={15} style={{marginBottom:"1rem"}}  className={classes.bg}/>
                    </Grid>
               </Grid>
            </div>
          );
   

}

export default NewsInfoSkeleton;
