import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from 'react-lottie';
import BackgroundImage from "../../Images/home/loginBg.json";

const useStyles = makeStyles({
  boxcontainer: {
    height: "100vh",
    position: "relative !important",
    width: "100% !important",
  },
  typeText: {
    fontSize: "16px !important",
    marginLeft: "1rem !important",
    color: "#fff",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
  },
  typeButton: {
    marginTop: "1rem !important",
    borderRadius: "10px !important",
    height: "3.5rem",
    boxShadow: "0 0 6px 0 rgba(157, 96, 212, 0.5)",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    boxShadow: "1px 1000px 1px #0D0D0D inset",
    "&:hover": {
      backgroundColor: "#2B2B2B",
    },
  },
  icon: {
    color: "#AC93FF",
  },
  container: {
    padding: "0px 1rem",
  },
  cardHeaderMargin: {
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "400 ",
    fontSize: "30px !important",
    lineHeight: "45px !important",
    color: "#AC93FF",
  },
});

const Landing = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BackgroundImage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Lottie
            style={{
              position: "absolute", background: "#0d0d0d",
              zIndex: -1
            }}
            options={defaultOptions}
          />

          <Grid container direction="column"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            <Container className={classes.container}>
              <Typography
                className={classes.cardHeaderMargin}
                style={{ color: "#fff" }}
                variant="h6"
              >
                Tell us
              </Typography>
              <Typography
                className={classes.cardHeaderMargin}
                variant="h5"
                style={{ fontWeight: "600" }}
              >
                who you are?
              </Typography>
            </Container>
          </Grid>
          <Container
            style={{
              position: "absolute",
              bottom: "60px",
              left: "50%",
              transform: "translate(-50%, -0%)",
            }}
          >
            <Grid container direction="column">

              <Grid style={{ marginTop: "7rem" }}>
                <Button
                  className={classes.typeButton}
                  fullWidth
                  onClick={() => {
                    navigate("/individualdetails",
                      {
                        replace: true,
                        state: location != undefined ? location.state : null
                      });
                  }}
                  variant="outlined"
                >
                  <Typography className={classes.typeText}>
                    Individual
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={() => {
                    navigate("/companydetails",
                      {
                        replace: true,
                        state: location != undefined ? location.state : null
                      });
                  }}
                  className={classes.typeButton}
                  fullWidth
                  variant="outlined"
                >
                  <Typography className={classes.typeText}>Company</Typography>
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Page>
    </>
  );
};

export default Landing;
