import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './style.css'
import { Button, Typography ,Box} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { makeStyles } from "@mui/styles";
import Link from '@mui/material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    nested: {
        padding:"0 15px !important",
    },
    font: {
        fontFamily: "Roboto !important",
        fontSize:"12px !important",
        alignItems:"baseline !important"
    },
    li:{
        minWidth:"1rem !important",
    },
    subtext:{
        textAlign:"justify !important", 
        fontSize:"12px !important" ,
        fontFamily:"Roboto !important"
    },
    docs:{
        color:"#8264E5",
        fontSize:"12px"

    },
    btn:{
        color:"#fff !important",
        padding:"10px 60px !important",
        background:"#6D50CC !important",
        fontFamily:"Poppins !important",
        borderRadius:"16px !important",
        fontWeight:"600 !important",
    },
    btnBox:{
        textAlign:"center !important"
    }
}));

const theme = createTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "rgb(0 0 0 / 10%)"
            }
        },
        MuiPaper: {
            elevation24: {
                boxShadow: "none",
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItem: {
            root: {
                alignItems: 'baseline',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
            gutters: {
                paddingLeft: '0px',
                paddingRight: '0px'
            }
        },
        MuiListItemIcon: {
            root: {
                marginLeft: '12px '
            }
        },
        MuiListItemText: {
            root: {
                // marginBottom:'6px'
            }
        },

        MuiTypography: {
            colorTextSecondary: {
                color: "initial"
            }
        },
        MuiList: {
            root: {
                marginTop: '-14px',
                marginBottom: '-64px',
            },
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0
            }
        }
    }
})

const TermsAndConditionAlert = (props) => {
    const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate()


    useEffect(()=>{
       if(location.state.condition){
        const element = document.getElementById("content");
        element.scrollIntoView();
       }
    },[])
    const handleClose = (()=>{
        if(location.state.type === "company"){
            navigate('/companydetails', {
                replace: true,
                state: location != undefined ? {...location.state,condition:false} : null,
              })
        }else{
            navigate('/individualdetails', {
                replace: true,
                state: location != undefined ? {...location.state,condition:false} : null,
              })
        }
    })

    const navigateToPages = ((url)=>{
        navigate(url, {
            replace: true,
            state: location != undefined ? location.state : null,
          })
    })

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <div
                style={{background:"#1b1b1b", color:"#fff", padding:'2rem'}}
                >
                   <React.Fragment style={{padding:"10px"}}>
                       <div style={{display:"flex", justifyContent:"space-between", margin:'0 -1rem 1rem'}}>
                       <Typography variant="h6" style={{ textAlign: 'initial', fontWeight: '500', marginTop: '-18px', fontSize:"14px" }} >
                            Terms and Conditions
                        </Typography>
                        <ClearIcon onClick={handleClose}  style={{fontSize:"24px", marginTop:"-18px"}}/>
                       </div>
                        <div style={{ marginBottom: 16 }}>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>1.</span> </b>
                                    <ListItemIcon className={classes.li} >

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >I / We as a buyer / seller (hereinafter refer as I /We/Me/Us/our) hereby confirm and understand that :</Typography>}
                                    />
                                </ListItem>
                            </List>

                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>a.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} ><b>Unlistedkart</b> is operated by <b>Unlistedkart LLP</b> partnership firm hereinafter refer as “<b>Unlistedkart</b>”. Further I / We hereby confirm and understand that <b>Unlistedkart.com is not a trading platform</b>.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>b.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} ><b>Unlistedkart</b> sells shares from their portfolio or referral clients, companies, institutions, who want liquidity for these investments.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>c.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I as a buyer/ seller will have to register with <b>Unlistedkart</b> to access the application based platform wherein I can buy or sell shares / Securities / Commodities from Unlistedkarts’ existing portfolio.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>d.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I am aware that Unlistedkart is not an advisor and does not give recommendations to buy any stock from our website, <b>Unlistedkart.com</b>. I as a buyer and / or seller should consult their financial/legal advisors before buying unlisted securities.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>e.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I am aware that <b>Unlistedkart is NOT a stock exchange recognized by the Securities Exchange Board of India (SEBI)</b> under the Securities Contract (Regulation) Act, 1956.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>f.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I am aware that all the loss will be borne by me and Unlistedkart are not responsible for any loss arising to me.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>g.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I am aware that Unlistedkart does not allow any secondary market trading of securities on the Platform.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>h.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I fully understand the features and risk of buying unlisted shares from the platforms.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>i.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I also confirm that I neither being influenced or nor been given any assurance of fixed returns arises out of investment in unlisted shares by employees, associates, partners, representatives, or relationship managers of <b>Unlistedkart</b>.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>j.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I fully understand that investing in unlisted shares/securities is not suitable for all the investors. An investment in unlisted company’s securities is highly speculative and involves a high degree of risk and should only be considered a long term investment and only after consulting our investment advisor(s).</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>k.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I fully understand that after buying unlisted shares, I may withstand the risk of total loss of investment as unlisted company’s securities are also highly illiquid and there is no guarantee that a market will develop for such securities.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>l.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I fully understand that each investment in unlisted shares also carries its own specific risks and I should complete my own independent due diligence regarding the investment, including obtaining additional information about the company, opinions, financial projections and legal or other investment advice.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>m.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I also understand that investing in unlisted companies' securities is appropriate only for those investors who can tolerate a high degree of risk and do not require a liquid investment.</Typography>}
                                    />
                                </ListItem>
                            </List>

                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>2.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >Receipt and payment of fund:</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>a.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I declare and confirm that the payment for the sale bought by me from Unlistedkart will be from my bank account provided at the time of on-boarding with Unlistedkart.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>b.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm and am aware that Unlistedkart will make payment in the Bank Account provided by me to Unlistedkart.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>c.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm that Unlistedkart can stop / cancel transfer of shares / securities in my demat account if they found that payment made by me to Unlistedkart does not belong to me.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>d.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm that as and when there is change in my Bank details I will inform the same to Unlistedkart.</Typography>}
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>3.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >Receipt and Transfer of Shares / Securities:</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>a.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I declare and confirm that the transfer of securities sold by me to Unlistedkart will be done from my Demat account provided by me at the time of onboarding.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>b.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm and aware that Unlistedkart will make payment in my Bank Account provided by me only after I transfer the securities in Unlistedkart Demat Account.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>c.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm that Unlistedkart can stop / cancel payment of funds if they find that the demat account from which securities transferred by me to Unlistedkart does not belong to me.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>d.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm and am aware that in case of buying of securities from Unlistedkart, Unlistedkart will transfer shares in my Demat Account which was provided by me at the time of onboarding.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>e.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >I confirm that as and when there is change in my Bank details I will inform the same to Unlisted Kart.I confirm that as and when there is change in my Bank details I will inform the same to Unlistedkart.</Typography>}
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>4.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >I declare and confirm to comply with all laws and regulations setup by SEBI, RBI, FEMA ACT, Companies Act 2013 or any Indian relevant acts or laws with regards to buying of unlisted shares in India. And in the event of violation of any laws, I will be held responsible for any legal consequences.</Typography>}
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>5.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >I am aware and confirm that research reports published in the platform of Unlistedkart is purely for the purpose of educating the client for the prospect of understanding the business model and should not by any means be considered as an investment advice. I am aware and confirm that Unlistedkart do not guarantee the correctness or accuracy of the information provided on their website or application. I am aware and confirm that I should do the proper due diligence before buying/selling the unlisted shares from the Unlistedkart.</Typography>}
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <List >
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>6.</span> </b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" className={classes.subtext} >Eligibility To Use by Accepting The Terms Of Use, You Hereby Represent That:</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>a.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >You Are Of 18 (Eighteen) Years Of Age Or Older And In Case Of You Are Acting As Guardian On Behalf Of A Minor, You Have The Necessary Authority To Register/Sign Up On The Platform For The Availing The Services On Behalf Of The Minor.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>b.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >You Are Of Legal Age To Form A Binding Contract, Are Not A Person Barred From Receiving The Platform Services Under The Applicable Laws And Are Competent To Enter Into A Binding Contract. The Company Reserves The Right To Refuse Access To The Platform To New Users And Any Such User Who Has Been Suspended, Disqualified, Debarred And/Or Removed By The Company For Any Regulatory And/Or Statutory Authority Reason Whatsoever Shall Not Been Entitled To Avail The Platform Services.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>c.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >You Agree To Abide By The Terms Of Use, Offer Documents, Investor Application Form, Demat Account Opening Form And Risk Disclosure Documents And Any Other Information Provided By You On And Through The Platform For The Provision Of The Platform Services.</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <List className={classes.nested}>
                                 <ListItem style={{padding:0, alignItems:"baseline"}}>
                                    <b><span className={classes.font}>d.</span></b>
                                    <ListItemIcon className={classes.li}>

                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={<Typography variant="subtitle1" className={classes.subtext} >You Shall Be Solely Responsible For All The Investment Decisions Executed By You On The Platform In Your Portfolio And The Company Shall Nowhere Be Responsible For Any Loss Or Harm Incurred By You Due To The Investment Activities Carried Out In Your Portfolio.</Typography>}
                                    />
                                </ListItem>
                            </List>
                        </div>

                        <Typography variant="subtitle1" style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px', fontSize:"12px" }}>
                            Additionally, I acknowledge that I have read, understand the below policies or information listed on unlistedkart.com
                        </Typography>
                        <Typography  component={Link} style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px' }}>
                            <ul><li className={classes.docs} ><a onClick={()=>  navigate("/legalpolicy",{ replace: true , state:location.state})} style={{ textDecoration: "none",  color:"#fff", fontWeight:"bold" }}> Legal & Compliance</a></li></ul>
                        </Typography>
                        <Typography  component={Link} style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px' }}>
                            <ul><li className={classes.docs} ><a onClick={()=>  navigate("/subtermsandconditions",{ replace: true , state:location.state})} style={{ textDecoration: "none",  color:"#fff", fontWeight:"bold" , color:"#fff", fontWeight:"bold"}}>Terms & Conditions</a></li></ul>
                        </Typography>
                        <Typography  component={Link} style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px' }}>
                            <ul><li className={classes.docs} ><a onClick={()=>  navigate("/privacyandpolicy",{ replace: true , state:location.state})}  style={{ textDecoration: "none",  color:"#fff", fontWeight:"bold" }}>Privacy Policy & Security</a></li></ul>
                        </Typography>
                        <Typography  component={Link} style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px' }}>
                            <ul><li className={classes.docs} ><a onClick={()=>  navigate("/risksanddisclosures",{ replace: true , state:location.state})} style={{ textDecoration: "none",  color:"#fff", fontWeight:"bold" }}>Risk Disclosure & Compliance</a></li></ul>
                        </Typography>
                        <Typography  component={Link} style={{ textAlign: 'initial', fontWeight: '500', marginTop: '16px' }}>
                            <ul><li className={classes.docs} ><a onClick={()=>  navigate("/noreturnpolicy",{ replace: true , state:location.state})} style={{ textDecoration: "none",  color:"#fff", fontWeight:"bold" }}>Return & Exchange Policy</a></li></ul>
                        </Typography>
           <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={handleClose} id="content">
                Close
            </Button>
           </Box>

                    </React.Fragment>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default TermsAndConditionAlert