import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex !important",
    justifyContent: "center  !important",
    width: "100% !important",
    height: "54px",
    borderRadius: "10px  !important",
    padding: "10px 35px !important",
    boxShadow: "none  !important",
    "&:hover": {
      backgroundColor: "#8264E5  !important",
      boxShadow: "none  !important",
    },
    textTransform: "uppercase  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "16px  !important",
    lineHeight: "22px  !important",
    color: "#fff !important",
    "&:disabled":{
      color:"#6E58B0 !important",
      backgroundColor:"#3B3159 !important"
    },
  },
  animate:{
    display: "flex !important",
    justifyContent: "center  !important",
    width: "100% !important",
    height: "54px",
    borderRadius: "10px  !important",
    padding: "10px 35px !important",
    boxShadow: "none  !important",
    textTransform: "uppercase  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "16px  !important",
    lineHeight: "22px  !important",
    color: "#fff !important",
    background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
    backgroundRepeat:"repeat-x",
    backgroundSize:"400% 100%",
    WebkitAnimation:"AnimationName 6s linear infinite",
    MozAnimation:"AnimationName 6s linear infinite",
    animation:"AnimationName 6s linear infinite",
  },
  bottom: {
    position: "absolute",
    bottom: "40px",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
}));

function Buttons({ value , value1,value2,handleError, type,submitButton, animation}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.bottom} >
     <Grid item xs={12} md={12} mx={2}>
     <Button disabled={submitButton}
      onClick={() => {
        handleError && handleError(value1,value2);
      }}
      style={{
        backgroundColor: submitButton == true ? "#323333 " : "#8264E5 ",
      }}
      type={type}
      variant="contained"
      disableRipple
      className={animation? classes.animate :classes.button}
      elevation={0}
    >
      {value}
    </Button>
     </Grid>
    </Grid>
  );
}

export default Buttons;
