import axios from "axios";
import { helpers } from "../_helpers/index";
// const settings = require("../settings");
// const apiEndpoint = settings.ApiEndPoint;

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const allSector = {
  getAllSector,
  postFiltersector,
};

function getAllSector() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllSector`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

function postFiltersector(data) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(`/filtersector`, data, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
}
