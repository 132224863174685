import React, { useEffect } from 'react'
import { json } from 'react-router-dom'

export default function Appredirector() {
    useEffect(() => {
        const platform = window.navigator.platform
      
        if(platform=="iPhone"){
            window.open("https://apps.apple.com/in/app/unlistedkart/id1573104285", "_parent")
          }else{
            window.open("https://play.google.com/store/apps/details?id=com.unlisted.unlistedkart", "_parent")
           }

    },[])
    return (

        <div>appredirector</div>
    )
}
