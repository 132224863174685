import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import BottomNavigation from "../../component/Home/BottomNavigation";
import { Box, Button, Typography, Grid, Dialog, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Edit from "../../Images/Edit.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import * as Yup from "yup";
import { userService } from "../../../src/_services/users";
import { connect } from "react-redux";
import { logoutSuccess } from "../../../src/store/bugs";
import { RegisterService } from "../../_services/register";
import Notification from "../../component/AlertModel/Notification";
import { RecaptchaVerifier, signInWithPhoneNumber,getAuth } from 'firebase/auth';
import { initializeApp,getApps } from "firebase/app";
// import jwt from 'jsonwebtoken';
import DisableRightClick from "../../_helpers/DisableRightClick";
import { loginService } from "../../_services/login";
const secretKey = 'unlistedsecratetestingkey';

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
    padding: "16px !important",
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  text: {
    color: "#fff!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 16px !important",
    lineHeight: "24px !important",
  },
  cards: {
    "& 	.MuiDialog-paper": {
      border: "solid 1px transparent !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      minWidth: "327px !important",
      borderRadius: "16px !important",
      backgroundColor:"#0D0D0D !important",
      padding: "0 0 8px 0px !important",
    },
  },
  label: {
    marginTop: "24px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "12px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },
  dialogTitle:{
    marginTop: "16px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#A489FF !important",
    textAlign:"center !important",
    lineHeight: "24px !important",
  },
  dialogBtn:{
    borderRadius:"10px !important",
    fontSize: "14px  !important",
    fontFamily: "Poppins  !important",
    textTransform: "uppercase !important",
    fontWeight: "500 !important",
    lineHeight:"14px  !important"
},
cards: {
  "& 	.MuiDialog-paper": {
    width: "241px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
    // padding: "16px 0px !important",
    // height:"136px !important"
  },
},
dialogSubTitle: {
  fontFamily: "Poppins  !important",
  fontStyle: "normal  !important",
  fontWeight: "400  !important",
  fontSize: "12px  !important",
  color: "#F4F4F4 !important",
  lineHeight: "18px !important",
  textAlign: "center !important",
  margin:"1rem !important",
  padding:"0 !important"
},
  textfield: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      padding:"7px 0px 5px",
      color: " #FFF !important",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
    },
  },
  outlined: {
    width: "100% !important",
    height: "44px !important",
    fontFamily: "Poppins !important",
    padding: "10px 20px !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#AC93FF !important",
    borderColor: "#AC93FF !important",
    borderRadius: "10px !important",
    "&:hover": {
      borderColor: "#AC93FF !important",
    },
    margin: "10px 0  !important",
  },
  contained: {
    width: "100% !important",
    marginRight:"1rem !important",
    height: "44px !important",
    fontFamily: "Poppins !important",
    padding: "10px 20px !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#fff",
    backgroundColor: "#6D50CC !important",
    borderRadius: "10px !important",
    "&:hover": {
      backgroundColor: "#6D50CC !important",
    },
    "&:disabled":{
      color:"#6E58B0 !important",
      backgroundColor:"#3B3159 !important"
    },
    margin: "10px 0  !important",
  },
}));

function EditProfile(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(location?.state === "number" ? "number" : location?.state === "email" ? "email" : null);
  const userID =
    props.authReducer &&
    props.authReducer.auth &&
    props.authReducer.auth.user &&
    props.authReducer.auth.user.id;
  const [initValues, setInitValues] = useState([]);
  const [notification,setNotification]=  useState(false);
  const [submit,setSubmit]=  useState(false);
  // const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  let auth;
  const getDetails = () => {
    if (userID) {
      userService
        .getUserDetails(userID)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              const first_name = res.data[0].first_name;
              const last_name = res.data[0].last_name;
              setInitValues({
                name: res.data[0].code ==="COMPANY"? res.data[0].company_name : `${first_name} ${last_name}`,
                number: res.data[0].contact_no.startsWith('+') ? res.data[0].contact_no : '+' + res.data[0].contact_no,
                email: res.data[0].email,
                canEmailUpdate:res.data[0].can_email_update,
                canMobileUpdate:res.data[0].can_mobile_update
              });
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                console.log(res.status);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha',{
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      }
    }, auth);
  }

  async function initializeFirebae(){
    try {
      let app;
      let firebaseEnv = process.env.REACT_APP_ADMIN_CLIENT.replace(/\\/g, '');
      let firebaseEnvFormated = firebaseEnv.replace(/""/g, '');
      let firebaseEnvFormatedObject = JSON.parse(firebaseEnvFormated)
        if(getApps().length === 0 ){
        app = initializeApp(firebaseEnvFormatedObject.REACT_APP_ADMIN_CLIENT);
      }
      auth = getAuth(app);
    } catch (error) {
     console.log(error);
    } 
   }
  const handleOnSubmit = async(values) => {
    await initializeFirebae()
    setSubmit(true)
    if(edit === "number"){
     if(values.number === initValues.number){
      setSeverity("error");
      setSnackbarTitle("Please update new number");
      setNotification(true);
     }else{
      RegisterService.registerMobileNumber(values.number).then((res) => {
        setSubmit(false)
        console.log(res)
          if (res) {
            if (res.status === 200) {
                  if(res.status === 200){
                    generateRecaptcha();
                    let appVerifier = window.recaptchaVerifier;
        console.log(res)

                    signInWithPhoneNumber(auth, values.number , appVerifier)
                      .then((confirmationResult) => {
                         window.confirmationResult = confirmationResult;
                         console.log(values.number)
                         navigate("/changemobilenumber", {
                          replace: true,
                          state: { mobile: values.number,userId:userID},
                        
                        });
                      }).catch((error) => {
                        // Error; SMS not sent
                        console.log(error);
                      });
                    
                 
                  }
            }else if (res.status === 401) {
              setSeverity("error");
              setSnackbarTitle("Mobile number already registered");
              setNotification(true);
            } else if (res.status === 400) {
              setSeverity("error");
              setSnackbarTitle("Please enter valid number");
              setNotification(true);
            } else if (res.status === 204) {
              setSeverity("error");
              setSnackbarTitle("Mobile number is required");
              setNotification(true);
            } else if (res.status === 500) {
              setSeverity("error");
              setSnackbarTitle("Something went wrong");
              setNotification(true);
            }
            else {
              console.log('hi')
              setSeverity("error");
              setSnackbarTitle("Something went wrong");
              setNotification(true);
            }
          }
        }).catch(()=>{
          // setSubmit(false)
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setNotification(true);
        })
     }
    }else if(edit === "email"){
      if(values.email === initValues.email){
        setSeverity("error");
        setSnackbarTitle("Please update new email");
        setNotification(true);
       }else{
      const data = {
        email: values.email,
      };
      RegisterService.changeEmail(data).then((res) => {
        setSubmit(false)
        if (res.data.message === "OTP SENT SUCCESSSFULLY") {
            navigate("/changeemail", {
              replace: true,
              state: { email: values.email,userId:userID },
            });
        }else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS") {
          setSeverity("error");
          setSnackbarTitle("Email already exists");
          setNotification(true);
        }else {
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setNotification(true);
        }
      }).catch(()=>{
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setNotification(true);
      })
  }}
  };

  useEffect(() => {
    getDetails();
  }, [userID]);
  const toTitleCase =  str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())

  return (
    <div>
      <Page>
        <Notification snackbarTitle={snackbarTitle} open={notification} setOpen={setNotification} type={severity}/>
        <Box className={classes.boxcontainer}>
          <Grid container direction="row" style={{ marginBottom:"10px"}}>
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={() => navigate("/profile")}
              />
              <Typography ml={2.5} className={classes.text}>Edit Profile</Typography>
          </Grid>
          <Grid>
            <Formik
              enableReinitialize={true}
              initialValues={initValues}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .nullable()
                  // .matches(phoneRegex, "Enter a valid Mobile Number")
                  .required("Name is required"),
                number: Yup.string().nullable().required("Number is required"),
                email: Yup.string().email("Enter a valid email").required("Email is required"),
              })}
              onSubmit={(values) => {
                handleOnSubmit(values);
              }}
            >
              {({
                errors,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container flexDirection="column">
                    <Typography
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                    >
                      Name
                    </Typography>
                    <Grid
                      container
                      style={{
                        borderBottom:
                          edit == "name"
                            ? " solid 1px #AC93FF "
                            : " solid 1px #1C1C1C ",
                      }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="standard">
                          <InputBase
                            onChange={handleChange}
                            name="name"
                            id="name"
                            value={
                              edit == "name" ? values?.name && toTitleCase(values.name) :initValues?.name && toTitleCase(initValues.name)
                            }
                            className={classes.textfield}
                            inputProps={{
                              readOnly: true,
                            }}
                            placeholder={initValues.name ? initValues.name : ""}
                          />
                          {values.name ? (
                            ""
                          ) : (
                            <FormHelperText style={{ color: "red" }}>
                              {errors.name && touched.name && errors.name}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Typography
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                    >
                      Phone Number
                    </Typography>

                    <Grid
                      container
                      style={{
                        borderBottom:
                          edit == "number"
                            ? " solid 1px #AC93FF "
                            : " solid 1px #1C1C1C ",
                      }}
                    >
                      <Grid item xs={11} sm={11} md={11}>
                        <FormControl variant="standard">
                          <InputBase
                            type="tel"
                            onChange={(e)=>{
                              setFieldValue("number", e.target.value)
                              setNotification(false)
                            }}
                            onInput={(e) => {
                              const maxLength = 15
                              e.target.value = e.target.value
                              .toString()
                              .slice(0, maxLength)
                              .replace(/[^0-9()+]/g, "")
                            }}
                            name="number"
                            id="number"
                            value={
                              edit == "number"
                                ? values.number
                                : initValues.number
                            }
                            className={classes.textfield}
                            inputProps={{
                              readOnly: edit == "number" ? false : true,
                            }}
                            placeholder={
                              edit == "number"
                                ? values.number
                                : " Enter Number "
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        style={{ textAlign: "center", margin: "auto" }}
                        
                      >
                      <IconButton>
                      <img
                          onClick={() => {
                            setEdit("number");
                            // setOpen(true)
                            setFieldValue("email", initValues.email);
                          }}
                          src={Edit}
                          alt=""
                          style={{
                            fontSize: "1rem",
                            color:  "#AC93FF",
                            // opacity: initValues.canMobileUpdate ? 1 : 0.4, 
                            display: edit == "number" ? " none " : " block ",
                          }}
                        />
                      </IconButton>
                      </Grid>
                    </Grid>
                          <FormHelperText style={{ color: "#DD4A47", fontFamily:"Poppins", fontSize:"10px", lineHeight:"16px" ,marginTop:"8px" }}>
                            {errors.number && touched.number && errors.number}
                          </FormHelperText>
                    <Grid
                      container
                      direction="row"
                      style={{
                        display: edit == "number" ? "flex" : "none ",
                        justifyContent:"space-between"
                      }}
                    >
                      <Grid xs={12} md={12} style={{display:"flex"}}>
                      <Button
                        type="submit"
                        disabled={values.number === initValues.number || values && values.number && values.number.length<=12 || submit}
                          variant="contained"
                          className={classes.contained}
                        >
                          UPDATE
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.outlined}
                          onClick={(e) => {
                            setEdit(false);
                            setFieldValue("number", initValues.number);
                            setNotification(false)
                          }}
                        >
                          CANCEL
                        </Button>
                      </Grid>
                    </Grid>

                    <Typography
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.label}
                    >
                      Email Address
                    </Typography>
                    <Grid
                      container
                      style={{
                        borderBottom:
                          edit == "email"
                            ? " solid 1px #AC93FF "
                            : " solid 1px #1C1C1C ",
                      }}
                    >
                      <Grid item xs={11} sm={11} md={11}>
                        <FormControl variant="standard">
                          <InputBase
                            type="email"
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e)=>{
                              setFieldValue("email", e.target.value)
                              setNotification(false)
                            }}
                            name="email"
                            id="email"
                            inputProps={{
                              readOnly: edit == "email" ? false : true,
                            }}
                            value={
                              edit == "email" ? values.email : initValues.email
                            }
                            className={classes.textfield}
                            placeholder={
                              edit == "email" ? values.email : "Enter email"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                         <IconButton >
                        <img
                          onClick={() => {
                            // setOpen(true);
                            setEdit("email");
                            setFieldValue("number", initValues.number);
                          }}
                          src={Edit}
                          alt=""
                          style={{
                            fontSize: "1rem",
                            color: "#AC93FF",
                            // opacity: initValues.canEmailUpdate ? 1 : 0.4, 
                            display: edit == "email" ? " none " : " block ",
                          }}
                        />
                       </IconButton>
                      </Grid>
                    </Grid>
                    <FormHelperText mt={1} style={{ color: "#DD4A47", fontFamily:"Poppins", fontSize:"10px", lineHeight:"16px" ,marginTop:"8px"}}>
                              {errors.email && touched.email && errors.email}
                    </FormHelperText>
                    <Grid
                      container
                      direction="row"
                      style={{
                        display: edit == "email" ? "flex" : "none ",
                        justifyContent:"space-between"
                      }}
                    > 
                     <Grid xs={12} md={12} style={{display:"flex"}}>
                      <Button
                        disabled={values.email === initValues.email || errors.email || submit}
                          //  onClick={() => navigate("/home")} 
                          type="submit"
                          variant="contained"
                          className={classes.contained}
                        >
                          UPDATE
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.outlined}
                          onClick={(e) => {
                            setEdit(false);
                            setFieldValue("email", initValues.email);
                            setNotification(false)
                          }}
                        >
                          CANCEL
                        </Button>
                  </Grid>
                  </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Box>
      </Page>
      <div id="recaptcha"></div>
      <BottomNavigation activeTab={2} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});
export default connect(mapStateToProps, dispatchToProps)(EditProfile);
