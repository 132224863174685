import React from "react";
import { Box, Typography, Grid , Avatar} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  card: {
    border:"1px solid #1C1C1C !important ",
    borderRadius: "10px !important",
    // backgroundColor:"#1C1C1C !important",
    // opacity:"0.5"
  },
 bg:{
    backgroundColor:"#1C1C1C !important",
 }
}));

function CardSkeleton({}) {
  const classes = useStyles();
          return (
            <div>
              <Box sx={{ m: 1 }}>
             
                <Grid container className={classes.card}>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rectangular"   className={classes.bg}>
                      <Avatar  />
                    </Skeleton>
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                      <Grid xs={8} md={8} sx={{ margin: "auto" }}>
                        {" "}
                        <Skeleton width="100%"  className={classes.bg}>
                          <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width="70%"  className={classes.bg}>
                          <Typography>.</Typography>
                        </Skeleton>
                      </Grid>
                      <Grid xs={4} md={4} sx={{ margin: "auto" }}>
                        {" "}
                        <Grid style={{ padding: "10px", textAlign: "center" }}>
                          <Skeleton width="100%"  className={classes.bg}>
                          </Skeleton>
                          <Skeleton width="100%"  className={classes.bg}>
                          </Skeleton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                      <Grid xs={8} md={8} sx={{ margin: "auto" }}>
                        {" "}
                        <Skeleton width="80%"  className={classes.bg}>
                        </Skeleton>
                      </Grid>
                      <Grid
                        xs={4}
                        md={4}
                        sx={{ textAlign: "end", alignSelf: "flex-end" }}
                      >
                        {" "}
                        <Skeleton width="100%"  className={classes.bg}>
                        </Skeleton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              
              </Box>
            </div>
          );
   

}

export default CardSkeleton;
