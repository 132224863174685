import React, {useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box,  Typography, Grid, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    paddingBottom:'2rem'
    
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  text: {
    color: "#A489FF!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 20px !important",
    lineHeight: "24px !important",
    marginLeft:"1rem !important"
  },
  Typography: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF",
  }
},
  typo: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF"},
     '& b': {
      color: "#A489FF",
   },
    marginTop:4,
  },
  typoHeader: {
    textAlign: "justify",
    color: "#A489FF",
    marginTop: 16,
    padding:"0px 30px !important",
  },
  typoSubHeader:{
    textAlign: "start",
    color: "#A489FF",
    marginTop: 16,
    padding:"30px !important",
  },
  ulClass:{
    padding:"0px 0px 0px 16px"
  },
  btn:{
    color:"#fff !important",
    padding:"10px 60px !important",
    background:"#6D50CC !important",
    fontFamily:"Poppins !important",
    borderRadius:"16px !important",
    fontWeight:"600 !important",
},
btnBox:{
    textAlign:"center !important"
}

}));

function TermsConditions() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    const element = document.getElementById("content");
    element.scrollIntoView();
   },[])
 
   const navigateFunction = (()=>{
    if(location.state){
      navigate("/termsandcondition",{ replace: true , state:{...location.state,condition:true}})
    }else{
      navigate("/profile",{ replace: true , state:{condition:true}})
    }
   })

  return (
    <div>
        <Box className={classes.boxcontainer} id="content">
          <Grid container direction="row" style={{padding: "20px ",}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "initial",
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={navigateFunction}
              />
              <Typography className={classes.text}>Terms and Conditions</Typography>
            </Grid>
          </Grid>
       
          <Grid item sm={12} xs={12} md={12} lg={12}>
          <Typography variant="body2" className={classes.Typography}>
            Welcome to the Unlistedkart! At UNLISTEDKART we are creating a
            system that strives to bring unlisted share investment opportunities
            that was otherwise only available for the wealthy. We want to be a
            leading trusted, secure, and safe transaction source for Unlisted
            shares and in the process create a growing loyal customer base.
            Using the product, you can buy or sell shares, buy neutral research
            reports, manage unlisted holdings, and read insights about unlisted
            market.
          </Typography>

          <Typography variant="body2" className={classes.typo}>
            As UNLISTEDKART grows, we aim to help our members discover ways to
            invest in upcoming new age companies, help as customers own an
            equity or in other words part of the company. As founders of the
            company, we want our customers to be able to access the much need
            non-interfering efficient capital from the family houses and other
            similar managements to be able to grow your business thoroughly.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart LLP (“<b>Unlistedkart LLP</b>”) is the owner and
            controller of ‘UNLISTEDKART’ which is a web-based smartphone
            application that provides a unified enabling a User to make his/ her
            investments towards unlisted shares and includes other related
            facilities and services offered from time to time on UNLISTEDKART (“
            <b>App Services</b>”) currently or in the future, where such
            services can be accessed via the internet either on mobile and
            desktop/laptop (“<b>UNLISTEDKART</b>” or “<b>App</b>”). UNLISTEDKART
            is NOT an investment advisor and any information represented as part
            of the Unlisted shares is intended to not provide any financial
            advice related to equities, equity-related products, fixed income
            products, mutual funds, financial planning, tax planning or any
            other investment analysis. All investment decisions carried by out
            by customers, is based on the financial knowledge of our customers,
            and relevant advice they may receive from their financial advisors
            outside of Unlistedkart. Please read Risk and Disclosure to
            understand more in Investing Unlisted market.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            By accessing and using our Services (defined below), you (“Our
            User”) are agreeing to these terms and conditions contained
            hereinafter (“<b>Terms of Service</b>” or “<b>Terms</b>”). We,
            Unlistedkart LLP, including its affiliates and subsidiaries, strongly
            urge you to read the Terms of Service in detail before registering,
            accessing, or using the App Services. Supplemental terms may apply
            to certain Services, such as policies for a particular event,
            activity or promotion or new feature and such supplemental terms
            will be disclosed to you in connection with the applicable Services.
            Supplemental terms are in addition to, and shall be deemed a part
            of, the Terms for the purposes of the applicable Services.
            Supplemental terms shall prevail over these Terms in the event of a
            conflict with respect to the applicable Services. Your use of App
            Services will be considered as your acceptance of the supplemental
            terms. We strongly recommend you read the supplemental terms for
            each service given below or within the App from time to time.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may amend the Terms at any time by posting an updated version on
            the App. The updated version of these Terms shall supersede the
            previous version of the Terms and take effect immediately upon
            posting and may be notified, via the App. You have the right to
            opt-out of agreeing to these Terms or any future updated version of
            the Terms, and therefore also using the App. If you are not
            comfortable with any of the Terms or do not wish to be bound by the
            same, you are at liberty to refrain from using the App and the App
            Services. However, please be aware that your continued use of the
            App Services for a period of 7 (seven) days from the launch of any
            updated Terms would mean deemed acceptance of these Terms and its
            associated policies mentioned here, including any modification
            thereof.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            For the purpose of these Terms of Use, wherever the context so
            requires, the term “User” shall mean and include any natural or
            legal person who has agreed to these Terms of Use on behalf of
            itself or any other legal entity.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            It is clarified that the Privacy Policy (that is provided
            separately), form an integral part of these Terms of Use and should
            be read contemporaneously with the Terms of Use. Illegality or
            unenforceability of one or more provisions of these Terms of Use
            shall not affect the legality and enforceability of the other terms
            of the Terms of Use. For avoidance of doubt, if any of the
            provisions becomes void or unenforceable, the rest of the provisions
            of these Terms of Use shall be binding upon the User.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Eligibility
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            By registering with UNLISTEDKART and using the App Services, you are
            representing the following:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>You are 18 years of age or older.</li>
              <li>You can enter into a legally binding agreement.</li>
              <li>
                You are not barred or otherwise legally prohibited from
                accessing or using Unlistedkart LLP/UNLISTEDKART and the App
                Services.
              </li>
            </ul>
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If you allow anyone to use your account, including individuals under
            18 years of age, you will still be responsible for ensuring that
            such individuals comply with the terms. You will be responsible for
            all actions these individuals take in and/or through your account.
            You also acknowledge that Unlistedkart LLP does not have the
            responsibility of ensuring that you meet the aforesaid requirements.
            You agree to comply with all applicable laws when using the
            Services, and you may only use the Services for lawful purposes.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Please be aware that Unlistedkart LLP reserves the right to revoke
            your access to the UNLISTEDKART App or App Services, subject to our
            reasonable discretion. In this regard, we may also choose to suspend
            your UNLISTEDKART Account or your access to the same. As a
            registered User, in such an event, you may contact our support team
            to assist you/resolve the situation. Please note that if such
            situation arises, shares already procured from you would continue to
            remain in your DEMAT Account, and not accessible by Unlistedkart.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Your Use of Unlistedkart
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            When you use our App, you are representing to us that:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                Your use of the App shall not violate any applicable law or
                regulation, and that you fulfil the eligibility criteria set
                forth under Section 2.
              </li>
              <li>
                All registration information you submit is truthful, complete,
                and accurate and you agree to maintain accurate, complete, and
                up-to-date account information in your Account.
              </li>
              <li>
                You are responsible for all activity that occurs under your
                Account. Unless otherwise permitted by Unlistedkart LLP in
                writing, you may only possess one Account.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of the
                access Unlistedkart credentials of your account and are fully
                responsible for all activities that occur under your Account.
                You agree to immediately notify us of any unauthorized use of
                your passcode or Account or any other breach of security.
              </li>
              <li>
                Your use of the App, or its content is only for personal
                purposes. By using the App Services, you hereby agree that you
                are aware and responsible for all transactions taking place
                through your Account. This include the payments made for
                Unlistedkart under the selected payment methods that are not in
                the name of the Account Holder, but a family member, friend, or
                acquaintance. By making a payment for a Unlistedkart payment
                methods that does not belong to You, you assume responsibility
                for the transactions that have taken place through that
                Unlistedkart App for the payment that is made by You using the
                App Services.
              </li>
              <li>
                Your personal and non-commercial use of the App prevents you
                from decompiling, reverse engineering, disassembling content,
                removing any intellectual property right associated with our
                App, including copyright, trademarks, trade secrets, designs,
                and patents, or other proprietary notices.
              </li>
              <li>
                You shall not access or use the App in any manner that may be
                harmful to the operation of the App or its content.
              </li>
              <li>
                You shall not use any product or service available on the App
                for commercial purposes or use the App Services in any way that
                is unlawful, or harms Unlistedkart LLP or any other person or
                entity.
              </li>
              <li>
                You will not post, distribute, or otherwise transmit or make
                available any software or other computer files that contain a
                virus or other harmful component, or otherwise impair or damage
                the App or any connected network, or otherwise interfere with
                any person or entity's use or enjoyment of the App.
              </li>
              <li>
                You will not delete or modify any content of the App, such as
                legal notices, disclaimers or proprietary notices, copyright or
                trademark symbols and logos.
              </li>
              <li>
                Your use of Unlistedkart LLP shall indicate that you have
                provided consent to automatically receive requested updates such
                as bug fixes, patches, enhanced functions, missing plug-ins, and
                new versions (collectively, ‘Updates’), for the purpose of
                effective delivery of the App Services. Please note that your
                continued use of the App following such Updates would mean
                deemed acceptance by you of the same.
              </li>
              <li>
                You understand and accept that not all products, services and
                rewards offered on this App are available in all geographic
                areas and you may not be eligible for all the products, services
                and rewards offered by UNLISTEDKART on the application.
                UNLISTEDKART reserves the right to determine the availability
                and eligibility for any product, services and rewards offered on
                the App.
              </li>
              <li>
                You shall request Unlistedkart, to block the Account and change
                the passcode immediately for the Account, if your Device has
                been lost or stolen.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Violation of Terms of Use / Suspicious Activity
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If we believe that you have violated any of the conditions as
            mentioned under these Terms or our Privacy Policy, we reserve the
            right to suspend your access to the App and/or delete your Account
            without prior notice to You. If we have reason to believe that there
            is suspicious or unusual activity being carried out through your
            account, we may temporarily or permanently suspend your access to
            the App Services. You may reach out to the UNLISTEDKART support team
            to assist you with any query or question arising because of the
            afore-mentioned suspension/ deletion to resolve the same.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You may end your legal agreement with Unlistedkart LLP at any time
            by discontinuing the use of the App Services. We provide all our
            Users an option through which a User may request archive of a
            specific account stored on his/her account or deletion/deactivation
            of the complete account through the support mail{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" , color:"#A489FF"}}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>
            . Following such request, we consider the agreement to be terminated
            and take appropriate steps.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You are responsible for any fees that may be applicable to certain
            transactions or use of the App; where you will be notified of such
            applicable fees, prior to the completion of any transaction. Only if
            you consent to paying the above-mentioned fees, shall the
            transaction go ahead. All fees shall be determined by Unlistedkart
            LLP and will be inclusive of taxes.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Refunds
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You are responsible for all the information you input in your
            Account, including the correctness of details for payment of your
            Unlistedkart account and all charges that result from these
            payments. Unlistedkart LLP shall not be responsible for any payment
            for an incorrect Unlistedkart account or bill payment number. In
            case, money has been charged to your card or bank account and a
            payment/service is not delivered by the service provider within 5
            working days of your completion of the transaction then you may
            inform us regarding the same through the customer support email{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>
            . We shall investigate such incidents and if it is found that money
            was indeed charged to your card or bank account without delivery of
            the service then you will be refunded the money within twenty-one
            (21) working days from the date of receipt of your email. All
            refunds will be to your card or bank account as registered and used
            on the transaction on Unlistedkart account. Unlistedkart LLP will
            have the sole discretion to determine the mode of reversal from the
            above-mentioned options. However, Unlistedkart LLP disclaims all
            liability, including for refunds, in the event of any error arising
            from a failure in the applicable bank or Unlistedkart payment
            method’s service provider’s system or network.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            In case a payment has been wrongfully made to your account or bank
            account, UNLISTEDKART and Unlistedkart LLP reserve the right to
            automatically initiate a refund through your bank. By agreeing to
            these terms, you hereby consent to such a refund initiated by your
            concerned Bank, at the request of UNLISTEDKART, to offset any
            incorrect transaction, or double payment made to your account. Once
            such transaction is processed, UNLISTEDKART shall inform you of the
            same and the corrective action taken by UNLISTEDKART to refund such
            payment.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Offers and Rewards
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            To participate and win rewards and other offers as made available
            within the App, you must agree to the offer/reward terms and
            conditions. You agree that your participation in the offer
            constitutes your understanding of the agreement and to the offer
            terms and conditions. The offer terms and conditions form a binding
            legal agreement between you and UNLISTEDKART with respect to the
            offers, and any undefined terms used in the offer terms and
            conditions shall have the meaning set forth in these terms and
            conditions. UNLISTEDKART reserves the right, at its sole discretion,
            to disqualify any User who does not meet the offer requirements or
            in case of reasons including but not limited to any misuse of the
            offer or fraud or suspicious transaction/activity or under any legal
            requirement or applicable rules and regulations. UNLISTEDKART also
            reserved the right to discontinue or change any offer or reward or
            the UNLISTEDKART offer programme at any time, at its sole
            discretion.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            For eligibility, in addition to your compliance of these terms and
            conditions, you must meet the eligibility criteria for each offer to
            participate. Details of each offer and/or reward and the eligibility
            terms and conditions for each offer shall be provided in the offers
            page of your UNLISTEDKART App. Offers provided to a particular User
            may vary from User to User. Please read the offer terms and
            conditions applicable to your offer as provided in your UNLISTEDKART
            App carefully.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Privacy
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We collect, hold, use and transfer your personal data in accordance
            with our Privacy Policy. By agreeing to the Terms of Use, you hereby
            agree to our Privacy Policy, which may be updated and/or modified by
            us from time to time. You understand and agree that, to the extent
            permitted by applicable law, any data provided by you in connection
            with the App Services may be shared with our afﬁliates or partners,
            and/ or used by us for enhancing the App Services, including but not
            limited to creating new products. Please refer to the Privacy Policy
            for further clarity on the subject.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Compliance with NPCI Guidelines
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            National Payments Corporation of India (‘<b>NPCI</b>’) is an
            authorized payment system operator by the Reserve Bank of India (‘
            <b>RBI</b>’). NPCI owns and operates UPI payment system. A Payment
            Service Provider (‘<b>PSP</b>’) is the banking company authorized to
            act as a Payment Service Provider under the UPI framework. PSP is
            engaged by a Third-Party Application Provider (‘<b>TPAP</b>’) to
            provide UPI services to You/User. A TPAP is an entity that provides
            the UPI compliant app(s) to the end-user customers to facilitate UPI
            based payment transactions. The Bank where the end-user customer
            maintains his/her account and has linked the account for the purpose
            of debiting/crediting the payment transactions made through UPI is
            the Customers Bank. The end user customer is the individual who uses
            UPI payment facility to send and receive payments.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We, Unlistedkart LLP are a TPAP, and we use a PSP who participate in
            UPI to manage transactions. We are responsible for facilitating
            grievances / complaints resolution of the customers on-boarded on
            our UPI application.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We shall be the first point of contact for all UPI related
            grievances/complaints for customers on-boarded by us. In case the
            complaint/grievance remains unresolved, the next level for
            escalation will be the PSP, followed by the bank (where you maintain
            the account) and NPCI in the same order. After exercising these
            options, you can approach the Banking Ombudsman and/or the Ombudsman
            for Digital Complaints, as the case may be.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Disclaimer
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The App Services, including all content, software, functions,
            material, and information made available or accessible through the
            Services are provided “as is”. Unlistedkart LLP, UNLISTEDKART, their
            respective agents, co-branders, or partners, make no representation
            and warranty of any kind for the content, software, functions,
            material, and information available/accessible through the Services.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart LLP does not warrant that the functions contained in
            content, information, and materials on the App, including, without
            limitation any third-party sites or services linked to the App will
            be uninterrupted, timely or error-free, that the defects will be
            rectified, or that the App or the servers that make such content,
            information, and materials available are free of viruses or other
            harmful components.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Limitation of Liability
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            In no event shall Unlistedkart LLP, its officers, owners, directors,
            employees and agents, partners, co-branders, licensors, licensees,
            consultants, or contractors be liable to you or any third party for
            any special, incidental, indirect, consequential or punitive damages
            or losses whatsoever, or damages for loss of data or profits,
            goodwill, and/ or other intangible loss, whether or not foreseeable
            and regardless of whether Unlistedkart LLP has been advised of the
            possibility of such damages, or based on any theory of liability,
            including breach of contract or warranty, negligence or other
            tortious action, or any other claim arising out of or in connection
            with your use of, or access to, the App or App Services.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            In no event shall Unlistedkart LLP’s total cumulative liability to
            You in connection with the App Services for all damages, losses and
            causes of action, arising from or relating to these terms and
            conditions exceed the net fees Unlistedkart LLP has received and
            retained from your valid transactions during the three (3) month
            period immediately preceding the date of the claim.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Indemnity
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You agree to defend, indemnify and hold harmless Unlistedkart LLP,
            its officers, owners, directors, employees and agents, partners,
            co-branders, licensors, licensees, consultants, contractors and
            other applicable third parties (collectively ‘Indemnified Parties’)
            from and against all claims, demands, damages, obligations, losses,
            liabilities, cause of action, costs or debt, and expenses (including
            any legal fees) arising from:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>your use of and access to the App.</li>
              <li>your violation of any of these terms.</li>
              <li>
                your violation of any third party right, including any
                intellectual property right or privacy right.
              </li>
              <li>
                the committing of any of the prohibited activities as stated
                here.
              </li>
              <li>
                your failure to follow applicable law, including tax
                regulations; or
              </li>
              <li>
                any claim that your use of the App caused damage to a third
                party.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Disputes and Arbitration
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            In consideration for UNLISTEDKART granting you access to and use of
            the app Services, you agree that in case of any dispute,
            Unlistedkart LLP shall not be a party to the same. While
            Unlistedkart LLP is not obligated to mediate disputes, we will
            assist users in communicating with parties involved such as PSP, or
            NPCI or any other stakeholder regarding payments regarding a
            dispute.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Except as otherwise set forth in these Terms, these Terms shall be
            exclusively governed by and construed in accordance with the laws of
            India. Any dispute, conflict, claim, or controversy arising out of
            or broadly in connection with or relating to the App Services or
            these Terms, including those relating to its validity, its
            construction, or its enforceability (any “Dispute”) shall be first
            mandatorily submitted to mediation proceedings in terms of the
            Indian law. If such Dispute has not been settled within sixty (60)
            days after a request for mediation has been submitted under the
            Indian laws, such Dispute can be referred to and shall be
            exclusively and finally resolved by arbitration under the
            Arbitration and Conciliation Act, 1996 (“Act”). The Dispute shall be
            resolved by one (1) arbitrator to be appointed in accordance with
            the Act. The place of both mediation and arbitration shall be
            Bangalore, India. The language of the mediation and/or arbitration
            shall be English, unless you do not speak English, in which case the
            mediation and/or arbitration shall be conducted in both English and
            your native language. The existence and content of the mediation and
            arbitration proceedings, including documents and briefs submitted by
            the parties, any correspondence from the mediator, and
            correspondence, orders and awards issued by the sole arbitrator,
            shall remain strictly confidential and shall not be disclosed to any
            third party without the express written consent from the other party
            unless: <br />
            <br /> (i) the disclosure to the third party is reasonably required
            in the context of conducting the mediation or arbitration
            proceedings; <br /> <br />
            (ii) the third party agrees unconditionally in writing to be bound
            by the confidentiality obligation stipulated herein.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Use and Protection of Intellectual Property Rights
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart LLP, UNLISTEDKART and the App Services are protected by
            copyright, trademarks, patents, trade secret and/or other
            intellectual property laws. No information, content or material from
            the App including, without limitation, all the page headers, images,
            illustrations, graphics, audio clips, video clips or text, reports
            generated, trademarks, tradenames may be copied, reproduced,
            republished, uploaded, posted, transmitted, or distributed in any
            way without Unlistedkart LLP's express written permission. You are
            hereby given a limited licence to use the App Services, subject to
            your agreement of these Terms. All the above-mentioned information,
            content or material is our and our licensor’s intellectual property.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart LLP, UNLISTEDKART and its licensors, if any, are the
            sole owners of the underlying software and source code associated
            with the App Services and all the trademarks, copyright an any other
            intellectual property rights of any nature in the Product.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            When you upload, submit, store, send or receive content that may
            include feedback to or through our App, you give Unlistedkart LLP a
            worldwide, perpetual licence to use, host, store, reproduce, modify,
            create derivative works, communicate, publish, publicly perform,
            publicly display, and distribute such content. This licence shall
            not revert to you even if it is not used by Unlistedkart LLP. The
            rights you grant in this licence are for the limited purpose of
            operating, promoting, and improving the App Services, and to develop
            new ones. This licence continues even if you stop using the App
            Services.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Tax Liability
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You hereby agree to comply with all applicable tax laws in
            connection with your use of the App Services, including without
            limitation, the reporting and payment of any taxes arising in
            connection with payments made through the App, or funds/income
            received through the App Services.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Governing Law and Jurisdiction
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The App, the App Services, all your transactions with the App, and
            our relationship shall be governed by the laws of India, without
            regard to conflict of law principles. You agree that all claims,
            differences, and disputes which we may have shall be subject to the
            exclusive jurisdiction of the competent courts located in Bangalore.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Assignment
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The licence granted hereunder to You shall not be transferred or
            assigned by you.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You give your approval to Unlistedkart LLP for it to assign or
            transfer these Terms in whole or in part, including to
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>a subsidiary or an affiliate.</li>
              <li>
                an acquirer of Unlistedkart LLP’s equity, business, or assets;
                or
              </li>
              <li>a successor by merger.</li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            General
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            No joint venture, partnership, employment, or agency relationship
            exists between you, Unlistedkart LLP or any Third Party because of
            the contract between you and Unlistedkart LLP or the use of the App
            Services. If any provision of these Terms is held to be illegal,
            invalid, or unenforceable, in whole or in part, under any law, such
            provision or part thereof shall to that extent be deemed not to form
            part of these Terms but the legality, validity and enforceability of
            the other provisions in these Terms shall not be affected. In that
            event, the parties shall replace the illegal, invalid, or
            unenforceable provision or part thereof with a provision or part
            thereof that is legal, valid, and enforceable and that has, to the
            greatest extent possible, a similar effect as the illegal, invalid,
            or unenforceable provision or part thereof, given the contents and
            purpose of these Terms. These Terms constitute the entire agreement
            and understanding of the parties with respect to its subject matter
            and replaces and supersedes all prior or contemporaneous agreements
            or undertakings regarding such subject matter. In these Terms, the
            words “including” and “include” mean “including, but not limited
            to.”
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <b>UNLISTEDKART</b> (the "Company," or "we") is a service identity.
            Research consulting and services firm and are offered through
            dedicated legal entities and broker-dealers. None of the information
            displayed on or downloadable from http://unlistedkart.com/ (the
            "Website") represents an offer to buy or sell or a solicitation of
            an offer to buy or sell any security. This Website is a publication
            and intellectual property of <b>UNLISTEDKART</b>. All content is
            believed to be factual, accurate, and up to date, but we cannot and
            do not guarantee its accuracy and it should not be regarded as a
            complete analysis of the subjects discussed. All expressions of
            opinions reflect the judgment of the authors of such opinion as of
            the date of publication and are subject to change. Information on
            our Website does not involve the rendering of personalized
            investment advice but is limited to the dissemination of general
            information on products and services. A professional adviser should
            be consulted before implementing any of the options presented.
            Registered representatives of <b>UNLISTEDKART</b> do not (1) advise
            or assist in the determination of fair value of any security or
            investment, or (2) provide legal or tax services. Always consult an
            attorney or tax professional regarding your specific legal or tax
            situation.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Past performance may not be an indicator of future results. Hence,
            you should assume that the future performance of any specific
            investment, investment strategy (including the investments and/or
            investment strategies recommended by the adviser), or product
            referred to directly or indirectly on this Website, or indirectly
            via hyperlink to any unaffiliated third-party website, will be
            profitable or equal to past performance levels.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            All investment strategies have the potential for profit or loss.
            Different types of investments involve varying degrees of risk, and
            there can be no assurance that any specific investment will either
            be suitable or profitable for your investment portfolio. Information
            regarding companies in our portfolio are available on the Website
            has been collected from or generated from publicly available
            sources. The availability of company information does not indicate
            that such company has endorsed, supports, or otherwise participates
            with <b>UNLISTEDKART</b>. Company "Research Reports" or "Liquidity
            Watchlists" are the opinions of <b>UNLISTEDKART</b> and or the
            authors of the opinion as of the date of such opinion and are not
            recommendations to buy, sell, or hold any security of such company.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Please note that access to this Website is provided for
            informational purposes and for your convenience only and without any
            warranties, expressed or implied, regarding the accuracy,
            completeness, timeliness, or results obtained from any information
            posted to this Website or any third party website linked to this
            Website. <b>UNLISTEDKART</b> assumes no liability or responsibility
            for any errors or omissions in the content of this Website. Under no
            circumstances and under no legal theory shall <b>UNLISTEDKART</b> be
            liable to a viewer of this Website or any other person for any
            damages, whether direct, indirect, incidental, consequential or
            special arising from viewer's access to or use of this Website. All
            trademarks on this Website including but not limited to "{" "}
            <b>UNLISTEDKART</b>'," <b>UNLISTEDKART</b>'s logo. All other
            trademarks are the property of their respective owners.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Contact us
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If you have any concerns, complaints or grievances about the terms
            or other users or their manner of use of the application infringe or
            violate your rights or any complaints about how we handle your
            personal information, please feel free to contact the Grievance
            Officer any time at:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Email:{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>
          </Typography>
          <Typography variant="h6" className={classes.typoSubHeader}>
            SPECIAL TERMS AND CONDITIONS FOR RESEARCH REPORTS
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            These terms and conditions are specific to your access and
            participation in the buying research reports introduced by
            Unlistedkart LLP (‘UNLISTEDKART’). By accessing or purchasing
            research reports (hereinafter referred to as ‘Research reports’,
            ‘Services’), you agree to the following terms and conditions and
            give your explicit consent after having read the terms in their
            entirety.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            These terms and conditions are applicable to you in addition to the
            earlier Terms you have agreed for the use of other services provided
            by UNLISTEDKART. In case of any discrepancy or dispute between these
            terms and the Terms already agreed upon, these terms will prevail
            insofar as the User is using the Services as defined hereinabove
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Please read the terms below carefully and make sure you understand
            them fully before using the Services. Kindly note that if you do not
            accept these terms, you will not be able to use the Services.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Research reports is a curated list of companies’ neutral reports
            prepared from publicly available/disclosed information from the
            companies. UNLISTEDKART acts as a marketplace for third party
            sellers to list their reports on the UNLISTEDKART Store offering
            standard personalized reports for Users. For the avoidance of doubt,
            it is clarified that UNLISTEDKART does not maintain an inventory of
            the reports of companies listed on Research reports section and only
            provides an e-commerce marketplace platform to third party partners
            for listing such reports.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Use of Research reports
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                For purchasing reports from the list of companies, the User may
                pay the full price as listed for such report; through the
                preferred payment methods as made available on the research
                report section from time to time.
              </li>
              <li>
                For the avoidance of doubt, it is hereby clarified that:
                <br />
                <ul className={classes.ulClass}>
                  <li>
                    UNLISTEDKART shall not be liable for any discrepancies in
                    the reports sold by third party partners. We recommend each
                    User to verify the validity and usefulness of each research
                    report, using the overview option before purchasing the
                    same.
                  </li>
                  <li>
                    UNLISTEDKART shall not be liable for any claims arising in
                    relation to the delivery of the reports or the quality of
                    the reports purchased by the User.
                  </li>
                  <li>
                    The return, replacement and/ refund in relation to such
                    reports shall be dealt with in the manner as provided in
                    Unlistedkart’s policies, as available on Unlistedkart’ s
                    Platform; and
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Pricing and Availability
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We list availability information for reports sold by us on the App.
            Beyond what we say on that page or otherwise on the website, we
            cannot be more specific about availability. As we process your
            order, you will be informed by e-mail if any products you order turn
            out to be unavailable.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            All prices are listed in Rupees. "Price", as displayed, is inclusive
            of all taxes at the appropriate rate. It is also possible that an
            item's price may decrease between the time you place it in your
            basket and the time you purchase it. All prices are inclusive of
            VAT/CST, service tax, Goods and Services Tax ("GST"), duties and
            cesses as applicable - unless stated otherwise.
          </Typography>
          <Typography variant="h6" className={classes.typoSubHeader}>
            TRANSACTION and USER VERIFICATION POLICY
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            This Transaction and User Verification Policy ("TandU Policy") is
            testimony to the Company’s commitment to maintaining a clean system
            and preventing use of UNLISTEDKART services for illegal activities.
            It describes the Company's policies and procedures instituted to
            ensure that the Services offered by the Company are not being used
            by the Users to facilitate commission of any criminal offences,
            including but not limited to those under any applicable money
            laundering laws together with the rules issued thereunder and the
            rules and regulations prescribed by the regulators. The Company has
            prepared this TandU Policy to ensure the transparency of trading and
            to ensure the prevention of money laundering and other illegal
            activities.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The terms "We", "Our", "Company" and "Us" refer to the Company, and
            the terms "User", "You" and "“Your" refer to a User of our Online
            Platforms.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            This TandU Policy applies uniformly to any User desirous of availing
            the Services or otherwise using or benefitting from the use of the
            Online Platforms and may be read as a part of the User Terms and
            Conditions. It is imperative that you read this TandU Policy before
            using the Online Platforms or submitting any personal information.
            By using the Online Platforms, you are expressly consenting to be
            bound by the User Terms and Conditions and consequently this TandU
            Policy.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The Company may change and update this TandU Policy from time to time.
            Such changes may be made without prior notice, but any changes will
            only apply to activities and information on a going forward, not
            retroactive basis. You are encouraged to review this TandU Policy
            whenever you access the Online Platforms.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                <Typography variant="subtitle1" className={classes.typoHeader}>
                  Your Obligations.
                </Typography>
                <ul className={classes.ulClass}>
                  <li>
                    You acknowledge that it is your duty to ensure compliance
                    with the terms and conditions described in this TandU Policy
                    and accord your consent to not using the Services and the
                    Online Platforms in any manner which results in
                    committing/attempting to commit any criminal offences. You
                    also agree and consent to any changes made to this Privacy
                    Policy in due course and without notice.
                  </li>
                  <li>
                    You must ensure that any personal information and/or
                    Identification Documents submitted by you belong to you.
                  </li>
                  <li>
                    In case you are acting on behalf of a juridical person, you
                    must identify the Beneficial Owner and assist in
                    verification of the identity of such Beneficial Owner and
                    any individual who purports to act on behalf of such
                    juridical person.
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle1" className={classes.typoHeader}>
                  Purpose of this policy
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  To mitigate its risks relating to money laundering and other
                  illegal activities, the Company intends to put in place this
                  policy which has the following elements:
                </Typography>
                <ul className={classes.ulClass}>
                  <li>
                    <Typography
                      variant="subtitle1"
                      className={classes.typoHeader}
                    >
                      Customer Acceptance Terms.
                    </Typography>
                    <ul className={classes.ulClass}>
                      <li>
                        <Typography
                          variant="subtitle1"
                          className={classes.typoSubHeader}
                        >
                          The Company may either at the time of opening the User
                          Account, or while undertaking any transactions, or
                          during Periodic Updates, or for any other reason,
                          ensure your compliance with the following:
                        </Typography>
                        <ul className={classes.ulClass}>
                          <li>
                            Require that you undergo a verification process
                            during the activation process of your User Account
                            by submitting your Identification Documents and such
                            other details, as mandated under Clause 4 (Customer
                            Verification Procedure) of this TandU Policy.
                          </li>
                          <li>
                            Require you to furnish such other details as may be
                            deemed necessary by the Company to verify your
                            identity.
                          </li>
                          <li>
                            Require you to submit such additional information
                            and/or data as may be directed by a competent
                            enforcement authority.
                          </li>
                          <li>
                            Require you to certify that your Linked Bank Account
                            is held only with a scheduled commercial bank
                            compliant with all Know Your Customer (KYC)
                            procedures mandated under the applicable laws.
                          </li>
                          <li>
                            Require you to do a digital/video KYC periodically
                            every year.
                          </li>
                        </ul>
                      </li>
                      <li>
                        The Company may, in its sole discretion, refuse to open
                        any new accounts, terminate existing User Accounts after
                        giving due notice, or refuse to process any transactions
                        on the Online Platforms if it is unable to ensure
                        compliance with any of the conditions, either due to
                        non- cooperation by the User or due to the details
                        provided by the User being unreliable or unverifiable to
                        the Company’s satisfaction.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography
                      variant="subtitle1"
                      className={classes.typoSubHeader}
                    >
                      Customer Verification Procedure.
                    </Typography>
                    <ul className={classes.ulClass}>
                      <li>
                        The Company, during activation of User Accounts or while
                        undertaking any transactions or for any other reason,
                        may require for the purposes of verification of any
                        User’s identity – one copy of any Identification
                        Document containing their identity and address details;
                        one recent photograph; any other documents pertaining to
                        business/financial status of such individual as may be
                        prescribed by the Company from time to time.
                      </li>
                      <li>
                        For the purposes of verification of any User’s identity,
                        the Company may rely on appropriate and licensed
                        third-party service providers (such as KYC validators,
                        IT dept) to authenticate the Identification Documents
                        and other incidental details provided by the User.
                      </li>
                      <li>
                        If the Company finds any User information obtained in
                        accordance with the procedure described under this
                        Clause to be inadequate or insufficient, the Company may
                        in its discretion either refuse or terminate (as the
                        case may be) the registration of such User Account or
                        require verification of such User’s Identification
                        Documents again.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography
                      variant="subtitle1"
                      className={classes.typoSubHeader}
                    >
                      Transaction Monitoring Terms.
                    </Typography>
                    <ul className={classes.ulClass}>
                      <li>
                        All transactions executed and/or attempted to be
                        executed through the UNLISTEDKART App are regularly
                        monitored by the Company, to promptly identify and
                        highlight certain kinds of transactions which are deemed
                        suspicious by the Company.
                      </li>
                      <li>
                        The Company may, from time to time, undertake necessary
                        investigation to identify and examine transactions
                        inconsistent with any User’s risk profile,
                        sophistication, and expected usage pattern.
                      </li>
                      <li>
                        The extent of monitoring shall depend on various factors
                        including upon each User’s risk profile.
                      </li>
                      <li>
                        <Typography variant="body2" className={classes.typo}>
                          In case the Company requires, it can take any of the
                          following steps to regulate the use of the
                          UNLISTEDKART App by any User:
                        </Typography>
                        <ul className={classes.ulClass}>
                          <li>
                            <b>Negative Name Checks</b>: Name checks to ensure
                            that the user does not transfer the funds to his own
                            account.
                          </li>
                          <li>
                            <b>PAN Check</b>: For each transaction, Company may
                            require the user to enter the PAN Card of the person
                            to whom the payment is being made.
                          </li>
                          <li>
                            The Company may also put transaction limits meaning
                            each account may have a limit on the total number of
                            transactions and/or the size of the transaction.
                            These limits may be finalised on a per transaction
                            basis as well as a monthly basis.
                          </li>
                          <li>
                            In addition to the above, Company may also limit the
                            total number of recipients per user.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="subtitle1" className={classes.typoHeader}>
                  MAINTENANCE OF RECORDS.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  The Company will maintain and preserve the following
                  information and/or data:
                </Typography>
                <ul className={classes.ulClass}>
                  <li>
                    Records of all such suspicious transactions executed through
                    the UNLISTEDKART App, for a period of at least 5 (Five)
                    years from the date of each transaction.
                  </li>
                  <li>
                    Records of all and any suspicious transactions identified
                    under Clause 5 (Transaction Monitoring Terms) above for a
                    period of at least 5 (Five) years, including but not limited
                    to the information about the nature, value and parties to
                    such transactions, and their date of remittance.
                  </li>
                  <li>
                    Identification records of Users, during the subsistence of
                    and for a period of at least 7 (Seven) years from the date
                    of termination of such User Account.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
         </Grid>
         <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={navigateFunction}>
                Close
            </Button>
           </Box>
        </Box>
    </div>
  );
}

export default TermsConditions;
