import React, {useState,useEffect} from 'react'
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "../../Images/success.svg";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    helpertextContent: {
      color: "#fff !important",
      fontSize: "10px !important",
      marginLeft: "4px !important",
      fontFamily:"Poppins !important",
      fontWeight:"400 !important",
      lineHeight:"17px !important"
    },
    box:{
        display: "flex !important",
        height: "100% !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        transition:"display 500ms"
    },
    icon:{
        color: "#fff",
        fontSize: "14px !important" 
    }
  }));

const Notification = ({snackbarTitle,open,setOpen,type}) => {
  const classes = useStyles();

 
  return (
    open && 
     <div style={{ background: type === "success" ? "#61AE3D"  : type === "error" ? "#DD4A47" : "#6D50CC", height: "1.5rem" }}>
      <div className={classes.box}>
     { type === "success" ?
       <img src={CheckCircleIcon}
       alt="verified"
       width="14"
       height="14"
       />: 
     <InfoIcon className={classes.icon} />}
      <Typography className={classes.helpertextContent}>
       {snackbarTitle}
      </Typography>
    </div>
  </div>
  )
}

export default Notification