import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import bulb from "../../Images/bulb.svg";
import ActiveBulb from "../../Images/ActiveBulb.svg";
import ActiveLike from "../../Images/ActiveLike.svg";
import ActiveHeart from "../../Images/ActiveHeart.svg";
import like from "../../Images/like.svg";
import heart from "../../Images/heart.svg";
import NewsSkeleton from "../../component/Skeleton/NewsSkeleton";
import { rateAlert, setRequestID } from "../../store/bugs";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { NewsServices } from "../../_services/insights";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';
import Lottie from 'react-lottie';
import LikeJson from './lotties/like.json';
import BulbJson from './lotties/bulb.json';
import LoveJson from './lotties/love.json';
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
  },

  center: {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "16px  !important",
    color: "#5F6060 !important",
    lineHeight: "24px !important",
    textAlign: "center !important"
  },
  reactionText: {
    color: "#fff !important",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    lineHeight: "15px !important",
    transition: "all !important"
  },
  title: {
    marginLeft: "1rem  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "14px  !important",
    lineHeight: "17.5px !important",
    color: "#fff !important",
    height:"52px !important",
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    marginLeft: "1rem  !important",
    marginTop: "8px",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    lineHeight: "15px !important",
    color: "#5F6060 !important",
  },
  rate: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
  },
  count: {
    fontFamily: 'Poppins  !important',
    fontStyle: 'normal  !important',
    fontWeight: '400  !important',
    fontSize: '10px  !important',
    lineHeight: '15px  !important',
    color: '#FFFFFF  !important',
    display: "flex !important",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  loader: {
    color: "white !important",
    width: "15px !important",
    height: "15px !important",
  },
  bottom:{
    display: "flex !important", 
    justifyContent: "space-between !important",
     marginTop: '5px !important' 
  }
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    margin:"0 0 -2px 6px !important",
  },

}));

function News(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [expand, setExpand] = useState(false);
  const [reaction, setReaction] = useState(false);
  const [id, setId] = useState();
  const userID = props.authReducer &&
    props.authReducer.auth &&
    props.authReducer.auth.user &&
    props.authReducer.auth.user.id;

  useEffect(() => {
    NewsApi();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (newsId) => {
    if(id ===newsId && open){
      setOpen(false); 
    }
    else{
      setOpen(true);
      setId(newsId)
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: reaction === 1 ? BulbJson : reaction === 2 ? LoveJson : reaction === 3 ? LikeJson : null,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const NewsApi = () => {
    const data = {
      userId: userID
    }
    NewsServices.getNews(data, props.securityId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setTimeout(() => {
            setReaction(false)
            setOpen(false)
              // setId(null)
              if (res.data) {
                const sortData = res.data.sort((a, b) =>
                a.published_at < b.published_at ? 1 : -1
                );
                setNewsList(sortData);
                setSkeletonLoader(false);
              }
            }, 300)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const updateReaction = (reactId, isDelete, newsId, reactionId) => {
    const data = {
      related_security_id: props.securityId,
      related_user_id: userID,
      deleteReaction: isDelete,
      reaction_type_id: reactId,
      related_news_id: parseInt(newsId)
    }
    const id = reactionId ? reactionId[0].id : null
     setReaction(reactId)
     !reaction && NewsServices.postNewsReaction(id, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            NewsApi()
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const NavigatetoNews = ((id,) => {
    navigate('/newinfo', {
      replace: true,
      state: { newsId: id, userId: userID, securityId: props.securityId },
    })
  })

  return (
    <div
      style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
    >
      {skeletonLoader ? (
        <>
          {[1, 2, 3, 4, 5, 6].map((n) => (
            <NewsSkeleton key={n} />
          ))}
        </>
      ) : (
        (newsList && newsList.length >= 1) ?
          (
            newsList.map((item) => {
              return (
                <div>
                  <Box style={{ margin: "28px 16px 4px 16px" }}
                  >
                   <Grid container>
                   <Grid item xs={12} md={12} style={{display:"flex"}}>
                      <Grid onClick={() => NavigatetoNews(item.id)}>
                        <img
                          className={classes.card}
                          src={item.news_image_url !== "" ? item.news_image_url : ScriptLogo}
                          width={122}
                          height={87}
                          alt={item.id}
                        />
                      </Grid>
                      <Grid style={{width:"calc(100% - 122px)"}}>
                        <Typography className={classes.title} onClick={() => NavigatetoNews(item.id)}>
                          {item.short_description}
                        </Typography>
                        <hr
                          style={{
                            margin: "7px 0 5px 15px ",
                            backgroundColor: "#1c1c1c",
                            border: "none",
                            height: "2px",
                          }}
                        />

                        <div className={classes.bottom}>
                          <Typography className={classes.subtitle} style={{ fontFamily: "Poppins" }}>
                            {moment(item.news_picked_date).format("MMM DD, YY")}
                          </Typography>
                          <Grid style={{ textAlign: 'center', minHeight: '37px' }} >
                            <HtmlTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              TransitionComponent={Zoom}
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <React.Fragment>

                                  {item.id === id && <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding:"2px",
                                      marginTop: item.id === id && (item.heart_reaction || item.like_reaction || item.bulb_reaction) ? "0px" : "",
                                      background: (expand && item.id == id) && "#1C1C1C",
                                      borderRadius: "10px", transition: "all 500ms"
                                    }}
                                  >
                                  
                                        <Box onClick={() => id === item.id &&  updateReaction(3, item.like_reaction ? 1 : 0, item.id, item.json_agg)}  style={{ minWidth: "34px", transition: "width 300ms,margin-left 300ms, margin-right 300ms" }}>
                                          {reaction !== 3 && <Typography className={classes.reactionText}>Like</Typography>}
                                          {reaction == 3 ? <Lottie
                                            options={defaultOptions}
                                            height={35}
                                            width={35}
                                          /> : item.like_reaction ? <img width={24} height={24} src={ActiveLike} alt={"like"} /> : <img width={24} height={24} src={like} alt={"rupees"} />}
                                        </Box>

                                        <Box onClick={() => id === item.id &&  updateReaction(1, item.bulb_reaction ? 1 : 0, item.id, item.json_agg)} style={{ minWidth: "57px", transition: "width 300ms, margin-left 300ms, margin-right 300ms" }}>
                                          {reaction !== 1 && <Typography className={classes.reactionText}>Informative</Typography>}
                                          {reaction == 1 ? <Lottie
                                            options={defaultOptions}
                                            height={35}
                                            width={35}
                                          /> : item.bulb_reaction ? <img width={24} height={24} src={ActiveBulb} alt={"bulb"} /> : <img width={24} height={24} src={bulb} alt={"bulb"} />}
                                        </Box>

                                        <Box onClick={() => id === item.id &&  updateReaction(2, item.heart_reaction ? 1 : 0, item.id, item.json_agg)}  style={{ minWidth: "34px", transition: "width 300ms, margin-left 300ms,margin-right 300ms" }} >
                                          {reaction !== 2 && <Typography className={classes.reactionText}>Love</Typography>}
                                          {reaction == 2 ? <Lottie
                                            options={defaultOptions}
                                            height={35}
                                            width={35}
                                          /> : item.heart_reaction ? <img width={24} height={24} src={ActiveHeart} alt={"heart"} /> : <img width={24} height={24} src={heart} alt={"heart"} />}
                                        </Box>
                                  </div>}
                                </React.Fragment>
                              }
                              placement="top"
                            >

                              <div
                                onClick={() => {
                                  handleTooltipOpen(item.id)
                                  setExpand(true)
                                  setReaction()
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent:'end',
                                  borderRadius: "10px", transition: "all 500ms"
                                }}
                              >
                               <Box>
                              <img width={24} height={24} src={ item.like_reaction || item.bulb_reaction ? heart : like} alt={"rupees"} />
                            </Box>
                            <Box >
                             <img width={24} height={24} src={ item.bulb_reaction ? like  : bulb} alt={"bulb"} style={{ marginLeft:  "-12px" }} />
                            </Box>
                            <Box>
                            {item.like_reaction || item.bulb_reaction || item.heart_reaction ? <img width={24} height={24} src={ item.like_reaction ? ActiveLike : item.bulb_reaction ? ActiveBulb : ActiveHeart } alt={"heart"} style={{ marginLeft: "-12px" }} /> :  <img width={24} height={24} src={heart} alt={"rupees"} style={{ marginLeft: "-12px" }}/>}
                            </Box>
                                <Typography ml={0.75} mb={0.5} className={classes.count}>{item.news_count}</Typography>
                              </div>
                            </HtmlTooltip>
                          </Grid>
                        </div>

                      </Grid>
                    </Grid>
                   </Grid>
                  </Box>
                  <hr
                    style={{
                      backgroundColor: "#1c1c1c",
                      border: "none",
                      height: "2px",
                      margin: 0, padding: 0
                    }}
                  />
                </div>
              );
            }
            )) : (
            <Grid className={classes.center}><Typography className={classes.text}>Oopsy, no news!</Typography></Grid>
          )
      )
      })
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

const dispatchToProps = (dispatch) => ({
  rateAlert: () => dispatch(rateAlert()),
  request_id: (req) => dispatch(setRequestID(req)),
});

export default connect(mapStateToProps, dispatchToProps)(News);
