import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { InputBase, IconButton, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../../src/index.css";
import Logo from "../../Images/filter.svg";
import { useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

const useStyles = makeStyles((theme) => ({
 
    searchField: {
        "& .MuiInputBase-input": {
          position: "relative",
          width: "100%",
          color: " #FFF !important",
          fontFamily: "Poppins  !important",
          fontStyle: "normal  !important",
          fontWeight: "400 !important",
          fontSize: "14px  !important",
          lineHeight: "16px  !important",
        },
        padding: "5px ",
        borderRadius: "10px",
        border: "solid 1px transparent",
        backgroundOrigin: "border-box !important",
        backgroundClip: "content-box, border-box",
        boxShadow: "2px 1000px 1px black inset",
        // height:'3.5rem !important',
        "&:hover": {
          backgroundColor: "#0d0d0d",
        },
        color: "#fff !important",
        backgroundImage:
          "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)",
        backgroundOrigin: "border-box",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 400 !important",
        fontSize: "16px !important",
        lineHeight: "16px !important",
        color: "#fff !important",
      },
}));

function Searh({ searchkey ,placeholder,type,text}) {
  
  const inputEl = useRef("");
  const classes = useStyles();
  const navigate = useNavigate();

  const getSearchterm = (e) => {

    searchkey(inputEl.current.value);
  };


  return (
      <InputBase
         type={type}
          className={classes.searchField}
          inputRef={inputEl}
          autoFocus={text && true}
          onChange={getSearchterm}
          fullWidth
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search',style: { textTransform:text === true? "uppercase":"none "} }}
          startAdornment={<SearchIcon sx={{ color: "#B9BABB", opacity: "1" , marginRight:'8px'}}/>}
        />
  );
}

export default Searh;
