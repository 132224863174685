
import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {fontFamily: 'Poppins !important',
  padding:"0px !important",
  fontStyle: "normal !important",
  fontWeight:" 500 !important",
  fontSize: "10px!important",
  lineHeight: "15px !important",
    borderRadius: "10px  !important",
    textTransform:"none !important",
    height: "27px !important",
    width:" 73px !important",
    margin:" auto !important",
    "&:disabled": {
      color: "#fff !important"
    }
  },

}));

function TextButton({Text,value,onclick,submit}) {
  const classes = useStyles();
  return <Button variant="contained" 
  disableRipple
  onClick={onclick} disabled={!value}
  style={!submit ?{
    backgroundImage:
      value == true
        ? "linear-gradient(#AB97EB , #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%)":
         " linear-gradient(#0D0D0D , #0D0D0D), linear-gradient(180deg, #0D0D0D 100%, #0D0D0D 100%) "
  } : {
    background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
            backgroundRepeat:"repeat-x",
            backgroundSize:"400% 100%",
            WebkitAnimation:"AnimationName 6s linear infinite",
            MozAnimation:"AnimationName 6s linear infinite",
            animation:"AnimationName 6s linear infinite"
  }}
  className={classes.button}>{Text}</Button>;
}

export default TextButton;
