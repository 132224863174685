import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "30px",
    borderRadius: "5px !important",
    padding: "4px 15px !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#8264E5 !important",
      boxShadow: "none !important",
    },
    "&:disabled": {
      backgroundColor: "#323333 !important",
      color: "#fff !important",
    },
    marginTop: "12px !important",
    marginRight: "10px !important",
    textTransform: "none !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: "12px !important",
    lineHeight: "22px !important",
    color: "#fff !important",
  },
}));

function OtpButton({ value, handleOtp, disabled }) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      onClick={handleOtp}
      disableRipple
      disabled={disabled}
      className={classes.button}
      elevation={0}
      style={{
        backgroundColor: disabled == true ? "#323333 " : "#8264E5 "
      }}
    >
      {value}
    </Button>
  );
}

export default OtpButton;
