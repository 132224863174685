import axios from "axios";
import { helpers } from "../_helpers/index";
import { kycService } from "./kyc";

// const settings = require("../settings");

// const apiEndpoint = settings.ApiEndPoint;
 
 

const pennyDropVerification = async (formData,user_id) => {
  try {
     helpers.setHeader();
    return axios
      .post(`/pennyDropverification`, {
        userId: user_id,
        accountNo: formData.userAccountNo,
        ifcsCode:formData.ifscCode,
        accountHolderName:formData.userName,
        from:'user'
      })
       .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  } catch (error) {}
};
const idVerification = async (formData,userID) => {
     let request_id= localStorage.getItem('requstID')
    if(request_id==''){
     let kycData = await kycService.getKycInfo()
        request_id = kycData.digioResponse[0].request_id;
     }
    formData.append("request_id", request_id);
    
  try {
    const from = 'user'
    helpers.setHeader();
    return axios
      .post(`/uploadKycDocs/?user_id=${userID}&from=${from}`, formData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  } catch (error) {}
  return true;
};
const getVerificationStatus = (userID) => {
  try {
    helpers.setHeader();
    return axios
      .get(`/userUploadedDocs/?user_id=${userID}`, {
        userId: userID,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  } catch (error) {}
};

const  updateKYCStatus = (data) => {
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/update_user_kyc_status`, data)
      .then(res => res)
      .catch(error => error.response)
}

const getAllNeededDouments = () => {
  try {
    helpers.setHeader();
    return axios
      .get(`/getAllDocumentsInTemplate`, {
        userId: 9711,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  } catch (error) {}
};
const getUserToken = ()=>{
  return helpers.getToken()
}
export const digioVerificationService = {
  idVerification,
  pennyDropVerification,
  getVerificationStatus,
  getAllNeededDouments,
  updateKYCStatus,
  getUserToken
};
