import React from "react";
import { Grid, Box, } from "@mui/material";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import Unlistedkart from "../../Images/Unlistedkart.svg";
const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        display: "grid",
        padding: "0 0px !important",
        minHeight: "100vh ",
        backgroundImage:
        "linear-gradient(#1E153A, #40306F), linear-gradient(180deg, #1E153A 0%, #40306F 100%) !important",
    },

    center: {
        margin: " auto",
        textAlign: "center",
    },

}));

const Splash  = () => {
    const classes = useStyles();


    return (
        <>
            <Page>
                <Box className={classes.boxcontainer}>


                    <Grid className={classes.center}  >

                        <img src={Unlistedkart} style={{ height: "100%", width: "100%", marginBottom: "20px" }} />

                        

                    </Grid>
                </Box>

            </Page>
        </>
    );
};

export default Splash;
