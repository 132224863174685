import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { allSector } from "../../_services/getAllSector";
import { Context } from '../../store/store';

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight:"100vh !important",
    overflow:"inherit !important",
    "& .MuiTab-root ": {
      color: "white !important",
      width: "168px !important",
     height: "47px !important",
    },
    "& .Mui-selected": {
      color: "#A489FF !important",
      fontWeight:'400 !important',
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  mainTab: {
    fontFamily: "Poppins  !important",
    alignItems:"start !important",
    textTransform:"none !important",
    fontWeight:'500 !important',
    fontSize: "14px !important",
    padding: " 0px !important",
    borderBottom: "1px solid #1C1C1C !important",
    paddingLeft:"1rem !important"
  },
  tab: {
    alignItems:"start !important",
    textTransform:"none !important",
    fontWeight:'500 ',
    fontSize: "14px !important",
    padding: "5px 0px 5px 16px !important",
  },
  notchedOutline: {
    border: "none !important",
    color: "#fff !important",
    borderBottom: "1px solid white !important",
    borderRadius: "0px !imortant",
  },
  tabpannel: {
    width:"100vw",
    color: "#fff !important",
    padding: " 0px !important",
    "& .MuiBox-root": {
      padding: "0px !important",
 
    },
  },
  grid:{
    minHeight:"47px",
    display: "flex",
    alignItems: "center",
    marginLeft:"19px",
    borderBottom: "1px solid #1C1C1C !important",
  
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function FilterTab({sector,setSector, setInitialValue,setSortValue,sortValue}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedSector, setSelectedSector] = useState([]);
  const [state, dispatch] = React.useContext(Context);
  const marketCap =[
  {
    label:"Small Cap",
    value:"1"
  },
  {
    label:"Middle Cap",
    value:"2"
  },
  {
    label:"Large Cap",
    value:"3"
  }]
  
  const selectItem = (item) => {
    setSelectedSector([...selectedSector, item.description]);
 
  };
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // get all sector types for filtering
  useEffect(() => {
   if(state.sector === null){
    allSector.getAllSector().then((res) => {
      if (res) {
        if (res.status === 200) {
          const newArray = res.data.data.map((data) =>({...data, isActive: false}))
          setSector(newArray);
          setInitialValue(newArray)
        }
      }
    });
   }else{
    setSector(state.sector)
   }
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "#0d0d0d",
        display: "flex",
        border: 2,
        borderColor: "#1C1C1C",
        borderBottom: "none",
        paddingBottom:"6rem"      
      }}
    >
      <Tabs
        className={classes.tabs}
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 2, borderColor: "#1C1C1C" }}
      >
        <Tab label="Sector" {...a11yProps(0)} className={classes.mainTab}/>
        <Tab label="Market Cap" {...a11yProps(1)} className={classes.mainTab}/>
      </Tabs>
      <TabPanel className={classes.tabpannel} value={value} index={0}>
        <>
          {sector ? (
            sector.map((item) => {
              return (
                <Grid
                  className={classes.grid}
                  key={item.id}
                  onClick={() => {
                    selectItem(item);
                    if(item.isActive === false){
                      setSector(current =>
                        current.map(obj => {
                          if (obj.id === item.id) {
                            return {...obj, isActive:true};
                          }
                          return obj;
                        }),
                      );
                    }else{
                      setSector(current =>
                        current.map(obj => {
                          if (obj.id === item.id) {
                            return {...obj, isActive:false};
                          }
                          return obj;
                        }),
                      );
                    }
                  }}
                >
                  <>
                  <CheckIcon sx={{  color : item.isActive ? "#AC93FF" : "#fff" }} />
                    <Typography
                      className={classes.tab}
                      variant="h6"
                      style={{
                        color : item.isActive ? "#AC93FF" : "#fff",
                        fontWeight: item.isActive ?  "500" :"400",
                        fontSize: "16px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </>
                </Grid>
              );
            })
          ) : (
           <Typography style={{color:'#fff'}}>
            Loading...
           </Typography>
          )}
        </>
      </TabPanel>
      <TabPanel className={classes.tabpannel} value={value} index={1}>
      <>
         {marketCap.map((item)=>{
         return (
            <Grid className={classes.grid}
          fullWidth
          onClick={()=>{
            if(sortValue ===item.value){
              setSortValue()
            }else{
              setSortValue(item.value)
            }
          }} >
           <CheckIcon sx={{  color: sortValue===item.value ? "#AC93FF" : "#fff" }} />
            <Typography
              className={classes.tab}
              variant="h6"
              style={{ color: sortValue===item.value ? "#A489FF" : "#fff", fontWeight: "400", fontSize: "16px" }}
            >
              {item.label}
            </Typography>
          </Grid>
          )
         }
          
          
         )}
        </>
      </TabPanel>
     
    </Box>
  );
}

export default FilterTab;
