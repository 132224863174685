import React, {useEffect} from "react";
import { Grid, Typography, Box, } from "@mui/material";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
// import internet from "./../Overview/lotties/cart.json";
import Lottie from 'react-lottie';
import Cart from "./../Overview/lotties/cart.json";
import { orderService } from "../../_services/orders";
import { razorPayIntegration } from "../../_services/razorPayIntegration";
import { useLocation, useNavigate } from "react-router";
const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        display: "grid",
        padding: "0 0px !important",
        minHeight: "100vh ",
        background: "#0D0D0D !important",
    },

    center: {
        margin: " auto",
        textAlign: "center",
    },
    heading: {
        fontStyle: "normal  !important",
        color: "#fff  !important",
        fontWeight: "500  !important",
        fontSize: "18px  !important",
        fontFamily: "poppins  !important",
        lineHeight: "27px  !important",
    },
    text: {
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "400  !important",
        fontSize: "12px  !important",
        color: "#767677  !important",
        lineHeight: "18px !important",
    },
}));

const Loading  = () => {
    const classes = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key =searchParams.get('data')
    const navigate = useNavigate()
    useEffect(()=>{
     if(key){
      const data = {
        paymentid:key
      }
      razorPayIntegration.getPaymentStatus(data).then((res)=>{
        console.log(res)
        if(res.data.message.data.status === 'completed' || res.data.message.data.status === 'awaiting settlement'){
            const orderData = {
                order_id:key
              }
               orderService.createServiceOrderForVouch(orderData).then((res)=>{
                console.log(res)
                if(res.data.success){
                    navigate("/payment-status", {
                      replace: true,
                      state: { orderData: res.data.service_id,data:{razorpay_order_id:key}, payment_status: "SUCCESS"}
                     });
                }
            })
          }else{
            navigate("/payment-status", {
              replace: true,
              state: { data: data, payment_status: "FAIL"}
             });
          }
        })
  }
      },[])
      
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Cart,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <>
            <Page>
                <Box className={classes.boxcontainer}>


                    <Grid className={classes.center}  >
                    <Lottie 
                     style={{ 
                        width:"60px",
                        height:"60x",
                        marginLeft:"5rem"
                        }}
                              options={defaultOptions}
                              />

                        <Typography
                        mt={1}
                            className={classes.heading}
                        >
                            Please wait..
                        </Typography>
                        <Typography className={classes.text} mt={0.2}>

                            Do not go back or close the application.
                        </Typography>


                    </Grid>
                </Box>

            </Page>
        </>
    );
};

export default Loading;