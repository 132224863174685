import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextButton from "../../component/Home/TextButton";
import { buyUnlistedShareService } from "../../_services/buyUnlistedShare";
import ScriptLogo from "../../Images/home/scrip_logo.svg";
import CardSkeleton from "../../component/Skeleton/CardSkeleton";
import { rateAlert, setRequestID } from "../../store/bugs";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: "94px !important",
    minHeight: "94px !important",
    padding: "14px 16px !important",
    borderRadius: "10px !important",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important", 
    backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #1C1C1C inset  !important",
  },
  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight:"19px !important",
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "11px  !important",
    color: "#AC93FF !important",
    lineHeight:"15px !important",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:"nowrap", 
    maxWidth: '90%',
    textTransform: "uppercase",

  },
  rate: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "12px  !important",
    color: "#fff !important",
  },
  value: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "10px  !important",
    color: "#7BDE89 !important",
  },
  loader:{
    color: "white !important" ,
    width:'15px !important',
    height:'15px !important',
   },
   padding: {
    "& .MuiBox-root": {
      padding: "0px  !important ",
    },
  }, 
  center :{
    position: "absolute",
    top: "53%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    },
  text: {
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400  !important",
      fontSize: "16px  !important",
      color: "#5F6060 !important",
      lineHeight: "24px !important",
      textAlign:"center !important"
    },
    verticalFlex:{
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "space-between",
      justifyContent: "center",
    
    }
}));

function OutOfStockSlide(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [shareValues, setShareValues] = useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const userID = props.authReducer &&
  props.authReducer.auth &&
  props.authReducer.auth.user &&
  props.authReducer.auth.user.id;
  

// Update notify for out of stock scrips
  const setNotify = (id) => {
    setId(id)
    setLoading(true)
      const data ={
        related_security_id:id,
        related_user_id:userID
      }
    buyUnlistedShareService.updateSecurityNotify(data).then((res) => {
      setLoading(false)
      if (res) {
        if (res.status === 200) {
          setSkeletonLoader(false);
          outOfStockApi()
        }else if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setOpenSessionExpiredModal(true)
        }
      }
    });
  };


  useEffect(() => {
    outOfStockApi()
    const element = document.getElementById("content");
    element.scrollIntoView();
  }, []);

  // get out of stock scrips
  const outOfStockApi =()=>{
    buyUnlistedShareService.getUnlistedShare(userID,2).then((res) => {
      if (res) {
        if (res.status === 200) {
          setShareValues(res.data);
          setSkeletonLoader(false);
        }
      }
    });
  }
  return (
<div
id="content"
style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
>
{skeletonLoader ?
    <>
      {[1, 2, 3, 4, 5,6,7,8,9,10].map(n => (
        <CardSkeleton key={n} />
    ))}
    </>
      :
      shareValues &&
  shareValues.map((item) => {
    return (
      <div className={classes.padding}  key={item.id}  >
      <Box sx={{ m: 0,mb:2}} className={classes.padding}   >
          <Grid container className={classes.card}>
            <Grid
              item
              xs={3}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                 <img
              style={{ height: "63px", width: "76px" }}
              src={
                item.logo_url !== null
                  ? item.logo_url
                  : ScriptLogo
              }
              alt="logo"
            />
            </Grid>
            <Grid item xs={9} md={9} pl={2} className={classes.verticalFlex}>
            <Grid item xs={12} md={12} sx={{display:"flex"}}>
              <Grid xs={8} md={8} >
                {" "}
                <Typography className={classes.title}>
                  {item.name}
                </Typography>
              </Grid>
              <Grid xs={4} md={4}  sx={{margin:"auto"}}>
                {" "}
              
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} sx={{display:"flex"}}>
              <Grid xs={8} md={8} alignSelf="self-end">
                {" "}
                <Typography
                  variant="subtitle1"
                  className={classes.subtitle}
                >
                    {item.sector}
                </Typography>
              </Grid>
              <Grid
            xs={4}
            md={4}
            style={{ textAlign: "end" , marginTop: "-2px"}}
          >
         <TextButton
           Text={"Notify me"}
           onclick={()=> setNotify(item.id)}
           submit={id === item.id && loading}
           value={item.hassecuritynotify ===0 ? true : false}
          />
          </Grid>
              
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  })}
     <SessionExpiredModal
                open={openSessionExpiredModal}
            />
      { !skeletonLoader &&  shareValues && shareValues.length === 0 && 
                    <Grid className={classes.center}><Typography className={classes.text}>Oopsy, no results!</Typography></Grid>
                  }
</div>

 
  );
}
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

const dispatchToProps = (dispatch) => ({
  rateAlert: () => dispatch(rateAlert()),
  request_id: (req) => dispatch(setRequestID(req)),
});

export default connect(mapStateToProps, dispatchToProps)(OutOfStockSlide);
