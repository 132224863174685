import axios from 'axios'
import { helpers } from '../_helpers/index'
 
export const signDealtermService = {
    unlistedkartApiCall
};

async function unlistedkartApiCall(urlEndpoint,inputData,uploadRiskRealTermId,data="") {
    helpers.setHeader();
var config = {
    method: 'post',
    url: `/${urlEndpoint}`,
    data : {
        data:inputData,
        uploadRiskRealTerm:uploadRiskRealTermId,
        userdata:data
    }
  };
  
  return axios(config)
  .then((data) => {
    return data
  })
  .catch((error) => error.response);
  
}

 
