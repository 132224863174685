import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#314b8f00",      
    display: "flex",
    marginRight: "8px",
    justifyContent: "center",
    alignItems: "center",
    margin:'auto',
    zIndex: 1201,
  },
}));

export default function CircularLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <CircularProgress 
         style={{color:"#fff", width:'25px',
         height:'25px'}}
         size={18}
         thickness={6}   
        />
    </div>
  );
}
