import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import Page from "../../component/Page/Page";
import Textfield from "../../component/DarkMode/Textfield";
import Buttons from "../../component/DarkMode/Button";
import HelperText from "../../component/DarkMode/HelperText";
import { RegisterService } from "../../_services/register";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../component/DarkMode/Header";
import Notification from "../../component/AlertModel/Notification";
import { loginService } from "../../_services/login";

const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    background: "#0d0d0d",
    position:'relative'
  },
  verifyText: {
    color: "white",
    left: "calc(50% - 173px/2 - 77.5px)",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
  },
  loader:{
    color: "white !important" ,
    width:'25px !important',
    height:'25px !important',
   }
}));



const SignupEmail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation()  

  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [helperText, setHelperText] = useState();
  const [type, setType] = useState();
  const [submit, setSubmit] = useState(false);
  const [showValidEmailText, setShowValidEmailText] = useState(location?.state?.email ? true :false);
  const [valueColor, setValueColor] = useState();
  const handleOnSubmit = (values) => {
    if(location?.state?.type === "login"){
      const data = {
        email: values.email,
      };
      loginService
      .emailAuthentication(data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            navigate("/otpemail", {
              replace: true,
              state: {
                email:values.email,
                type: "login",
              },
            });
          } else if (res.status === 401) {
            if (res.data === "REQUIRED_REGISTRATION") {
              setSeverity("error");
              setSnackbarTitle("Please enter a registered email");
              setSubmit(false);
              setOpen(true);
            } else {
              if (res.data.message === "ACCOUNT_BLOCKED") {
                setSeverity("error");
                let timerMsg =
                  "Account blocked, please try after " +
                  Math.abs(res.data.time.hour) +
                  " hours " +
                  Math.abs(res.data.time.minute) +
                  " minutes";
                setSnackbarTitle(timerMsg);
                setSubmit(false);
                setOpen(true);
              }
            }
          } else if (res.status === 400) {
            setSeverity("error");
            setSnackbarTitle("Invalid email");
            setSubmit(false);
            setOpen(true);
          } else if (res.status === 204) {
            setSubmit(false);
            setSeverity("error");
            setSnackbarTitle("Email is required");
            setOpen(true);
          } else if (res.status === 403) {
            setSubmit(false);
            setSeverity("error");
            setSnackbarTitle("You don't have access to login");
            setOpen(true);
          } else {
            setSubmit(false);
            setSeverity("error");
            setSnackbarTitle("Network error, please try later");
            setOpen(true);
          }
        } else {
          setSubmit(false);
          setSeverity("error");
          setSnackbarTitle("Network error, please try later");
          setOpen(true);
        }
      })
      .catch(() => {
        setSubmit(false);
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setOpen(true);
      });
    } else if (emailValidation(values.email) !== true) {
      setHelperText()
      setType()
    } else {
    setSubmit(true)
    if (emailValidation(values.email) == true) {
      const data = {
        email: values.email,
      };
      RegisterService.registerEmail(data).then((res) => {
        setSubmit(false)
        if (res.data.message === "OTP SENT SUCCESSSFULLY") {
            navigate("/otpemail", {
              replace: true,
              state: { email: values.email },
            });
        }else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" &&  res.data.data.isEmailVerified === true &&   res.data.data.isMobileVerified === false) {
          setSeverity("error");
          setOpen(true);
          setSnackbarTitle("Email already verified");  
          setTimeout(() => {
            navigate("/signupphone",{
              replace: true,
              state: { email: values.email, userId:res.data.data.created_user_id , type: "register"}
            });
          }, 2000);
        }else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" &&  res.data.data.isEmailVerified === true &&   res.data.data.isMobileVerified === true) {
            let email = res.data.data.email.replace(/^(.{2})[^@]+/, "$1*******");
            let str = res.data.data.mobileno
            const regex = /\d(?=(?:\D*\d){4})/g
            var mobile = str.replace(regex,"*")
            navigate("/signupphone", {
              replace: true,
              state:{type: "login", message:`${email} is linked to ${mobile.replace("+",'')}`},
            });
        } else if(res.data.message === "ACCOUNT_BLOCKED"){
          setValueColor(2)
          setType("error");
          setHelperText(`Account blocked`);

        }else {
          setSeverity("error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
        }
      }).catch(()=>{
        setSubmit(false)
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setOpen(true);
      })
  }
}
}

const emailValidation = (email) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) {
    setValueColor(1)
    return true;
  } else {
    setValueColor()
    return false;
  }
};

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
        <Notification  snackbarTitle={snackbarTitle} open={open} setOpen={setOpen} type={severity}/>
            <Header/>
          <Container >
            <Grid className={classes.container} spacing={1}>
              <Formik
                enableReinitialize={true}
                initialValues={{ email: location?.state?.email ? location.state.email : "" }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Enter a valid email")
                })}
                onSubmit={(values) => {
                  handleOnSubmit(values);
                }}
              >
                {({
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}  autoComplete="off">
                    <Grid>
                      <Typography className={classes.verifyText}>
                        Enter your email address
                      </Typography>
                    </Grid>
                    <Grid>
                      <Textfield
                        valueColor={valueColor}
                        name="email"
                        id="email"
                        type="email"
                        placeholder="xyz@email.com"
                        value={values.email}
                        onChange={(e) => {
                          setOpen(false)
                          setFieldValue("email", e.target.value.toLowerCase());
                          setValueColor()
                            if (emailValidation(e.target.value) == true) {
                              const middle = e.target.value.slice(
                                e.target.value.indexOf('@') + 1,
                                e.target.value.lastIndexOf('.'),
                              );
                              if(middle.toLowerCase() === "rediff"){
                              setValueColor(2)
                              setHelperText("Please use a different email address")
                              setType("error")
                              setShowValidEmailText(false);
                              }else{
                              setHelperText("Valid Email")
                              setType("success")
                              setShowValidEmailText(true);
                              }
                            } else {
                              setShowValidEmailText(false);
                              setHelperText("")
                            }
                        }}
                        helperText={
                          (touched.email || errors.email) && values.email
                            ? 3
                            : values.email
                            ? 1
                            : null
                        }
                      />
                    <HelperText helperText={helperText}  type={type}/>
                    </Grid>
                    <Buttons
                      submitButton={!showValidEmailText || submit}
                      // handleError={handleOnSubmit}
                      value1={values}
                      type="submit"
                      value= "SEND OTP"
                      animation={submit}
                    />
                  </form>
                )}
              </Formik>
            </Grid>
          </Container>
        </Box>
      </Page>
    </>
  );
};

export default SignupEmail;
