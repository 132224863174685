import axios from 'axios'
import { helpers } from '../_helpers/index'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const buyUnlistedShareService = {
    getBuyUnlistedShare,
    postBuyUnlistedShare,
    getUnlistedShare,
    updateSecurityInterested,
    updateSecurityNotify,
    checkBuyUnlistedShare
};


function getBuyUnlistedShare() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/buy_unlisted_shares`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postBuyUnlistedShare(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/buy_unlisted_shares`,JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function getUnlistedShare(userId,status,hasFilter,data,marketCap) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
     return axios.post(`/security_by_filter_and_sort?userId=${userId}&status=${status}&hasFilter=${hasFilter}&hasMarketSort=${marketCap}`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateSecurityInterested(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/setSecurityInterestData`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateSecurityNotify(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/setSecurityNotifyData`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function checkBuyUnlistedShare(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/check_user_details/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

