import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import placeholder from "../../Images/placeholder.svg";
import InputBase from "@mui/material/InputBase";
import BottomNavigation from "../../component/Home/BottomNavigation";
import SearchIcon from "@mui/icons-material/Search";
import { kycService } from "../../_services/kyc";
import Loader from "../../component/Loading/Loading";
import Search from "../../component/Kyc/Search";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    padding: "15px 16px  34px 16px !important",
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    // paddingBottom: "1rem !important",
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
    marginRight: "20px",
  },



  center: {
    position: "absolute",
    bottom: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    fontSize: "16px  !important",
    color: "#5F6060 !important",
    lineHeight: "24px !important",
    textAlign: "center !important"
  },

  texts: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#A489FF !important",
    lineHeight: "18px !important",
  },
  bankname: {
    textTransform: "capitalize",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "20px !important",
  },
}));

const Bank = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, serIsLoading] = useState(false);
  const [allBank, setAllBank] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchresult, setSearchresult] = useState([]);

  // get all bank names
  const allBanks = () => {
    kycService
      .getAllBanks()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAllBank(res.data.data);
            serIsLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              console.log(res.status);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    serIsLoading(true);
    allBanks();
  }, []);


  // search by bank name
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const key = "name";
      const filteredArr =allBank && allBank.filter(obj => obj[key].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      setSearchresult(filteredArr);
    } else {
      setSearchresult(allBank);
    }

  };

  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/status")}
                className={classes.icon}
              />
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Select your Bank
              </Typography>
            </Grid>
          </Grid>

          <Grid style={{
            padding: "0px 16px "
          }} >
            <Search searchkey={searchHandler} placeholder="Search by bank name" type='text' />

          </Grid>
          {isLoading ? (
            <Grid
              style={{
                padding: "24px"
              }}
            >
              <Loader />
            </Grid>
          ) : (
            <>
              {searchTerm.length < 1 &&
                <Box>
                  <Grid

                    container
                    flexDirection="column"
                    style={{
                      padding: "24px 16px 16px  16px"
                    }}
                  >
                    <Typography className={classes.texts}>Common Banks</Typography>
                  </Grid>

                  <Grid item pb={2}>
                    <hr
                      style={{
                        margin: "0px",
                        backgroundColor: "#323333",
                        border: "none",
                        height: "1px",
                      }}
                    />
                  </Grid>


                  {" "}
                  {allBank.map((ele) => {
                    if (ele.common_banks === true) {
                      return (
                        <>
                          <Grid
                            xs={12}
                            md={12}
                            key={ele.id}
                            // className={classes.container}
                            style={{
                              padding: "0 16px"
                            }}
                            container
                            flexDirection="row"
                            onClick={() =>
                              navigate("/branch", {
                                replace: true,
                                state: {
                                  bankId: ele.id,
                                  bankName: ele.name
                                },
                              })
                            }
                          >
                            <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center" }}>
                              {" "}
                              <img src={ele.logo_url} alt="banklogo" style={{ objectFit: "contain", width: "30px", height: "30px" }} />

                              <Typography className={classes.bankname} ml={3}>
                                {ele.name}
                              </Typography>

                            </Grid>
                          </Grid>
                          <hr
                            style={{
                              margin: "16px",
                              backgroundColor: "#1C1C1C",
                              border: "none",
                              height: "1px",
                            }}
                          />

                        </>
                      )
                    }
                  })}

                  <Grid
                    style={{
                      padding: "16px"
                    }}
                    container
                    flexDirection="column"
                  >
                    <Typography className={classes.texts}>All Banks</Typography>
                  </Grid>

                  <Grid item pb={2}>
                    <hr
                      style={{
                        margin: "0px",
                        backgroundColor: "#323333",
                        border: "none",
                        height: "1px",
                      }}
                    />
                  </Grid>
                </Box>}


              {searchresult && searchresult.length === 0 && searchTerm && <Grid className={classes.center}><Typography className={classes.text}>Oopsy, no results!</Typography></Grid>}
              {searchTerm.length < 1 ? (
                allBank.map((item) => {
                  return (
                    <>
                      <Grid
                        xs={12}
                        md={12}
                        mt={2}
                        key={item.id}
                        // className={classes.container}
                        style={{
                          padding: "0px 16px"
                        }}
                        container
                        flexDirection="row"
                        onClick={() =>
                          navigate("/branch", {
                            replace: true,
                            state: {
                              bankId: item.id,
                              bankName: item.name
                            },
                          })
                        }
                      >
                        <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center" }}>
                          {" "}
                          <img src={item.logo_url} alt="banklogo" style={{ objectFit: "contain", width: "30px", height: "30px" }} />
                          <Typography className={classes.bankname} ml={3}>
                            {item.name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <hr
                        style={{
                          margin: "16px 16px 0",
                          backgroundColor: "#1C1C1C",
                          border: "none",
                          height: "1px",
                        }}
                      />
                    </>
                  );
                })) : (



                searchresult.map((item) => {

                  return (
                    <>
                    <Grid
                      xs={12}
                      md={12}
                      mt={2}
                      key={item.id}
                      // className={classes.container}
                      style={{
                        padding: "0px 16px"
                      }}
                      container
                      flexDirection="row"
                      onClick={() =>
                        navigate("/branch", {
                          replace: true,
                          state: {
                            bankId: item.id,
                            bankName: item.name
                          },
                        })
                      }
                    >
                      <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <img src={item.logo_url} alt="banklogo" style={{ objectFit: "contain", width: "30px", height: "30px" }} />
                        <Typography className={classes.bankname} ml={3}>
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <hr
                      style={{
                        margin: "16px 16px 0",
                        backgroundColor: "#1C1C1C",
                        border: "none",
                        height: "1px",
                      }}
                    />
                  </>
                  );
                })
              )
              }


            </>
          )}

        </Box>

        <BottomNavigation />
      </Page>
    </>
  );
};

export default Bank;
