import React, {useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import { Box,  Typography, Grid , Button} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "100vh",
    background: "#0D0D0D !important",
    paddingBottom:'2rem'
  
  },
  icon: {
    fill: "#fff  !important",
    cursor: "pointer",
  },
  text: {
    color: "#A489FF!important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: " 20px !important",
    lineHeight: "24px !important",
    marginLeft:'1rem !important'
  },
  Typography: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF",
  }
},
  typo: {
    textAlign: "justify",
    color: "#fff !important",
    padding:"5px 30px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: " 400 !important",
    fontSize: " 14px !important",
    lineHeight: "24px !important",
    '& strong': {
     color: "#A489FF"},
     '& b': {
      color: "#A489FF",
   },
    marginTop:4,
  },
  typoHeader: {
    textAlign: "justify",
    color: "#A489FF",
    marginTop: 16,
    padding:"0px 30px !important",
  },
  typoSubHeader:{
    textAlign: "start",
    color: "#A489FF",
    marginTop: 16,
    padding:"30px !important",
  },
  ulClass:{
    padding:"0px 0px 0px 16px"
  },
  btn:{
    color:"#fff !important",
    padding:"10px 60px !important",
    background:"#6D50CC !important",
    fontFamily:"Poppins !important",
    borderRadius:"16px !important",
    fontWeight:"600 !important",
},
btnBox:{
    textAlign:"center !important"
}


}));

function PolicyReferences() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    const element = document.getElementById("content");
    element.scrollIntoView();
   },[])
 
   const navigateFunction = (()=>{
    if(location.state){
      navigate("/termsandcondition",{ replace: true , state:{...location.state,condition:true}})
    }else{
      navigate("/profile",{ replace: true , state:{condition:true}})
    }
   })

  return (
    <div>
        <Box className={classes.boxcontainer} id="content">
          <Grid container direction="row" style={{padding: "20px "}}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "initial",
              }}
            >
              <KeyboardBackspaceIcon
                className={classes.icon}
                onClick={navigateFunction}
              />
              <Typography className={classes.text}>Privacy Policy and Security</Typography>
            </Grid>
          </Grid>
       
          <Grid item sm={12} xs={12} md={12} lg={12}>
          <Typography variant="body2" className={classes.Typography}>
            We at the UNLISTEDKART group i.e., Unlistedkart LLP and its
            subsidiaries, (“the Company/Unlistedkart/We/Us/UNLISTEDKART”) are
            committed to protecting the privacy and security of your personal
            information. Your privacy is important to us and maintaining your
            trust is paramount.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            This Privacy Policy explains how we collect, use, process and
            disclose information about you. By using our website/app and
            affiliated services, you consent to the terms of our privacy policy
            (“<b>Privacy Policy</b>”) in addition to our Terms of Use. We
            encourage you to read this Privacy Policy regarding the collection,
            use, and disclosure of your information from time to time to keep
            yourself updated with the changes and updated that we make to this
            Policy.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Definitions
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            For the purposes of this Privacy Policy:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
              <li>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Unlistedkart
                LLC, 1206 12th floor 'A' Wing , Mittal towers, MG Road Next to
                Citi Bank, Bangalore 560001.
              </li>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </li>
              <li>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </li>
              <li>
                <strong>Website</strong> refers to Unlistedkart, accessible from
                Unlsitedkart.com.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Country</strong> refers to: Karnataka, India
              </li>
              <li>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> refers to any
                website or any social network website through which a User can
                log in or create an account to use the Service.
              </li>
              <li>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </li>
              <li>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Information we collect.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The following information is collected by or on behalf of
            UNLISTEDKART.
            <br />
            <ul className={classes.ulClass}>
              <li>
                <Typography variant="subtitle1" className={classes.typoHeader}>
                  Information You provide us.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  When you start using the App Services, we ask you to provide
                  certain information as part of the registration process, and
                  during your interface with the App. We will collect this
                  information through various means and in various places
                  through the App Services, including account registration
                  forms, contact us forms, or when you otherwise interact with
                  UNLISTEDKART including at customer support.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  At the time of registration, we ask for the following personal
                  information. Name (First Name, Last Name); Mobile Number;
                  Email ID; Date of Birth; PAN Card.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  Pursuant to the services consumed by You from time to time, we
                  may explicitly seek additional information including address,
                  payment, or banking information, DEMAT information and any
                  other governmental identification numbers or documents. You
                  may choose to provide such information if you wish to use the
                  services provided by the App.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  We will also maintain a record of the information you provide
                  to us when using customer support services.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  From time to time, during your use of the App Services,
                  UNLISTEDKART may require access to certain additional
                  information such as SMS and contact details. Prior to accessing
                  any such additional information, explicit consent shall be
                  sought from you. Please note that even after consent has been
                  provided by you, we only read transactional or promotional SMS
                  and do not open, access, or read any personal SMS.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  Certain features may be restricted, unavailable or unusable if
                  you choose not to provide certain information.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" className={classes.typoSubHeader}>
                  Information created when You use our App Services.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  We collect information relating to your use of our website/app
                  using various technologies. This includes transaction details
                  related to your use of our services including the type of
                  services you requested, the payment method, amount, and other
                  related transactional and financial information. Further, when
                  you visit our website/app, we may log certain information such
                  as your IP address, browser type, mobile operating system,
                  manufacturer and model of your mobile device, geolocation,
                  preferred language, access time, and time spent. We will also
                  collect information about the pages you view within our sites
                  and other actions you take while visiting our website/app.
                </Typography>
                <Typography variant="body2" className={classes.typo}>
                  We also maintain some records of users who contact us for
                  support, for the purpose of responding to such queries and
                  other related activities. However, we do not provide this
                  information to any third party without your permission or
                  utilize the same for any purposes not set out hereunder.
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            How We Use the Information we collect
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may use, store, and process the information provided by you to{" "}
            <br />
            <ul className={classes.ulClass}>
              <li>Improve the App Services.</li>
              <li>
                Create and maintain a trusted and safe environment on
                UNLISTEDKART (such as complying with our legal obligations and
                compliance with our policies).
              </li>
              <li>
                Provide, personalise, measure, and improve our products and
                services.
              </li>
            </ul>
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            The information collected through different channels, allows us to
            collect statistics about our website/app usage and effectiveness,
            personalise your experience whilst you are on our website/app, as
            well as customize our interactions with you and to enhance the scope
            of the App Services. The following paragraphs describe in more
            detail how we use your personal information.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Providing, understanding and improving App Services
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Any information provided by you will be used for creating and
            updating your Account and processing your transaction(s) or for any
            other purposes for which you have granted access to such information
            to us, based on your interaction with the UNLISTEDKART App.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            To complete a financial transaction, we may share financial
            information (such as payment mode details) provided by you with
            authorised third parties, for instance, our Payment gateway service
            providers, business partners, financial teams/institutions, or
            postal/government authorities involved in fulfilment of the said
            transactions, if any. This does not include any information
            collected from e-mails. In connection with a financial transaction,
            we may also contact you as part of our customer satisfaction surveys
            or for market research purposes.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may use the information collected to perform internal operations
            necessary to provide our services, including to troubleshoot
            software bugs and operational problems, to conduct data analysis,
            testing and research and to monitor and analyse usage and activity
            trends. We process this personal information for these purposes
            given our legitimate interest in improving the App Services.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            To expand the scope of our App services, we may from time to time,
            seek additional information and financial documents such as
            information related to investments in equity, mutual funds, and
            other documents.{" "}
            <b>
              Any such collection of additional information and documents shall be
              subject to an explicit and purpose specific consent sought from all
              Users
            </b>
            .
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Safety, security, and resolution of issues
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may use your personal information, created as part of payment
            services availed by you, to ensure that your access and use of
            payment services follows our legal obligations (such as anti-money
            laundering regulations). We may share such information, with our
            advisors, third party service partners and providers for a seamless
            experience for you.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may use the information to create and maintain a safe environment
            and use the same to detect and prevent fraud, span, abuse, security
            incidents and other harmful activity.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We use the information we collect (including recordings of customer
            support calls) to assist you when you contact our customer support
            services to investigate and resolve your queries, monitor, and improve
            our customer support responses. Certain online transactions may
            involve us calling you. They may also involve online chats. Please
            be aware that it is our general practice to monitor and, in some
            cases, record such interactions for staff training or quality
            assurance purposes or to retain evidence of a particular transaction
            or interaction.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We intend to protect your personal information and to maintain its
            accuracy as confirmed by you. We implement reasonable physical,
            administrative, and technical safeguards to help us protect your
            personal information from unauthorised access, use, and disclosure.
            For example, we encrypt all sensitive personal information such as
            Account information when we transmit such information over the
            internet. We also require that our commercial partners and vendors
            protect such information from unauthorised access, use, and
            disclosure.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We blend security at multiple steps within our products with
            state-of-the-art technology to ensure our systems maintain strong
            security measures. The overall data and privacy security design
            allows us to defend our systems ranging from low hanging issue up to
            sophisticated attacks.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We are committed to protecting your data as if it were our own. If
            you are a security enthusiast or a researcher and you have found a
            possible security vulnerability on Unlistedkart products, we
            encourage you to report the issue to us responsibly.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You could submit a bug report to us at{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
            with detailed steps required to reproduce the vulnerability. We
            shall put best of our efforts to investigate and fix the legitimate
            issues in a reasonable time frame, meanwhile, requesting you not to
            publicly disclose it.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Sharing and Disclosure of data with Third Parties
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            As required by law, at times we might be required to disclose your
            personal information including personal, transactional, and
            financial information to relevant regulatory, and governmental
            authorities and also to our advisors such as law firms and audit
            firms while responding to request from the regulatory authorities.
            In some cases, when we believe that such disclosure is necessary to
            protect our rights, or the rights of others, or to comply with a
            judicial proceeding, court order, or legal process served on our
            website/app we would share such information pursuant to a lawful
            request from law enforcement agencies.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Subject to explicit and prior consent from a you, we may use
            information created by your use of our App services,{" "}
            <b>
              not including information collected from other sources such as
              e-mails etc
            </b>
            . for marketing purposes. This consent is purely voluntary, and you
            may at any time choose not to receive marketing materials from us by
            following the unsubscribe instructions included in each e-mail you
            may receive, by indicating so when we call you, or by contacting us
            directly. Further, if you want to remove your contact information
            from all our lists and newsletters, please click on the unsubscribe
            button on the emailers or send an email request to{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Subject to explicit and prior consent from you, we may disclose
            certain information that is created by your use of our App services,
            not including information collected from other sources such as
            e-mails etc. to other affiliate entities and partners that are not
            acting as our suppliers or business partners. For the sake of
            clarity, we do not sell or lease such information.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Some of our campaigns/programmes/related events may be co-branded,
            that is sponsored by both the third parties and us. If you sign up
            for such campaigns/programmes/related events, please note that your
            information may also be collected by and shared with those third
            parties. We urge you to familiarise yourself with their privacy
            policies to gain an understanding of the way they will handle
            information about you.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We may display targeted or non-targeted third-party online
            advertisements on the UNLISTEDKART website/app. We may also
            advertise our activities and organizational goals on other
            websites/apps. We may collaborate with other website/app operators
            as well as network advertisers to do so. We request you to read and
            understand such concerned third party privacy policies to understand
            their practices relating to advertising, including what type of
            information they may collect about your internet usage.{" "}
            <b>
              No personally identifiable information is shared with any
              third-party online advertiser or website or app as part of any
              such activity. Unlistedkart does not provide any information
              relating to your usage to such website operators or network
              advertisers
            </b>
            .
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            During your use of the App services, you may come across links to
            third party websites/apps that are not affiliated with Unlistedkart.
            Unlistedkart is not responsible for the privacy practices or the
            content of those other websites, or for any acts/ omissions by such
            third parties during your transaction with them.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Account Archival/Deletion
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We provide all our Users an option to request the archival/deletion
            a specific account by reaching out to the support through
            operations@unlistedkart.com. Following such request, we archive all
            such information related to the specific account including but not
            limited to profile information, Account data, transaction details,
            reward details, referrals details, statement details, google oath
            sessions etc. that we are not required to retain.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            In certain circumstances, we may be unable to delete your account,
            such as if there is any outstanding dispute or unresolved claims
            pending on your account or for the sake of legal reasons required to
            store the data for access. In such instances the data will be
            encrypted and only be accessible to the law authorities and you will
            be reached out before providing any such information to the
            authorities. However, upon resolution of the issue preventing
            deletion, the information is immediately archived/deleted and cannot
            be recovered thereafter.{" "}
            <b>
              note that we may retain certain information if necessary for our
              own legitimate business interests such as fraud prevention and
              enhancing users’ safety and security or to fulfil our legal
              obligations and compliance
            </b>
            .
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            You also have the option to request for an account
            deactivation/archival instead. This will temporarily block your
            access to UNLISTEDKART App until you send a re-activation request on{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
            and successfully re-activate your account.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Cookies
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We use data collection devices such as “cookies”, etc. on certain
            parts of the App to help analyse the App Services, user interaction
            with the App, measure promotional effectiveness, and promote trust
            and safety. For the sake of clarity, “cookies” are small files
            placed on your device hard-drive/storage that assist us in providing
            the App Services. Please be informed that we offer certain features
            via the App that are only available using a “cookie”.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We also use cookies to allow you to enter a particular password less
            frequently during a session. Cookies also help us obtain information
            that assists us in curating the Services more targeted to your
            interests. You are always free to decline our cookies if your device
            permits, although in that case you may not be able to use certain
            features on the app and you may be required to provide a password
            more frequently during a session.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Changes to Privacy Policy
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Unlistedkart reserves the right to change this policy from time to
            time. Any changes shall be effective immediately upon the posting of
            the revised Privacy Policy. We encourage you to periodically review
            this page for latest information on our privacy practices.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Privacy Questions and Access
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If you have questions, concerns, or suggestions regarding our
            Privacy Policy, we can be reached using the contact information on
            our “Contact Us” page or at{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
            . In certain cases, you may have the ability to view or edit your
            personal information online. In the event your information is not
            accessible online, and you wish to obtain a copy of information you
            provided to us, or if you become aware the information is incorrect
            and you would like us to correct it, please contact us immediately.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Before we can provide you with any information or correct any
            inaccuracies, however, we may ask you to verify your identity and to
            provide other details to ascertain your identity and to help us to
            respond to your request. We will contact you within 30 days of your
            request.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Grievance Redressal Officer
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            If you have a grievance regarding our privacy policy or data usage
            practices, you may reach out to our Grievance Redressal Officer at
            the details below:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            Grievance Redressal:
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
          </Typography>
          <Typography variant="h6" className={classes.typoHeader}>
            SECURITY
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Best practices to follow
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have always placed your security first on UNLISTEDKART. To ensure
            maximum security, we have made a simple list of security to-do's you
            can follow:
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            <ul className={classes.ulClass}>
              <li>
                Never divulge your personal bank details like card number, CVV,
                PIN, and OTP in any medium, including calls, texts, or emails.
              </li>
              <li>
                We will never, ever ask you for any of the sensitive details
                mentioned above.
              </li>
              <li>
                We will never call you and ask to do any payment transaction on
                the app or install any remote access software such as
                TeamViewer, any desk, etc.
              </li>
              <li>Never respond to such emails, texts, or phone calls.</li>
              <li>
                Our customer support can only be reached via the app. please do
                not engage with phone numbers that claim to be of our support
                team.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Privacy Practices
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We do not sell your personal information to or share it with
            unaffiliated third parties for their own advertising or marketing
            purposes without your explicit consent.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Cloud Infrastructure
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            UNLISTEDKART is hosted on a Virtual Private Cloud on Amazon Web
            Services which provides a secure and scalable technology platform to
            ensure we can provide you services securely and reliably.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Perimeter Security
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have deployed Defence in Depth Architecture using a network
            firewall, web application firewall, DDoS protection layer, and a
            content delivery network. Our infrastructure is launched in
            compliance with the AWS Well Architected Framework and from the
            security perspective incorporating practices from the AWS Cloud
            Adoption Framework. We have a 3-Tier Architecture which incorporates
            best practices from various standards and certifications.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have strict network segmentation and isolation of environments
            and services in place.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Host Security
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We use industry leading solutions around anti-virus, anti-malware,
            intrusion prevention systems, intrusion detection systems, file
            integrity monitoring, application control, application and audit log
            aggregation, and automated patching. All our servers are launched
            using the Centre for Internet Security Benchmarks for Amazon Linux.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Data Security
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We employ separation of environments and segregation of duties and
            have strict role-based access control on a documented, authorized,
            need-to-use basis. We use key management services to limit access to
            data except the data team. Stored data is protected by encryption at
            rest and sensitive data by application-level encryption. We use data
            replication for data resiliency, snapshotting for data durability
            and backup/restore testing for data reliability.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Incident and Change Management
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have deployed mature processes around Change Management which
            enables us to release thoroughly tested features for you both
            reliably and securely enabling you to enjoy the UNLISTEDKART
            experience with maximum assurance.
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have an aggressive stance on Incident Management on both Systems
            downtime and Security and have a Network Operations Centre and an
            Information Security Management System in place which quickly
            reacts, remediates, or escalates any Incidents arising out of
            planned or unplanned changes.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoSubHeader}>
            Vulnerability Assessment and Penetration Testing
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We have an inhouse network security team which uses industry leading
            products to conduct manual and automated VA/PT activities. We employ
            both static application security testing and dynamic application
            security testing which is incorporated into our continuous
            integration / continuous deployment pipeline. We also leverage
            CERT-IN certified auditors to do periodic external security testing
            and audits. All compliance/audit statuses will be updated in this
            section in this policy.
          </Typography>
          <Typography variant="subtitle1" className={classes.typoHeader}>
            Responsible Disclosure
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            We at UNLISTEDKART are committed about our customer's data and
            privacy. We blend security at multiple steps within our products
            with state-of-the-art technology to ensure our systems maintain
            strong security measures. The overall data and privacy security
            design allows us to defend our systems ranging from low hanging
            issue up to sophisticated attacks. If you are a security enthusiast
            or a researcher and you have found a possible security vulnerability
            on UNLISTEDKART products, we encourage you to report the issue to us
            responsibly. You could submit a bug report to us at{" "}
            <a
              style={{ textDecoration: "none",  color:"#A489FF" }}
              href="mailto:operations@unlistedkart.com"
            >
              operations@unlistedkart.com
            </a>{" "}
            with detailed steps required to reproduce the vulnerability. We
            shall put best of our efforts to investigate and fix the legitimate
            issues in a reasonable time frame, meanwhile, requesting you not to
            publicly disclose it.
          </Typography>
        </Grid>
        <Box className={classes.btnBox} mt={4}>
           <Button className={classes.btn} onClick={navigateFunction}>
                Close
            </Button>
           </Box>
        </Box>
    </div>
  );
}

export default PolicyReferences;
