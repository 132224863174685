import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Loader from "../../component/Loading/Loading";
const useStyles = makeStyles((theme) => ({

    button: {
        padding: "12px 44px  !important ",
        backgroundColor: "#6D50CC  !important",
        borderRadius: "10px  !important",
        textTransform: "uppercase !important",
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "500 !important",
        fontSize: "16px  !important",
        color: "#FFF !important",
        lineHeight: "24px !important",
         textTransform: "uppercase ",
         height:"54px",
        "&:disabled": {
            color: "#6E58B0 !important",
            backgroundColor: "#3B3159 !important",
        },
    },
}));

function VerifyButton({ submitButton, text, verify ,onClick,isLoading}) {
    const classes = useStyles();


    return (
        <Button
            disabled={submitButton}
            type="submit"
            className={classes.button}
            fullWidth
            disableRipple
            variant="contained"
            onClick={onClick}
            style={isLoading ? {background:"repeating-linear-gradient(120deg, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5,#9b80d6,#8264E5, #9b80d6, #8264E5)",
            backgroundRepeat:"repeat-x",
            backgroundSize:"400% 100%",
            WebkitAnimation:"AnimationName 6s linear infinite",
            MozAnimation:"AnimationName 6s linear infinite",
            animation:"AnimationName 6s linear infinite", width:"calc(100vw - 36px)"} : { width:"calc(100vw - 36px)"}}
        >
         {text}  
        </Button>
    );
}

export default VerifyButton;
