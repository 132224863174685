import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckCircleOutlineIcon from "../../Images/valid.svg";
import ErrorInfo from "../../Images/errorInfo.svg";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  helpertextContent: {
    height: "16px",
    left: "50px",
    top: "217px",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
  },
  box:{
    display: "flex",
    alignItems: "center",
    marginTop:'8px'
  }
}));
function HelperText({ helperText ,type}) {
  const classes = useStyles();

  return (
    helperText &&  <div className={classes.box}>
        {type ==="success" ?
          <img
          src={CheckCircleOutlineIcon}
          alt="verified"
          width="14"
          height="14"
        />:  type ==="error"? <img
        src={ErrorInfo}
        alt="error"
        width="14"
        height="14"
      /> : null}
          <Typography
            className={classes.helpertextContent}
            style={{ color: type === "success" ? "#83CE60" : type === "error" ? "#DD4A47 " : "#fff", marginLeft:(type === "success" ||type === "error") ? "6px" : "2px"}}
          >
           {helperText}
          </Typography>
        </div>
  );
}

export default HelperText;
