const rawCountries =[
  {
     "countryName":"Afghanistan",
     "code":"af",
     "countryCode":"93",
     "emoji":"🇦🇫",
     "mobileNoFormat":null
  },
  {
     "countryName":"Albania",
     "code":"al",
     "countryCode":"355",
     "emoji":"🇦🇱",
     "mobileNoFormat":null
  },
  {
     "countryName":"Algeria",
     "code":"dz",
     "countryCode":"213",
     "emoji":"🇩🇿",
     "mobileNoFormat":null
  },
  {
     "countryName":"Andorra",
     "code":"ad",
     "countryCode":"376",
     "emoji":"🇦🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Angola",
     "code":"ao",
     "countryCode":"244",
     "emoji":"🇦🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Argentina",
     "code":"ar",
     "countryCode":"54",
     "emoji":"🇦🇷",
     "mobileNoLength":10,
     "mobileNoFormat":"(..) ........"
  },
  {
     "countryName":"Armenia",
     "code":"am",
     "countryCode":"374",
     "emoji":"🇦🇲",
     "mobileNoLength":8,
     "mobileNoFormat":".. ......"
  },
  {
     "countryName":"Aruba",
     "code":"aw",
     "countryCode":"297",
     "emoji":"🇦🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Australia",
     "code":"au",
     "countryCode":"61",
     "emoji":"🇦🇺",
     "mobileNoLength":10,
     "mobileNoFormat":"(..) .... ...."
  },
  {
     "countryName":"Austria",
     "code":"at",
     "countryCode":"43",
     "emoji":"🇦🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Azerbaijan",
     "code":"az",
     "countryCode":"994",
     "emoji":"🇦🇿",
     "mobileNoLength":9,
     "mobileNoFormat":"(..) ... .. .."
  },
  {
     "countryName":"Bahamas",
     "code":"bs",
     "countryCode":"1242",
     "emoji":"🇧🇸",
     "mobileNoFormat":null
  },
  {
     "countryName":"Bahrain",
     "code":"bh",
     "countryCode":"973",
     "emoji":"🇧🇭",
     "mobileNoFormat":null
  },
  {
     "countryName":"Bangladesh",
     "code":"bd",
     "countryCode":"880",
     "emoji":"🇧🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Barbados",
     "code":"bb",
     "countryCode":"1246",
     "emoji":"🇧🇧",
     "mobileNoFormat":null
  },
  {
     "countryName":"Belarus",
     "code":"by",
     "countryCode":"375",
     "emoji":"🇧🇾",
     "mobileNoLength":9,
     "mobileNoFormat":"(..) ... .. .."
  },
  {
     "countryName":"Belgium",
     "code":"be",
     "countryCode":"32",
     "emoji":"🇧🇪",
     "mobileNoLength":9,
     "mobileNoFormat":"... .. .. .."
  },
  {
     "countryName":"Belize",
     "code":"bz",
     "countryCode":"501",
     "emoji":"🇧🇿",
     "mobileNoFormat":null
  },
  {
     "countryName":"Benin",
     "code":"bj",
     "countryCode":"229",
     "emoji":"🇧🇯",
     "mobileNoFormat":null
  },
  {
     "countryName":"Bhutan",
     "code":"bt",
     "countryCode":"975",
     "emoji":"🇧🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Bolivia",
     "code":"bo",
     "countryCode":"591",
     "emoji":"🇧🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Botswana",
     "code":"bw",
     "countryCode":"267",
     "emoji":"🇧🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Brazil",
     "code":"br",
     "countryCode":"55",
     "emoji":"🇧🇷",
     "mobileNoLength":11,
     "mobileNoFormat":"(..) ........."
  },
  {
     "countryName":"British Indian Ocean Territory",
     "code":"io",
     "countryCode":"246",
     "emoji":"🇮🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Brunei",
     "code":"bn",
     "countryCode":"673",
     "emoji":"🇧🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Bulgaria",
     "code":"bg",
     "countryCode":"359",
     "emoji":"🇧🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"Burkina Faso",
     "code":"bf",
     "countryCode":"226",
     "emoji":"🇧🇫",
     "mobileNoFormat":null
  },
  {
     "countryName":"Burundi",
     "code":"bi",
     "countryCode":"257",
     "emoji":"🇧🇮",
     "mobileNoFormat":null
  },
  {
     "countryName":"Cambodia",
     "code":"kh",
     "countryCode":"855",
     "emoji":"🇰🇭",
     "mobileNoFormat":null
  },
  {
     "countryName":"Cameroon",
     "code":"cm",
     "countryCode":"237",
     "emoji":"🇨🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Canada",
     "code":"ca",
     "countryCode":"1",
     "emoji":"🇨🇦",
     "mobileNoLength":10,
     "mobileNoFormat":"(...) ...-...."
  },
  {
     "countryName":"Cape Verde",
     "code":"cv",
     "countryCode":"238",
     "emoji":"🇨🇻",
     "mobileNoFormat":null
  },
  {
     "countryName":"Caribbean Netherlands",
     "code":"bq",
     "countryCode":"599",
     "emoji":"🇧🇶",
     "mobileNoLength":0,
     "mobileNoFormat":""
  },
  {
     "countryName":"Central African Republic",
     "code":"cf",
     "countryCode":"236",
     "emoji":"🇨🇫",
     "mobileNoFormat":null
  },
  {
     "countryName":"Chad",
     "code":"td",
     "countryCode":"235",
     "emoji":"🇹🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Chile",
     "code":"cl",
     "countryCode":"56",
     "emoji":"🇨🇱",
     "mobileNoFormat":null
  },
  {
     "countryName":"China",
     "code":"cn",
     "countryCode":"86",
     "emoji":"🇨🇳",
     "mobileNoLength":11,
     "mobileNoFormat":"..-........."
  },
  {
     "countryName":"Colombia",
     "code":"co",
     "countryCode":"57",
     "emoji":"🇨🇴",
     "mobileNoLength":10,
     "mobileNoFormat":"... ... ...."
  },
  {
     "countryName":"Comoros",
     "code":"km",
     "countryCode":"269",
     "emoji":"🇰🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Costa Rica",
     "code":"cr",
     "countryCode":"506",
     "emoji":"🇨🇷",
     "mobileNoLength":8,
     "mobileNoFormat":"....-...."
  },
  {
     "countryName":"Côte d’Ivoire",
     "code":"ci",
     "countryCode":"225",
     "emoji":"🇨🇮",
     "mobileNoLength":8,
     "mobileNoFormat":".. .. .. .."
  },
  {
     "countryName":"Croatia",
     "code":"hr",
     "countryCode":"385",
     "emoji":"🇭🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Cuba",
     "code":"cu",
     "countryCode":"53",
     "emoji":"🇨🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Curaçao",
     "code":"cw",
     "countryCode":"599",
     "emoji":"🇨🇼",
     "mobileNoLength":0,
     "mobileNoFormat":""
  },
  {
     "countryName":"Cyprus",
     "code":"cy",
     "countryCode":"357",
     "emoji":"🇨🇾",
     "mobileNoLength":8,
     "mobileNoFormat":".. ......"
  },
  {
     "countryName":"Denmark",
     "code":"dk",
     "countryCode":"45",
     "emoji":"🇩🇰",
     "mobileNoLength":8,
     "mobileNoFormat":".. .. .. .."
  },
  {
     "countryName":"Djibouti",
     "code":"dj",
     "countryCode":"253",
     "emoji":"🇩🇯",
     "mobileNoFormat":null
  },
  {
     "countryName":"Dominica",
     "code":"dm",
     "countryCode":"1767",
     "emoji":"🇩🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Dominican Republic",
     "code":"do",
     "countryCode":"1",
     "emoji":"🇩🇴",
     "mobileNoLength":0,
     "mobileNoFormat":""
  },
  {
     "countryName":"Ecuador",
     "code":"ec",
     "countryCode":"593",
     "emoji":"🇪🇨",
     "mobileNoFormat":null
  },
  {
     "countryName":"Egypt",
     "code":"eg",
     "countryCode":"20",
     "emoji":"🇪🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"El Salvador",
     "code":"sv",
     "countryCode":"503",
     "emoji":"🇸🇻",
     "mobileNoLength":8,
     "mobileNoFormat":"....-...."
  },
  {
     "countryName":"Equatorial Guinea",
     "code":"gq",
     "countryCode":"240",
     "emoji":"🇬🇶",
     "mobileNoFormat":null
  },
  {
     "countryName":"Eritrea",
     "code":"er",
     "countryCode":"291",
     "emoji":"🇪🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Estonia",
     "code":"ee",
     "countryCode":"372",
     "emoji":"🇪🇪",
     "mobileNoLength":10,
     "mobileNoFormat":".... ......"
  },
  {
     "countryName":"Ethiopia",
     "code":"et",
     "countryCode":"251",
     "emoji":"🇪🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Fiji",
     "code":"fj",
     "countryCode":"679",
     "emoji":"🇫🇯",
     "mobileNoFormat":null
  },
  {
     "countryName":"Finland",
     "code":"fi",
     "countryCode":"358",
     "emoji":"🇫🇮",
     "mobileNoLength":9,
     "mobileNoFormat":".. ... .. .."
  },
  {
     "countryName":"France",
     "code":"fr",
     "countryCode":"33",
     "emoji":"🇫🇷",
     "mobileNoLength":9,
     "mobileNoFormat":". .. .. .. .."
  },
  {
     "countryName":"French Guiana",
     "code":"gf",
     "countryCode":"594",
     "emoji":"🇬🇫",
     "mobileNoFormat":null
  },
  {
     "countryName":"French Polynesia",
     "code":"pf",
     "countryCode":"689",
     "emoji":"🇵🇫",
     "mobileNoFormat":null
  },
  {
     "countryName":"Gabon",
     "code":"ga",
     "countryCode":"241",
     "emoji":"🇬🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Gambia",
     "code":"gm",
     "countryCode":"220",
     "emoji":"🇬🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Georgia",
     "code":"ge",
     "countryCode":"995",
     "emoji":"🇬🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Germany",
     "code":"de",
     "countryCode":"49",
     "emoji":"🇩🇪",
     "mobileNoLength":12,
     "mobileNoFormat":".... ........"
  },
  {
     "countryName":"Ghana",
     "code":"gh",
     "countryCode":"233",
     "emoji":"🇬🇭",
     "mobileNoFormat":null
  },
  {
     "countryName":"Greece",
     "code":"gr",
     "countryCode":"30",
     "emoji":"🇬🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Grenada",
     "code":"gd",
     "countryCode":"1473",
     "emoji":"🇬🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Guadeloupe",
     "code":"gp",
     "countryCode":"590",
     "emoji":"🇬🇵",
     "mobileNoLength":0,
     "mobileNoFormat":""
  },
  {
     "countryName":"Guam",
     "code":"gu",
     "countryCode":"1671",
     "emoji":"🇬🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Guatemala",
     "code":"gt",
     "countryCode":"502",
     "emoji":"🇬🇹",
     "mobileNoLength":8,
     "mobileNoFormat":"....-...."
  },
  {
     "countryName":"Guinea",
     "code":"gn",
     "countryCode":"224",
     "emoji":"🇬🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Guinea-Bissau",
     "code":"gw",
     "countryCode":"245",
     "emoji":"🇬🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Guyana",
     "code":"gy",
     "countryCode":"592",
     "emoji":"🇬🇾",
     "mobileNoFormat":null
  },
  {
     "countryName":"Haiti",
     "code":"ht",
     "countryCode":"509",
     "emoji":"🇭🇹",
     "mobileNoLength":8,
     "mobileNoFormat":"....-...."
  },
  {
     "countryName":"Honduras",
     "code":"hn",
     "countryCode":"504",
     "emoji":"🇭🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Hungary",
     "code":"hu",
     "countryCode":"36",
     "emoji":"🇭🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Iceland",
     "code":"is",
     "countryCode":"354",
     "emoji":"🇮🇸",
     "mobileNoLength":7,
     "mobileNoFormat":"... ...."
  },
  {
     "countryName":"India",
     "code":"in",
     "countryCode":"91",
     "emoji":"🇮🇳",
     "mobileNoLength":10,
     "mobileNoFormat":".....-....."
  },
  {
     "countryName":"Indonesia",
     "code":"id",
     "countryCode":"62",
     "emoji":"🇮🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Iran",
     "code":"ir",
     "countryCode":"98",
     "emoji":"🇮🇷",
     "mobileNoLength":10,
     "mobileNoFormat":"... ... ...."
  },
  {
     "countryName":"Iraq",
     "code":"iq",
     "countryCode":"964",
     "emoji":"🇮🇶",
     "mobileNoFormat":null
  },
  {
     "countryName":"Ireland",
     "code":"ie",
     "countryCode":"353",
     "emoji":"🇮🇪",
     "mobileNoLength":9,
     "mobileNoFormat":".. ......."
  },
  {
     "countryName":"Israel",
     "code":"il",
     "countryCode":"972",
     "emoji":"🇮🇱",
     "mobileNoLength":10,
     "mobileNoFormat":"... ... ...."
  },
  {
     "countryName":"Italy",
     "code":"it",
     "countryCode":"39",
     "emoji":"🇮🇹",
     "mobileNoLength":10,
     "mobileNoFormat":"... ......."
  },
  {
     "countryName":"Jamaica",
     "code":"jm",
     "countryCode":"1876",
     "emoji":"🇯🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Japan",
     "code":"jp",
     "countryCode":"81",
     "emoji":"🇯🇵",
     "mobileNoLength":10,
     "mobileNoFormat":".. .... ...."
  },
  {
     "countryName":"Jordan",
     "code":"jo",
     "countryCode":"962",
     "emoji":"🇯🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Kazakhstan",
     "code":"kz",
     "countryCode":"7",
     "emoji":"🇰🇿",
     "mobileNoLength":10,
     "mobileNoFormat":"... ...-..-.."
  },
  {
     "countryName":"Kenya",
     "code":"ke",
     "countryCode":"254",
     "emoji":"🇰🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Kiribati",
     "code":"ki",
     "countryCode":"686",
     "emoji":"🇰🇮",
     "mobileNoFormat":null
  },
  {
     "countryName":"Kosovo",
     "code":"xk",
     "countryCode":"383",
     "emoji":"🇽🇰",
     "mobileNoFormat":null
  },
  {
     "countryName":"Kuwait",
     "code":"kw",
     "countryCode":"965",
     "emoji":"🇰🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Kyrgyzstan",
     "code":"kg",
     "countryCode":"996",
     "emoji":"🇰🇬",
     "mobileNoLength":9,
     "mobileNoFormat":"... ... ..."
  },
  {
     "countryName":"Laos",
     "code":"la",
     "countryCode":"856",
     "emoji":"🇱🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Latvia",
     "code":"lv",
     "countryCode":"371",
     "emoji":"🇱🇻",
     "mobileNoLength":8,
     "mobileNoFormat":".. ... ..."
  },
  {
     "countryName":"Lebanon",
     "code":"lb",
     "countryCode":"961",
     "emoji":"🇱🇧",
     "mobileNoFormat":null
  },
  {
     "countryName":"Lesotho",
     "code":"ls",
     "countryCode":"266",
     "emoji":"🇱🇸",
     "mobileNoFormat":null
  },
  {
     "countryName":"Liberia",
     "code":"lr",
     "countryCode":"231",
     "emoji":"🇱🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Libya",
     "code":"ly",
     "countryCode":"218",
     "emoji":"🇱🇾",
     "mobileNoFormat":null
  },
  {
     "countryName":"Liechtenstein",
     "code":"li",
     "countryCode":"423",
     "emoji":"🇱🇮",
     "mobileNoFormat":null
  },
  {
     "countryName":"Lithuania",
     "code":"lt",
     "countryCode":"370",
     "emoji":"🇱🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Luxembourg",
     "code":"lu",
     "countryCode":"352",
     "emoji":"🇱🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Madagascar",
     "code":"mg",
     "countryCode":"261",
     "emoji":"🇲🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"Malawi",
     "code":"mw",
     "countryCode":"265",
     "emoji":"🇲🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Malaysia",
     "code":"my",
     "countryCode":"60",
     "emoji":"🇲🇾",
     "mobileNoLength":10,
     "mobileNoFormat":"..-....-...."
  },
  {
     "countryName":"Maldives",
     "code":"mv",
     "countryCode":"960",
     "emoji":"🇲🇻",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mali",
     "code":"ml",
     "countryCode":"223",
     "emoji":"🇲🇱",
     "mobileNoFormat":null
  },
  {
     "countryName":"Malta",
     "code":"mt",
     "countryCode":"356",
     "emoji":"🇲🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Marshall Islands",
     "code":"mh",
     "countryCode":"692",
     "emoji":"🇲🇭",
     "mobileNoFormat":null
  },
  {
     "countryName":"Martinique",
     "code":"mq",
     "countryCode":"596",
     "emoji":"🇲🇶",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mauritania",
     "code":"mr",
     "countryCode":"222",
     "emoji":"🇲🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mauritius",
     "code":"mu",
     "countryCode":"230",
     "emoji":"🇲🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mexico",
     "code":"mx",
     "countryCode":"52",
     "emoji":"🇲🇽",
     "mobileNoLength":10,
     "mobileNoFormat":"... ... ...."
  },
  {
     "countryName":"Micronesia",
     "code":"fm",
     "countryCode":"691",
     "emoji":"🇫🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Moldova",
     "code":"md",
     "countryCode":"373",
     "emoji":"🇲🇩",
     "mobileNoLength":8,
     "mobileNoFormat":"(..) ..-..-.."
  },
  {
     "countryName":"Monaco",
     "code":"mc",
     "countryCode":"377",
     "emoji":"🇲🇨",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mongolia",
     "code":"mn",
     "countryCode":"976",
     "emoji":"🇲🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Montenegro",
     "code":"me",
     "countryCode":"382",
     "emoji":"🇲🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Morocco",
     "code":"ma",
     "countryCode":"212",
     "emoji":"🇲🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Mozambique",
     "code":"mz",
     "countryCode":"258",
     "emoji":"🇲🇿",
     "mobileNoFormat":null
  },
  {
     "countryName":"Namibia",
     "code":"na",
     "countryCode":"264",
     "emoji":"🇳🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Nauru",
     "code":"nr",
     "countryCode":"674",
     "emoji":"🇳🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Nepal",
     "code":"np",
     "countryCode":"977",
     "emoji":"🇳🇵",
     "mobileNoFormat":null
  },
  {
     "countryName":"Netherlands",
     "code":"nl",
     "countryCode":"31",
     "emoji":"🇳🇱",
     "mobileNoLength":10,
     "mobileNoFormat":".. ........"
  },
  {
     "countryName":"New Caledonia",
     "code":"nc",
     "countryCode":"687",
     "emoji":"🇳🇨",
     "mobileNoFormat":null
  },
  {
     "countryName":"New Zealand",
     "code":"nz",
     "countryCode":"64",
     "emoji":"🇳🇿",
     "mobileNoLength":10,
     "mobileNoFormat":"...-...-...."
  },
  {
     "countryName":"Nicaragua",
     "code":"ni",
     "countryCode":"505",
     "emoji":"🇳🇮",
     "mobileNoFormat":null
  },
  {
     "countryName":"Niger",
     "code":"ne",
     "countryCode":"227",
     "emoji":"🇳🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Nigeria",
     "code":"ng",
     "countryCode":"234",
     "emoji":"🇳🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"North Korea",
     "code":"kp",
     "countryCode":"850",
     "emoji":"🇰🇵",
     "mobileNoFormat":null
  },
  {
     "countryName":"Norway",
     "code":"no",
     "countryCode":"47",
     "emoji":"🇳🇴",
     "mobileNoLength":8,
     "mobileNoFormat":"... .. ..."
  },
  {
     "countryName":"Oman",
     "code":"om",
     "countryCode":"968",
     "emoji":"🇴🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Pakistan",
     "code":"pk",
     "countryCode":"92",
     "emoji":"🇵🇰",
     "mobileNoLength":10,
     "mobileNoFormat":"...-......."
  },
  {
     "countryName":"Palau",
     "code":"pw",
     "countryCode":"680",
     "emoji":"🇵🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Panama",
     "code":"pa",
     "countryCode":"507",
     "emoji":"🇵🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Papua New Guinea",
     "code":"pg",
     "countryCode":"675",
     "emoji":"🇵🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"Paraguay",
     "code":"py",
     "countryCode":"595",
     "emoji":"🇵🇾",
     "mobileNoFormat":null
  },
  {
     "countryName":"Peru",
     "code":"pe",
     "countryCode":"51",
     "emoji":"🇵🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Philippines",
     "code":"ph",
     "countryCode":"63",
     "emoji":"🇵🇭",
     "mobileNoLength":11,
     "mobileNoFormat":".... ......."
  },
  {
     "countryName":"Poland",
     "code":"pl",
     "countryCode":"48",
     "emoji":"🇵🇱",
     "mobileNoLength":9,
     "mobileNoFormat":"...-...-..."
  },
  {
     "countryName":"Portugal",
     "code":"pt",
     "countryCode":"351",
     "emoji":"🇵🇹",
     "mobileNoFormat":null
  },
  {
     "countryName":"Puerto Rico",
     "code":"pr",
     "countryCode":"1",
     "emoji":"🇵🇷",
     "mobileNoLength":0,
     "mobileNoFormat":""
  },
  {
     "countryName":"Qatar",
     "code":"qa",
     "countryCode":"974",
     "emoji":"🇶🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Réunion",
     "code":"re",
     "countryCode":"262",
     "emoji":"🇷🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Romania",
     "code":"ro",
     "countryCode":"40",
     "emoji":"🇷🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Russia",
     "code":"ru",
     "countryCode":"7",
     "emoji":"🇷🇺",
     "mobileNoLength":10,
     "mobileNoFormat":"(...) ...-..-.."
  },
  {
     "countryName":"Rwanda",
     "code":"rw",
     "countryCode":"250",
     "emoji":"🇷🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Samoa",
     "code":"ws",
     "countryCode":"685",
     "emoji":"🇼🇸",
     "mobileNoFormat":null
  },
  {
     "countryName":"San Marino",
     "code":"sm",
     "countryCode":"378",
     "emoji":"🇸🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Saudi Arabia",
     "code":"sa",
     "countryCode":"966",
     "emoji":"🇸🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"Senegal",
     "code":"sn",
     "countryCode":"221",
     "emoji":"🇸🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Serbia",
     "code":"rs",
     "countryCode":"381",
     "emoji":"🇷🇸",
     "mobileNoFormat":null
  },
  {
     "countryName":"Seychelles",
     "code":"sc",
     "countryCode":"248",
     "emoji":"🇸🇨",
     "mobileNoFormat":null
  },
  {
     "countryName":"Sierra Leone",
     "code":"sl",
     "countryCode":"232",
     "emoji":"🇸🇱",
     "mobileNoFormat":null
  },
  {
     "countryName":"Singapore",
     "code":"sg",
     "countryCode":"65",
     "emoji":"🇸🇬",
     "mobileNoLength":8,
     "mobileNoFormat":"....-...."
  },
  {
     "countryName":"Slovakia",
     "code":"sk",
     "countryCode":"421",
     "emoji":"🇸🇰",
     "mobileNoFormat":null
  },
  {
     "countryName":"Slovenia",
     "code":"si",
     "countryCode":"386",
     "emoji":"🇸🇮",
     "mobileNoFormat":null
  },
  {
     "countryName":"Solomon Islands",
     "code":"sb",
     "countryCode":"677",
     "emoji":"🇸🇧",
     "mobileNoFormat":null
  },
  {
     "countryName":"Somalia",
     "code":"so",
     "countryCode":"252",
     "emoji":"🇸🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"South Africa",
     "code":"za",
     "countryCode":"27",
     "emoji":"🇿🇦",
     "mobileNoFormat":null
  },
  {
     "countryName":"South Korea",
     "code":"kr",
     "countryCode":"82",
     "emoji":"🇰🇷",
     "mobileNoLength":11,
     "mobileNoFormat":"... .... ...."
  },
  {
     "countryName":"South Sudan",
     "code":"ss",
     "countryCode":"211",
     "emoji":"🇸🇸",
     "mobileNoFormat":null
  },
  {
     "countryName":"Spain",
     "code":"es",
     "countryCode":"34",
     "emoji":"🇪🇸",
     "mobileNoLength":9,
     "mobileNoFormat":"... ... ..."
  },
  {
     "countryName":"Sri Lanka",
     "code":"lk",
     "countryCode":"94",
     "emoji":"🇱🇰",
     "mobileNoFormat":null
  },
  {
     "countryName":"Sudan",
     "code":"sd",
     "countryCode":"249",
     "emoji":"🇸🇩",
     "mobileNoFormat":null
  },
  {
     "countryName":"Suriname",
     "code":"sr",
     "countryCode":"597",
     "emoji":"🇸🇷",
     "mobileNoFormat":null
  },
  {
     "countryName":"Sweden",
     "code":"se",
     "countryCode":"46",
     "emoji":"🇸🇪",
     "mobileNoLength":9,
     "mobileNoFormat":"(...) ...-..."
  },
  {
     "countryName":"Switzerland",
     "code":"ch",
     "countryCode":"41",
     "emoji":"🇨🇭",
     "mobileNoLength":9,
     "mobileNoFormat":".. ... .. .."
  },
  {
     "countryName":"Syria",
     "code":"sy",
     "countryCode":"963",
     "emoji":"🇸🇾",
     "mobileNoFormat":null
  },
  {
     "countryName":"Taiwan",
     "code":"tw",
     "countryCode":"886",
     "emoji":"🇹🇼",
     "mobileNoFormat":null
  },
  {
     "countryName":"Tajikistan",
     "code":"tj",
     "countryCode":"992",
     "emoji":"🇹🇯",
     "mobileNoFormat":null
  },
  {
     "countryName":"Tanzania",
     "code":"tz",
     "countryCode":"255",
     "emoji":"🇹🇿",
     "mobileNoFormat":null
  },
  {
     "countryName":"Thailand",
     "code":"th",
     "countryCode":"66",
     "emoji":"🇹🇭",
     "mobileNoFormat":null
  },
  {
     "countryName":"Timor-Leste",
     "code":"tl",
     "countryCode":"670",
     "emoji":"🇹🇱",
     "mobileNoFormat":null
  },
  {
     "countryName":"Togo",
     "code":"tg",
     "countryCode":"228",
     "emoji":"🇹🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"Tonga",
     "code":"to",
     "countryCode":"676",
     "emoji":"🇹🇴",
     "mobileNoFormat":null
  },
  {
     "countryName":"Tunisia",
     "code":"tn",
     "countryCode":"216",
     "emoji":"🇹🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Turkey",
     "code":"tr",
     "countryCode":"90",
     "emoji":"🇹🇷",
     "mobileNoLength":10,
     "mobileNoFormat":"... ... .. .."
  },
  {
     "countryName":"Turkmenistan",
     "code":"tm",
     "countryCode":"993",
     "emoji":"🇹🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Tuvalu",
     "code":"tv",
     "countryCode":"688",
     "emoji":"🇹🇻",
     "mobileNoFormat":null
  },
  {
     "countryName":"Uganda",
     "code":"ug",
     "countryCode":"256",
     "emoji":"🇺🇬",
     "mobileNoFormat":null
  },
  {
     "countryName":"Ukraine",
     "code":"ua",
     "countryCode":"380",
     "emoji":"🇺🇦",
     "mobileNoLength":9,
     "mobileNoFormat":"(..) ... .. .."
  },
  {
     "countryName":"United Arab Emirates",
     "code":"ae",
     "countryCode":"971",
     "emoji":"🇦🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"United Kingdom",
     "code":"gb",
     "countryCode":"44",
     "emoji":"🇬🇧",
     "mobileNoLength":10,
     "mobileNoFormat":".... ......"
  },
  {
     "countryName":"United States",
     "code":"us",
     "countryCode":"1",
     "emoji":"🇺🇸",
     "mobileNoLength":10,
     "mobileNoFormat":"(...) ...-...."
  },
  {
     "countryName":"Uruguay",
     "code":"uy",
     "countryCode":"598",
     "emoji":"🇺🇾",
     "mobileNoFormat":null
  },
  {
     "countryName":"Uzbekistan",
     "code":"uz",
     "countryCode":"998",
     "emoji":"🇺🇿",
     "mobileNoLength":9,
     "mobileNoFormat":".. ... .. .."
  },
  {
     "countryName":"Vanuatu",
     "code":"vu",
     "countryCode":"678",
     "emoji":"🇻🇺",
     "mobileNoFormat":null
  },
  {
     "countryName":"Vatican City",
     "code":"va",
     "countryCode":"39",
     "emoji":"🇻🇦",
     "mobileNoLength":10,
     "mobileNoFormat":".. .... ...."
  },
  {
     "countryName":"Venezuela",
     "code":"ve",
     "countryCode":"58",
     "emoji":"🇻🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Vietnam",
     "code":"vn",
     "countryCode":"84",
     "emoji":"🇻🇳",
     "mobileNoFormat":null
  },
  {
     "countryName":"Yemen",
     "code":"ye",
     "countryCode":"967",
     "emoji":"🇾🇪",
     "mobileNoFormat":null
  },
  {
     "countryName":"Zambia",
     "code":"zm",
     "countryCode":"260",
     "emoji":"🇿🇲",
     "mobileNoFormat":null
  },
  {
     "countryName":"Zimbabwe",
     "code":"zw",
     "countryCode":"263",
     "emoji":"🇿🇼",
     "mobileNoFormat":null
  }
]
export default rawCountries