import React from "react";
import { Grid, Typography, Box,Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import internet from "./../Overview/lotties/cart.json";
import Lottie from 'react-lottie';
import Cart from "./../Overview/lotties/maintenance.json";
import { useLocation, useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        display: "grid",
        padding: "0 0px !important",
        minHeight: "100vh ",
        // background: "#40306F !important",
        background: "linear-gradient(180deg, #1E153A 0%, #40306F 100%)"

    },

    center: {
        margin: " auto",
        textAlign: "center",
    },
    heading: {
        fontStyle: "normal  !important",
        color: "#fff  !important",
        fontWeight: "500  !important",
        fontSize: "14px  !important",
        fontFamily: "poppins  !important",
        lineHeight: "27px  !important",
    },
    text: {
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "400  !important",
        fontSize: "12px  !important",
        color: "#767677  !important",
        lineHeight: "18px !important",
    },
}));
const Maintenance  = () => {
    const classes = useStyles();
    const location = useLocation()
    const  navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Cart,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <Box className={classes.boxcontainer}>


        <Grid className={classes.center}  >
        <Lottie 
         style={{ 
            width:"300px",
            height:"250px",
            }}
                  options={defaultOptions}
                  />

            <Typography
                className={classes.heading}
            >
              Something exciting cooking!
              <br/>
              We’ll be back soon.
            </Typography>
            <Button className={classes.heading} sx={{ textTransform:'none', borderRadius:'16px',padding:'0 20px',mt:3, background:'#8264e6'}}
            onClick={()=>{
                navigate("/companyOverview", {
                    replace: true,
                    state: { id: location.state.id},
                   });
            }}
            >
            Return
            </Button>

        </Grid>
    </Box>
    );
};

export default Maintenance;
