import axios from 'axios'
import { helpers } from '../_helpers/index'
 
export const NewsServices = {
    getNews,
    getIndividualNews,
    postNewsReaction

}

function getNews(data, id) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getSecurityNews/${id}`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postNewsReaction(id, data) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/setSecurityNewsReaction/${id}`,data, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }

  function getIndividualNews(id, data) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/getSecurityNewsReactionCount/${id}`,data, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }