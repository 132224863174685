import React from "react";
import { Box, Typography, Grid , Avatar} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  card: {
    border:"1px solid #1C1C1C !important ",
    borderRadius: "10px !important",
    // backgroundColor:"#1C1C1C !important",
    // opacity:"0.5"
  },
 bg:{
    backgroundColor:"#1C1C1C !important",
 }
}));

function NewsSkeleton({}) {
  const classes = useStyles();
          return (
            <div>
              <Box sx={{ m: 1 }}>
             
                <Grid container >
                  <Grid
                    item
                    xs={5}
                    md={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton width="80%"  height="140%" style={{marginBottom:"1rem"}}  className={classes.bg}>
                      <Avatar  />
                    </Skeleton>
                  </Grid>
                  <Grid item xs={7} md={7}>
                        <Skeleton width="85%"  className={classes.bg}/>
                        <Skeleton width="90%"  className={classes.bg}/>
                        <Skeleton width="70%"  className={classes.bg}/>
                        <Grid container style={{margin:'4px 0'}}>
                            <Grid item xs={7} md={7}>
                            <Skeleton width="70%" height={20}  className={classes.bg}/>
                            </Grid>
                        </Grid>
                        <Skeleton width="110%" height={5}  className={classes.bg}/>
                        <Grid container style={{margin:'4px 0'}}>
                            <Grid item xs={4} md={4}>
                            <Skeleton width="80%" height={20}  className={classes.bg}/>
                            </Grid>
                          
                            <Grid item  xs={3} md={3}>
                            <Skeleton width="80%" height={20}  className={classes.bg}/>
                            </Grid>
                        </Grid>
                  </Grid>
                </Grid>
              </Box>
                <Skeleton width="100%" height={5}  className={classes.bg}/>
            </div>
          );
   

}

export default NewsSkeleton;
