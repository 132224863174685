import axios from "axios";
import { helpers } from "../_helpers/index";
 

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const userService = {
  getUserDetails,
  getUserBankAccounts,
  getUserDemat,
  updateFcmToken,
  getHoliday,
  createTicket,
  getTickets,
  getObjectUrl
};

function getUserDetails(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/users/basic_info/${id}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}
function getUserBankAccounts(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/user/bank/?user_id=${id}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

function getUserDemat(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/demat/?user_id=${id}`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}
 function updateFcmToken(data){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/updateUsersFcmToken`, data)
      .then(res => res)
      .catch(error => error.response)
}

function getHoliday() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getHolidayForMobile`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}


function createTicket(data){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.post(`/user/tickets`, data,requestOptions)
      .then(res => res)
      .catch(error => error.response)
}

function getTickets(userId){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.get(`/user/tickets/${userId}`,requestOptions)
      .then(res => res)
      .catch(error => error.response)
}

function getObjectUrl(id){
  helpers.setHeader();
  const requestOptions = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
  return axios.get(`/getPresignedUrlByFileID/?id=${id}`, requestOptions)
      .then(data => data)
      .catch(error => error.response)
}

