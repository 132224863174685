import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import Edit from "@mui/icons-material/Edit";
import EditImg from "../../Images/Edit.svg";
import InputBase from "@mui/material/InputBase";
import BottomNavigation from "../../component/Home/BottomNavigation";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import FormControl from "@mui/material/FormControl";
import VerifyButton from "../../component/Kyc/VerifyButton"
import Textfield from "../../component/DarkMode/Textfield";
import * as Yup from "yup";
import { Formik } from "formik";
import { kycService } from "../../_services/kyc";
import { connect } from "react-redux";
import HelperText from "../../component/DarkMode/HelperText";


const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    padding: "15px 16px  0px 16px !important",
    minHeight: "calc(100vh - 77px)",
    background: "#0D0D0D !important",
  },
  container: {
    padding: "0px 0px  34px 0px !important",
    backgroundColor: "#0D0D0D !important",
    display: "flex",
    // paddingBottom: "1rem !important",
  },
  icon: {
    fill: "#fff  !important",
    // margin: "10px",
    cursor: "pointer",
    marginLeft: "0px",
  },


  title: {
    fontFamily: "Roboto  !important",
    fontStyle: "normal  !important",
    fontWeight: " 600  !important",
    fontSize: "18px  !important",
    color: "#fff !important",
    lineHeight: "19px !important",
  },


  textfield: {
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      position: "relative",
      width: "100%",
      padding: "10px 60px 10px 16px",
      border: "solid 1px transparent !important",
      backgroundImage:
        "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
      backgroundOrigin: "border-box !important",
      backgroundClip: "content-box, border-box !important",
      boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
      color: " #5F6060",
      fontFamily: "Poppins  !important",
      fontStyle: "normal  !important",
      fontWeight: "400 !important",
      fontSize: "14px  !important",
    },
    margin: "5px 0px 15px 0px !important ",
  },
  label: {
    marginTop: "20px  !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "400 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "18px !important",
  },

  text: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#A489FF !important",
    lineHeight: "22px !important",
  },
  bankname: {
    textTransform: "capitalize !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "22px !important",
  },
  bottom: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  bottom1:{
    marginTop:"10rem !important",
    paddingBottom:'40px  !important'
  },
  button: {
    padding: "12px 44px  !important ",
    backgroundColor: "#6D50CC  !important",
    borderRadius: "10px  !important",
    textTransform: "uppercase !important",
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500 !important",
    fontSize: "16px  !important",
    color: "#FFF !important",
    lineHeight: "24px !important",
  },
  box: {
    padding: "16px",
    borderRadius: "10px",
    border: "solid 1px transparent !important",
    backgroundImage:
      "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
    backgroundOrigin: "border-box !important",
    // backgroundClip: "content-box, border-box !important",
    boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
  }
}));

const AddBank = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [submitButton, setSubmitButton] = useState(true);
  const [error, setError] = useState(false);
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;
  const [isLoading, setIsLoading] = useState(false);

  const [bankIfsc, setBankId] = useState(
    location != undefined
      ? location.state != undefined
        ? location.state.bankIfsc
        : null
      : null
  );
  const [bankName, setBankName] = useState(
    location != undefined
      ? location.state != undefined
        ? location.state.bankName
        : null
      : null
  );
  const [accnumber, setAccnumber] = useState();

  // Calling bank api to store user bank details
  const handleOnSubmit = async(values) => {
    setIsLoading(true)
     let bankResponse = await kycService.verifyBankAccount(values.accnum, bankIfsc,userID,bankName)
     setIsLoading(false)
    if(bankResponse.data.success==true){
      navigate("/demat", {
        replace: true,
        state: { id:userID},
      });
    } else if(bankResponse.data.success==false) {
      setError(true)
    }else{
      console.log(error)
    }
  }
  const isKeyboardOpen = useDetectKeyboardOpen();
  return (
    <>
      <Page>
        <Box className={classes.boxcontainer}>
          <Grid className={classes.container} container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/branch")}
                className={classes.icon}
              />
              <Typography
              ml={2.5}
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  lineHeight: "24px",
                }}
              >
                Add Bank Account
              </Typography>
            </Grid>
          </Grid>

          <Grid container xs={12}
            md={12} className={classes.box}>
            <Grid item xs={11} style={{ alignItems: "center" }}>
              <Typography className={classes.bankname}>
                {bankName}
              </Typography>
              <Typography className={classes.text}> IFSC {bankIfsc}</Typography>
            </Grid>
            <Grid item xs={1} style={{ margin: "auto", textAlign: "center" }}>
              <img
                src={EditImg}
                alt=""
                style={{
                  fontSize: "1rem",
                  color: "#AC93FF",
                }}
                onClick={() =>
                  navigate("/bank")
                }
              />
            </Grid>

          </Grid>
          <Formik
            initialValues={{
              accnum: "",
            }}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              accnum: Yup.string()
                .nullable()
                .required("num is required")

            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}

                autoComplete="off"

              >
                <Grid className={classes.container} container flexDirection="column">
                  <Typography
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.label}
                  >
                    Enter Bank Account
                  </Typography>
                  <FormControl variant="standard" style={{ fontFamily: "Poppins" }}>

                    <Textfield
                      type="text"
                      name="accnum"
                      id="accountnumber"
                      value={values.accnum}
                      fullWidth
                      onChange={handleChange}
                      onInput={(e) => {
                        setError(false)
                        e.target.value = e.target.value
                            .toString().replace(/[^0-9]/g, "")
                        if (e.target.value.length >= 9 && e.target.value.length <= 18) {
                          setSubmitButton(false);
                        } else {
                          setSubmitButton(true);
                        }
                      }}
                      placeholder={"012345678912"}
                      valueColor={accnumber}
                      error={Boolean(touched.accnum && errors.accnum)}
                      helperText={touched.accnum && errors.accnum}
                    />
                   {error && <HelperText helperText="Invalid details"  type="error"/>}
                  </FormControl>
                </Grid>
                <Grid className={ isKeyboardOpen ? classes.bottom1: classes.bottom}>
                  {" "}
                  < VerifyButton submitButton={submitButton || isLoading} isLoading={isLoading} text={" Verify"} />

                </Grid>
              </form>
            )}
          </Formik>
        </Box>

        <BottomNavigation />
      </Page>
    </>
  );
};
 
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
 

export default connect(mapStateToProps)(AddBank);