import React from 'react'
import { Grid, Typography, Box, Stack, MenuItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import Page from "../../component/Page/Page";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "../../component/Home/BottomNavigation";

const useStyles = makeStyles((theme) => ({
    boxcontainer: {
    padding: "0 16px !important",
      minHeight: "calc(100vh - 77px)",
      background: "#0D0D0D !important",
    },
    container: {
      backgroundColor: "#0D0D0D !important",
      display: "flex",
    },
    icon: {
      fill: "#fff  !important",
      margin: "10px",
      cursor: "pointer",
      marginLeft: "0px",
    },
    card: {
        margin: "16px auto",
        borderRadius: "10px !important",
        border: "solid 1px transparent !important",
        background: '#1C1C1C',
        display: "flex !important",
        justifyContent: "space-between !important",
        padding: "15px !important",
      },
  }));
const ConfirmBankAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page>
      <Box className={classes.boxcontainer}>
        <Grid className={classes.container} container direction="row">
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom:"15px"
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => navigate("/sell")}
              className={classes.icon}
            />
            <Typography
              variant="h6"
              style={{
                color: "#fff",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "poppins",
              }}
            >
              Confirm Bank Account
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.card} >
            </Grid>
        </Box>
        <BottomNavigation activeTab={2} />
        </Page>

  )
}

export default ConfirmBankAccount